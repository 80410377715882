import * as React from 'react';

import { GoogleMap } from '@react-google-maps/api';

import debounce from '../../../../functions/debounce';

export default function DeckGLGoogleMap(props) {
	const { mobile, setMap, location, bounds } = props;

	const [mapIsRendering, setMapIsRendering] = React.useState();

	const handleAfterRender = debounce(() => {
		setMapIsRendering(false);
	});

	const handleMapLoad = React.useCallback(
		function callback(loadedMap) {
			console.log(location);
			setMap(loadedMap);
		},
		[location, setMap]
	);

	React.useEffect(() => {}, [location]);

	return (
		<GoogleMap
			center={location.coordinates}
			mapContainerStyle={{ height: '100%' }}
			tilt={0}
			zoom={mobile ? 19 : 20}
			onLoad={handleMapLoad}
			options={{
				draggableCursor: 'crosshair',
				mapTypeId: 'satellite',
				rotateControl: false,
				streetViewControl: false,
				minZoom: 17,
				restriction: {
					latLngBounds: {
						north: location.coordinates.lat + 0.003,
						south: location.coordinates.lat - 0.003,
						west: location.coordinates.lng - 0.003,
						east: location.coordinates.lng + 0.003,
					},
					strictBounds: false,
				},
			}}
		>
			{props.children}
		</GoogleMap>
	);
}

import './animations.css';

export default function SignalAnimation(props) {
	const { animationClass } = props;

	return (
		<svg
			version='1.1'
			id='signal'
			xmlns='http://www.w3.org/2000/svg'
			x='0px'
			y='0px'
			viewBox='2.2 5 15.6 11.3'
			height='50%'
			width='100%'
		>
			<path
				id='signal3'
				className={animationClass}
				fill='#808080'
				fillOpacity='1'
				d='M9.9,5C6.8,5,4,6.4,2.2,8.7l1.1,1.1c1.6-2,4-3.2,6.7-3.2c2.7,0,5.1,1.3,6.7,3.2l1.1-1.1 		C15.8,6.4,13,5,9.9,5z'
			></path>
			<path
				id='signal2'
				className={animationClass}
				fill='#808080'
				fillOpacity='1'
				d='M9.9,8c-2.3,0-4.3,1.1-5.6,2.8l1.1,1.1c1-1.4,2.6-2.4,4.5-2.4c1.9,0,3.5,0.9,4.5,2.4l1.1-1.1 		C14.2,9.1,12.2,8,9.9,8z'
			></path>
			<path
				id='signal1'
				className={animationClass}
				fill='#808080'
				fillOpacity='1'
				d='M9.9,11c-1.5,0-2.7,0.8-3.4,2l1.1,1.1c0.4-0.9,1.3-1.6,2.3-1.6s2,0.7,2.3,1.6l1.1-1.1 		C12.6,11.8,11.4,11,9.9,11z'
			></path>
			<circle
				id='dot'
				className={animationClass}
				fill='#808080'
				fillOpacity='1'
				cx='9.9'
				cy='15.3'
				r='1'
			></circle>
		</svg>
	);
}

import * as React from 'react';

import PlacesAutocomplete from './PlacesAutocomplete';
import LandingMap from './map/LandingMap';
import SignupInfoAlert from './SignupInfoAlert';
import PlanCard from './PlanCard';
import PropertyTypeSelect from './PropertyTypeSelect';
import CheckoutForm from './CheckoutForm';
import ScreeningForm from './ScreeningForm';
import footerImg from '../../../assets/images/bg2.png';

import { useLoadScript } from '@react-google-maps/api';

import {
	Backdrop,
	Button,
	Box,
	CircularProgress,
	Container,
	Fade,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';

import * as turf from '@turf/turf';
import { VectorTile } from '@mapbox/vector-tile';
import Protobuf from 'pbf';

export const OrderContext = React.createContext();

export default function LandingPage(props) {
	/* OrderContext Values */
	const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
	const [userProgress, setUserProgress] = React.useState(0);
	// 0 - start
	// 1 - select property type
	// 2 - place marker
	// 3 - confirm location
	// 4 - location confirmed
	// 5 - failed signal check
	// 6 - passed signal check
	// 7 - order review
	const [allScreeningAnswered, setAllScreeningAnswered] = React.useState(false);
	const [screeningAnswers, setScreeningAnswers] = React.useState([]);
	const [location, setLocation] = React.useState();
	const [propertyType, setPropertyType] = React.useState();
	const [currentService, setCurrentService] = React.useState();
	const [markerPos, setMarkerPos] = React.useState();
	const [plan, setPlan] = React.useState('placeholder');
	const [fullName, setFullName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [serviceLine1, setServiceLine1] = React.useState('');
	const [serviceLine2, setServiceLine2] = React.useState('');
	const [serviceCity, setServiceCity] = React.useState('');
	const [serviceCounty, setServiceCounty] = React.useState('');
	const [serviceState, setServiceState] = React.useState('');
	const [serviceZip, setServiceZip] = React.useState('');
	const [shippingLine1, setshippingLine1] = React.useState('');
	const [shippingLine2, setShippingLine2] = React.useState('');
	const [shippingCity, setShippingCity] = React.useState('');
	const [shippingState, setShippingState] = React.useState('');
	const [shippingZip, setShippingZip] = React.useState('');
	const [cable, setCable] = React.useState('');

	const [libraries] = React.useState(['places', 'maps']);
	const [overlay] = React.useState(new GoogleMapsOverlay(props));
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: 'AIzaSyDUWuMZqUY3VME4BYizglLOLKlD8Wn0IgA',
		libraries,
	});

	// React.useEffect(() => {
	// 	console.log(signalCheckProgress);
	// }, [signalCheckProgress]);

	React.useEffect(() => {
		console.log('userProgress', userProgress);
	}, [userProgress]);

	React.useEffect(() => {
		if (location) {
			setUserProgress(1);
			setServiceLine1(
				`${
					location.address_components.find((item) =>
						item.types.includes('street_number')
					).short_name
				} ${
					location.address_components.find((item) =>
						item.types.includes('route')
					).short_name
				}`
			);
			setServiceCity(
				location.address_components.find((item) =>
					item.types.includes('locality')
				).short_name
			);
			setServiceState(
				location.address_components.find((item) =>
					item.types.includes('administrative_area_level_1')
				).short_name
			);
			setServiceCounty(
				location.address_components.find((item) =>
					item.types.includes('administrative_area_level_2')
				).short_name
			);
			const zip = location.address_components.find((item) =>
				item.types.includes('postal_code')
			).short_name;
			const zipSuffix = location.address_components.find((item) =>
				item.types.includes('postal_code_suffix')
			).short_name;
			setServiceZip(`${zip}${zipSuffix ? '-' + zipSuffix : ''}`);
		}
	}, [location]);

	const getSignalFeatures = () => {
		// setSignalCheckProgress(0);
		const start = Date.now();

		var allLayers = [];
		var allFeatures = turf.bboxPolygon([0, 0, 0, 0]);
		var radius = turf.circle([markerPos.lng, markerPos.lat], 0.0189394, {
			steps: 8,
			units: 'miles',
		});

		const url = new URL(
			'https://hod8dxj546.execute-api.us-west-2.amazonaws.com/dev/hello-world'
		);
		const bounds = {
			_sw: {
				lat: location.coordinates.lat - 0.003,
				lng: location.coordinates.lng - 0.003,
			},
			_ne: {
				lat: location.coordinates.lat + 0.003,
				lng: location.coordinates.lng + 0.003,
			},
		};

		url.search = new URLSearchParams(
			`bounds=${JSON.stringify(bounds)}`
		).toString();

		fetch(url)
			.then((response) => response.json())
			.then(async (response) => {
				// console.log('signalCheck', response);
				const n = 2 ** 15;
				const upperX = Math.floor(((markerPos.lng + 180.003) / 360) * n);
				const lowerX = Math.floor(((markerPos.lng + 179.997) / 360) * n);
				const lowerY = Math.floor(
					((1 -
						Math.log(
							Math.tan(((markerPos.lat + 0.003) * Math.PI) / 180) +
								1 / Math.cos(((markerPos.lat + 0.003) * Math.PI) / 180)
						) /
							Math.PI) /
						2) *
						n
				);
				const upperY = Math.floor(
					((1 -
						Math.log(
							Math.tan(((markerPos.lat - 0.003) * Math.PI) / 180) +
								1 / Math.cos(((markerPos.lat - 0.003) * Math.PI) / 180)
						) /
							Math.PI) /
						2) *
						n
				);
				// console.log(lowerX, upperX, lowerY, upperY);
				// let progress = 0;
				// var signalCheckInterval = setInterval(() => {
				// 	setSignalCheckProgress(
				// 		normalize(progress, 0, response.data.length) / 2
				// 	);
				// }, 1000);
				for (let i = 0; i < response.data.length; i++) {
					if (
						response.data[i].sgmp_cpe_height === '20' ||
						response.data[i].sgmp_cpe_height === '10'
					) {
						for (let j = lowerX; j <= upperX; j++) {
							for (let k = lowerY; k <= upperY; k++) {
								try {
									const tileResponse = await fetch(
										`https://assets.digitalpath.info/${response.data[i].sgmp_site_id}/${response.data[i].sgmp_device_heading}/${response.data[i].sgmp_cpe_height}/15/${j}/${k}.pbf`
									);
									if (tileResponse.ok) {
										const pbfData = await tileResponse.arrayBuffer();
										const pbf = new Protobuf(pbfData);
										const tile = new VectorTile(pbf);
										allLayers.push({
											layer: tile.layers[response.data[i].sgmp_site_id],
											j: j,
											k: k,
										});
									}
								} catch (error) {
									console.log(error);
								}
							}
						}
					}
					// progress++;
					// if (progress % 3 === 0) {
					// 	flushSync(() => {
					// 		setSignalCheckProgress(
					// 			normalize(progress, 0, response.data.length) / 2
					// 		);
					// 	});
					// }
				}

				// console.log(allLayers);
				// let total = 0;
				// progress = 0;
				// for (let i = 0; i < allLayers.length; i++) {
				// 	total += allLayers[i].layer.length;
				// }
				for (let i = 0; i < allLayers.length; i++) {
					for (let n = 0; n < allLayers[i].layer.length; n++) {
						const feature = allLayers[i].layer
							.feature(n)
							.toGeoJSON(allLayers[i].j, allLayers[i].k, 15);
						if (!turf.booleanDisjoint(feature, radius)) {
							allFeatures = turf.union(feature, allFeatures);
						}
						// progress++;
						// if (progress % 10000 === 0) {
						// 	flushSync(() => {
						// 		setSignalCheckProgress(50 + normalize(progress, 0, total) / 2);
						// 	});
						// }
					}
					// progress++;
					// flushSync(() => {
					// 	setSignalCheckProgress(
					// 		50 + normalize(progress, 0, allLayers.length) / 2
					// 	);
					// });
				}
				// console.log(allFeatures);
				// console.log(
				// 	turf.area(turf.intersect(allFeatures, radius)),
				// 	turf.area(radius),
				// 	turf.area(turf.intersect(allFeatures, radius)) / turf.area(radius)
				// );
				if (
					turf.area(turf.intersect(allFeatures, radius)) / turf.area(radius) >=
					0.3
				) {
					setUserProgress(6);
				} else {
					setUserProgress(5);
				}
				// setSignalCheckProgress(100);
				console.log(`runtime: ${Date.now() - start}ms`);
			});
	};

	const handleConfirmLocation = () => {
		const point = overlay._overlay
			.getProjection()
			.fromLatLngToContainerPixel(markerPos);
		const layersUnderMarker = overlay.pickMultipleObjects({
			x: point.x,
			y: point.y,
		});
		const layerIds = layersUnderMarker.map((layer) => layer.layer.id);
		console.log(layersUnderMarker);
		if (layerIds.includes('high-res-lidar')) {
			setUserProgress(4);
			getSignalFeatures();
		} else {
			setUserProgress(5);
		}
	};

	// const normalize = (value, min, max) => ((value - min) * 100) / (max - min);

	return (
		<OrderContext.Provider
			value={{
				mobile,
				userProgress,
				setUserProgress,
				screeningAnswers,
				setScreeningAnswers,
				location,
				setLocation,
				propertyType,
				setPropertyType,
				currentService,
				setCurrentService,
				markerPos,
				setMarkerPos,
				plan,
				setPlan,
				fullName,
				setFullName,
				email,
				setEmail,
				phone,
				setPhone,
				serviceLine1,
				setServiceLine1,
				serviceLine2,
				setServiceLine2,
				serviceCity,
				setServiceCity,
				serviceCounty,
				setServiceCounty,
				serviceState,
				setServiceState,
				serviceZip,
				setServiceZip,
				shippingLine1,
				setshippingLine1,
				shippingLine2,
				setShippingLine2,
				shippingCity,
				setShippingCity,
				shippingState,
				setShippingState,
				shippingZip,
				setShippingZip,
				cable,
				setCable,
			}}
		>
			<Box sx={{ position: 'relative', minHeight: '100vh' }}>
				<Container
					maxWidth='md'
					sx={{
						paddingTop: '1em',
						paddingBottom: '164px',
					}}
				>
					{((mobile && userProgress === 0) || !mobile) && (
						<Typography variant='h3' align='center' gutterBottom>
							Gen8 Self-Install Service Beta
						</Typography>
					)}
					{/* <ul>
					<li>
						<Typography>
							This self-install program is for new customers and existing
							customers that want to upgrade. You must have some basic
							installation knowledge or a friend or family member that can
							assist you.
						</Typography>
					</li>
					<li>
						<Typography>
							The antenna that comes with the kit must be pointed at the tower
							and installed with line of sight or be in the signal area
							displayed on the map.
						</Typography>
					</li>
					<li>
						<Typography>
							Elevation matters - generally, the higher the antenna is off the
							ground, the better the odds are for installation. Select a
							mounting location and mount type that you are comfortable
							installing. This selection, along with your address, are used to
							find the expected signal at your location. You can change these on
							the following page as well.
						</Typography>
					</li>
					<li>
						<Typography>
							Once you receive the self-install kit, make sure you have signal
							before permanently installing the antenna and running the cable.
							Once signal is verified, permanently install the antenna, run the
							cable into the premises, and follow the setup guide in the
							instructions.
						</Typography>
					</li>
				</ul> */}
					{isLoaded && userProgress === 0 && (
						<React.Fragment>
							<ScreeningForm
								setScreeningAnswers={setScreeningAnswers}
								setAllScreeningAnswered={setAllScreeningAnswered}
							/>
							{allScreeningAnswered && (
								<PlacesAutocomplete setLocation={setLocation} />
							)}
						</React.Fragment>
					)}
					{userProgress === 1 && (
						<Fade in={true} timeout={2000}>
							<Box>
								<PropertyTypeSelect
									mobile={mobile}
									setUserProgress={setUserProgress}
									propertyType={propertyType}
									setPropertyType={setPropertyType}
									currentService={currentService}
									setCurrentService={setCurrentService}
								/>
							</Box>
						</Fade>
					)}
					{location && isLoaded && userProgress > 1 && userProgress < 5 && (
						<Fade in={true} timeout={2000}>
							<Box textAlign='center'>
								<Paper
									sx={{
										p: 1,
										aspectRatio: !mobile ? '16/9' : 'unset',
										height: mobile ? '50vh' : 'unset',
										mt: mobile ? '1em' : 'unset',
									}}
								>
									<LandingMap
										mobile={mobile}
										overlay={overlay}
										setUserProgress={setUserProgress}
									>
										{!mobile && (
											<SignupInfoAlert
												userProgress={userProgress}
												handleConfirmLocation={handleConfirmLocation}
											/>
										)}
										{userProgress === 4 && (
											<Backdrop
												sx={{ position: 'absolute', zIndex: 667 }}
												open={true}
											>
												<CircularProgress
													disableShrink
													sx={{ color: 'grey.100', opacity: 0.8 }}
												/>
											</Backdrop>
										)}
									</LandingMap>
								</Paper>
								{mobile && (
									<React.Fragment>
										<Typography mt='1em' textAlign='center'>
											Place a marker on your house or business
										</Typography>
										<Button
											onClick={handleConfirmLocation}
											variant='contained'
											disabled={!markerPos || userProgress === 4}
											sx={{ mt: '1em' }}
										>
											Confirm Location
										</Button>
									</React.Fragment>
								)}
							</Box>
						</Fade>
					)}
					{userProgress > 4 && userProgress < 7 && (
						<Stack
							direction={mobile ? 'column' : 'row'}
							spacing={2}
							justifyContent='center'
							alignItems='center'
						>
							<Fade in={true} timeout={2000}>
								<div>
									<PlanCard setUserProgress={setUserProgress} />
								</div>
							</Fade>
							<Fade
								in={true}
								timeout={2000}
								style={{ transitionDelay: '500ms' }}
							>
								<div>
									<PlanCard setUserProgress={setUserProgress} />
								</div>
							</Fade>
							<Fade
								in={true}
								timeout={2000}
								style={{ transitionDelay: '1000ms' }}
							>
								<div>
									<PlanCard setUserProgress={setUserProgress} />
								</div>
							</Fade>
						</Stack>
					)}
					{userProgress === 7 && (
						<Fade in={true} timeout={2000}>
							<div>
								<CheckoutForm />
							</div>
						</Fade>
					)}
				</Container>
				<Box
					sx={{
						backgroundImage: `url(${footerImg})`,
						backgroundRepeat: 'repeat-x',
						position: 'absolute',
						bottom: 0,
						width: '100%',
						height: '82px',
					}}
				/>
			</Box>
		</OrderContext.Provider>
	);
}

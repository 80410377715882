import * as React from 'react';

import OrderSummary from './OrderSummary';
import CustomMobileStepper from './CustomMobileStepper';
import ContactInfoBox from './ContactInfoBox';
import ServiceInfoBox from './ServiceInfoBox';

import {
	Box,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as Color from '@mui/material/colors';

export default function ContractPage(props) {
	const [unitType, setUnitType] = React.useState('');
	const [cstType, setCstType] = React.useState('');
	const [cstService, setCstService] = React.useState('');

	const steps = [
		{
			label: 'Contact Information',
			content: <ContactInfoBox />,
		},
		{
			label: 'Service Information',
			content: <ServiceInfoBox />,
		},
		{
			label: 'Shipping Information',
			content: 'Shipping Information',
		},
	];

	const handleUnitTypeChange = (e) => {
		setUnitType(e.target.value);
	};

	const handleCstTypeChange = (e) => {
		setCstType(e.target.value);
	};

	const handleCstServiceChange = (e) => {
		setCstService(e.target.value);
	};

	const mobile = true;

	return (
		<React.Fragment>
			{mobile && (
				<React.Fragment>
					<Box
						sx={{
							height: '40vh',
							position: 'relative',
							backgroundColor: Color.grey[200],
						}}
					>
						<Container>
							<OrderSummary />
						</Container>
					</Box>
					<Box
						sx={{
							height: '60vh',
						}}
					>
						<CustomMobileStepper steps={steps} />
					</Box>
				</React.Fragment>
			)}

			{!mobile && <Grid container columns={2}></Grid>}
		</React.Fragment>

		// <Grid container columns={2}>
		// 	<Grid xs={1}>
		// 		<Container maxWidth='lg'>
		// 			<Typography>Order Confirmation</Typography>
		// 			<Grid container spacing={2}>
		// 				<Grid xs={12}>
		// 					<Typography>Contact Information</Typography>
		// 				</Grid>
		// 				<Grid xs={6}>
		// 					<TextField
		// 						label='Contact Email'
		// 						variant='standard'
		// 						sx={{ width: '100%' }}
		// 					/>
		// 				</Grid>
		// 				<Grid xs={6}>
		// 					<TextField
		// 						label='Contact Phone Number'
		// 						variant='standard'
		// 						sx={{ width: '100%' }}
		// 					/>
		// 				</Grid>
		// 				<Grid xs={12}>
		// 					<Typography>Service Information</Typography>
		// 				</Grid>
		// 				<Grid xs={4}>
		// 					<TextField
		// 						label='Full Name'
		// 						variant='standard'
		// 						sx={{ width: '100%' }}
		// 					/>
		// 				</Grid>
		// 				<Grid xs={4}>
		// 					<FormControl variant='standard' sx={{ width: '100%' }}>
		// 						<InputLabel id='cst-type-select-label'>
		// 							New or Existing Customer
		// 						</InputLabel>
		// 						<Select
		// 							labelId='cst-type-select-label'
		// 							id='cst-type-select'
		// 							value={cstType}
		// 							onChange={handleCstTypeChange}
		// 							label='New or Existing Customer'
		// 						>
		// 							<MenuItem value={0}>New Customer / Signup</MenuItem>
		// 							<MenuItem value={1}>Existing Customer / Upgrade</MenuItem>
		// 						</Select>
		// 					</FormControl>
		// 				</Grid>
		// 				<Grid xs={4}>
		// 					<FormControl variant='standard' sx={{ width: '100%' }}>
		// 						<InputLabel id='cst-service-select-label'>
		// 							Current Internet Service at Location
		// 						</InputLabel>
		// 						<Select
		// 							labelId='cst-service-select-label'
		// 							id='cst-service-select'
		// 							value={cstService}
		// 							onChange={handleCstServiceChange}
		// 							label='Current Internet Service at Location'
		// 						>
		// 							<MenuItem value={0}>None</MenuItem>
		// 							<MenuItem value={1}>Yes, Phone Only</MenuItem>
		// 							<MenuItem value={2}>Yes, Internet Only</MenuItem>
		// 							<MenuItem value={3}>Yes, Phone & Internet</MenuItem>
		// 						</Select>
		// 					</FormControl>
		// 				</Grid>
		// 				<Grid xs={10}>
		// 					<Grid xs={6}>
		// 						<TextField
		// 							label='Service Address'
		// 							variant='standard'
		// 							InputProps={{
		// 								readOnly: true,
		// 							}}
		// 							sx={{ width: '100%' }}
		// 						/>
		// 					</Grid>
		// 					<Grid container xs={6}>
		// 						<Grid xs={6}>
		// 							<FormControl variant='standard' sx={{ width: '100%' }}>
		// 								<InputLabel id='unit-type-select-label'>
		// 									Unit Type
		// 								</InputLabel>
		// 								<Select
		// 									labelId='unit-type-select-label'
		// 									id='unit-type-select'
		// 									value={unitType}
		// 									onChange={handleUnitTypeChange}
		// 									label='Unit Type'
		// 								>
		// 									<MenuItem value=''>
		// 										<em>None</em>
		// 									</MenuItem>
		// 									<MenuItem value={1}>Apartment</MenuItem>
		// 									<MenuItem value={3}>Building</MenuItem>
		// 									<MenuItem value={12}>Office</MenuItem>
		// 									<MenuItem value={19}>Space</MenuItem>
		// 									<MenuItem value={21}>Suite</MenuItem>
		// 									<MenuItem value={23}>Unit</MenuItem>
		// 								</Select>
		// 							</FormControl>
		// 						</Grid>
		// 						<Grid xs={6}>
		// 							<TextField
		// 								label='Unit Number'
		// 								variant='standard'
		// 								sx={{ width: '100%' }}
		// 							/>
		// 						</Grid>
		// 					</Grid>
		// 				</Grid>
		// 				<Grid xs={12}>
		// 					<Typography>Shipping Information</Typography>
		// 				</Grid>
		// 			</Grid>
		// 		</Container>
		// 	</Grid>
		// 	<Grid xs={1}></Grid>
		// </Grid>
	);
}

import * as React from 'react';

import {
	Box,
	Button,
	Container,
	ToggleButton,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Add, Block, Business, Home, Phone, Wifi } from '@mui/icons-material';

export default function PropertyTypeSelect(props) {
	const {
		mobile,
		setUserProgress,
		propertyType,
		setPropertyType,
		currentService,
		setCurrentService,
	} = props;

	return (
		<Container sx={{ textAlign: 'center' }}>
			<Box>
				<Typography
					variant='h4'
					sx={{ my: 1, fontSize: mobile ? '1.5rem' : '2.125rem' }}
				>
					Is this a home or business address?
				</Typography>
				<Box
					sx={{ display: 'flex', justifyContent: 'center', columnGap: '3em' }}
				>
					<ToggleButton
						value='home'
						color='primary'
						selected={propertyType === 'home'}
						onChange={() => {
							setPropertyType('home');
						}}
					>
						<Home />
						&nbsp;Home
					</ToggleButton>
					<ToggleButton
						value='biz'
						color='primary'
						selected={propertyType === 'biz'}
						onChange={() => {
							setPropertyType('biz');
						}}
					>
						<Business />
						&nbsp;Business
					</ToggleButton>
				</Box>
			</Box>

			<Box my={2}>
				<Typography
					variant='h4'
					sx={{ mb: 1, fontSize: mobile ? '1.5rem' : '2.125rem' }}
				>
					What kind of service do you currently have at this address?
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						columnGap: '3em',
						flexDirection: mobile ? 'column' : 'row',
						rowGap: '0.5em',
					}}
				>
					<ToggleButton
						value='both'
						color='primary'
						selected={currentService === 'both'}
						onChange={() => {
							setCurrentService('both');
						}}
					>
						<Grid container columns={2} direction='column' alignItems='center'>
							<Grid xs={1}>
								<Wifi />
								<Add />
								<Phone />
							</Grid>
							<Grid xs={1} width='inherit'>
								Both Internet and Phone
							</Grid>
						</Grid>
					</ToggleButton>
					<ToggleButton
						value='internet'
						color='primary'
						selected={currentService === 'internet'}
						onChange={() => {
							setCurrentService('internet');
						}}
					>
						<Grid container columns={2} direction='column' alignItems='center'>
							<Grid xs={1}>
								<Wifi />
							</Grid>
							<Grid xs={1} width='inherit'>
								Just Internet
							</Grid>
						</Grid>
					</ToggleButton>
					<ToggleButton
						value='phone'
						color='primary'
						selected={currentService === 'phone'}
						onChange={() => {
							setCurrentService('phone');
						}}
					>
						<Grid container columns={2} direction='column' alignItems='center'>
							<Grid xs={1}>
								<Phone />
							</Grid>
							<Grid xs={1} width='inherit'>
								Just Phone
							</Grid>
						</Grid>
					</ToggleButton>
					<ToggleButton
						value='none'
						color='primary'
						selected={currentService === 'none'}
						onChange={() => {
							setCurrentService('none');
						}}
					>
						<Grid container columns={2} direction='column' alignItems='center'>
							<Grid xs={1}>
								<Block />
							</Grid>
							<Grid xs={1} width='inherit'>
								None
							</Grid>
						</Grid>
					</ToggleButton>
				</Box>
			</Box>

			<Button
				onClick={() => setUserProgress(2)}
				variant='contained'
				disabled={!(propertyType && currentService)}
			>
				Confirm
			</Button>
		</Container>
	);
}

import * as React from 'react';

import { TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function ContactInfoBox(props) {
	return (
		<React.Fragment>
			<Typography>Contact Information</Typography>
			<Grid container columns={2} spacing={2}>
				<Grid xs={1}>
					<TextField
						label='First Name'
						variant='standard'
						SelectProps={{ native: true }}
						sx={{ width: '100%' }}
					/>
				</Grid>
				<Grid xs={1}>
					<TextField
						label='Last Name'
						variant='standard'
						SelectProps={{ native: true }}
						sx={{ width: '100%' }}
					/>
				</Grid>
				<Grid xs={2}>
					<TextField
						label='Email Address'
						variant='standard'
						SelectProps={{ native: true }}
						sx={{ width: '100%' }}
					/>
				</Grid>
				<Grid xs={2}>
					<TextField
						label='Phone Number'
						variant='standard'
						SelectProps={{ native: true }}
						sx={{ width: '100%' }}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

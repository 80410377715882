import * as React from 'react';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';

export default function DeckGLOverlay(props) {
	// console.log('DeckGLOverlay render');
	const { map, overlay } = props;
	overlay.setMap(map);
	overlay.setProps(props);

	return null;
}

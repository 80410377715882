import * as React from 'react';

import { Box, Button, MobileStepper, Paper, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import * as Color from '@mui/material/colors';

export default function CustomMobileStepper(props) {
	const { steps } = props;
	console.log(steps);

	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
		if (activeStep < steps.length) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				flexGrow: 1,
				zIndex: 665,
				bottom: 0,
			}}
		>
			<Paper
				square
				elevation={0}
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: 50,
					pl: 2,
					backgroundColor: Color.grey[100],
				}}
			>
				<Typography sx={{ fontWeight: 'bold' }}>
					{steps[activeStep].label}
				</Typography>
			</Paper>
			<Box
				sx={{
					flexGrow: 1,
					flexShrink: 1,
					p: 2,
					overflow: 'auto',
					backgroundColor: '#fff',
				}}
			>
				{steps[activeStep].content}
			</Box>
			<MobileStepper
				variant='dots'
				steps={steps.length}
				activeStep={activeStep}
				nextButton={
					<Button
						size='small'
						onClick={handleNext}
						disabled={activeStep === steps.length}
					>
						{activeStep !== steps.length - 1 ? 'Next' : 'Finish'}
						{theme.direction === 'rtl' ? (
							<KeyboardArrowLeft />
						) : (
							<KeyboardArrowRight />
						)}
					</Button>
				}
				backButton={
					<Button size='small' onClick={handleBack} disabled={activeStep === 0}>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowRight />
						) : (
							<KeyboardArrowLeft />
						)}
						Back
					</Button>
				}
				sx={{ position: 'relative' }}
			/>
		</Box>
	);
}

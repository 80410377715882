import * as React from 'react';

import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	TextField,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export default function ServiceInfoBox(props) {
	return (
		<React.Fragment>
			<Typography>Service Information</Typography>
			<Grid container columns={2} spacing={2}>
				<Grid xs={2}>
					<TextField
						label='Service Address'
						variant='standard'
						InputProps={{ native: true }}
						sx={{ width: '100%' }}
					/>
				</Grid>
				<Grid xs={1}>Unit Type</Grid>
				<Grid xs={1}>
					<TextField
						label='Unit Number'
						variant='standard'
						InputProps={{ native: true }}
						sx={{ width: '100%' }}
					></TextField>
				</Grid>
			</Grid>
			<FormGroup>
				<FormControlLabel
					control={<Checkbox />}
					label='Ship to a different address'
				/>
			</FormGroup>
		</React.Fragment>
	);
}

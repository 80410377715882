import * as React from 'react';

import DeckGLGoogleMap from './DeckGLGoogleMap';
import DeckGLOverlay from './DeckGLOverlay';
import MiscLayers from '../../../layers/MiscLayers';

import calculateBounds from '../../../../functions/calculateBounds';

import * as turf from '@turf/turf';

import { GeoJsonLayer } from '@deck.gl/layers';

import { Marker } from '@react-google-maps/api';

import { OrderContext } from '../LandingPage';

export default function LandingMap(props) {
	const { mobile, location, markerPos, setMarkerPos } =
		React.useContext(OrderContext);
	const { overlay, setUserProgress } = props;

	const [map, setMap] = React.useState(null);
	const [bounds, setBounds] = React.useState();
	const [layers, setLayers] = React.useState([]);
	const [miscLayers, setMiscLayers] = React.useState([]);
	const [radiusLayer, setRadiusLayer] = React.useState();
	const [signalLayers, setSignalLayers] = React.useState([]);

	const handleMapClick = (e) => {
		console.log('click', e);
		setMarkerPos({ lat: e.coordinate[1], lng: e.coordinate[0] });
		setUserProgress(3);
	};

	// React.useEffect(() => {
	// 	setBounds(calculateBounds(location.coordinates, 10000));
	// }, [location, setBounds]);

	React.useEffect(() => {
		if (map) {
			map.panTo(location.coordinates);
		}
	}, [location, map, setUserProgress]);

	React.useEffect(() => {
		if (markerPos) {
			setRadiusLayer(
				new GeoJsonLayer({
					id: 'radius-layer',
					data: turf.circle([markerPos.lng, markerPos.lat], 0.0189394, {
						units: 'miles',
					}),
					stroked: false,
					pickable: false,
					getFillColor: [0, 0, 255, 25],
				})
			);
		}
		console.log('markerPos', markerPos);
	}, [markerPos]);

	React.useEffect(() => {
		console.log(radiusLayer);
		setLayers([miscLayers, radiusLayer]);
	}, [setLayers, miscLayers, radiusLayer]);

	return (
		<React.Fragment>
			<DeckGLGoogleMap
				mobile={mobile}
				setMap={setMap}
				location={location}
				bounds={bounds}
			>
				<DeckGLOverlay
					map={map}
					overlay={overlay}
					layers={layers}
					onClick={handleMapClick}
				/>
				{markerPos && <Marker position={markerPos} />}
				<MiscLayers setMiscLayers={setMiscLayers} />
				{props.children}
			</DeckGLGoogleMap>
		</React.Fragment>
	);
}

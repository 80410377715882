import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Paper, Typography } from '@mui/material';

export default function InstallMetrics(props) {
	const { alignmentScore } = props;

	return (
		<Paper variant='outlined'>
			<Grid
				container
				columns={2}
				// sx={{ border: '1px solid black', borderRadius: '5px' }}
			>
				<Grid xs={2} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
					<Grid container direction='column' columns={2} alignItems='center'>
						<Grid xs={1} fontWeight={500}>
							{alignmentScore ? alignmentScore : 0} / 30
						</Grid>
						<Grid xs={1}>
							<Typography fontSize='0.9em'>Alignment Score</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={1} sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
					<Grid container direction='column' columns={2} alignItems='center'>
						<Grid xs={1} fontWeight={500}>
							15
						</Grid>
						<Grid xs={1} width='auto'>
							<Typography fontSize='0.9em'>Minimum Passing Score</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={1}>
					<Grid container direction='column' columns={2} alignItems='center'>
						<Grid xs={1} fontWeight={500}>
							20
						</Grid>
						<Grid xs={1} width='auto'>
							<Typography fontSize='0.9em'>Recommended Score</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}

import * as React from 'react';

import InstallMetrics from './InstallMetrics';

import { Button, Container, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

export default function InstallInstructions(props) {
	const {
		mobile,
		installProgress,
		errorMsg,
		startMonitoring,
		alignmentScore,
		pathLoss,
		finalize,
	} = props;

	return (
		<Container
			maxWidth='xs'
			sx={{ textAlign: 'center', mt: '0.5em', mb: '0.5em' }}
		>
			{(installProgress === 'failed' || installProgress === 'timed out') && (
				<React.Fragment>
					<Typography>{errorMsg}</Typography>
					<Button
						variant='outlined'
						sx={{ mt: '2em' }}
						onClick={startMonitoring}
					>
						Reconnect
					</Button>
				</React.Fragment>
			)}
			{installProgress === 'connecting' && (
				<Typography>Connecting - this may take a minute...</Typography>
			)}
			{installProgress === 'connected' ||
			installProgress === 'finalizing' ||
			installProgress === 'finalized' ? (
				<React.Fragment>
					{alignmentScore >= 15 ? (
						<Typography color='#00bfff'>
							Alignment Score Verified&nbsp;
							<CheckCircleOutlineOutlinedIcon
								fontSize='larger'
								sx={{ verticalAlign: 'text-top' }}
							/>
						</Typography>
					) : (
						<Typography>Verifying Alignment Score...</Typography>
					)}
					{pathLoss && pathLoss[0] <= 143 && pathLoss[1] <= 143 ? (
						<Typography color='#00bfff'>
							Signal Quality Verified&nbsp;
							<CheckCircleOutlineOutlinedIcon
								fontSize='larger'
								sx={{ verticalAlign: 'text-top' }}
							/>
						</Typography>
					) : (
						<Typography>Verifying Signal Quality...</Typography>
					)}
					{installProgress === 'finalizing' && (
						<Typography>Verifying Speeds...</Typography>
					)}
					{installProgress === 'finalized' && (
						<Typography color='#00bfff'>
							Speeds Verified&nbsp;
							<CheckCircleOutlineOutlinedIcon
								fontSize='larger'
								sx={{ verticalAlign: 'text-top' }}
							/>
						</Typography>
					)}
					<InstallMetrics alignmentScore={alignmentScore} />
					{installProgress !== 'finalized' &&
					installProgress !== 'finalizing' ? (
						<React.Fragment>
							<Typography textAlign='left' margin='0.5em' fontSize='inherit'>
								Adjust horizontal and vertical tilt to maximize your score, then
								lock in your device and verify speeds to complete installation.
							</Typography>
							{alignmentScore &&
								pathLoss &&
								pathLoss[0] <= 143 &&
								pathLoss[1] <= 143 && (
									<Button
										variant='outlined'
										size={mobile ? 'small' : 'medium'}
										onClick={finalize}
									>
										Finalize
									</Button>
								)}
						</React.Fragment>
					) : (
						installProgress === 'finalized' && (
							<Typography fontSize='1.5em'>Installation Complete!</Typography>
						)
					)}
				</React.Fragment>
			) : null}
		</Container>
	);
}

import * as React from 'react';

import { Alert, Button } from '@mui/material';

export default function SignupInfoAlert(props) {
	const { userProgress, handleConfirmLocation } = props;

	const [severity, setSeverity] = React.useState('info');
	const [action, setAction] = React.useState();
	const [message, setMessage] = React.useState(
		'Place a marker on your house or business'
	);

	React.useEffect(() => {
		if (userProgress === 2) {
			setSeverity('info');
			setAction();
			setMessage('Place a marker on your house or business');
		} else if (userProgress === 3) {
			setSeverity('success');
			setAction(
				<Button
					onClick={handleConfirmLocation}
					color='inherit'
					size='small'
					variant='outlined'
				>
					Confirm Location
				</Button>
			);
			setMessage('Place a marker on your house or business');
		} else if (userProgress === 4) {
			setSeverity('success');
			setAction();
			setMessage('Place a marker on your house or business');
		} else if (userProgress === 5) {
			setSeverity('warning');
			setAction();
			setMessage(
				"We can't guarantee that this location is eligible for self-install."
			);
		}
	}, [userProgress, setSeverity, setAction, setMessage]);

	return (
		<Alert
			severity={severity}
			action={action}
			sx={{
				position: 'absolute',
				bottom: 30,
				left: 10,
				zIndex: 666,
			}}
		>
			{message}
		</Alert>
	);
}

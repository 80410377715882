import * as React from 'react';

import { Box, ToggleButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export default function ScreeningForm(props) {
	const { setScreeningAnswers, setAllScreeningAnswered } = props;
	const [powerTools, setPowerTools] = React.useState();
	const [roof, setRoof] = React.useState();
	const [permission, setPermission] = React.useState();
	const [outlet, setOutlet] = React.useState();

	React.useEffect(() => {
		let answers = [];
		if (powerTools) {
			answers.push('powerTools');
		}
		if (roof) {
			answers.push('roof');
		}
		if (permission) {
			answers.push('permission');
		}
		if (outlet) {
			answers.push('outlet');
		}
		setScreeningAnswers(answers);
		if (
			powerTools !== undefined &&
			roof !== undefined &&
			permission !== undefined &&
			outlet !== undefined
		) {
			setAllScreeningAnswered(true);
		}
	}, [
		setScreeningAnswers,
		setAllScreeningAnswered,
		powerTools,
		roof,
		permission,
		outlet,
	]);

	return (
		<React.Fragment>
			<Typography gutterBottom>
				This is a beta program designed to allow for faster install times
				without having to schedule an appointment. This self-install program is
				for new customers and existing customers that want to upgrade. You must
				have some basic installation knowledge or a friend or family member that
				can assist you. Please answer some questions to help us help you with
				your order and installation:
			</Typography>
			<Grid container columns={1} rowSpacing={2} sx={{ textAlign: 'center' }}>
				<Grid xs={1}>
					<Typography variant='h5'>
						Do you have access to power tools and would you be willing to use
						them?
					</Typography>
					<Box
						sx={{ display: 'flex', justifyContent: 'center', columnGap: '3em' }}
					>
						<ToggleButton
							value={true}
							color='primary'
							selected={powerTools}
							onChange={() => {
								setPowerTools(true);
							}}
						>
							Yes
						</ToggleButton>
						<ToggleButton
							value={false}
							color='primary'
							selected={powerTools === false}
							onChange={() => {
								setPowerTools(false);
							}}
						>
							No
						</ToggleButton>
					</Box>
				</Grid>
				<Grid xs={1}>
					<Typography variant='h5'>
						Do you have access to the service location's roof and would you be
						willing to get on it yourself?
					</Typography>
					<Box
						sx={{ display: 'flex', justifyContent: 'center', columnGap: '3em' }}
					>
						<ToggleButton
							value={true}
							color='primary'
							selected={roof}
							onChange={() => {
								setRoof(true);
							}}
						>
							Yes
						</ToggleButton>
						<ToggleButton
							value={false}
							color='primary'
							selected={roof === false}
							onChange={() => {
								setRoof(false);
							}}
						>
							No
						</ToggleButton>
					</Box>
				</Grid>
				<Grid xs={1}>
					<Typography variant='h5'>
						Do you have permission to modify the service property for
						installation?
					</Typography>
					<Box
						sx={{ display: 'flex', justifyContent: 'center', columnGap: '3em' }}
					>
						<ToggleButton
							value={true}
							color='primary'
							selected={permission}
							onChange={() => {
								setPermission(true);
							}}
						>
							Yes
						</ToggleButton>
						<ToggleButton
							value={false}
							color='primary'
							selected={permission === false}
							onChange={() => {
								setPermission(false);
							}}
						>
							No
						</ToggleButton>
					</Box>
				</Grid>
				<Grid xs={1}>
					<Typography variant='h5'>
						Do you have access to electrical power outlets at the service
						location?
					</Typography>
					<Box
						sx={{ display: 'flex', justifyContent: 'center', columnGap: '3em' }}
					>
						<ToggleButton
							value={true}
							color='primary'
							selected={outlet}
							onChange={() => {
								setOutlet(true);
							}}
						>
							Yes
						</ToggleButton>
						<ToggleButton
							value={false}
							color='primary'
							selected={outlet === false}
							onChange={() => {
								setOutlet(false);
							}}
						>
							No
						</ToggleButton>
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

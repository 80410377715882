import * as React from 'react';

import AppContentArea from './components/AppContentArea';

function App() {
	console.log('App render');

	return <AppContentArea />;
}

export default App;

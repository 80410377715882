import * as React from 'react';

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import LockIcon from '@mui/icons-material/Lock';

import { OrderContext } from './LandingPage';

export default function CheckoutForm(props) {
	const {
		mobile,
		propertyType,
		screeningAnswers,
		currentService,
		markerPos,
		plan,
		fullName,
		setFullName,
		email,
		setEmail,
		phone,
		setPhone,
		serviceLine1,
		serviceLine2,
		setServiceLine2,
		serviceZip,
		setServiceZip,
		serviceCity,
		serviceCounty,
		serviceState,
		shippingLine1,
		setshippingLine1,
		shippingLine2,
		setShippingLine2,
		shippingCity,
		setShippingCity,
		shippingState,
		setShippingState,
		shippingZip,
		setShippingZip,
		cable,
		setCable,
	} = React.useContext(OrderContext);
	const [separateShipping, setSeparateShipping] = React.useState(false);
	const [readyToPlace, setReadyToPlace] = React.useState(false);
	const [inputErrors, setInputErrors] = React.useState([]);
	const [awaitingResponse, setAwaitingResponse] = React.useState(false);
	const [submissionFailed, setSubmissionFailed] = React.useState(false);
	const [submissionCompleted, setSubmissionCompleted] = React.useState(false);

	React.useEffect(() => {
		if (fullName !== '' && email !== '' && phone !== '' && cable) {
			if (separateShipping) {
				if (
					shippingLine1 !== '' &&
					shippingCity !== '' &&
					shippingState !== '' &&
					shippingZip !== ''
				) {
					setReadyToPlace(true);
				} else {
					setReadyToPlace(false);
				}
			} else {
				setReadyToPlace(true);
			}
		} else {
			setReadyToPlace(false);
		}
	}, [
		fullName,
		email,
		phone,
		separateShipping,
		shippingLine1,
		shippingCity,
		shippingState,
		shippingZip,
		cable,
	]);

	const handleNameChange = (e) => {
		setFullName(e.target.value);
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
		if (inputErrors.includes('email')) {
			const emailRegex = new RegExp(
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
			);
			if (emailRegex.test(e.target.value)) {
				let errors = inputErrors.filter((e) => e !== 'email');
				setInputErrors(errors);
			}
		}
	};

	const handlePhoneChange = (e) => {
		setPhone(e.target.value);
		if (inputErrors.includes('phone')) {
			const phoneRegex = new RegExp(
				/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			);
			if (phoneRegex.test(e.target.value)) {
				let errors = inputErrors.filter((e) => e !== 'phone');
				setInputErrors(errors);
			}
		}
	};

	const handleServiceLine2Change = (e) => {
		setServiceLine2(e.target.value);
	};

	const handleServiceZipChange = (e) => {
		setServiceZip(e.target.value);
	};

	const handleSeparateShippingChange = (e) => {
		setSeparateShipping(!e.target.checked);
	};

	const handleshippingLine1Change = (e) => {
		setshippingLine1(e.target.value);
	};

	const handleShippingLine2Change = (e) => {
		setShippingLine2(e.target.value);
	};

	const handleShippingCityChange = (e) => {
		setShippingCity(e.target.value);
	};

	const handleShippingStateChange = (e) => {
		setShippingState(e.target.value);
	};

	const handleShippingZipChange = (e) => {
		setShippingZip(e.target.value);
		if (inputErrors.includes('zip')) {
			const zipRegex = new RegExp(/^\d{5}([ \-]\d{4})?$/);
			if (zipRegex.test(e.target.value)) {
				let errors = inputErrors.filter((e) => e !== 'zip');
				setInputErrors(errors);
			}
		}
	};

	const handleCableChange = (e) => {
		setCable(e.target.value);
	};

	const handlePlaceOrderClick = () => {
		const emailRegex = new RegExp(
			/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
		);
		const phoneRegex = new RegExp(
			/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
		);
		const zipRegex = new RegExp(/^\d{5}([ \-]\d{4})?$/);
		var errors = [];
		if (!emailRegex.test(email)) {
			errors.push('email');
		}
		if (!phoneRegex.test(phone)) {
			errors.push('phone');
		}
		if (separateShipping && !zipRegex.test(shippingZip)) {
			errors.push('zip');
		}
		if (errors.length !== 0) {
			setInputErrors(errors);
			return;
		} else {
			const data = {
				email: email,
				fullName: fullName,
				phone: phone,
				screeningAnswers: screeningAnswers,
				propertyType: propertyType,
				currentService: currentService,
				plan: plan,
				cable: cable,
				markerLat: markerPos.lat,
				markerLng: markerPos.lng,
				serviceAddressLine1: serviceLine1,
				serviceAddressLine2: serviceLine2,
				serviceCity: serviceCity,
				serviceCounty: serviceCounty,
				serviceState: serviceState,
				serviceZip: serviceZip,
				shipAddressLine1: separateShipping ? shippingLine1 : serviceLine1,
				shipAddressLine2: separateShipping ? shippingLine2 : serviceLine2,
				shipCity: separateShipping ? shippingCity : serviceCity,
				shipState: separateShipping ? shippingState : serviceState,
				shipZip: separateShipping ? shippingZip : serviceZip,
			};
			setAwaitingResponse(true);
			fetch('https://selfinstall.dev.digitalpath.info/api/newOrderHandler/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
				.then((response) => {
					if (!response.ok) {
						setSubmissionFailed(true);
						setAwaitingResponse(false);
						throw new Error('HTTP status ' + response.status);
					}
					return response.json();
				})
				.then((response) => {
					console.log(response);
					setSubmissionCompleted(true);
				});
		}
	};

	const usStates = [
		{ name: 'Alabama', abbreviation: 'AL' },
		{ name: 'Alaska', abbreviation: 'AK' },
		{ name: 'Arizona', abbreviation: 'AZ' },
		{ name: 'Arkansas', abbreviation: 'AR' },
		{ name: 'California', abbreviation: 'CA' },
		{ name: 'Colorado', abbreviation: 'CO' },
		{ name: 'Connecticut', abbreviation: 'CT' },
		{ name: 'Delaware', abbreviation: 'DE' },
		{ name: 'Florida', abbreviation: 'FL' },
		{ name: 'Georgia', abbreviation: 'GA' },
		{ name: 'Hawaii', abbreviation: 'HI' },
		{ name: 'Idaho', abbreviation: 'ID' },
		{ name: 'Illinois', abbreviation: 'IL' },
		{ name: 'Indiana', abbreviation: 'IN' },
		{ name: 'Iowa', abbreviation: 'IA' },
		{ name: 'Kansas', abbreviation: 'KS' },
		{ name: 'Kentucky', abbreviation: 'KY' },
		{ name: 'Louisiana', abbreviation: 'LA' },
		{ name: 'Maine', abbreviation: 'ME' },
		{ name: 'Maryland', abbreviation: 'MD' },
		{ name: 'Massachusetts', abbreviation: 'MA' },
		{ name: 'Michigan', abbreviation: 'MI' },
		{ name: 'Minnesota', abbreviation: 'MN' },
		{ name: 'Mississippi', abbreviation: 'MS' },
		{ name: 'Missouri', abbreviation: 'MO' },
		{ name: 'Montana', abbreviation: 'MT' },
		{ name: 'Nebraska', abbreviation: 'NE' },
		{ name: 'Nevada', abbreviation: 'NV' },
		{ name: 'New Hampshire', abbreviation: 'NH' },
		{ name: 'New Jersey', abbreviation: 'NJ' },
		{ name: 'New Mexico', abbreviation: 'NM' },
		{ name: 'New York', abbreviation: 'NY' },
		{ name: 'North Carolina', abbreviation: 'NC' },
		{ name: 'North Dakota', abbreviation: 'ND' },
		{ name: 'Ohio', abbreviation: 'OH' },
		{ name: 'Oklahoma', abbreviation: 'OK' },
		{ name: 'Oregon', abbreviation: 'OR' },
		{ name: 'Pennsylvania', abbreviation: 'PA' },
		{ name: 'Rhode Island', abbreviation: 'RI' },
		{ name: 'South Carolina', abbreviation: 'SC' },
		{ name: 'South Dakota', abbreviation: 'SD' },
		{ name: 'Tennessee', abbreviation: 'TN' },
		{ name: 'Texas', abbreviation: 'TX' },
		{ name: 'Utah', abbreviation: 'UT' },
		{ name: 'Vermont', abbreviation: 'VT' },
		{ name: 'Virginia', abbreviation: 'VA' },
		{ name: 'Washington', abbreviation: 'WA' },
		{ name: 'West Virginia', abbreviation: 'WV' },
		{ name: 'Wisconsin', abbreviation: 'WI' },
		{ name: 'Wyoming', abbreviation: 'WY' },
	];

	return (
		<React.Fragment>
			{!mobile && !submissionCompleted && (
				<Grid container columns={3} spacing={3}>
					<Grid xs={2}>
						<Box sx={{ mb: 2 }}>
							<Typography variant='h5' gutterBottom>
								Contact Information
							</Typography>
							<Grid container columns={2} spacing={1}>
								<Grid xs={2}>
									<TextField
										onChange={handleNameChange}
										value={fullName}
										required
										fullWidth
										label='Full Name'
									/>
								</Grid>
								<Grid xs={1}>
									<TextField
										onChange={handleEmailChange}
										value={email}
										required
										fullWidth
										label='Email Address'
										error={inputErrors.includes('email')}
										helperText={
											inputErrors.includes('email') ? 'Invalid address' : ''
										}
									/>
								</Grid>
								<Grid xs={1}>
									<TextField
										onChange={handlePhoneChange}
										value={phone}
										required
										fullWidth
										label='Phone Number'
										error={inputErrors.includes('phone')}
										helperText={
											inputErrors.includes('phone') ? 'Invalid number' : ''
										}
									/>
								</Grid>
							</Grid>
						</Box>
						<Grid xs={2}>
							<Typography variant='h5' gutterBottom>
								Shipping Information
							</Typography>
							<Grid container columns={6} spacing={1}>
								<Grid xs={3}>
									<TextField
										value={serviceLine1}
										fullWidth
										required
										label='Service Address'
										inputProps={{ readOnly: true }}
									/>
								</Grid>
								<Grid xs={3}>
									<TextField
										onChange={handleServiceLine2Change}
										value={serviceLine2}
										fullWidth
										label='Service Address Line 2'
									/>
								</Grid>
								<Grid xs={2}>
									<TextField
										value={serviceCity}
										fullWidth
										required
										label='City'
										inputProps={{ readOnly: true }}
									/>
								</Grid>
								<Grid xs={2}>
									<FormControl sx={{ minWidth: '100%' }}>
										<InputLabel id='service-state-select-label'>
											State *
										</InputLabel>
										<Select
											labelId='service-state-select-label'
											value={serviceState}
											label='State *'
											inputProps={{ readOnly: true }}
										>
											{usStates.map((usState) => (
												<MenuItem
													key={usState.abbreviation}
													value={usState.abbreviation}
												>
													{usState.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={2}>
									<TextField
										onChange={handleServiceZipChange}
										value={serviceZip}
										fullWidth
										required
										label='Postal Code'
										inputProps={{ readOnly: true }}
									/>
								</Grid>
							</Grid>
							<FormControlLabel
								control={
									<Checkbox
										checked={!separateShipping}
										onChange={handleSeparateShippingChange}
									/>
								}
								label='Ship to service address'
							/>
							{separateShipping && (
								<Grid container columns={6} spacing={1}>
									<Grid xs={3}>
										<TextField
											onChange={handleshippingLine1Change}
											value={shippingLine1}
											fullWidth
											required
											label='Shipping Address Line 1'
										/>
									</Grid>
									<Grid xs={3}>
										<TextField
											onChange={handleShippingLine2Change}
											value={shippingLine2}
											fullWidth
											label='Shipping Address Line 2'
										/>
									</Grid>
									<Grid xs={2}>
										<TextField
											onChange={handleShippingCityChange}
											value={shippingCity}
											fullWidth
											required
											label='City'
										/>
									</Grid>
									<Grid xs={2}>
										<FormControl sx={{ minWidth: '100%' }}>
											<InputLabel id='shippping-state-select-label'>
												State *
											</InputLabel>
											<Select
												labelId='shipping-state-select-label'
												onChange={handleShippingStateChange}
												value={shippingState}
												label='State *'
											>
												{usStates.map((usState) => (
													<MenuItem
														key={usState.abbreviation}
														value={usState.abbreviation}
													>
														{usState.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={2}>
										<TextField
											onChange={handleShippingZipChange}
											value={shippingZip}
											fullWidth
											required
											label='Postal Code'
											error={inputErrors.includes('zip')}
											helperText={
												inputErrors.includes('zip') ? 'Invalid code' : ''
											}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Divider orientation='vertical' flexItem />
					<Grid xs>
						<Typography variant='h5' gutterBottom>
							Order Details
						</Typography>
						<Grid container columns={2} spacing={1} sx={{ mb: 2 }}>
							<Grid xs={1}>
								<Typography>Gen8 Metro 200 Plus</Typography>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>$89.99/mo</Typography>
							</Grid>
							<Grid xs={1}>
								<Typography>Term</Typography>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>12 Months</Typography>
							</Grid>
							<Grid xs={1}>
								<FormControl variant='standard' sx={{ minWidth: '100%' }}>
									<InputLabel id='cable-select-label'>
										Cable Length *
									</InputLabel>
									<Select
										labelId='cable-select-label'
										value={cable}
										onChange={handleCableChange}
										label='Cable Length *'
									>
										<MenuItem value={100}>100ft</MenuItem>
										<MenuItem value={150}>150ft</MenuItem>
										<MenuItem value={200}>200ft</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>FREE</Typography>
							</Grid>
							<Grid xs={1}>
								<Typography>Shipping</Typography>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>FREE</Typography>
							</Grid>
						</Grid>
						<Box sx={{ position: 'relative' }}>
							<Button
								onClick={() => {
									handlePlaceOrderClick();
								}}
								variant='contained'
								fullWidth
								disabled={
									!(readyToPlace && inputErrors.length === 0) ||
									awaitingResponse
								}
								startIcon={<LockIcon />}
							>
								Place Order
							</Button>
							{awaitingResponse && (
								<CircularProgress
									size={24}
									sx={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										mt: '-12px',
										ml: '-12px',
									}}
								/>
							)}
						</Box>
						{submissionFailed && (
							<Typography color='error'>
								There was an issue completing your order. Review your order
								information and try again.
							</Typography>
						)}
					</Grid>
				</Grid>
			)}
			{mobile && !submissionCompleted && (
				<Grid container columns={3} spacing={2} direction='column'>
					<Grid xs={1} sx={{ width: '100%' }}>
						<Typography variant='h5' gutterBottom>
							Order Details
						</Typography>
						<Grid container columns={2} spacing={1} sx={{ mb: 2 }}>
							<Grid xs={1}>
								<Typography>Gen8 Metro 200 Plus</Typography>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>$89.99/mo</Typography>
							</Grid>
							<Grid xs={1}>
								<Typography>Term</Typography>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>12 Months</Typography>
							</Grid>
							<Grid xs={1}>
								<FormControl variant='standard' sx={{ minWidth: '100%' }}>
									<InputLabel id='cable-select-label'>
										Cable Length *
									</InputLabel>
									<Select
										labelId='cable-select-label'
										value={cable}
										onChange={handleCableChange}
										label='Cable Length *'
									>
										<MenuItem value={100}>100ft</MenuItem>
										<MenuItem value={150}>150ft</MenuItem>
										<MenuItem value={200}>200ft</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>FREE</Typography>
							</Grid>
							<Grid xs={1}>
								<Typography>Shipping</Typography>
							</Grid>
							<Grid
								xs={1}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<Typography variant='subtitle2'>FREE</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={1} sx={{ width: '100%' }}>
						<Typography variant='h5' gutterBottom>
							Contact Information
						</Typography>
						<Grid container columns={3} spacing={1} direction='column'>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									onChange={handleNameChange}
									value={fullName}
									required
									fullWidth
									label='Full Name'
								/>
							</Grid>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									onChange={handleEmailChange}
									value={email}
									required
									fullWidth
									label='Email Address'
									error={inputErrors.includes('email')}
									helperText={
										inputErrors.includes('email') ? 'Invalid address' : ''
									}
								/>
							</Grid>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									onChange={handlePhoneChange}
									value={phone}
									required
									fullWidth
									label='Phone Number'
									error={inputErrors.includes('phone')}
									helperText={
										inputErrors.includes('phone') ? 'Invalid number' : ''
									}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={1} sx={{ width: '100%' }}>
						<Typography variant='h5' gutterBottom>
							Shipping Information
						</Typography>
						<Grid container columns={5} spacing={1} direction='column'>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									value={serviceLine1}
									fullWidth
									required
									label='Service Address'
									inputProps={{ readOnly: true }}
								/>
							</Grid>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									onChange={handleServiceLine2Change}
									value={serviceLine2}
									fullWidth
									label='Service Address Line 2'
								/>
							</Grid>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									value={serviceCity}
									fullWidth
									required
									label='City'
									inputProps={{ readOnly: true }}
								/>
							</Grid>
							<Grid xs={1} sx={{ width: '100%' }}>
								<FormControl sx={{ minWidth: '100%' }}>
									<InputLabel id='service-state-select-label'>
										State *
									</InputLabel>
									<Select
										labelId='service-state-select-label'
										value={serviceState}
										label='State *'
										inputProps={{ readOnly: true }}
									>
										{usStates.map((usState) => (
											<MenuItem
												key={usState.abbreviation}
												value={usState.abbreviation}
											>
												{usState.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid xs={1} sx={{ width: '100%' }}>
								<TextField
									onChange={handleServiceZipChange}
									value={serviceZip}
									fullWidth
									required
									label='Postal Code'
									inputProps={{ readOnly: true }}
								/>
							</Grid>
						</Grid>
						<FormControlLabel
							control={
								<Checkbox
									checked={!separateShipping}
									onChange={handleSeparateShippingChange}
								/>
							}
							label='Ship to service address'
						/>
						{separateShipping && (
							<Grid container columns={5} spacing={1}>
								<Grid xs={1} sx={{ width: '100%' }}>
									<TextField
										onChange={handleshippingLine1Change}
										value={shippingLine1}
										fullWidth
										required
										label='Shipping Address Line 1'
									/>
								</Grid>
								<Grid xs={1} sx={{ width: '100%' }}>
									<TextField
										onChange={handleShippingLine2Change}
										value={shippingLine2}
										fullWidth
										label='Shipping Address Line 2'
									/>
								</Grid>
								<Grid xs={1} sx={{ width: '100%' }}>
									<TextField
										onChange={handleShippingCityChange}
										value={shippingCity}
										fullWidth
										required
										label='City'
									/>
								</Grid>
								<Grid xs={1} sx={{ width: '100%' }}>
									<FormControl sx={{ minWidth: '100%' }}>
										<InputLabel id='shippping-state-select-label'>
											State *
										</InputLabel>
										<Select
											labelId='shipping-state-select-label'
											onChange={handleShippingStateChange}
											value={shippingState}
											label='State *'
										>
											{usStates.map((usState) => (
												<MenuItem
													key={usState.abbreviation}
													value={usState.abbreviation}
												>
													{usState.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={1} sx={{ width: '100%' }}>
									<TextField
										onChange={handleShippingZipChange}
										value={shippingZip}
										fullWidth
										required
										label='Postal Code'
										error={inputErrors.includes('zip')}
										helperText={
											inputErrors.includes('zip') ? 'Invalid code' : ''
										}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Box sx={{ position: 'relative', textAlign: 'center' }}>
						<Button
							onClick={() => {
								handlePlaceOrderClick();
							}}
							variant='contained'
							disabled={
								!(readyToPlace && inputErrors.length === 0) || awaitingResponse
							}
							startIcon={<LockIcon />}
						>
							Place Order
						</Button>
						{awaitingResponse && (
							<CircularProgress
								size={24}
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									mt: '-12px',
									ml: '-12px',
								}}
							/>
						)}
					</Box>
					{submissionFailed && (
						<Typography color='error'>
							There was an issue completing your order. Review your order
							information and try again.
						</Typography>
					)}
				</Grid>
			)}
			{submissionCompleted && (
				<Typography>
					Your order has been placed. You will receive a confirmation email
					shortly.
				</Typography>
			)}
		</React.Fragment>
	);
}

import * as React from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function OrderSummary(props) {
	return (
		<React.Fragment>
			<Typography>Order Summary</Typography>
			<Grid container></Grid>
		</React.Fragment>
	);
}

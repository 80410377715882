import * as React from 'react';

import {
	Autocomplete,
	Box,
	Fade,
	LinearProgress,
	TextField,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from 'use-places-autocomplete';
import parse from 'autosuggest-highlight/parse';

import { OrderContext } from './LandingPage';

export default function PlacesAutocomplete(props) {
	const { setLocation } = React.useContext(OrderContext);

	const [isLoading, setIsLoading] = React.useState(false);

	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete({});

	return (
		<Box>
			<Autocomplete
				freeSolo
				disabled={!ready}
				sx={{ p: 1 }}
				getOptionLabel={(option) =>
					typeof option === 'string' ? option : option.description
				}
				filterOptions={(x) => x}
				options={data}
				autoComplete
				includeInputInList
				filterSelectedOptions
				value={value}
				noOptionsText='No addresses found'
				onInputChange={(e) => {
					if (e) setValue(e.target.value);
				}}
				onChange={async (event, option, reason) => {
					if (reason === 'selectOption' && option !== null) {
						setIsLoading(true);
						setValue(option.description);
						const results = await getGeocode({ placeId: option.place_id });
						console.log(results);
						// option.coordinates = getLatLng(results[0]);
						results[0].coordinates = getLatLng(results[0]);
						setLocation(results[0]);
						// console.log(option);
						setIsLoading(false);
						clearSuggestions();
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Enter your address'
						fullWidth
						variant='standard'
					/>
				)}
				renderOption={(props, option) => {
					const matches =
						option.structured_formatting.main_text_matched_substrings || [];

					const parts = parse(
						option.structured_formatting.main_text,
						matches.map((match) => [match.offset, match.offset + match.length])
					);
					return (
						<li {...props}>
							<Grid container alignItems='center'>
								<Grid sx={{ display: 'flex', width: 44 }}>
									<LocationOnIcon />
								</Grid>
								<Grid
									sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
								>
									{parts.map((part, index) => (
										<Box
											key={index}
											component='span'
											sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
										>
											{part.text}
										</Box>
									))}

									<Typography variant='body2'>
										{option.structured_formatting.secondary_text}
									</Typography>
								</Grid>
							</Grid>
						</li>
					);
				}}
			/>
			<Fade in={isLoading} style={{ transitionDelay: '800ms' }}>
				<LinearProgress />
			</Fade>
		</Box>
	);
}

export const highResLidar = {
	type: 'FeatureCollection',
	name: 'high res2',
	crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
	features: [
		{
			type: 'Feature',
			properties: { name: 'High Res' },
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[-120.223388671875, 37.090239803072066, 0.0],
							[-120.21240234375, 37.090239803072066, 0.0],
							[-120.201416015625, 37.090239803072066, 0.0],
							[-120.1904296875, 37.090239803072066, 0.0],
							[-120.179443359375, 37.090239803072066, 0.0],
							[-120.179443359375, 37.099002943876208, 0.0],
							[-120.16845703125, 37.099002943876208, 0.0],
							[-120.157470703125, 37.099002943876208, 0.0],
							[-120.146484375, 37.099002943876208, 0.0],
							[-120.146484375, 37.107765071185142, 0.0],
							[-120.146484375, 37.116526184911187, 0.0],
							[-120.135498046875, 37.116526184911187, 0.0],
							[-120.135498046875, 37.125286284966791, 0.0],
							[-120.12451171875, 37.125286284966791, 0.0],
							[-120.113525390625, 37.125286284966791, 0.0],
							[-120.113525390625, 37.134045371264456, 0.0],
							[-120.113525390625, 37.142803443716836, 0.0],
							[-120.1025390625, 37.142803443716836, 0.0],
							[-120.091552734375, 37.142803443716836, 0.0],
							[-120.08056640625, 37.142803443716836, 0.0],
							[-120.08056640625, 37.151560502236663, 0.0],
							[-120.069580078125, 37.151560502236663, 0.0],
							[-120.05859375, 37.151560502236663, 0.0],
							[-120.047607421875, 37.151560502236663, 0.0],
							[-120.03662109375, 37.151560502236663, 0.0],
							[-120.025634765625, 37.151560502236663, 0.0],
							[-120.025634765625, 37.142803443716836, 0.0],
							[-120.0146484375, 37.142803443716836, 0.0],
							[-120.0146484375, 37.134045371264456, 0.0],
							[-120.0146484375, 37.125286284966791, 0.0],
							[-120.003662109375, 37.125286284966791, 0.0],
							[-120.003662109375, 37.116526184911187, 0.0],
							[-119.99267578125, 37.116526184911187, 0.0],
							[-119.99267578125, 37.107765071185142, 0.0],
							[-119.981689453125, 37.107765071185142, 0.0],
							[-119.981689453125, 37.099002943876208, 0.0],
							[-119.970703125, 37.099002943876208, 0.0],
							[-119.970703125, 37.090239803072066, 0.0],
							[-119.959716796875, 37.090239803072066, 0.0],
							[-119.94873046875, 37.090239803072066, 0.0],
							[-119.94873046875, 37.081475648860518, 0.0],
							[-119.937744140625, 37.081475648860518, 0.0],
							[-119.937744140625, 37.072710481329437, 0.0],
							[-119.9267578125, 37.072710481329437, 0.0],
							[-119.9267578125, 37.063944300566838, 0.0],
							[-119.915771484375, 37.063944300566838, 0.0],
							[-119.90478515625, 37.063944300566838, 0.0],
							[-119.90478515625, 37.055177106660814, 0.0],
							[-119.893798828125, 37.055177106660814, 0.0],
							[-119.893798828125, 37.046408899699564, 0.0],
							[-119.8828125, 37.046408899699564, 0.0],
							[-119.8828125, 37.037639679771402, 0.0],
							[-119.871826171875, 37.037639679771402, 0.0],
							[-119.86083984375, 37.037639679771402, 0.0],
							[-119.86083984375, 37.028869446964741, 0.0],
							[-119.849853515625, 37.028869446964741, 0.0],
							[-119.849853515625, 37.020098201368114, 0.0],
							[-119.8388671875, 37.020098201368114, 0.0],
							[-119.827880859375, 37.020098201368114, 0.0],
							[-119.827880859375, 37.011325943070133, 0.0],
							[-119.81689453125, 37.011325943070133, 0.0],
							[-119.805908203125, 37.011325943070133, 0.0],
							[-119.805908203125, 37.002552672159545, 0.0],
							[-119.794921875, 37.002552672159545, 0.0],
							[-119.794921875, 36.993778388725168, 0.0],
							[-119.783935546875, 36.993778388725168, 0.0],
							[-119.783935546875, 36.985003092855962, 0.0],
							[-119.783935546875, 36.976226784640957, 0.0],
							[-119.77294921875, 36.976226784640957, 0.0],
							[-119.77294921875, 36.96744946416932, 0.0],
							[-119.77294921875, 36.958671131530316, 0.0],
							[-119.77294921875, 36.949891786813275, 0.0],
							[-119.783935546875, 36.949891786813275, 0.0],
							[-119.783935546875, 36.941111430107696, 0.0],
							[-119.783935546875, 36.932330061503137, 0.0],
							[-119.783935546875, 36.923547681089289, 0.0],
							[-119.794921875, 36.923547681089289, 0.0],
							[-119.805908203125, 36.923547681089289, 0.0],
							[-119.805908203125, 36.914764288955929, 0.0],
							[-119.805908203125, 36.905979885192949, 0.0],
							[-119.81689453125, 36.905979885192949, 0.0],
							[-119.827880859375, 36.905979885192949, 0.0],
							[-119.827880859375, 36.897194469890344, 0.0],
							[-119.827880859375, 36.888408043138213, 0.0],
							[-119.827880859375, 36.879620605026759, 0.0],
							[-119.8388671875, 36.879620605026759, 0.0],
							[-119.849853515625, 36.879620605026759, 0.0],
							[-119.86083984375, 36.879620605026759, 0.0],
							[-119.871826171875, 36.879620605026759, 0.0],
							[-119.8828125, 36.879620605026759, 0.0],
							[-119.893798828125, 36.879620605026759, 0.0],
							[-119.90478515625, 36.879620605026759, 0.0],
							[-119.915771484375, 36.879620605026759, 0.0],
							[-119.915771484375, 36.888408043138213, 0.0],
							[-119.915771484375, 36.897194469890344, 0.0],
							[-119.9267578125, 36.897194469890344, 0.0],
							[-119.937744140625, 36.897194469890344, 0.0],
							[-119.937744140625, 36.905979885192949, 0.0],
							[-119.937744140625, 36.914764288955929, 0.0],
							[-119.94873046875, 36.914764288955929, 0.0],
							[-119.94873046875, 36.923547681089289, 0.0],
							[-119.94873046875, 36.932330061503137, 0.0],
							[-119.94873046875, 36.941111430107696, 0.0],
							[-119.959716796875, 36.941111430107696, 0.0],
							[-119.970703125, 36.941111430107696, 0.0],
							[-119.970703125, 36.949891786813275, 0.0],
							[-119.981689453125, 36.949891786813275, 0.0],
							[-119.981689453125, 36.958671131530316, 0.0],
							[-119.981689453125, 36.96744946416932, 0.0],
							[-119.99267578125, 36.96744946416932, 0.0],
							[-120.003662109375, 36.96744946416932, 0.0],
							[-120.0146484375, 36.96744946416932, 0.0],
							[-120.0146484375, 36.976226784640957, 0.0],
							[-120.0146484375, 36.985003092855962, 0.0],
							[-120.0146484375, 36.993778388725168, 0.0],
							[-120.025634765625, 36.993778388725168, 0.0],
							[-120.03662109375, 36.993778388725168, 0.0],
							[-120.047607421875, 36.993778388725168, 0.0],
							[-120.047607421875, 37.002552672159545, 0.0],
							[-120.03662109375, 37.002552672159545, 0.0],
							[-120.03662109375, 37.011325943070133, 0.0],
							[-120.03662109375, 37.020098201368114, 0.0],
							[-120.03662109375, 37.028869446964741, 0.0],
							[-120.03662109375, 37.037639679771402, 0.0],
							[-120.03662109375, 37.046408899699564, 0.0],
							[-120.047607421875, 37.046408899699564, 0.0],
							[-120.05859375, 37.046408899699564, 0.0],
							[-120.069580078125, 37.046408899699564, 0.0],
							[-120.069580078125, 37.055177106660814, 0.0],
							[-120.08056640625, 37.055177106660814, 0.0],
							[-120.091552734375, 37.055177106660814, 0.0],
							[-120.1025390625, 37.055177106660814, 0.0],
							[-120.113525390625, 37.055177106660814, 0.0],
							[-120.12451171875, 37.055177106660814, 0.0],
							[-120.135498046875, 37.055177106660814, 0.0],
							[-120.146484375, 37.055177106660814, 0.0],
							[-120.146484375, 37.046408899699564, 0.0],
							[-120.146484375, 37.037639679771402, 0.0],
							[-120.157470703125, 37.037639679771402, 0.0],
							[-120.16845703125, 37.037639679771402, 0.0],
							[-120.179443359375, 37.037639679771402, 0.0],
							[-120.1904296875, 37.037639679771402, 0.0],
							[-120.1904296875, 37.028869446964741, 0.0],
							[-120.201416015625, 37.028869446964741, 0.0],
							[-120.21240234375, 37.028869446964741, 0.0],
							[-120.223388671875, 37.028869446964741, 0.0],
							[-120.223388671875, 37.037639679771402, 0.0],
							[-120.223388671875, 37.046408899699564, 0.0],
							[-120.223388671875, 37.055177106660814, 0.0],
							[-120.234375, 37.055177106660814, 0.0],
							[-120.234375, 37.063944300566838, 0.0],
							[-120.234375, 37.072710481329437, 0.0],
							[-120.223388671875, 37.072710481329437, 0.0],
							[-120.223388671875, 37.081475648860518, 0.0],
							[-120.223388671875, 37.090239803072066, 0.0],
						],
					],
					[
						[
							[-120.25634765625, 37.291535472927364, 0.0],
							[-120.2783203125, 37.291535472927364, 0.0],
							[-120.2783203125, 37.300275281344312, 0.0],
							[-120.289306640625, 37.300275281344312, 0.0],
							[-120.30029296875, 37.300275281344312, 0.0],
							[-120.311279296875, 37.300275281344312, 0.0],
							[-120.311279296875, 37.3090140742759, 0.0],
							[-120.311279296875, 37.317751851636878, 0.0],
							[-120.322265625, 37.317751851636878, 0.0],
							[-120.333251953125, 37.317751851636878, 0.0],
							[-120.333251953125, 37.326488613342057, 0.0],
							[-120.333251953125, 37.335224359306387, 0.0],
							[-120.34423828125, 37.335224359306387, 0.0],
							[-120.34423828125, 37.343959089444901, 0.0],
							[-120.355224609375, 37.343959089444901, 0.0],
							[-120.3662109375, 37.343959089444901, 0.0],
							[-120.3662109375, 37.352692803672738, 0.0],
							[-120.3662109375, 37.361425501905167, 0.0],
							[-120.377197265625, 37.361425501905167, 0.0],
							[-120.38818359375, 37.361425501905167, 0.0],
							[-120.399169921875, 37.361425501905167, 0.0],
							[-120.41015625, 37.361425501905167, 0.0],
							[-120.421142578125, 37.361425501905167, 0.0],
							[-120.43212890625, 37.361425501905167, 0.0],
							[-120.43212890625, 37.370157184057511, 0.0],
							[-120.43212890625, 37.378887850045253, 0.0],
							[-120.43212890625, 37.387617499783943, 0.0],
							[-120.43212890625, 37.39634613318924, 0.0],
							[-120.43212890625, 37.405073750176932, 0.0],
							[-120.443115234375, 37.405073750176932, 0.0],
							[-120.4541015625, 37.405073750176932, 0.0],
							[-120.465087890625, 37.405073750176932, 0.0],
							[-120.47607421875, 37.405073750176932, 0.0],
							[-120.47607421875, 37.39634613318924, 0.0],
							[-120.47607421875, 37.387617499783943, 0.0],
							[-120.487060546875, 37.387617499783943, 0.0],
							[-120.498046875, 37.387617499783943, 0.0],
							[-120.509033203125, 37.387617499783943, 0.0],
							[-120.509033203125, 37.378887850045253, 0.0],
							[-120.52001953125, 37.378887850045253, 0.0],
							[-120.531005859375, 37.378887850045253, 0.0],
							[-120.531005859375, 37.387617499783943, 0.0],
							[-120.5419921875, 37.387617499783943, 0.0],
							[-120.552978515625, 37.387617499783943, 0.0],
							[-120.552978515625, 37.39634613318924, 0.0],
							[-120.552978515625, 37.405073750176932, 0.0],
							[-120.552978515625, 37.413800350662882, 0.0],
							[-120.552978515625, 37.422525934563069, 0.0],
							[-120.56396484375, 37.422525934563069, 0.0],
							[-120.574951171875, 37.422525934563069, 0.0],
							[-120.5859375, 37.422525934563069, 0.0],
							[-120.5859375, 37.413800350662882, 0.0],
							[-120.5859375, 37.405073750176932, 0.0],
							[-120.596923828125, 37.405073750176932, 0.0],
							[-120.60791015625, 37.405073750176932, 0.0],
							[-120.618896484375, 37.405073750176932, 0.0],
							[-120.618896484375, 37.413800350662882, 0.0],
							[-120.618896484375, 37.422525934563069, 0.0],
							[-120.6298828125, 37.422525934563069, 0.0],
							[-120.640869140625, 37.422525934563069, 0.0],
							[-120.65185546875, 37.422525934563069, 0.0],
							[-120.65185546875, 37.431250501793571, 0.0],
							[-120.662841796875, 37.431250501793571, 0.0],
							[-120.673828125, 37.431250501793571, 0.0],
							[-120.684814453125, 37.431250501793571, 0.0],
							[-120.684814453125, 37.439974052270586, 0.0],
							[-120.684814453125, 37.448696585910376, 0.0],
							[-120.684814453125, 37.457418102629369, 0.0],
							[-120.69580078125, 37.457418102629369, 0.0],
							[-120.69580078125, 37.448696585910376, 0.0],
							[-120.706787109375, 37.448696585910376, 0.0],
							[-120.706787109375, 37.457418102629369, 0.0],
							[-120.706787109375, 37.466138602344053, 0.0],
							[-120.7177734375, 37.466138602344053, 0.0],
							[-120.7177734375, 37.474858084971025, 0.0],
							[-120.728759765625, 37.474858084971025, 0.0],
							[-120.73974609375, 37.474858084971025, 0.0],
							[-120.73974609375, 37.483576550426996, 0.0],
							[-120.750732421875, 37.483576550426996, 0.0],
							[-120.750732421875, 37.492293998628767, 0.0],
							[-120.750732421875, 37.50101042949327, 0.0],
							[-120.750732421875, 37.509725842937506, 0.0],
							[-120.750732421875, 37.518440238878597, 0.0],
							[-120.750732421875, 37.527153617233779, 0.0],
							[-120.750732421875, 37.53586597792038, 0.0],
							[-120.750732421875, 37.544577320855822, 0.0],
							[-120.750732421875, 37.553287645957653, 0.0],
							[-120.73974609375, 37.553287645957653, 0.0],
							[-120.73974609375, 37.561996953143513, 0.0],
							[-120.73974609375, 37.570705242331151, 0.0],
							[-120.73974609375, 37.579412513438392, 0.0],
							[-120.73974609375, 37.588118766383211, 0.0],
							[-120.73974609375, 37.596824001083661, 0.0],
							[-120.73974609375, 37.605528217457888, 0.0],
							[-120.728759765625, 37.605528217457888, 0.0],
							[-120.7177734375, 37.605528217457888, 0.0],
							[-120.7177734375, 37.614231415424172, 0.0],
							[-120.7177734375, 37.622933594900864, 0.0],
							[-120.706787109375, 37.622933594900864, 0.0],
							[-120.69580078125, 37.622933594900864, 0.0],
							[-120.69580078125, 37.631634755806445, 0.0],
							[-120.69580078125, 37.640334898059479, 0.0],
							[-120.69580078125, 37.64903402157865, 0.0],
							[-120.69580078125, 37.657732126282731, 0.0],
							[-120.706787109375, 37.657732126282731, 0.0],
							[-120.7177734375, 37.657732126282731, 0.0],
							[-120.7177734375, 37.666429212090605, 0.0],
							[-120.706787109375, 37.666429212090605, 0.0],
							[-120.69580078125, 37.666429212090605, 0.0],
							[-120.69580078125, 37.675125278921278, 0.0],
							[-120.69580078125, 37.683820326693805, 0.0],
							[-120.69580078125, 37.692514355327425, 0.0],
							[-120.706787109375, 37.692514355327425, 0.0],
							[-120.7177734375, 37.692514355327425, 0.0],
							[-120.728759765625, 37.692514355327425, 0.0],
							[-120.728759765625, 37.701207364741393, 0.0],
							[-120.73974609375, 37.701207364741393, 0.0],
							[-120.750732421875, 37.701207364741393, 0.0],
							[-120.76171875, 37.701207364741393, 0.0],
							[-120.772705078125, 37.701207364741393, 0.0],
							[-120.772705078125, 37.692514355327425, 0.0],
							[-120.772705078125, 37.683820326693805, 0.0],
							[-120.78369140625, 37.683820326693805, 0.0],
							[-120.794677734375, 37.683820326693805, 0.0],
							[-120.8056640625, 37.683820326693805, 0.0],
							[-120.816650390625, 37.683820326693805, 0.0],
							[-120.82763671875, 37.683820326693805, 0.0],
							[-120.838623046875, 37.683820326693805, 0.0],
							[-120.849609375, 37.683820326693805, 0.0],
							[-120.849609375, 37.692514355327425, 0.0],
							[-120.849609375, 37.701207364741393, 0.0],
							[-120.849609375, 37.709899354855139, 0.0],
							[-120.838623046875, 37.709899354855139, 0.0],
							[-120.838623046875, 37.718590325588146, 0.0],
							[-120.82763671875, 37.718590325588146, 0.0],
							[-120.816650390625, 37.718590325588146, 0.0],
							[-120.816650390625, 37.727280276860036, 0.0],
							[-120.816650390625, 37.735969208590504, 0.0],
							[-120.8056640625, 37.735969208590504, 0.0],
							[-120.8056640625, 37.74465712069938, 0.0],
							[-120.794677734375, 37.74465712069938, 0.0],
							[-120.794677734375, 37.75334401310657, 0.0],
							[-120.794677734375, 37.762029885732105, 0.0],
							[-120.794677734375, 37.770714738496096, 0.0],
							[-120.794677734375, 37.779398571318765, 0.0],
							[-120.8056640625, 37.779398571318765, 0.0],
							[-120.816650390625, 37.779398571318765, 0.0],
							[-120.816650390625, 37.78808138412046, 0.0],
							[-120.816650390625, 37.796763176821599, 0.0],
							[-120.82763671875, 37.796763176821599, 0.0],
							[-120.838623046875, 37.796763176821599, 0.0],
							[-120.849609375, 37.796763176821599, 0.0],
							[-120.860595703125, 37.796763176821599, 0.0],
							[-120.87158203125, 37.796763176821599, 0.0],
							[-120.882568359375, 37.796763176821599, 0.0],
							[-120.8935546875, 37.796763176821599, 0.0],
							[-120.904541015625, 37.796763176821599, 0.0],
							[-120.91552734375, 37.796763176821599, 0.0],
							[-120.91552734375, 37.78808138412046, 0.0],
							[-120.91552734375, 37.779398571318765, 0.0],
							[-120.926513671875, 37.779398571318765, 0.0],
							[-120.9375, 37.779398571318765, 0.0],
							[-120.948486328125, 37.779398571318765, 0.0],
							[-120.95947265625, 37.779398571318765, 0.0],
							[-120.970458984375, 37.779398571318765, 0.0],
							[-120.970458984375, 37.78808138412046, 0.0],
							[-120.9814453125, 37.78808138412046, 0.0],
							[-120.9814453125, 37.796763176821599, 0.0],
							[-120.9814453125, 37.805443949342724, 0.0],
							[-120.970458984375, 37.805443949342724, 0.0],
							[-120.970458984375, 37.814123701604458, 0.0],
							[-120.970458984375, 37.822802433527563, 0.0],
							[-120.970458984375, 37.831480145032877, 0.0],
							[-120.970458984375, 37.840156836041338, 0.0],
							[-120.9814453125, 37.840156836041338, 0.0],
							[-120.9814453125, 37.848832506474011, 0.0],
							[-120.9814453125, 37.857507156252034, 0.0],
							[-120.9814453125, 37.86618078529667, 0.0],
							[-120.9814453125, 37.874853393529271, 0.0],
							[-120.9814453125, 37.883524980871307, 0.0],
							[-120.970458984375, 37.883524980871307, 0.0],
							[-120.95947265625, 37.883524980871307, 0.0],
							[-120.95947265625, 37.874853393529271, 0.0],
							[-120.948486328125, 37.874853393529271, 0.0],
							[-120.9375, 37.874853393529271, 0.0],
							[-120.926513671875, 37.874853393529271, 0.0],
							[-120.91552734375, 37.874853393529271, 0.0],
							[-120.91552734375, 37.883524980871307, 0.0],
							[-120.91552734375, 37.892195547244356, 0.0],
							[-120.91552734375, 37.900865092570051, 0.0],
							[-120.91552734375, 37.90953361677019, 0.0],
							[-120.91552734375, 37.918201119766628, 0.0],
							[-120.91552734375, 37.926867601481341, 0.0],
							[-120.91552734375, 37.935533061836423, 0.0],
							[-120.926513671875, 37.935533061836423, 0.0],
							[-120.9375, 37.935533061836423, 0.0],
							[-120.948486328125, 37.935533061836423, 0.0],
							[-120.948486328125, 37.944197500754044, 0.0],
							[-120.95947265625, 37.944197500754044, 0.0],
							[-120.970458984375, 37.944197500754044, 0.0],
							[-120.9814453125, 37.944197500754044, 0.0],
							[-120.992431640625, 37.944197500754044, 0.0],
							[-120.992431640625, 37.952860918156489, 0.0],
							[-120.992431640625, 37.96152331396614, 0.0],
							[-120.992431640625, 37.970184688105483, 0.0],
							[-120.992431640625, 37.97884504049712, 0.0],
							[-120.992431640625, 37.987504371063729, 0.0],
							[-121.00341796875, 37.987504371063729, 0.0],
							[-121.00341796875, 37.996162679728123, 0.0],
							[-121.00341796875, 38.004819966413187, 0.0],
							[-121.00341796875, 38.013476231041921, 0.0],
							[-121.00341796875, 38.022131473537442, 0.0],
							[-120.992431640625, 38.022131473537442, 0.0],
							[-120.9814453125, 38.022131473537442, 0.0],
							[-120.9814453125, 38.030785693822942, 0.0],
							[-120.9814453125, 38.039438891821739, 0.0],
							[-120.9814453125, 38.048091067457236, 0.0],
							[-120.970458984375, 38.048091067457236, 0.0],
							[-120.970458984375, 38.056742220652943, 0.0],
							[-120.95947265625, 38.056742220652943, 0.0],
							[-120.95947265625, 38.065392351332477, 0.0],
							[-120.95947265625, 38.074041459419554, 0.0],
							[-120.95947265625, 38.082689544838004, 0.0],
							[-120.970458984375, 38.082689544838004, 0.0],
							[-120.9814453125, 38.082689544838004, 0.0],
							[-120.992431640625, 38.082689544838004, 0.0],
							[-121.00341796875, 38.082689544838004, 0.0],
							[-121.014404296875, 38.082689544838004, 0.0],
							[-121.025390625, 38.082689544838004, 0.0],
							[-121.025390625, 38.074041459419554, 0.0],
							[-121.036376953125, 38.074041459419554, 0.0],
							[-121.04736328125, 38.074041459419554, 0.0],
							[-121.04736328125, 38.082689544838004, 0.0],
							[-121.058349609375, 38.082689544838004, 0.0],
							[-121.058349609375, 38.091336607511742, 0.0],
							[-121.0693359375, 38.091336607511742, 0.0],
							[-121.080322265625, 38.091336607511742, 0.0],
							[-121.09130859375, 38.091336607511742, 0.0],
							[-121.102294921875, 38.091336607511742, 0.0],
							[-121.102294921875, 38.09998264736479, 0.0],
							[-121.102294921875, 38.108627664321276, 0.0],
							[-121.102294921875, 38.117271658305413, 0.0],
							[-121.11328125, 38.117271658305413, 0.0],
							[-121.124267578125, 38.117271658305413, 0.0],
							[-121.124267578125, 38.125914629241549, 0.0],
							[-121.11328125, 38.125914629241549, 0.0],
							[-121.102294921875, 38.125914629241549, 0.0],
							[-121.102294921875, 38.134556577054127, 0.0],
							[-121.09130859375, 38.134556577054127, 0.0],
							[-121.080322265625, 38.134556577054127, 0.0],
							[-121.080322265625, 38.143197501667643, 0.0],
							[-121.080322265625, 38.151837403006773, 0.0],
							[-121.080322265625, 38.160476280996221, 0.0],
							[-121.080322265625, 38.169114135560854, 0.0],
							[-121.080322265625, 38.177750966625595, 0.0],
							[-121.080322265625, 38.186386774115512, 0.0],
							[-121.080322265625, 38.195021557955727, 0.0],
							[-121.080322265625, 38.203655318071498, 0.0],
							[-121.080322265625, 38.212288054388168, 0.0],
							[-121.09130859375, 38.212288054388168, 0.0],
							[-121.102294921875, 38.212288054388168, 0.0],
							[-121.102294921875, 38.220919766831194, 0.0],
							[-121.102294921875, 38.229550455326134, 0.0],
							[-121.11328125, 38.229550455326134, 0.0],
							[-121.11328125, 38.238180119798642, 0.0],
							[-121.11328125, 38.24680876017446, 0.0],
							[-121.11328125, 38.255436376379471, 0.0],
							[-121.11328125, 38.264062968339609, 0.0],
							[-121.124267578125, 38.264062968339609, 0.0],
							[-121.13525390625, 38.264062968339609, 0.0],
							[-121.13525390625, 38.272688535980961, 0.0],
							[-121.13525390625, 38.281313079229669, 0.0],
							[-121.13525390625, 38.289936598012012, 0.0],
							[-121.13525390625, 38.298559092254344, 0.0],
							[-121.13525390625, 38.30718056188315, 0.0],
							[-121.146240234375, 38.30718056188315, 0.0],
							[-121.1572265625, 38.30718056188315, 0.0],
							[-121.168212890625, 38.30718056188315, 0.0],
							[-121.17919921875, 38.30718056188315, 0.0],
							[-121.190185546875, 38.30718056188315, 0.0],
							[-121.190185546875, 38.298559092254344, 0.0],
							[-121.201171875, 38.298559092254344, 0.0],
							[-121.201171875, 38.30718056188315, 0.0],
							[-121.212158203125, 38.30718056188315, 0.0],
							[-121.22314453125, 38.30718056188315, 0.0],
							[-121.22314453125, 38.298559092254344, 0.0],
							[-121.22314453125, 38.289936598012012, 0.0],
							[-121.22314453125, 38.281313079229669, 0.0],
							[-121.22314453125, 38.272688535980961, 0.0],
							[-121.22314453125, 38.264062968339609, 0.0],
							[-121.22314453125, 38.255436376379471, 0.0],
							[-121.22314453125, 38.24680876017446, 0.0],
							[-121.22314453125, 38.238180119798642, 0.0],
							[-121.22314453125, 38.229550455326134, 0.0],
							[-121.22314453125, 38.220919766831194, 0.0],
							[-121.22314453125, 38.212288054388168, 0.0],
							[-121.22314453125, 38.203655318071498, 0.0],
							[-121.22314453125, 38.195021557955727, 0.0],
							[-121.22314453125, 38.186386774115512, 0.0],
							[-121.22314453125, 38.177750966625595, 0.0],
							[-121.22314453125, 38.169114135560854, 0.0],
							[-121.22314453125, 38.160476280996221, 0.0],
							[-121.22314453125, 38.151837403006773, 0.0],
							[-121.22314453125, 38.143197501667643, 0.0],
							[-121.22314453125, 38.134556577054127, 0.0],
							[-121.22314453125, 38.125914629241549, 0.0],
							[-121.22314453125, 38.117271658305413, 0.0],
							[-121.22314453125, 38.108627664321276, 0.0],
							[-121.22314453125, 38.09998264736479, 0.0],
							[-121.22314453125, 38.091336607511742, 0.0],
							[-121.22314453125, 38.082689544838004, 0.0],
							[-121.22314453125, 38.074041459419554, 0.0],
							[-121.22314453125, 38.065392351332477, 0.0],
							[-121.22314453125, 38.056742220652943, 0.0],
							[-121.22314453125, 38.048091067457236, 0.0],
							[-121.22314453125, 38.039438891821739, 0.0],
							[-121.22314453125, 38.030785693822942, 0.0],
							[-121.22314453125, 38.022131473537442, 0.0],
							[-121.22314453125, 38.013476231041921, 0.0],
							[-121.22314453125, 38.004819966413187, 0.0],
							[-121.22314453125, 37.996162679728123, 0.0],
							[-121.22314453125, 37.987504371063729, 0.0],
							[-121.22314453125, 37.97884504049712, 0.0],
							[-121.22314453125, 37.970184688105483, 0.0],
							[-121.22314453125, 37.96152331396614, 0.0],
							[-121.22314453125, 37.952860918156489, 0.0],
							[-121.22314453125, 37.944197500754044, 0.0],
							[-121.22314453125, 37.935533061836423, 0.0],
							[-121.22314453125, 37.926867601481341, 0.0],
							[-121.22314453125, 37.918201119766628, 0.0],
							[-121.22314453125, 37.90953361677019, 0.0],
							[-121.22314453125, 37.900865092570051, 0.0],
							[-121.22314453125, 37.892195547244356, 0.0],
							[-121.22314453125, 37.883524980871307, 0.0],
							[-121.22314453125, 37.874853393529271, 0.0],
							[-121.22314453125, 37.86618078529667, 0.0],
							[-121.22314453125, 37.857507156252034, 0.0],
							[-121.22314453125, 37.848832506474011, 0.0],
							[-121.22314453125, 37.840156836041338, 0.0],
							[-121.22314453125, 37.831480145032877, 0.0],
							[-121.22314453125, 37.822802433527563, 0.0],
							[-121.22314453125, 37.814123701604458, 0.0],
							[-121.22314453125, 37.805443949342724, 0.0],
							[-121.22314453125, 37.796763176821599, 0.0],
							[-121.22314453125, 37.78808138412046, 0.0],
							[-121.22314453125, 37.779398571318765, 0.0],
							[-121.22314453125, 37.770714738496096, 0.0],
							[-121.22314453125, 37.762029885732105, 0.0],
							[-121.22314453125, 37.75334401310657, 0.0],
							[-121.22314453125, 37.74465712069938, 0.0],
							[-121.22314453125, 37.735969208590504, 0.0],
							[-121.22314453125, 37.727280276860036, 0.0],
							[-121.22314453125, 37.718590325588146, 0.0],
							[-121.22314453125, 37.709899354855139, 0.0],
							[-121.22314453125, 37.701207364741393, 0.0],
							[-121.22314453125, 37.692514355327425, 0.0],
							[-121.22314453125, 37.683820326693805, 0.0],
							[-121.22314453125, 37.675125278921278, 0.0],
							[-121.22314453125, 37.666429212090605, 0.0],
							[-121.22314453125, 37.657732126282731, 0.0],
							[-121.234130859375, 37.657732126282731, 0.0],
							[-121.2451171875, 37.657732126282731, 0.0],
							[-121.256103515625, 37.657732126282731, 0.0],
							[-121.256103515625, 37.666429212090605, 0.0],
							[-121.26708984375, 37.666429212090605, 0.0],
							[-121.278076171875, 37.666429212090605, 0.0],
							[-121.278076171875, 37.675125278921278, 0.0],
							[-121.2890625, 37.675125278921278, 0.0],
							[-121.2890625, 37.683820326693805, 0.0],
							[-121.300048828125, 37.683820326693805, 0.0],
							[-121.300048828125, 37.692514355327425, 0.0],
							[-121.31103515625, 37.692514355327425, 0.0],
							[-121.31103515625, 37.683820326693805, 0.0],
							[-121.322021484375, 37.683820326693805, 0.0],
							[-121.3330078125, 37.683820326693805, 0.0],
							[-121.3330078125, 37.675125278921278, 0.0],
							[-121.322021484375, 37.675125278921278, 0.0],
							[-121.322021484375, 37.666429212090605, 0.0],
							[-121.31103515625, 37.666429212090605, 0.0],
							[-121.31103515625, 37.657732126282731, 0.0],
							[-121.300048828125, 37.657732126282731, 0.0],
							[-121.300048828125, 37.64903402157865, 0.0],
							[-121.2890625, 37.64903402157865, 0.0],
							[-121.2890625, 37.640334898059479, 0.0],
							[-121.278076171875, 37.640334898059479, 0.0],
							[-121.278076171875, 37.631634755806445, 0.0],
							[-121.278076171875, 37.622933594900864, 0.0],
							[-121.2890625, 37.622933594900864, 0.0],
							[-121.300048828125, 37.622933594900864, 0.0],
							[-121.31103515625, 37.622933594900864, 0.0],
							[-121.322021484375, 37.622933594900864, 0.0],
							[-121.322021484375, 37.631634755806445, 0.0],
							[-121.3330078125, 37.631634755806445, 0.0],
							[-121.343994140625, 37.631634755806445, 0.0],
							[-121.35498046875, 37.631634755806445, 0.0],
							[-121.365966796875, 37.631634755806445, 0.0],
							[-121.365966796875, 37.640334898059479, 0.0],
							[-121.376953125, 37.640334898059479, 0.0],
							[-121.376953125, 37.64903402157865, 0.0],
							[-121.376953125, 37.657732126282731, 0.0],
							[-121.387939453125, 37.657732126282731, 0.0],
							[-121.387939453125, 37.666429212090605, 0.0],
							[-121.39892578125, 37.666429212090605, 0.0],
							[-121.39892578125, 37.675125278921278, 0.0],
							[-121.409912109375, 37.675125278921278, 0.0],
							[-121.4208984375, 37.675125278921278, 0.0],
							[-121.431884765625, 37.675125278921278, 0.0],
							[-121.431884765625, 37.683820326693805, 0.0],
							[-121.44287109375, 37.683820326693805, 0.0],
							[-121.44287109375, 37.692514355327425, 0.0],
							[-121.44287109375, 37.701207364741393, 0.0],
							[-121.44287109375, 37.709899354855139, 0.0],
							[-121.453857421875, 37.709899354855139, 0.0],
							[-121.46484375, 37.709899354855139, 0.0],
							[-121.475830078125, 37.709899354855139, 0.0],
							[-121.48681640625, 37.709899354855139, 0.0],
							[-121.48681640625, 37.718590325588146, 0.0],
							[-121.497802734375, 37.718590325588146, 0.0],
							[-121.497802734375, 37.727280276860036, 0.0],
							[-121.5087890625, 37.727280276860036, 0.0],
							[-121.5087890625, 37.735969208590504, 0.0],
							[-121.519775390625, 37.735969208590504, 0.0],
							[-121.53076171875, 37.735969208590504, 0.0],
							[-121.541748046875, 37.735969208590504, 0.0],
							[-121.552734375, 37.735969208590504, 0.0],
							[-121.563720703125, 37.735969208590504, 0.0],
							[-121.57470703125, 37.735969208590504, 0.0],
							[-121.585693359375, 37.735969208590504, 0.0],
							[-121.5966796875, 37.735969208590504, 0.0],
							[-121.607666015625, 37.735969208590504, 0.0],
							[-121.61865234375, 37.735969208590504, 0.0],
							[-121.629638671875, 37.735969208590504, 0.0],
							[-121.640625, 37.735969208590504, 0.0],
							[-121.651611328125, 37.735969208590504, 0.0],
							[-121.66259765625, 37.735969208590504, 0.0],
							[-121.673583984375, 37.735969208590504, 0.0],
							[-121.6845703125, 37.735969208590504, 0.0],
							[-121.695556640625, 37.735969208590504, 0.0],
							[-121.70654296875, 37.735969208590504, 0.0],
							[-121.717529296875, 37.735969208590504, 0.0],
							[-121.728515625, 37.735969208590504, 0.0],
							[-121.739501953125, 37.735969208590504, 0.0],
							[-121.75048828125, 37.735969208590504, 0.0],
							[-121.761474609375, 37.735969208590504, 0.0],
							[-121.7724609375, 37.735969208590504, 0.0],
							[-121.7724609375, 37.74465712069938, 0.0],
							[-121.7724609375, 37.75334401310657, 0.0],
							[-121.7724609375, 37.762029885732105, 0.0],
							[-121.7724609375, 37.770714738496096, 0.0],
							[-121.7724609375, 37.779398571318765, 0.0],
							[-121.7724609375, 37.78808138412046, 0.0],
							[-121.7724609375, 37.796763176821599, 0.0],
							[-121.7724609375, 37.805443949342724, 0.0],
							[-121.7724609375, 37.814123701604458, 0.0],
							[-121.7724609375, 37.822802433527563, 0.0],
							[-121.7724609375, 37.831480145032877, 0.0],
							[-121.7724609375, 37.840156836041338, 0.0],
							[-121.7724609375, 37.848832506474011, 0.0],
							[-121.7724609375, 37.857507156252034, 0.0],
							[-121.7724609375, 37.86618078529667, 0.0],
							[-121.7724609375, 37.874853393529271, 0.0],
							[-121.7724609375, 37.883524980871307, 0.0],
							[-121.7724609375, 37.892195547244356, 0.0],
							[-121.7724609375, 37.900865092570051, 0.0],
							[-121.7724609375, 37.90953361677019, 0.0],
							[-121.7724609375, 37.918201119766628, 0.0],
							[-121.7724609375, 37.926867601481341, 0.0],
							[-121.7724609375, 37.935533061836423, 0.0],
							[-121.7724609375, 37.944197500754044, 0.0],
							[-121.7724609375, 37.952860918156489, 0.0],
							[-121.7724609375, 37.96152331396614, 0.0],
							[-121.7724609375, 37.970184688105483, 0.0],
							[-121.783447265625, 37.970184688105483, 0.0],
							[-121.783447265625, 37.97884504049712, 0.0],
							[-121.79443359375, 37.97884504049712, 0.0],
							[-121.805419921875, 37.97884504049712, 0.0],
							[-121.805419921875, 37.970184688105483, 0.0],
							[-121.81640625, 37.970184688105483, 0.0],
							[-121.81640625, 37.97884504049712, 0.0],
							[-121.827392578125, 37.97884504049712, 0.0],
							[-121.83837890625, 37.97884504049712, 0.0],
							[-121.849365234375, 37.97884504049712, 0.0],
							[-121.849365234375, 37.970184688105483, 0.0],
							[-121.8603515625, 37.970184688105483, 0.0],
							[-121.8603515625, 37.96152331396614, 0.0],
							[-121.8603515625, 37.952860918156489, 0.0],
							[-121.8603515625, 37.944197500754044, 0.0],
							[-121.8603515625, 37.935533061836423, 0.0],
							[-121.849365234375, 37.935533061836423, 0.0],
							[-121.849365234375, 37.926867601481341, 0.0],
							[-121.849365234375, 37.918201119766628, 0.0],
							[-121.8603515625, 37.918201119766628, 0.0],
							[-121.8603515625, 37.90953361677019, 0.0],
							[-121.871337890625, 37.90953361677019, 0.0],
							[-121.88232421875, 37.90953361677019, 0.0],
							[-121.88232421875, 37.900865092570051, 0.0],
							[-121.88232421875, 37.892195547244356, 0.0],
							[-121.893310546875, 37.892195547244356, 0.0],
							[-121.893310546875, 37.883524980871307, 0.0],
							[-121.893310546875, 37.874853393529271, 0.0],
							[-121.904296875, 37.874853393529271, 0.0],
							[-121.904296875, 37.86618078529667, 0.0],
							[-121.904296875, 37.857507156252034, 0.0],
							[-121.893310546875, 37.857507156252034, 0.0],
							[-121.88232421875, 37.857507156252034, 0.0],
							[-121.88232421875, 37.848832506474011, 0.0],
							[-121.88232421875, 37.840156836041338, 0.0],
							[-121.88232421875, 37.831480145032877, 0.0],
							[-121.893310546875, 37.831480145032877, 0.0],
							[-121.893310546875, 37.822802433527563, 0.0],
							[-121.88232421875, 37.822802433527563, 0.0],
							[-121.88232421875, 37.814123701604458, 0.0],
							[-121.893310546875, 37.814123701604458, 0.0],
							[-121.893310546875, 37.805443949342724, 0.0],
							[-121.893310546875, 37.796763176821599, 0.0],
							[-121.904296875, 37.796763176821599, 0.0],
							[-121.904296875, 37.78808138412046, 0.0],
							[-121.915283203125, 37.78808138412046, 0.0],
							[-121.915283203125, 37.779398571318765, 0.0],
							[-121.92626953125, 37.779398571318765, 0.0],
							[-121.92626953125, 37.770714738496096, 0.0],
							[-121.937255859375, 37.770714738496096, 0.0],
							[-121.9482421875, 37.770714738496096, 0.0],
							[-121.959228515625, 37.770714738496096, 0.0],
							[-121.959228515625, 37.762029885732105, 0.0],
							[-121.97021484375, 37.762029885732105, 0.0],
							[-121.97021484375, 37.75334401310657, 0.0],
							[-121.97021484375, 37.74465712069938, 0.0],
							[-121.97021484375, 37.735969208590504, 0.0],
							[-121.981201171875, 37.735969208590504, 0.0],
							[-121.9921875, 37.735969208590504, 0.0],
							[-122.003173828125, 37.735969208590504, 0.0],
							[-122.01416015625, 37.735969208590504, 0.0],
							[-122.025146484375, 37.735969208590504, 0.0],
							[-122.025146484375, 37.74465712069938, 0.0],
							[-122.025146484375, 37.75334401310657, 0.0],
							[-122.025146484375, 37.762029885732105, 0.0],
							[-122.025146484375, 37.770714738496096, 0.0],
							[-122.025146484375, 37.779398571318765, 0.0],
							[-122.0361328125, 37.779398571318765, 0.0],
							[-122.0361328125, 37.78808138412046, 0.0],
							[-122.047119140625, 37.78808138412046, 0.0],
							[-122.047119140625, 37.796763176821599, 0.0],
							[-122.047119140625, 37.805443949342724, 0.0],
							[-122.05810546875, 37.805443949342724, 0.0],
							[-122.05810546875, 37.814123701604458, 0.0],
							[-122.069091796875, 37.814123701604458, 0.0],
							[-122.080078125, 37.814123701604458, 0.0],
							[-122.080078125, 37.822802433527563, 0.0],
							[-122.091064453125, 37.822802433527563, 0.0],
							[-122.091064453125, 37.831480145032877, 0.0],
							[-122.10205078125, 37.831480145032877, 0.0],
							[-122.10205078125, 37.840156836041338, 0.0],
							[-122.113037109375, 37.840156836041338, 0.0],
							[-122.113037109375, 37.848832506474011, 0.0],
							[-122.113037109375, 37.857507156252034, 0.0],
							[-122.1240234375, 37.857507156252034, 0.0],
							[-122.1240234375, 37.86618078529667, 0.0],
							[-122.135009765625, 37.86618078529667, 0.0],
							[-122.135009765625, 37.857507156252034, 0.0],
							[-122.14599609375, 37.857507156252034, 0.0],
							[-122.156982421875, 37.857507156252034, 0.0],
							[-122.156982421875, 37.848832506474011, 0.0],
							[-122.16796875, 37.848832506474011, 0.0],
							[-122.178955078125, 37.848832506474011, 0.0],
							[-122.18994140625, 37.848832506474011, 0.0],
							[-122.18994140625, 37.840156836041338, 0.0],
							[-122.200927734375, 37.840156836041338, 0.0],
							[-122.200927734375, 37.831480145032877, 0.0],
							[-122.200927734375, 37.822802433527563, 0.0],
							[-122.2119140625, 37.822802433527563, 0.0],
							[-122.2119140625, 37.831480145032877, 0.0],
							[-122.222900390625, 37.831480145032877, 0.0],
							[-122.23388671875, 37.831480145032877, 0.0],
							[-122.23388671875, 37.822802433527563, 0.0],
							[-122.244873046875, 37.822802433527563, 0.0],
							[-122.255859375, 37.822802433527563, 0.0],
							[-122.255859375, 37.814123701604458, 0.0],
							[-122.255859375, 37.805443949342724, 0.0],
							[-122.266845703125, 37.805443949342724, 0.0],
							[-122.266845703125, 37.796763176821599, 0.0],
							[-122.27783203125, 37.796763176821599, 0.0],
							[-122.288818359375, 37.796763176821599, 0.0],
							[-122.2998046875, 37.796763176821599, 0.0],
							[-122.310791015625, 37.796763176821599, 0.0],
							[-122.32177734375, 37.796763176821599, 0.0],
							[-122.32177734375, 37.805443949342724, 0.0],
							[-122.332763671875, 37.805443949342724, 0.0],
							[-122.332763671875, 37.814123701604458, 0.0],
							[-122.32177734375, 37.814123701604458, 0.0],
							[-122.32177734375, 37.822802433527563, 0.0],
							[-122.32177734375, 37.831480145032877, 0.0],
							[-122.310791015625, 37.831480145032877, 0.0],
							[-122.310791015625, 37.840156836041338, 0.0],
							[-122.2998046875, 37.840156836041338, 0.0],
							[-122.2998046875, 37.848832506474011, 0.0],
							[-122.2998046875, 37.857507156252034, 0.0],
							[-122.310791015625, 37.857507156252034, 0.0],
							[-122.32177734375, 37.857507156252034, 0.0],
							[-122.32177734375, 37.86618078529667, 0.0],
							[-122.32177734375, 37.874853393529271, 0.0],
							[-122.32177734375, 37.883524980871307, 0.0],
							[-122.332763671875, 37.883524980871307, 0.0],
							[-122.332763671875, 37.892195547244356, 0.0],
							[-122.332763671875, 37.900865092570051, 0.0],
							[-122.332763671875, 37.90953361677019, 0.0],
							[-122.34375, 37.90953361677019, 0.0],
							[-122.34375, 37.900865092570051, 0.0],
							[-122.354736328125, 37.900865092570051, 0.0],
							[-122.354736328125, 37.90953361677019, 0.0],
							[-122.36572265625, 37.90953361677019, 0.0],
							[-122.36572265625, 37.900865092570051, 0.0],
							[-122.376708984375, 37.900865092570051, 0.0],
							[-122.3876953125, 37.900865092570051, 0.0],
							[-122.3876953125, 37.90953361677019, 0.0],
							[-122.3876953125, 37.918201119766628, 0.0],
							[-122.398681640625, 37.918201119766628, 0.0],
							[-122.398681640625, 37.926867601481341, 0.0],
							[-122.40966796875, 37.926867601481341, 0.0],
							[-122.40966796875, 37.935533061836423, 0.0],
							[-122.420654296875, 37.935533061836423, 0.0],
							[-122.420654296875, 37.944197500754044, 0.0],
							[-122.420654296875, 37.952860918156489, 0.0],
							[-122.431640625, 37.952860918156489, 0.0],
							[-122.431640625, 37.96152331396614, 0.0],
							[-122.431640625, 37.970184688105483, 0.0],
							[-122.420654296875, 37.970184688105483, 0.0],
							[-122.40966796875, 37.970184688105483, 0.0],
							[-122.40966796875, 37.96152331396614, 0.0],
							[-122.398681640625, 37.96152331396614, 0.0],
							[-122.398681640625, 37.970184688105483, 0.0],
							[-122.3876953125, 37.970184688105483, 0.0],
							[-122.3876953125, 37.97884504049712, 0.0],
							[-122.376708984375, 37.97884504049712, 0.0],
							[-122.36572265625, 37.97884504049712, 0.0],
							[-122.36572265625, 37.987504371063729, 0.0],
							[-122.36572265625, 37.996162679728123, 0.0],
							[-122.376708984375, 37.996162679728123, 0.0],
							[-122.3876953125, 37.996162679728123, 0.0],
							[-122.398681640625, 37.996162679728123, 0.0],
							[-122.398681640625, 38.004819966413187, 0.0],
							[-122.398681640625, 38.013476231041921, 0.0],
							[-122.398681640625, 38.022131473537442, 0.0],
							[-122.398681640625, 38.030785693822942, 0.0],
							[-122.398681640625, 38.039438891821739, 0.0],
							[-122.398681640625, 38.048091067457236, 0.0],
							[-122.398681640625, 38.056742220652943, 0.0],
							[-122.398681640625, 38.065392351332477, 0.0],
							[-122.398681640625, 38.074041459419554, 0.0],
							[-122.398681640625, 38.082689544838004, 0.0],
							[-122.398681640625, 38.091336607511742, 0.0],
							[-122.398681640625, 38.09998264736479, 0.0],
							[-122.398681640625, 38.108627664321276, 0.0],
							[-122.398681640625, 38.117271658305413, 0.0],
							[-122.398681640625, 38.125914629241549, 0.0],
							[-122.40966796875, 38.125914629241549, 0.0],
							[-122.40966796875, 38.117271658305413, 0.0],
							[-122.40966796875, 38.108627664321276, 0.0],
							[-122.40966796875, 38.09998264736479, 0.0],
							[-122.40966796875, 38.091336607511742, 0.0],
							[-122.40966796875, 38.082689544838004, 0.0],
							[-122.40966796875, 38.074041459419554, 0.0],
							[-122.40966796875, 38.065392351332477, 0.0],
							[-122.40966796875, 38.056742220652943, 0.0],
							[-122.40966796875, 38.048091067457236, 0.0],
							[-122.40966796875, 38.039438891821739, 0.0],
							[-122.40966796875, 38.030785693822942, 0.0],
							[-122.40966796875, 38.022131473537442, 0.0],
							[-122.40966796875, 38.013476231041921, 0.0],
							[-122.40966796875, 38.004819966413187, 0.0],
							[-122.420654296875, 38.004819966413187, 0.0],
							[-122.431640625, 38.004819966413187, 0.0],
							[-122.442626953125, 38.004819966413187, 0.0],
							[-122.45361328125, 38.004819966413187, 0.0],
							[-122.464599609375, 38.004819966413187, 0.0],
							[-122.4755859375, 38.004819966413187, 0.0],
							[-122.486572265625, 38.004819966413187, 0.0],
							[-122.49755859375, 38.004819966413187, 0.0],
							[-122.49755859375, 38.013476231041921, 0.0],
							[-122.49755859375, 38.022131473537442, 0.0],
							[-122.49755859375, 38.030785693822942, 0.0],
							[-122.49755859375, 38.039438891821739, 0.0],
							[-122.49755859375, 38.048091067457236, 0.0],
							[-122.49755859375, 38.056742220652943, 0.0],
							[-122.49755859375, 38.065392351332477, 0.0],
							[-122.49755859375, 38.074041459419554, 0.0],
							[-122.49755859375, 38.082689544838004, 0.0],
							[-122.49755859375, 38.091336607511742, 0.0],
							[-122.49755859375, 38.09998264736479, 0.0],
							[-122.508544921875, 38.09998264736479, 0.0],
							[-122.508544921875, 38.091336607511742, 0.0],
							[-122.508544921875, 38.082689544838004, 0.0],
							[-122.508544921875, 38.074041459419554, 0.0],
							[-122.508544921875, 38.065392351332477, 0.0],
							[-122.508544921875, 38.056742220652943, 0.0],
							[-122.508544921875, 38.048091067457236, 0.0],
							[-122.508544921875, 38.039438891821739, 0.0],
							[-122.51953125, 38.039438891821739, 0.0],
							[-122.51953125, 38.030785693822942, 0.0],
							[-122.51953125, 38.022131473537442, 0.0],
							[-122.51953125, 38.013476231041921, 0.0],
							[-122.51953125, 38.004819966413187, 0.0],
							[-122.51953125, 37.996162679728123, 0.0],
							[-122.530517578125, 37.996162679728123, 0.0],
							[-122.530517578125, 38.004819966413187, 0.0],
							[-122.54150390625, 38.004819966413187, 0.0],
							[-122.552490234375, 38.004819966413187, 0.0],
							[-122.5634765625, 38.004819966413187, 0.0],
							[-122.574462890625, 38.004819966413187, 0.0],
							[-122.58544921875, 38.004819966413187, 0.0],
							[-122.596435546875, 38.004819966413187, 0.0],
							[-122.607421875, 38.004819966413187, 0.0],
							[-122.618408203125, 38.004819966413187, 0.0],
							[-122.62939453125, 38.004819966413187, 0.0],
							[-122.640380859375, 38.004819966413187, 0.0],
							[-122.6513671875, 38.004819966413187, 0.0],
							[-122.6513671875, 37.996162679728123, 0.0],
							[-122.662353515625, 37.996162679728123, 0.0],
							[-122.67333984375, 37.996162679728123, 0.0],
							[-122.684326171875, 37.996162679728123, 0.0],
							[-122.6953125, 37.996162679728123, 0.0],
							[-122.706298828125, 37.996162679728123, 0.0],
							[-122.71728515625, 37.996162679728123, 0.0],
							[-122.728271484375, 37.996162679728123, 0.0],
							[-122.728271484375, 38.004819966413187, 0.0],
							[-122.728271484375, 38.013476231041921, 0.0],
							[-122.728271484375, 38.022131473537442, 0.0],
							[-122.728271484375, 38.030785693822942, 0.0],
							[-122.728271484375, 38.039438891821739, 0.0],
							[-122.728271484375, 38.048091067457236, 0.0],
							[-122.728271484375, 38.056742220652943, 0.0],
							[-122.728271484375, 38.065392351332477, 0.0],
							[-122.728271484375, 38.074041459419554, 0.0],
							[-122.728271484375, 38.082689544838004, 0.0],
							[-122.728271484375, 38.091336607511742, 0.0],
							[-122.728271484375, 38.09998264736479, 0.0],
							[-122.728271484375, 38.108627664321276, 0.0],
							[-122.728271484375, 38.117271658305413, 0.0],
							[-122.728271484375, 38.125914629241549, 0.0],
							[-122.728271484375, 38.134556577054127, 0.0],
							[-122.728271484375, 38.143197501667643, 0.0],
							[-122.728271484375, 38.151837403006773, 0.0],
							[-122.728271484375, 38.160476280996221, 0.0],
							[-122.728271484375, 38.169114135560854, 0.0],
							[-122.728271484375, 38.177750966625595, 0.0],
							[-122.728271484375, 38.186386774115512, 0.0],
							[-122.728271484375, 38.195021557955727, 0.0],
							[-122.728271484375, 38.203655318071498, 0.0],
							[-122.7392578125, 38.203655318071498, 0.0],
							[-122.7392578125, 38.212288054388168, 0.0],
							[-122.7392578125, 38.220919766831194, 0.0],
							[-122.750244140625, 38.220919766831194, 0.0],
							[-122.76123046875, 38.220919766831194, 0.0],
							[-122.76123046875, 38.229550455326134, 0.0],
							[-122.76123046875, 38.238180119798642, 0.0],
							[-122.76123046875, 38.24680876017446, 0.0],
							[-122.772216796875, 38.24680876017446, 0.0],
							[-122.783203125, 38.24680876017446, 0.0],
							[-122.794189453125, 38.24680876017446, 0.0],
							[-122.80517578125, 38.24680876017446, 0.0],
							[-122.816162109375, 38.24680876017446, 0.0],
							[-122.8271484375, 38.24680876017446, 0.0],
							[-122.838134765625, 38.24680876017446, 0.0],
							[-122.84912109375, 38.24680876017446, 0.0],
							[-122.860107421875, 38.24680876017446, 0.0],
							[-122.87109375, 38.24680876017446, 0.0],
							[-122.882080078125, 38.24680876017446, 0.0],
							[-122.89306640625, 38.24680876017446, 0.0],
							[-122.904052734375, 38.24680876017446, 0.0],
							[-122.9150390625, 38.24680876017446, 0.0],
							[-122.926025390625, 38.24680876017446, 0.0],
							[-122.93701171875, 38.24680876017446, 0.0],
							[-122.947998046875, 38.24680876017446, 0.0],
							[-122.958984375, 38.24680876017446, 0.0],
							[-122.969970703125, 38.24680876017446, 0.0],
							[-122.98095703125, 38.24680876017446, 0.0],
							[-122.991943359375, 38.24680876017446, 0.0],
							[-122.991943359375, 38.255436376379471, 0.0],
							[-122.991943359375, 38.264062968339609, 0.0],
							[-122.991943359375, 38.272688535980961, 0.0],
							[-122.991943359375, 38.281313079229669, 0.0],
							[-122.991943359375, 38.289936598012012, 0.0],
							[-123.0029296875, 38.289936598012012, 0.0],
							[-123.0029296875, 38.281313079229669, 0.0],
							[-123.0029296875, 38.272688535980961, 0.0],
							[-123.0029296875, 38.264062968339609, 0.0],
							[-123.0029296875, 38.255436376379471, 0.0],
							[-123.0029296875, 38.24680876017446, 0.0],
							[-123.013916015625, 38.24680876017446, 0.0],
							[-123.02490234375, 38.24680876017446, 0.0],
							[-123.035888671875, 38.24680876017446, 0.0],
							[-123.046875, 38.24680876017446, 0.0],
							[-123.057861328125, 38.24680876017446, 0.0],
							[-123.06884765625, 38.24680876017446, 0.0],
							[-123.079833984375, 38.24680876017446, 0.0],
							[-123.0908203125, 38.24680876017446, 0.0],
							[-123.101806640625, 38.24680876017446, 0.0],
							[-123.11279296875, 38.24680876017446, 0.0],
							[-123.123779296875, 38.24680876017446, 0.0],
							[-123.134765625, 38.24680876017446, 0.0],
							[-123.145751953125, 38.24680876017446, 0.0],
							[-123.15673828125, 38.24680876017446, 0.0],
							[-123.167724609375, 38.24680876017446, 0.0],
							[-123.1787109375, 38.24680876017446, 0.0],
							[-123.189697265625, 38.24680876017446, 0.0],
							[-123.20068359375, 38.24680876017446, 0.0],
							[-123.211669921875, 38.24680876017446, 0.0],
							[-123.22265625, 38.24680876017446, 0.0],
							[-123.233642578125, 38.24680876017446, 0.0],
							[-123.24462890625, 38.24680876017446, 0.0],
							[-123.24462890625, 38.255436376379471, 0.0],
							[-123.24462890625, 38.264062968339609, 0.0],
							[-123.24462890625, 38.272688535980961, 0.0],
							[-123.24462890625, 38.281313079229669, 0.0],
							[-123.24462890625, 38.289936598012012, 0.0],
							[-123.24462890625, 38.298559092254344, 0.0],
							[-123.24462890625, 38.30718056188315, 0.0],
							[-123.24462890625, 38.315801006824991, 0.0],
							[-123.24462890625, 38.32442042700653, 0.0],
							[-123.24462890625, 38.333038822354553, 0.0],
							[-123.24462890625, 38.341656192795931, 0.0],
							[-123.24462890625, 38.350272538257641, 0.0],
							[-123.24462890625, 38.358887858666755, 0.0],
							[-123.24462890625, 38.367502153950454, 0.0],
							[-123.24462890625, 38.37611542403603, 0.0],
							[-123.24462890625, 38.384727668850836, 0.0],
							[-123.24462890625, 38.393338888322383, 0.0],
							[-123.24462890625, 38.401949082378223, 0.0],
							[-123.24462890625, 38.410558250946075, 0.0],
							[-123.24462890625, 38.419166393953716, 0.0],
							[-123.24462890625, 38.427773511329022, 0.0],
							[-123.24462890625, 38.436379603, 0.0],
							[-123.24462890625, 38.444984668894712, 0.0],
							[-123.24462890625, 38.453588708941375, 0.0],
							[-123.24462890625, 38.462191723068266, 0.0],
							[-123.24462890625, 38.47079371120379, 0.0],
							[-123.24462890625, 38.479394673276438, 0.0],
							[-123.24462890625, 38.487994609214802, 0.0],
							[-123.24462890625, 38.496593518947563, 0.0],
							[-123.24462890625, 38.505191402403547, 0.0],
							[-123.255615234375, 38.505191402403547, 0.0],
							[-123.255615234375, 38.496593518947563, 0.0],
							[-123.2666015625, 38.496593518947563, 0.0],
							[-123.277587890625, 38.496593518947563, 0.0],
							[-123.28857421875, 38.496593518947563, 0.0],
							[-123.299560546875, 38.496593518947563, 0.0],
							[-123.310546875, 38.496593518947563, 0.0],
							[-123.321533203125, 38.496593518947563, 0.0],
							[-123.33251953125, 38.496593518947563, 0.0],
							[-123.343505859375, 38.496593518947563, 0.0],
							[-123.3544921875, 38.496593518947563, 0.0],
							[-123.365478515625, 38.496593518947563, 0.0],
							[-123.37646484375, 38.496593518947563, 0.0],
							[-123.387451171875, 38.496593518947563, 0.0],
							[-123.3984375, 38.496593518947563, 0.0],
							[-123.409423828125, 38.496593518947563, 0.0],
							[-123.42041015625, 38.496593518947563, 0.0],
							[-123.431396484375, 38.496593518947563, 0.0],
							[-123.4423828125, 38.496593518947563, 0.0],
							[-123.453369140625, 38.496593518947563, 0.0],
							[-123.46435546875, 38.496593518947563, 0.0],
							[-123.475341796875, 38.496593518947563, 0.0],
							[-123.486328125, 38.496593518947563, 0.0],
							[-123.497314453125, 38.496593518947563, 0.0],
							[-123.497314453125, 38.505191402403547, 0.0],
							[-123.497314453125, 38.513788259511642, 0.0],
							[-123.497314453125, 38.522384090200838, 0.0],
							[-123.497314453125, 38.530978894400242, 0.0],
							[-123.497314453125, 38.53957267203905, 0.0],
							[-123.497314453125, 38.548165423046576, 0.0],
							[-123.497314453125, 38.556757147352208, 0.0],
							[-123.497314453125, 38.565347844885451, 0.0],
							[-123.497314453125, 38.573937515575921, 0.0],
							[-123.497314453125, 38.582526159353314, 0.0],
							[-123.497314453125, 38.591113776147431, 0.0],
							[-123.497314453125, 38.599700365888189, 0.0],
							[-123.497314453125, 38.60828592850558, 0.0],
							[-123.497314453125, 38.616870463929736, 0.0],
							[-123.497314453125, 38.625453972090838, 0.0],
							[-123.497314453125, 38.634036452919212, 0.0],
							[-123.497314453125, 38.642617906345265, 0.0],
							[-123.497314453125, 38.651198332299501, 0.0],
							[-123.497314453125, 38.659777730712527, 0.0],
							[-123.497314453125, 38.668356101515066, 0.0],
							[-123.497314453125, 38.676933444637918, 0.0],
							[-123.497314453125, 38.685509760012003, 0.0],
							[-123.497314453125, 38.694085047568322, 0.0],
							[-123.497314453125, 38.702659307238001, 0.0],
							[-123.497314453125, 38.711232538952252, 0.0],
							[-123.497314453125, 38.719804742642374, 0.0],
							[-123.497314453125, 38.728375918239784, 0.0],
							[-123.497314453125, 38.736946065676001, 0.0],
							[-123.497314453125, 38.745515184882642, 0.0],
							[-123.50830078125, 38.745515184882642, 0.0],
							[-123.519287109375, 38.745515184882642, 0.0],
							[-123.5302734375, 38.745515184882642, 0.0],
							[-123.541259765625, 38.745515184882642, 0.0],
							[-123.55224609375, 38.745515184882642, 0.0],
							[-123.563232421875, 38.745515184882642, 0.0],
							[-123.57421875, 38.745515184882642, 0.0],
							[-123.585205078125, 38.745515184882642, 0.0],
							[-123.59619140625, 38.745515184882642, 0.0],
							[-123.607177734375, 38.745515184882642, 0.0],
							[-123.6181640625, 38.745515184882642, 0.0],
							[-123.629150390625, 38.745515184882642, 0.0],
							[-123.64013671875, 38.745515184882642, 0.0],
							[-123.651123046875, 38.745515184882642, 0.0],
							[-123.662109375, 38.745515184882642, 0.0],
							[-123.673095703125, 38.745515184882642, 0.0],
							[-123.68408203125, 38.745515184882642, 0.0],
							[-123.695068359375, 38.745515184882642, 0.0],
							[-123.7060546875, 38.745515184882642, 0.0],
							[-123.717041015625, 38.745515184882642, 0.0],
							[-123.72802734375, 38.745515184882642, 0.0],
							[-123.739013671875, 38.745515184882642, 0.0],
							[-123.739013671875, 38.754083275791416, 0.0],
							[-123.739013671875, 38.76265033833414, 0.0],
							[-123.739013671875, 38.771216372442723, 0.0],
							[-123.739013671875, 38.779781378049165, 0.0],
							[-123.739013671875, 38.788345355085625, 0.0],
							[-123.739013671875, 38.79690830348428, 0.0],
							[-123.739013671875, 38.805470223177451, 0.0],
							[-123.739013671875, 38.81403111409756, 0.0],
							[-123.739013671875, 38.822590976177104, 0.0],
							[-123.739013671875, 38.83114980934873, 0.0],
							[-123.739013671875, 38.83970761354513, 0.0],
							[-123.739013671875, 38.848264388699121, 0.0],
							[-123.739013671875, 38.856820134743622, 0.0],
							[-123.739013671875, 38.865374851611641, 0.0],
							[-123.739013671875, 38.873928539236296, 0.0],
							[-123.739013671875, 38.882481197550796, 0.0],
							[-123.739013671875, 38.891032826488463, 0.0],
							[-123.739013671875, 38.899583425982712, 0.0],
							[-123.739013671875, 38.908132995967044, 0.0],
							[-123.739013671875, 38.916681536375087, 0.0],
							[-123.739013671875, 38.925229047140533, 0.0],
							[-123.739013671875, 38.933775528197209, 0.0],
							[-123.739013671875, 38.942320979479021, 0.0],
							[-123.739013671875, 38.950865400919994, 0.0],
							[-123.739013671875, 38.959408792454227, 0.0],
							[-123.739013671875, 38.967951154015921, 0.0],
							[-123.739013671875, 38.976492485539403, 0.0],
							[-123.739013671875, 38.985032786959074, 0.0],
							[-123.739013671875, 38.993572058209452, 0.0],
							[-123.75, 39.002110299225137, 0.0],
							[-123.760986328125, 39.002110299225137, 0.0],
							[-123.77197265625, 39.002110299225137, 0.0],
							[-123.782958984375, 39.002110299225137, 0.0],
							[-123.7939453125, 39.002110299225137, 0.0],
							[-123.804931640625, 39.002110299225137, 0.0],
							[-123.81591796875, 39.002110299225137, 0.0],
							[-123.826904296875, 39.002110299225137, 0.0],
							[-123.837890625, 39.002110299225137, 0.0],
							[-123.848876953125, 39.002110299225137, 0.0],
							[-123.85986328125, 39.002110299225137, 0.0],
							[-123.870849609375, 39.002110299225137, 0.0],
							[-123.8818359375, 39.002110299225137, 0.0],
							[-123.892822265625, 39.002110299225137, 0.0],
							[-123.90380859375, 39.002110299225137, 0.0],
							[-123.914794921875, 39.002110299225137, 0.0],
							[-123.92578125, 39.002110299225137, 0.0],
							[-123.936767578125, 39.002110299225137, 0.0],
							[-123.94775390625, 39.002110299225137, 0.0],
							[-123.958740234375, 39.002110299225137, 0.0],
							[-123.9697265625, 39.002110299225137, 0.0],
							[-123.980712890625, 39.002110299225137, 0.0],
							[-123.99169921875, 39.002110299225137, 0.0],
							[-123.99169921875, 39.010647509940839, 0.0],
							[-123.99169921875, 39.019183690291349, 0.0],
							[-123.99169921875, 39.027718840211605, 0.0],
							[-123.99169921875, 39.036252959636599, 0.0],
							[-123.99169921875, 39.044786048501429, 0.0],
							[-123.99169921875, 39.053318106741308, 0.0],
							[-123.99169921875, 39.061849134291535, 0.0],
							[-123.99169921875, 39.070379131087506, 0.0],
							[-123.99169921875, 39.078908097064748, 0.0],
							[-123.99169921875, 39.087436032158827, 0.0],
							[-123.99169921875, 39.095962936305483, 0.0],
							[-123.99169921875, 39.104488809440483, 0.0],
							[-123.99169921875, 39.113013651499735, 0.0],
							[-123.99169921875, 39.121537462419248, 0.0],
							[-123.99169921875, 39.130060242135102, 0.0],
							[-123.99169921875, 39.138581990583511, 0.0],
							[-123.99169921875, 39.147102707700753, 0.0],
							[-123.99169921875, 39.155622393423222, 0.0],
							[-123.99169921875, 39.164141047687423, 0.0],
							[-123.99169921875, 39.172658670429946, 0.0],
							[-123.99169921875, 39.18117526158747, 0.0],
							[-123.99169921875, 39.189690821096796, 0.0],
							[-123.99169921875, 39.198205348894803, 0.0],
							[-123.99169921875, 39.206718844918477, 0.0],
							[-123.99169921875, 39.215231309104908, 0.0],
							[-123.99169921875, 39.223742741391291, 0.0],
							[-123.99169921875, 39.232253141714892, 0.0],
							[-123.99169921875, 39.240762510013099, 0.0],
							[-123.99169921875, 39.249270846223389, 0.0],
							[-123.99169921875, 39.25777815028335, 0.0],
							[-123.99169921875, 39.266284422130646, 0.0],
							[-123.99169921875, 39.274789661703068, 0.0],
							[-123.99169921875, 39.283293868938493, 0.0],
							[-123.99169921875, 39.291797043774871, 0.0],
							[-123.99169921875, 39.300299186150291, 0.0],
							[-123.99169921875, 39.308800296002921, 0.0],
							[-123.99169921875, 39.317300373271024, 0.0],
							[-123.99169921875, 39.325799417892981, 0.0],
							[-123.99169921875, 39.334297429807236, 0.0],
							[-123.99169921875, 39.342794408952372, 0.0],
							[-123.99169921875, 39.351290355267039, 0.0],
							[-123.99169921875, 39.359785268690004, 0.0],
							[-123.99169921875, 39.368279149160131, 0.0],
							[-123.99169921875, 39.376771996616355, 0.0],
							[-123.99169921875, 39.385263810997756, 0.0],
							[-123.99169921875, 39.393754592243468, 0.0],
							[-123.99169921875, 39.402244340292761, 0.0],
							[-123.99169921875, 39.410733055084961, 0.0],
							[-123.99169921875, 39.419220736559545, 0.0],
							[-123.99169921875, 39.427707384656038, 0.0],
							[-123.99169921875, 39.436192999314081, 0.0],
							[-123.99169921875, 39.444677580473424, 0.0],
							[-123.99169921875, 39.453161128073923, 0.0],
							[-123.99169921875, 39.461643642055485, 0.0],
							[-123.99169921875, 39.470125122358169, 0.0],
							[-123.99169921875, 39.478605568922092, 0.0],
							[-123.99169921875, 39.487084981687488, 0.0],
							[-123.99169921875, 39.495563360594709, 0.0],
							[-123.99169921875, 39.504040705584153, 0.0],
							[-123.99169921875, 39.512517016596362, 0.0],
							[-123.99169921875, 39.520992293571958, 0.0],
							[-123.99169921875, 39.52946653645165, 0.0],
							[-123.99169921875, 39.53793974517626, 0.0],
							[-123.99169921875, 39.546411919686712, 0.0],
							[-123.99169921875, 39.554883059924023, 0.0],
							[-123.99169921875, 39.563353165829298, 0.0],
							[-123.99169921875, 39.571822237343731, 0.0],
							[-123.99169921875, 39.580290274408647, 0.0],
							[-123.99169921875, 39.588757276965453, 0.0],
							[-123.99169921875, 39.597223244955636, 0.0],
							[-123.99169921875, 39.605688178320804, 0.0],
							[-123.99169921875, 39.61415207700265, 0.0],
							[-123.99169921875, 39.622614940942974, 0.0],
							[-123.99169921875, 39.631076770083659, 0.0],
							[-123.99169921875, 39.639537564366698, 0.0],
							[-123.99169921875, 39.647997323734181, 0.0],
							[-123.99169921875, 39.656456048128291, 0.0],
							[-123.99169921875, 39.664913737491297, 0.0],
							[-123.99169921875, 39.673370391765587, 0.0],
							[-123.99169921875, 39.681826010893637, 0.0],
							[-123.99169921875, 39.69028059481802, 0.0],
							[-123.99169921875, 39.698734143481396, 0.0],
							[-123.99169921875, 39.707186656826551, 0.0],
							[-123.99169921875, 39.715638134796329, 0.0],
							[-123.99169921875, 39.724088577333703, 0.0],
							[-123.99169921875, 39.732537984381729, 0.0],
							[-123.99169921875, 39.740986355883564, 0.0],
							[-123.99169921875, 39.749433691782457, 0.0],
							[-123.99169921875, 39.757879992021756, 0.0],
							[-123.99169921875, 39.76632525654491, 0.0],
							[-123.99169921875, 39.774769485295458, 0.0],
							[-123.99169921875, 39.783212678217048, 0.0],
							[-123.99169921875, 39.791654835253418, 0.0],
							[-123.99169921875, 39.800095956348393, 0.0],
							[-123.99169921875, 39.808536041445905, 0.0],
							[-123.99169921875, 39.816975090489997, 0.0],
							[-123.99169921875, 39.825413103424779, 0.0],
							[-123.99169921875, 39.833850080194466, 0.0],
							[-123.99169921875, 39.842286020743394, 0.0],
							[-123.99169921875, 39.850720925015963, 0.0],
							[-123.99169921875, 39.859154792956687, 0.0],
							[-123.99169921875, 39.867587624510186, 0.0],
							[-123.99169921875, 39.876019419621151, 0.0],
							[-123.99169921875, 39.884450178234388, 0.0],
							[-123.99169921875, 39.892879900294787, 0.0],
							[-123.99169921875, 39.901308585747358, 0.0],
							[-123.99169921875, 39.909736234537185, 0.0],
							[-123.99169921875, 39.918162846609448, 0.0],
							[-123.99169921875, 39.926588421909436, 0.0],
							[-123.99169921875, 39.935012960382537, 0.0],
							[-123.99169921875, 39.943436461974215, 0.0],
							[-123.99169921875, 39.951858926630038, 0.0],
							[-123.99169921875, 39.960280354295698, 0.0],
							[-123.99169921875, 39.968700744916937, 0.0],
							[-123.99169921875, 39.977120098439634, 0.0],
							[-123.99169921875, 39.985538414809731, 0.0],
							[-123.99169921875, 39.993955693973298, 0.0],
							[-124.002685546875, 39.993955693973298, 0.0],
							[-124.013671875, 39.993955693973298, 0.0],
							[-124.024658203125, 39.993955693973298, 0.0],
							[-124.024658203125, 40.002371935876475, 0.0],
							[-124.03564453125, 40.002371935876475, 0.0],
							[-124.046630859375, 40.002371935876475, 0.0],
							[-124.0576171875, 40.002371935876475, 0.0],
							[-124.068603515625, 40.002371935876475, 0.0],
							[-124.07958984375, 40.002371935876475, 0.0],
							[-124.090576171875, 40.002371935876475, 0.0],
							[-124.1015625, 40.002371935876475, 0.0],
							[-124.112548828125, 40.002371935876475, 0.0],
							[-124.12353515625, 40.002371935876475, 0.0],
							[-124.134521484375, 40.002371935876475, 0.0],
							[-124.1455078125, 40.002371935876475, 0.0],
							[-124.156494140625, 40.002371935876475, 0.0],
							[-124.16748046875, 40.002371935876475, 0.0],
							[-124.178466796875, 40.002371935876475, 0.0],
							[-124.189453125, 40.002371935876475, 0.0],
							[-124.200439453125, 40.002371935876475, 0.0],
							[-124.21142578125, 40.002371935876475, 0.0],
							[-124.222412109375, 40.002371935876475, 0.0],
							[-124.2333984375, 40.002371935876475, 0.0],
							[-124.244384765625, 40.002371935876475, 0.0],
							[-124.244384765625, 40.010787140465517, 0.0],
							[-124.244384765625, 40.019201307686757, 0.0],
							[-124.244384765625, 40.027614437486648, 0.0],
							[-124.244384765625, 40.036026529811707, 0.0],
							[-124.244384765625, 40.044437584608566, 0.0],
							[-124.244384765625, 40.05284760182397, 0.0],
							[-124.244384765625, 40.061256581404727, 0.0],
							[-124.244384765625, 40.069664523297767, 0.0],
							[-124.244384765625, 40.078071427450091, 0.0],
							[-124.244384765625, 40.086477293808812, 0.0],
							[-124.244384765625, 40.094882122321152, 0.0],
							[-124.244384765625, 40.103285912934403, 0.0],
							[-124.244384765625, 40.111688665595963, 0.0],
							[-124.244384765625, 40.120090380253323, 0.0],
							[-124.244384765625, 40.128491056854081, 0.0],
							[-124.244384765625, 40.136890695345905, 0.0],
							[-124.244384765625, 40.145289295676605, 0.0],
							[-124.244384765625, 40.153686857794035, 0.0],
							[-124.25537109375, 40.153686857794035, 0.0],
							[-124.25537109375, 40.162083381646177, 0.0],
							[-124.25537109375, 40.170478867181096, 0.0],
							[-124.25537109375, 40.178873314346959, 0.0],
							[-124.266357421875, 40.178873314346959, 0.0],
							[-124.266357421875, 40.18726672309203, 0.0],
							[-124.266357421875, 40.195659093364654, 0.0],
							[-124.27734375, 40.195659093364654, 0.0],
							[-124.27734375, 40.204050425113287, 0.0],
							[-124.288330078125, 40.204050425113287, 0.0],
							[-124.288330078125, 40.212440718286466, 0.0],
							[-124.29931640625, 40.212440718286466, 0.0],
							[-124.29931640625, 40.220829972832853, 0.0],
							[-124.310302734375, 40.220829972832853, 0.0],
							[-124.310302734375, 40.229218188701161, 0.0],
							[-124.3212890625, 40.229218188701161, 0.0],
							[-124.3212890625, 40.237605365840238, 0.0],
							[-124.332275390625, 40.237605365840238, 0.0],
							[-124.332275390625, 40.245991504199012, 0.0],
							[-124.34326171875, 40.245991504199012, 0.0],
							[-124.354248046875, 40.245991504199012, 0.0],
							[-124.365234375, 40.245991504199012, 0.0],
							[-124.365234375, 40.254376603726499, 0.0],
							[-124.376220703125, 40.254376603726499, 0.0],
							[-124.38720703125, 40.254376603726499, 0.0],
							[-124.398193359375, 40.254376603726499, 0.0],
							[-124.4091796875, 40.254376603726499, 0.0],
							[-124.420166015625, 40.254376603726499, 0.0],
							[-124.43115234375, 40.254376603726499, 0.0],
							[-124.442138671875, 40.254376603726499, 0.0],
							[-124.453125, 40.254376603726499, 0.0],
							[-124.464111328125, 40.254376603726499, 0.0],
							[-124.47509765625, 40.254376603726499, 0.0],
							[-124.486083984375, 40.254376603726499, 0.0],
							[-124.486083984375, 40.262760664371825, 0.0],
							[-124.486083984375, 40.271143686084187, 0.0],
							[-124.486083984375, 40.279525668812916, 0.0],
							[-124.486083984375, 40.287906612507399, 0.0],
							[-124.486083984375, 40.296286517117153, 0.0],
							[-124.486083984375, 40.304665382591757, 0.0],
							[-124.486083984375, 40.313043208880899, 0.0],
							[-124.486083984375, 40.321419995934377, 0.0],
							[-124.486083984375, 40.329795743702064, 0.0],
							[-124.486083984375, 40.33817045213393, 0.0],
							[-124.486083984375, 40.34654412118006, 0.0],
							[-124.486083984375, 40.354916750790608, 0.0],
							[-124.486083984375, 40.363288340915823, 0.0],
							[-124.486083984375, 40.371658891506087, 0.0],
							[-124.486083984375, 40.380028402511826, 0.0],
							[-124.486083984375, 40.3883968738836, 0.0],
							[-124.486083984375, 40.396764305572042, 0.0],
							[-124.486083984375, 40.405130697527888, 0.0],
							[-124.486083984375, 40.413496049701955, 0.0],
							[-124.486083984375, 40.421860362045194, 0.0],
							[-124.486083984375, 40.4302236345086, 0.0],
							[-124.486083984375, 40.438585867043294, 0.0],
							[-124.486083984375, 40.44694705960049, 0.0],
							[-124.486083984375, 40.45530721213148, 0.0],
							[-124.486083984375, 40.46366632458767, 0.0],
							[-124.486083984375, 40.472024396920553, 0.0],
							[-124.486083984375, 40.48038142908171, 0.0],
							[-124.486083984375, 40.488737421022819, 0.0],
							[-124.486083984375, 40.497092372695668, 0.0],
							[-124.486083984375, 40.505446284052113, 0.0],
							[-124.486083984375, 40.513799155044133, 0.0],
							[-124.486083984375, 40.522150985623774, 0.0],
							[-124.486083984375, 40.530501775743204, 0.0],
							[-124.486083984375, 40.538851525354659, 0.0],
							[-124.486083984375, 40.547200234410482, 0.0],
							[-124.486083984375, 40.555547902863104, 0.0],
							[-124.486083984375, 40.563894530665074, 0.0],
							[-124.486083984375, 40.57224011776902, 0.0],
							[-124.486083984375, 40.580584664127628, 0.0],
							[-124.486083984375, 40.588928169693745, 0.0],
							[-124.486083984375, 40.597270634420248, 0.0],
							[-124.486083984375, 40.605612058260178, 0.0],
							[-124.486083984375, 40.613952441166596, 0.0],
							[-124.486083984375, 40.622291783092706, 0.0],
							[-124.486083984375, 40.630630083991797, 0.0],
							[-124.486083984375, 40.638967343817235, 0.0],
							[-124.486083984375, 40.647303562522502, 0.0],
							[-124.486083984375, 40.655638740061164, 0.0],
							[-124.486083984375, 40.663972876386879, 0.0],
							[-124.486083984375, 40.672305971453397, 0.0],
							[-124.486083984375, 40.68063802521457, 0.0],
							[-124.486083984375, 40.688969037624339, 0.0],
							[-124.486083984375, 40.69729900863674, 0.0],
							[-124.486083984375, 40.7056279382059, 0.0],
							[-124.486083984375, 40.713955826286039, 0.0],
							[-124.486083984375, 40.72228267283149, 0.0],
							[-124.486083984375, 40.730608477796643, 0.0],
							[-124.486083984375, 40.738933241136017, 0.0],
							[-124.486083984375, 40.747256962804208, 0.0],
							[-124.47509765625, 40.747256962804208, 0.0],
							[-124.464111328125, 40.747256962804208, 0.0],
							[-124.453125, 40.747256962804208, 0.0],
							[-124.442138671875, 40.747256962804208, 0.0],
							[-124.43115234375, 40.747256962804208, 0.0],
							[-124.420166015625, 40.747256962804208, 0.0],
							[-124.4091796875, 40.747256962804208, 0.0],
							[-124.398193359375, 40.747256962804208, 0.0],
							[-124.38720703125, 40.747256962804208, 0.0],
							[-124.376220703125, 40.747256962804208, 0.0],
							[-124.365234375, 40.747256962804208, 0.0],
							[-124.354248046875, 40.747256962804208, 0.0],
							[-124.34326171875, 40.747256962804208, 0.0],
							[-124.332275390625, 40.747256962804208, 0.0],
							[-124.3212890625, 40.747256962804208, 0.0],
							[-124.310302734375, 40.747256962804208, 0.0],
							[-124.29931640625, 40.747256962804208, 0.0],
							[-124.288330078125, 40.747256962804208, 0.0],
							[-124.27734375, 40.747256962804208, 0.0],
							[-124.266357421875, 40.747256962804208, 0.0],
							[-124.25537109375, 40.747256962804208, 0.0],
							[-124.244384765625, 40.747256962804208, 0.0],
							[-124.244384765625, 40.738933241136017, 0.0],
							[-124.244384765625, 40.730608477796643, 0.0],
							[-124.244384765625, 40.72228267283149, 0.0],
							[-124.244384765625, 40.713955826286039, 0.0],
							[-124.244384765625, 40.7056279382059, 0.0],
							[-124.244384765625, 40.69729900863674, 0.0],
							[-124.25537109375, 40.69729900863674, 0.0],
							[-124.266357421875, 40.69729900863674, 0.0],
							[-124.27734375, 40.69729900863674, 0.0],
							[-124.288330078125, 40.69729900863674, 0.0],
							[-124.29931640625, 40.69729900863674, 0.0],
							[-124.29931640625, 40.688969037624339, 0.0],
							[-124.288330078125, 40.688969037624339, 0.0],
							[-124.27734375, 40.688969037624339, 0.0],
							[-124.266357421875, 40.688969037624339, 0.0],
							[-124.25537109375, 40.688969037624339, 0.0],
							[-124.244384765625, 40.688969037624339, 0.0],
							[-124.2333984375, 40.688969037624339, 0.0],
							[-124.2333984375, 40.68063802521457, 0.0],
							[-124.222412109375, 40.68063802521457, 0.0],
							[-124.21142578125, 40.68063802521457, 0.0],
							[-124.21142578125, 40.672305971453397, 0.0],
							[-124.21142578125, 40.663972876386879, 0.0],
							[-124.200439453125, 40.663972876386879, 0.0],
							[-124.200439453125, 40.655638740061164, 0.0],
							[-124.189453125, 40.655638740061164, 0.0],
							[-124.189453125, 40.647303562522502, 0.0],
							[-124.189453125, 40.638967343817235, 0.0],
							[-124.178466796875, 40.638967343817235, 0.0],
							[-124.16748046875, 40.638967343817235, 0.0],
							[-124.156494140625, 40.638967343817235, 0.0],
							[-124.1455078125, 40.638967343817235, 0.0],
							[-124.134521484375, 40.638967343817235, 0.0],
							[-124.12353515625, 40.638967343817235, 0.0],
							[-124.12353515625, 40.647303562522502, 0.0],
							[-124.112548828125, 40.647303562522502, 0.0],
							[-124.112548828125, 40.638967343817235, 0.0],
							[-124.1015625, 40.638967343817235, 0.0],
							[-124.090576171875, 40.638967343817235, 0.0],
							[-124.07958984375, 40.638967343817235, 0.0],
							[-124.068603515625, 40.638967343817235, 0.0],
							[-124.068603515625, 40.630630083991797, 0.0],
							[-124.0576171875, 40.630630083991797, 0.0],
							[-124.046630859375, 40.630630083991797, 0.0],
							[-124.03564453125, 40.630630083991797, 0.0],
							[-124.03564453125, 40.638967343817235, 0.0],
							[-124.03564453125, 40.647303562522502, 0.0],
							[-124.03564453125, 40.655638740061164, 0.0],
							[-124.024658203125, 40.655638740061164, 0.0],
							[-124.013671875, 40.655638740061164, 0.0],
							[-124.013671875, 40.663972876386879, 0.0],
							[-124.002685546875, 40.663972876386879, 0.0],
							[-124.002685546875, 40.672305971453397, 0.0],
							[-123.99169921875, 40.672305971453397, 0.0],
							[-123.99169921875, 40.68063802521457, 0.0],
							[-123.99169921875, 40.688969037624339, 0.0],
							[-123.99169921875, 40.69729900863674, 0.0],
							[-124.002685546875, 40.69729900863674, 0.0],
							[-124.002685546875, 40.7056279382059, 0.0],
							[-124.002685546875, 40.713955826286039, 0.0],
							[-124.002685546875, 40.72228267283149, 0.0],
							[-124.002685546875, 40.730608477796643, 0.0],
							[-123.99169921875, 40.730608477796643, 0.0],
							[-123.980712890625, 40.730608477796643, 0.0],
							[-123.9697265625, 40.730608477796643, 0.0],
							[-123.9697265625, 40.738933241136017, 0.0],
							[-123.958740234375, 40.738933241136017, 0.0],
							[-123.94775390625, 40.738933241136017, 0.0],
							[-123.94775390625, 40.730608477796643, 0.0],
							[-123.936767578125, 40.730608477796643, 0.0],
							[-123.92578125, 40.730608477796643, 0.0],
							[-123.914794921875, 40.730608477796643, 0.0],
							[-123.914794921875, 40.72228267283149, 0.0],
							[-123.90380859375, 40.72228267283149, 0.0],
							[-123.892822265625, 40.72228267283149, 0.0],
							[-123.8818359375, 40.72228267283149, 0.0],
							[-123.8818359375, 40.713955826286039, 0.0],
							[-123.8818359375, 40.7056279382059, 0.0],
							[-123.8818359375, 40.69729900863674, 0.0],
							[-123.870849609375, 40.69729900863674, 0.0],
							[-123.870849609375, 40.688969037624339, 0.0],
							[-123.870849609375, 40.68063802521457, 0.0],
							[-123.870849609375, 40.672305971453397, 0.0],
							[-123.85986328125, 40.672305971453397, 0.0],
							[-123.85986328125, 40.663972876386879, 0.0],
							[-123.85986328125, 40.655638740061164, 0.0],
							[-123.848876953125, 40.655638740061164, 0.0],
							[-123.837890625, 40.655638740061164, 0.0],
							[-123.826904296875, 40.655638740061164, 0.0],
							[-123.826904296875, 40.647303562522502, 0.0],
							[-123.81591796875, 40.647303562522502, 0.0],
							[-123.804931640625, 40.647303562522502, 0.0],
							[-123.804931640625, 40.638967343817235, 0.0],
							[-123.804931640625, 40.630630083991797, 0.0],
							[-123.7939453125, 40.630630083991797, 0.0],
							[-123.782958984375, 40.630630083991797, 0.0],
							[-123.782958984375, 40.638967343817235, 0.0],
							[-123.77197265625, 40.638967343817235, 0.0],
							[-123.77197265625, 40.630630083991797, 0.0],
							[-123.760986328125, 40.630630083991797, 0.0],
							[-123.75, 40.630630083991797, 0.0],
							[-123.75, 40.622291783092706, 0.0],
							[-123.75, 40.613952441166596, 0.0],
							[-123.75, 40.605612058260178, 0.0],
							[-123.739013671875, 40.605612058260178, 0.0],
							[-123.739013671875, 40.613952441166596, 0.0],
							[-123.739013671875, 40.622291783092706, 0.0],
							[-123.72802734375, 40.622291783092706, 0.0],
							[-123.72802734375, 40.613952441166596, 0.0],
							[-123.72802734375, 40.605612058260178, 0.0],
							[-123.72802734375, 40.597270634420248, 0.0],
							[-123.717041015625, 40.597270634420248, 0.0],
							[-123.717041015625, 40.588928169693745, 0.0],
							[-123.717041015625, 40.580584664127628, 0.0],
							[-123.7060546875, 40.580584664127628, 0.0],
							[-123.695068359375, 40.580584664127628, 0.0],
							[-123.68408203125, 40.580584664127628, 0.0],
							[-123.68408203125, 40.57224011776902, 0.0],
							[-123.68408203125, 40.563894530665074, 0.0],
							[-123.68408203125, 40.555547902863104, 0.0],
							[-123.68408203125, 40.547200234410482, 0.0],
							[-123.673095703125, 40.547200234410482, 0.0],
							[-123.662109375, 40.547200234410482, 0.0],
							[-123.651123046875, 40.547200234410482, 0.0],
							[-123.651123046875, 40.538851525354659, 0.0],
							[-123.651123046875, 40.530501775743204, 0.0],
							[-123.64013671875, 40.530501775743204, 0.0],
							[-123.64013671875, 40.522150985623774, 0.0],
							[-123.629150390625, 40.522150985623774, 0.0],
							[-123.629150390625, 40.513799155044133, 0.0],
							[-123.6181640625, 40.513799155044133, 0.0],
							[-123.607177734375, 40.513799155044133, 0.0],
							[-123.59619140625, 40.513799155044133, 0.0],
							[-123.585205078125, 40.513799155044133, 0.0],
							[-123.585205078125, 40.505446284052113, 0.0],
							[-123.57421875, 40.505446284052113, 0.0],
							[-123.563232421875, 40.505446284052113, 0.0],
							[-123.55224609375, 40.505446284052113, 0.0],
							[-123.55224609375, 40.497092372695668, 0.0],
							[-123.541259765625, 40.497092372695668, 0.0],
							[-123.541259765625, 40.488737421022819, 0.0],
							[-123.5302734375, 40.488737421022819, 0.0],
							[-123.5302734375, 40.48038142908171, 0.0],
							[-123.519287109375, 40.48038142908171, 0.0],
							[-123.50830078125, 40.48038142908171, 0.0],
							[-123.50830078125, 40.472024396920553, 0.0],
							[-123.50830078125, 40.46366632458767, 0.0],
							[-123.50830078125, 40.45530721213148, 0.0],
							[-123.497314453125, 40.45530721213148, 0.0],
							[-123.497314453125, 40.44694705960049, 0.0],
							[-123.497314453125, 40.438585867043294, 0.0],
							[-123.497314453125, 40.4302236345086, 0.0],
							[-123.486328125, 40.4302236345086, 0.0],
							[-123.475341796875, 40.4302236345086, 0.0],
							[-123.475341796875, 40.421860362045194, 0.0],
							[-123.475341796875, 40.413496049701955, 0.0],
							[-123.475341796875, 40.405130697527888, 0.0],
							[-123.475341796875, 40.396764305572042, 0.0],
							[-123.46435546875, 40.396764305572042, 0.0],
							[-123.46435546875, 40.3883968738836, 0.0],
							[-123.46435546875, 40.380028402511826, 0.0],
							[-123.46435546875, 40.371658891506087, 0.0],
							[-123.453369140625, 40.371658891506087, 0.0],
							[-123.453369140625, 40.363288340915823, 0.0],
							[-123.453369140625, 40.354916750790608, 0.0],
							[-123.4423828125, 40.354916750790608, 0.0],
							[-123.431396484375, 40.354916750790608, 0.0],
							[-123.431396484375, 40.34654412118006, 0.0],
							[-123.42041015625, 40.34654412118006, 0.0],
							[-123.42041015625, 40.33817045213393, 0.0],
							[-123.42041015625, 40.329795743702064, 0.0],
							[-123.42041015625, 40.321419995934377, 0.0],
							[-123.409423828125, 40.321419995934377, 0.0],
							[-123.409423828125, 40.313043208880899, 0.0],
							[-123.409423828125, 40.304665382591757, 0.0],
							[-123.3984375, 40.304665382591757, 0.0],
							[-123.3984375, 40.296286517117153, 0.0],
							[-123.387451171875, 40.296286517117153, 0.0],
							[-123.37646484375, 40.296286517117153, 0.0],
							[-123.365478515625, 40.296286517117153, 0.0],
							[-123.365478515625, 40.287906612507399, 0.0],
							[-123.365478515625, 40.279525668812916, 0.0],
							[-123.365478515625, 40.271143686084187, 0.0],
							[-123.3544921875, 40.271143686084187, 0.0],
							[-123.3544921875, 40.262760664371825, 0.0],
							[-123.3544921875, 40.254376603726499, 0.0],
							[-123.3544921875, 40.245991504199012, 0.0],
							[-123.3544921875, 40.237605365840238, 0.0],
							[-123.3544921875, 40.229218188701161, 0.0],
							[-123.343505859375, 40.229218188701161, 0.0],
							[-123.33251953125, 40.229218188701161, 0.0],
							[-123.33251953125, 40.220829972832853, 0.0],
							[-123.33251953125, 40.212440718286466, 0.0],
							[-123.33251953125, 40.204050425113287, 0.0],
							[-123.321533203125, 40.204050425113287, 0.0],
							[-123.310546875, 40.204050425113287, 0.0],
							[-123.310546875, 40.195659093364654, 0.0],
							[-123.310546875, 40.18726672309203, 0.0],
							[-123.310546875, 40.178873314346959, 0.0],
							[-123.299560546875, 40.178873314346959, 0.0],
							[-123.28857421875, 40.178873314346959, 0.0],
							[-123.28857421875, 40.170478867181096, 0.0],
							[-123.28857421875, 40.162083381646177, 0.0],
							[-123.277587890625, 40.162083381646177, 0.0],
							[-123.277587890625, 40.153686857794035, 0.0],
							[-123.2666015625, 40.153686857794035, 0.0],
							[-123.255615234375, 40.153686857794035, 0.0],
							[-123.255615234375, 40.145289295676605, 0.0],
							[-123.2666015625, 40.145289295676605, 0.0],
							[-123.2666015625, 40.136890695345905, 0.0],
							[-123.2666015625, 40.128491056854081, 0.0],
							[-123.255615234375, 40.128491056854081, 0.0],
							[-123.255615234375, 40.120090380253323, 0.0],
							[-123.24462890625, 40.120090380253323, 0.0],
							[-123.24462890625, 40.128491056854081, 0.0],
							[-123.233642578125, 40.128491056854081, 0.0],
							[-123.233642578125, 40.120090380253323, 0.0],
							[-123.233642578125, 40.111688665595963, 0.0],
							[-123.22265625, 40.111688665595963, 0.0],
							[-123.22265625, 40.103285912934403, 0.0],
							[-123.211669921875, 40.103285912934403, 0.0],
							[-123.20068359375, 40.103285912934403, 0.0],
							[-123.20068359375, 40.094882122321152, 0.0],
							[-123.189697265625, 40.094882122321152, 0.0],
							[-123.1787109375, 40.094882122321152, 0.0],
							[-123.167724609375, 40.094882122321152, 0.0],
							[-123.15673828125, 40.094882122321152, 0.0],
							[-123.145751953125, 40.094882122321152, 0.0],
							[-123.145751953125, 40.103285912934403, 0.0],
							[-123.145751953125, 40.111688665595963, 0.0],
							[-123.145751953125, 40.120090380253323, 0.0],
							[-123.145751953125, 40.128491056854081, 0.0],
							[-123.134765625, 40.128491056854081, 0.0],
							[-123.134765625, 40.136890695345905, 0.0],
							[-123.123779296875, 40.136890695345905, 0.0],
							[-123.123779296875, 40.145289295676605, 0.0],
							[-123.11279296875, 40.145289295676605, 0.0],
							[-123.101806640625, 40.145289295676605, 0.0],
							[-123.101806640625, 40.136890695345905, 0.0],
							[-123.0908203125, 40.136890695345905, 0.0],
							[-123.0908203125, 40.145289295676605, 0.0],
							[-123.0908203125, 40.153686857794035, 0.0],
							[-123.079833984375, 40.153686857794035, 0.0],
							[-123.06884765625, 40.153686857794035, 0.0],
							[-123.06884765625, 40.145289295676605, 0.0],
							[-123.06884765625, 40.136890695345905, 0.0],
							[-123.057861328125, 40.136890695345905, 0.0],
							[-123.046875, 40.136890695345905, 0.0],
							[-123.046875, 40.145289295676605, 0.0],
							[-123.035888671875, 40.145289295676605, 0.0],
							[-123.02490234375, 40.145289295676605, 0.0],
							[-123.02490234375, 40.136890695345905, 0.0],
							[-123.013916015625, 40.136890695345905, 0.0],
							[-123.0029296875, 40.136890695345905, 0.0],
							[-123.0029296875, 40.145289295676605, 0.0],
							[-122.991943359375, 40.145289295676605, 0.0],
							[-122.98095703125, 40.145289295676605, 0.0],
							[-122.969970703125, 40.145289295676605, 0.0],
							[-122.969970703125, 40.136890695345905, 0.0],
							[-122.969970703125, 40.128491056854081, 0.0],
							[-122.969970703125, 40.120090380253323, 0.0],
							[-122.958984375, 40.120090380253323, 0.0],
							[-122.958984375, 40.111688665595963, 0.0],
							[-122.958984375, 40.103285912934403, 0.0],
							[-122.969970703125, 40.103285912934403, 0.0],
							[-122.969970703125, 40.094882122321152, 0.0],
							[-122.958984375, 40.094882122321152, 0.0],
							[-122.958984375, 40.086477293808812, 0.0],
							[-122.969970703125, 40.086477293808812, 0.0],
							[-122.969970703125, 40.078071427450091, 0.0],
							[-122.969970703125, 40.069664523297767, 0.0],
							[-122.958984375, 40.069664523297767, 0.0],
							[-122.958984375, 40.061256581404727, 0.0],
							[-122.947998046875, 40.061256581404727, 0.0],
							[-122.93701171875, 40.061256581404727, 0.0],
							[-122.926025390625, 40.061256581404727, 0.0],
							[-122.9150390625, 40.061256581404727, 0.0],
							[-122.904052734375, 40.061256581404727, 0.0],
							[-122.904052734375, 40.05284760182397, 0.0],
							[-122.89306640625, 40.05284760182397, 0.0],
							[-122.89306640625, 40.044437584608566, 0.0],
							[-122.882080078125, 40.044437584608566, 0.0],
							[-122.87109375, 40.044437584608566, 0.0],
							[-122.87109375, 40.05284760182397, 0.0],
							[-122.860107421875, 40.05284760182397, 0.0],
							[-122.84912109375, 40.05284760182397, 0.0],
							[-122.84912109375, 40.044437584608566, 0.0],
							[-122.838134765625, 40.044437584608566, 0.0],
							[-122.8271484375, 40.044437584608566, 0.0],
							[-122.816162109375, 40.044437584608566, 0.0],
							[-122.80517578125, 40.044437584608566, 0.0],
							[-122.794189453125, 40.044437584608566, 0.0],
							[-122.783203125, 40.044437584608566, 0.0],
							[-122.783203125, 40.05284760182397, 0.0],
							[-122.772216796875, 40.05284760182397, 0.0],
							[-122.76123046875, 40.05284760182397, 0.0],
							[-122.76123046875, 40.061256581404727, 0.0],
							[-122.750244140625, 40.061256581404727, 0.0],
							[-122.7392578125, 40.061256581404727, 0.0],
							[-122.728271484375, 40.061256581404727, 0.0],
							[-122.728271484375, 40.05284760182397, 0.0],
							[-122.71728515625, 40.05284760182397, 0.0],
							[-122.706298828125, 40.05284760182397, 0.0],
							[-122.706298828125, 40.061256581404727, 0.0],
							[-122.6953125, 40.061256581404727, 0.0],
							[-122.6953125, 40.05284760182397, 0.0],
							[-122.684326171875, 40.05284760182397, 0.0],
							[-122.67333984375, 40.05284760182397, 0.0],
							[-122.662353515625, 40.05284760182397, 0.0],
							[-122.662353515625, 40.044437584608566, 0.0],
							[-122.6513671875, 40.044437584608566, 0.0],
							[-122.640380859375, 40.044437584608566, 0.0],
							[-122.62939453125, 40.044437584608566, 0.0],
							[-122.618408203125, 40.044437584608566, 0.0],
							[-122.618408203125, 40.05284760182397, 0.0],
							[-122.618408203125, 40.061256581404727, 0.0],
							[-122.607421875, 40.061256581404727, 0.0],
							[-122.596435546875, 40.061256581404727, 0.0],
							[-122.58544921875, 40.061256581404727, 0.0],
							[-122.574462890625, 40.061256581404727, 0.0],
							[-122.574462890625, 40.069664523297767, 0.0],
							[-122.5634765625, 40.069664523297767, 0.0],
							[-122.552490234375, 40.069664523297767, 0.0],
							[-122.552490234375, 40.061256581404727, 0.0],
							[-122.54150390625, 40.061256581404727, 0.0],
							[-122.54150390625, 40.05284760182397, 0.0],
							[-122.530517578125, 40.05284760182397, 0.0],
							[-122.530517578125, 40.061256581404727, 0.0],
							[-122.51953125, 40.061256581404727, 0.0],
							[-122.508544921875, 40.061256581404727, 0.0],
							[-122.49755859375, 40.061256581404727, 0.0],
							[-122.486572265625, 40.061256581404727, 0.0],
							[-122.486572265625, 40.069664523297767, 0.0],
							[-122.4755859375, 40.069664523297767, 0.0],
							[-122.464599609375, 40.069664523297767, 0.0],
							[-122.45361328125, 40.069664523297767, 0.0],
							[-122.45361328125, 40.078071427450091, 0.0],
							[-122.442626953125, 40.078071427450091, 0.0],
							[-122.442626953125, 40.086477293808812, 0.0],
							[-122.431640625, 40.086477293808812, 0.0],
							[-122.420654296875, 40.086477293808812, 0.0],
							[-122.420654296875, 40.094882122321152, 0.0],
							[-122.40966796875, 40.094882122321152, 0.0],
							[-122.398681640625, 40.094882122321152, 0.0],
							[-122.3876953125, 40.094882122321152, 0.0],
							[-122.376708984375, 40.094882122321152, 0.0],
							[-122.376708984375, 40.103285912934403, 0.0],
							[-122.36572265625, 40.103285912934403, 0.0],
							[-122.354736328125, 40.103285912934403, 0.0],
							[-122.34375, 40.103285912934403, 0.0],
							[-122.34375, 40.111688665595963, 0.0],
							[-122.332763671875, 40.111688665595963, 0.0],
							[-122.332763671875, 40.120090380253323, 0.0],
							[-122.32177734375, 40.120090380253323, 0.0],
							[-122.310791015625, 40.120090380253323, 0.0],
							[-122.310791015625, 40.128491056854081, 0.0],
							[-122.2998046875, 40.128491056854081, 0.0],
							[-122.288818359375, 40.128491056854081, 0.0],
							[-122.27783203125, 40.128491056854081, 0.0],
							[-122.266845703125, 40.128491056854081, 0.0],
							[-122.266845703125, 40.136890695345905, 0.0],
							[-122.255859375, 40.136890695345905, 0.0],
							[-122.244873046875, 40.136890695345905, 0.0],
							[-122.23388671875, 40.136890695345905, 0.0],
							[-122.222900390625, 40.136890695345905, 0.0],
							[-122.222900390625, 40.145289295676605, 0.0],
							[-122.222900390625, 40.153686857794035, 0.0],
							[-122.222900390625, 40.162083381646177, 0.0],
							[-122.23388671875, 40.162083381646177, 0.0],
							[-122.23388671875, 40.170478867181096, 0.0],
							[-122.23388671875, 40.178873314346959, 0.0],
							[-122.23388671875, 40.18726672309203, 0.0],
							[-122.23388671875, 40.195659093364654, 0.0],
							[-122.244873046875, 40.195659093364654, 0.0],
							[-122.244873046875, 40.204050425113287, 0.0],
							[-122.23388671875, 40.204050425113287, 0.0],
							[-122.222900390625, 40.204050425113287, 0.0],
							[-122.222900390625, 40.212440718286466, 0.0],
							[-122.222900390625, 40.220829972832853, 0.0],
							[-122.2119140625, 40.220829972832853, 0.0],
							[-122.200927734375, 40.220829972832853, 0.0],
							[-122.18994140625, 40.220829972832853, 0.0],
							[-122.178955078125, 40.220829972832853, 0.0],
							[-122.178955078125, 40.229218188701161, 0.0],
							[-122.178955078125, 40.237605365840238, 0.0],
							[-122.16796875, 40.237605365840238, 0.0],
							[-122.156982421875, 40.237605365840238, 0.0],
							[-122.14599609375, 40.237605365840238, 0.0],
							[-122.14599609375, 40.245991504199012, 0.0],
							[-122.135009765625, 40.245991504199012, 0.0],
							[-122.135009765625, 40.254376603726499, 0.0],
							[-122.1240234375, 40.254376603726499, 0.0],
							[-122.1240234375, 40.262760664371825, 0.0],
							[-122.113037109375, 40.262760664371825, 0.0],
							[-122.113037109375, 40.271143686084187, 0.0],
							[-122.113037109375, 40.279525668812916, 0.0],
							[-122.1240234375, 40.279525668812916, 0.0],
							[-122.135009765625, 40.279525668812916, 0.0],
							[-122.14599609375, 40.279525668812916, 0.0],
							[-122.14599609375, 40.271143686084187, 0.0],
							[-122.156982421875, 40.271143686084187, 0.0],
							[-122.16796875, 40.271143686084187, 0.0],
							[-122.178955078125, 40.271143686084187, 0.0],
							[-122.178955078125, 40.262760664371825, 0.0],
							[-122.18994140625, 40.262760664371825, 0.0],
							[-122.18994140625, 40.271143686084187, 0.0],
							[-122.18994140625, 40.279525668812916, 0.0],
							[-122.178955078125, 40.279525668812916, 0.0],
							[-122.16796875, 40.279525668812916, 0.0],
							[-122.16796875, 40.287906612507399, 0.0],
							[-122.156982421875, 40.287906612507399, 0.0],
							[-122.156982421875, 40.296286517117153, 0.0],
							[-122.14599609375, 40.296286517117153, 0.0],
							[-122.135009765625, 40.296286517117153, 0.0],
							[-122.135009765625, 40.304665382591757, 0.0],
							[-122.1240234375, 40.304665382591757, 0.0],
							[-122.113037109375, 40.304665382591757, 0.0],
							[-122.10205078125, 40.304665382591757, 0.0],
							[-122.10205078125, 40.313043208880899, 0.0],
							[-122.091064453125, 40.313043208880899, 0.0],
							[-122.080078125, 40.313043208880899, 0.0],
							[-122.080078125, 40.321419995934377, 0.0],
							[-122.069091796875, 40.321419995934377, 0.0],
							[-122.069091796875, 40.329795743702064, 0.0],
							[-122.069091796875, 40.33817045213393, 0.0],
							[-122.05810546875, 40.33817045213393, 0.0],
							[-122.047119140625, 40.33817045213393, 0.0],
							[-122.0361328125, 40.33817045213393, 0.0],
							[-122.025146484375, 40.33817045213393, 0.0],
							[-122.025146484375, 40.34654412118006, 0.0],
							[-122.025146484375, 40.354916750790608, 0.0],
							[-122.025146484375, 40.363288340915823, 0.0],
							[-122.025146484375, 40.371658891506087, 0.0],
							[-122.025146484375, 40.380028402511826, 0.0],
							[-122.0361328125, 40.380028402511826, 0.0],
							[-122.047119140625, 40.380028402511826, 0.0],
							[-122.05810546875, 40.380028402511826, 0.0],
							[-122.05810546875, 40.3883968738836, 0.0],
							[-122.069091796875, 40.3883968738836, 0.0],
							[-122.080078125, 40.3883968738836, 0.0],
							[-122.080078125, 40.380028402511826, 0.0],
							[-122.091064453125, 40.380028402511826, 0.0],
							[-122.10205078125, 40.380028402511826, 0.0],
							[-122.113037109375, 40.380028402511826, 0.0],
							[-122.113037109375, 40.371658891506087, 0.0],
							[-122.113037109375, 40.363288340915823, 0.0],
							[-122.1240234375, 40.363288340915823, 0.0],
							[-122.135009765625, 40.363288340915823, 0.0],
							[-122.135009765625, 40.354916750790608, 0.0],
							[-122.14599609375, 40.354916750790608, 0.0],
							[-122.14599609375, 40.34654412118006, 0.0],
							[-122.156982421875, 40.34654412118006, 0.0],
							[-122.16796875, 40.34654412118006, 0.0],
							[-122.16796875, 40.354916750790608, 0.0],
							[-122.178955078125, 40.354916750790608, 0.0],
							[-122.178955078125, 40.363288340915823, 0.0],
							[-122.18994140625, 40.363288340915823, 0.0],
							[-122.200927734375, 40.363288340915823, 0.0],
							[-122.2119140625, 40.363288340915823, 0.0],
							[-122.2119140625, 40.371658891506087, 0.0],
							[-122.200927734375, 40.371658891506087, 0.0],
							[-122.200927734375, 40.380028402511826, 0.0],
							[-122.2119140625, 40.380028402511826, 0.0],
							[-122.2119140625, 40.3883968738836, 0.0],
							[-122.222900390625, 40.3883968738836, 0.0],
							[-122.23388671875, 40.3883968738836, 0.0],
							[-122.23388671875, 40.396764305572042, 0.0],
							[-122.244873046875, 40.396764305572042, 0.0],
							[-122.244873046875, 40.405130697527888, 0.0],
							[-122.244873046875, 40.413496049701955, 0.0],
							[-122.255859375, 40.413496049701955, 0.0],
							[-122.266845703125, 40.413496049701955, 0.0],
							[-122.266845703125, 40.405130697527888, 0.0],
							[-122.27783203125, 40.405130697527888, 0.0],
							[-122.27783203125, 40.413496049701955, 0.0],
							[-122.288818359375, 40.413496049701955, 0.0],
							[-122.2998046875, 40.413496049701955, 0.0],
							[-122.2998046875, 40.421860362045194, 0.0],
							[-122.310791015625, 40.421860362045194, 0.0],
							[-122.310791015625, 40.413496049701955, 0.0],
							[-122.32177734375, 40.413496049701955, 0.0],
							[-122.332763671875, 40.413496049701955, 0.0],
							[-122.34375, 40.413496049701955, 0.0],
							[-122.354736328125, 40.413496049701955, 0.0],
							[-122.354736328125, 40.421860362045194, 0.0],
							[-122.36572265625, 40.421860362045194, 0.0],
							[-122.376708984375, 40.421860362045194, 0.0],
							[-122.376708984375, 40.4302236345086, 0.0],
							[-122.3876953125, 40.4302236345086, 0.0],
							[-122.398681640625, 40.4302236345086, 0.0],
							[-122.40966796875, 40.4302236345086, 0.0],
							[-122.420654296875, 40.4302236345086, 0.0],
							[-122.420654296875, 40.438585867043294, 0.0],
							[-122.431640625, 40.438585867043294, 0.0],
							[-122.431640625, 40.44694705960049, 0.0],
							[-122.442626953125, 40.44694705960049, 0.0],
							[-122.45361328125, 40.44694705960049, 0.0],
							[-122.464599609375, 40.44694705960049, 0.0],
							[-122.464599609375, 40.45530721213148, 0.0],
							[-122.4755859375, 40.45530721213148, 0.0],
							[-122.486572265625, 40.45530721213148, 0.0],
							[-122.486572265625, 40.44694705960049, 0.0],
							[-122.49755859375, 40.44694705960049, 0.0],
							[-122.508544921875, 40.44694705960049, 0.0],
							[-122.508544921875, 40.438585867043294, 0.0],
							[-122.51953125, 40.438585867043294, 0.0],
							[-122.51953125, 40.4302236345086, 0.0],
							[-122.508544921875, 40.4302236345086, 0.0],
							[-122.508544921875, 40.421860362045194, 0.0],
							[-122.51953125, 40.421860362045194, 0.0],
							[-122.51953125, 40.413496049701955, 0.0],
							[-122.51953125, 40.405130697527888, 0.0],
							[-122.51953125, 40.396764305572042, 0.0],
							[-122.51953125, 40.3883968738836, 0.0],
							[-122.51953125, 40.380028402511826, 0.0],
							[-122.51953125, 40.371658891506087, 0.0],
							[-122.51953125, 40.363288340915823, 0.0],
							[-122.530517578125, 40.363288340915823, 0.0],
							[-122.54150390625, 40.363288340915823, 0.0],
							[-122.552490234375, 40.363288340915823, 0.0],
							[-122.552490234375, 40.354916750790608, 0.0],
							[-122.552490234375, 40.34654412118006, 0.0],
							[-122.5634765625, 40.34654412118006, 0.0],
							[-122.574462890625, 40.34654412118006, 0.0],
							[-122.58544921875, 40.34654412118006, 0.0],
							[-122.58544921875, 40.33817045213393, 0.0],
							[-122.596435546875, 40.33817045213393, 0.0],
							[-122.607421875, 40.33817045213393, 0.0],
							[-122.607421875, 40.329795743702064, 0.0],
							[-122.618408203125, 40.329795743702064, 0.0],
							[-122.618408203125, 40.321419995934377, 0.0],
							[-122.618408203125, 40.313043208880899, 0.0],
							[-122.62939453125, 40.313043208880899, 0.0],
							[-122.640380859375, 40.313043208880899, 0.0],
							[-122.640380859375, 40.321419995934377, 0.0],
							[-122.6513671875, 40.321419995934377, 0.0],
							[-122.6513671875, 40.313043208880899, 0.0],
							[-122.6513671875, 40.304665382591757, 0.0],
							[-122.662353515625, 40.304665382591757, 0.0],
							[-122.662353515625, 40.313043208880899, 0.0],
							[-122.67333984375, 40.313043208880899, 0.0],
							[-122.684326171875, 40.313043208880899, 0.0],
							[-122.6953125, 40.313043208880899, 0.0],
							[-122.6953125, 40.304665382591757, 0.0],
							[-122.706298828125, 40.304665382591757, 0.0],
							[-122.706298828125, 40.313043208880899, 0.0],
							[-122.71728515625, 40.313043208880899, 0.0],
							[-122.728271484375, 40.313043208880899, 0.0],
							[-122.728271484375, 40.304665382591757, 0.0],
							[-122.7392578125, 40.304665382591757, 0.0],
							[-122.750244140625, 40.304665382591757, 0.0],
							[-122.750244140625, 40.313043208880899, 0.0],
							[-122.76123046875, 40.313043208880899, 0.0],
							[-122.772216796875, 40.313043208880899, 0.0],
							[-122.772216796875, 40.321419995934377, 0.0],
							[-122.783203125, 40.321419995934377, 0.0],
							[-122.794189453125, 40.321419995934377, 0.0],
							[-122.80517578125, 40.321419995934377, 0.0],
							[-122.80517578125, 40.329795743702064, 0.0],
							[-122.816162109375, 40.329795743702064, 0.0],
							[-122.8271484375, 40.329795743702064, 0.0],
							[-122.8271484375, 40.33817045213393, 0.0],
							[-122.816162109375, 40.33817045213393, 0.0],
							[-122.80517578125, 40.33817045213393, 0.0],
							[-122.80517578125, 40.34654412118006, 0.0],
							[-122.794189453125, 40.34654412118006, 0.0],
							[-122.783203125, 40.34654412118006, 0.0],
							[-122.783203125, 40.354916750790608, 0.0],
							[-122.772216796875, 40.354916750790608, 0.0],
							[-122.772216796875, 40.363288340915823, 0.0],
							[-122.772216796875, 40.371658891506087, 0.0],
							[-122.783203125, 40.371658891506087, 0.0],
							[-122.794189453125, 40.371658891506087, 0.0],
							[-122.80517578125, 40.371658891506087, 0.0],
							[-122.816162109375, 40.371658891506087, 0.0],
							[-122.816162109375, 40.380028402511826, 0.0],
							[-122.8271484375, 40.380028402511826, 0.0],
							[-122.838134765625, 40.380028402511826, 0.0],
							[-122.838134765625, 40.3883968738836, 0.0],
							[-122.84912109375, 40.3883968738836, 0.0],
							[-122.84912109375, 40.380028402511826, 0.0],
							[-122.860107421875, 40.380028402511826, 0.0],
							[-122.860107421875, 40.371658891506087, 0.0],
							[-122.87109375, 40.371658891506087, 0.0],
							[-122.882080078125, 40.371658891506087, 0.0],
							[-122.882080078125, 40.363288340915823, 0.0],
							[-122.89306640625, 40.363288340915823, 0.0],
							[-122.89306640625, 40.354916750790608, 0.0],
							[-122.89306640625, 40.34654412118006, 0.0],
							[-122.904052734375, 40.34654412118006, 0.0],
							[-122.904052734375, 40.33817045213393, 0.0],
							[-122.9150390625, 40.33817045213393, 0.0],
							[-122.9150390625, 40.329795743702064, 0.0],
							[-122.926025390625, 40.329795743702064, 0.0],
							[-122.926025390625, 40.33817045213393, 0.0],
							[-122.93701171875, 40.33817045213393, 0.0],
							[-122.947998046875, 40.33817045213393, 0.0],
							[-122.958984375, 40.33817045213393, 0.0],
							[-122.958984375, 40.329795743702064, 0.0],
							[-122.958984375, 40.321419995934377, 0.0],
							[-122.969970703125, 40.321419995934377, 0.0],
							[-122.969970703125, 40.329795743702064, 0.0],
							[-122.98095703125, 40.329795743702064, 0.0],
							[-122.991943359375, 40.329795743702064, 0.0],
							[-123.0029296875, 40.329795743702064, 0.0],
							[-123.013916015625, 40.329795743702064, 0.0],
							[-123.02490234375, 40.329795743702064, 0.0],
							[-123.02490234375, 40.33817045213393, 0.0],
							[-123.035888671875, 40.33817045213393, 0.0],
							[-123.046875, 40.33817045213393, 0.0],
							[-123.046875, 40.34654412118006, 0.0],
							[-123.046875, 40.354916750790608, 0.0],
							[-123.046875, 40.363288340915823, 0.0],
							[-123.035888671875, 40.363288340915823, 0.0],
							[-123.035888671875, 40.371658891506087, 0.0],
							[-123.02490234375, 40.371658891506087, 0.0],
							[-123.013916015625, 40.371658891506087, 0.0],
							[-123.013916015625, 40.380028402511826, 0.0],
							[-123.013916015625, 40.3883968738836, 0.0],
							[-123.0029296875, 40.3883968738836, 0.0],
							[-123.0029296875, 40.396764305572042, 0.0],
							[-123.0029296875, 40.405130697527888, 0.0],
							[-123.0029296875, 40.413496049701955, 0.0],
							[-123.0029296875, 40.421860362045194, 0.0],
							[-123.0029296875, 40.4302236345086, 0.0],
							[-123.0029296875, 40.438585867043294, 0.0],
							[-123.0029296875, 40.44694705960049, 0.0],
							[-123.0029296875, 40.45530721213148, 0.0],
							[-123.0029296875, 40.46366632458767, 0.0],
							[-122.991943359375, 40.46366632458767, 0.0],
							[-122.991943359375, 40.472024396920553, 0.0],
							[-122.991943359375, 40.48038142908171, 0.0],
							[-122.98095703125, 40.48038142908171, 0.0],
							[-122.969970703125, 40.48038142908171, 0.0],
							[-122.969970703125, 40.488737421022819, 0.0],
							[-122.958984375, 40.488737421022819, 0.0],
							[-122.958984375, 40.497092372695668, 0.0],
							[-122.958984375, 40.505446284052113, 0.0],
							[-122.969970703125, 40.505446284052113, 0.0],
							[-122.969970703125, 40.513799155044133, 0.0],
							[-122.969970703125, 40.522150985623774, 0.0],
							[-122.969970703125, 40.530501775743204, 0.0],
							[-122.98095703125, 40.530501775743204, 0.0],
							[-122.98095703125, 40.538851525354659, 0.0],
							[-122.98095703125, 40.547200234410482, 0.0],
							[-122.98095703125, 40.555547902863104, 0.0],
							[-122.991943359375, 40.555547902863104, 0.0],
							[-123.0029296875, 40.555547902863104, 0.0],
							[-123.0029296875, 40.563894530665074, 0.0],
							[-123.0029296875, 40.57224011776902, 0.0],
							[-123.0029296875, 40.580584664127628, 0.0],
							[-123.013916015625, 40.580584664127628, 0.0],
							[-123.013916015625, 40.588928169693745, 0.0],
							[-123.013916015625, 40.597270634420248, 0.0],
							[-123.013916015625, 40.605612058260178, 0.0],
							[-123.02490234375, 40.605612058260178, 0.0],
							[-123.035888671875, 40.605612058260178, 0.0],
							[-123.035888671875, 40.613952441166596, 0.0],
							[-123.035888671875, 40.622291783092706, 0.0],
							[-123.046875, 40.622291783092706, 0.0],
							[-123.046875, 40.630630083991797, 0.0],
							[-123.057861328125, 40.630630083991797, 0.0],
							[-123.057861328125, 40.638967343817235, 0.0],
							[-123.06884765625, 40.638967343817235, 0.0],
							[-123.079833984375, 40.638967343817235, 0.0],
							[-123.079833984375, 40.630630083991797, 0.0],
							[-123.079833984375, 40.622291783092706, 0.0],
							[-123.0908203125, 40.622291783092706, 0.0],
							[-123.0908203125, 40.630630083991797, 0.0],
							[-123.0908203125, 40.638967343817235, 0.0],
							[-123.101806640625, 40.638967343817235, 0.0],
							[-123.101806640625, 40.647303562522502, 0.0],
							[-123.11279296875, 40.647303562522502, 0.0],
							[-123.11279296875, 40.655638740061164, 0.0],
							[-123.11279296875, 40.663972876386879, 0.0],
							[-123.11279296875, 40.672305971453397, 0.0],
							[-123.11279296875, 40.68063802521457, 0.0],
							[-123.11279296875, 40.688969037624339, 0.0],
							[-123.11279296875, 40.69729900863674, 0.0],
							[-123.123779296875, 40.69729900863674, 0.0],
							[-123.123779296875, 40.7056279382059, 0.0],
							[-123.134765625, 40.7056279382059, 0.0],
							[-123.134765625, 40.69729900863674, 0.0],
							[-123.145751953125, 40.69729900863674, 0.0],
							[-123.145751953125, 40.688969037624339, 0.0],
							[-123.15673828125, 40.688969037624339, 0.0],
							[-123.15673828125, 40.68063802521457, 0.0],
							[-123.167724609375, 40.68063802521457, 0.0],
							[-123.1787109375, 40.68063802521457, 0.0],
							[-123.1787109375, 40.672305971453397, 0.0],
							[-123.189697265625, 40.672305971453397, 0.0],
							[-123.189697265625, 40.663972876386879, 0.0],
							[-123.20068359375, 40.663972876386879, 0.0],
							[-123.211669921875, 40.663972876386879, 0.0],
							[-123.211669921875, 40.655638740061164, 0.0],
							[-123.22265625, 40.655638740061164, 0.0],
							[-123.233642578125, 40.655638740061164, 0.0],
							[-123.233642578125, 40.663972876386879, 0.0],
							[-123.24462890625, 40.663972876386879, 0.0],
							[-123.24462890625, 40.672305971453397, 0.0],
							[-123.24462890625, 40.68063802521457, 0.0],
							[-123.24462890625, 40.688969037624339, 0.0],
							[-123.255615234375, 40.688969037624339, 0.0],
							[-123.2666015625, 40.688969037624339, 0.0],
							[-123.2666015625, 40.69729900863674, 0.0],
							[-123.277587890625, 40.69729900863674, 0.0],
							[-123.277587890625, 40.7056279382059, 0.0],
							[-123.28857421875, 40.7056279382059, 0.0],
							[-123.28857421875, 40.713955826286039, 0.0],
							[-123.299560546875, 40.713955826286039, 0.0],
							[-123.310546875, 40.713955826286039, 0.0],
							[-123.310546875, 40.72228267283149, 0.0],
							[-123.310546875, 40.730608477796643, 0.0],
							[-123.321533203125, 40.730608477796643, 0.0],
							[-123.33251953125, 40.730608477796643, 0.0],
							[-123.33251953125, 40.738933241136017, 0.0],
							[-123.343505859375, 40.738933241136017, 0.0],
							[-123.3544921875, 40.738933241136017, 0.0],
							[-123.365478515625, 40.738933241136017, 0.0],
							[-123.37646484375, 40.738933241136017, 0.0],
							[-123.387451171875, 40.738933241136017, 0.0],
							[-123.3984375, 40.738933241136017, 0.0],
							[-123.409423828125, 40.738933241136017, 0.0],
							[-123.42041015625, 40.738933241136017, 0.0],
							[-123.42041015625, 40.730608477796643, 0.0],
							[-123.431396484375, 40.730608477796643, 0.0],
							[-123.431396484375, 40.738933241136017, 0.0],
							[-123.4423828125, 40.738933241136017, 0.0],
							[-123.453369140625, 40.738933241136017, 0.0],
							[-123.46435546875, 40.738933241136017, 0.0],
							[-123.46435546875, 40.730608477796643, 0.0],
							[-123.475341796875, 40.730608477796643, 0.0],
							[-123.475341796875, 40.738933241136017, 0.0],
							[-123.486328125, 40.738933241136017, 0.0],
							[-123.486328125, 40.747256962804208, 0.0],
							[-123.486328125, 40.75557964275589, 0.0],
							[-123.497314453125, 40.75557964275589, 0.0],
							[-123.497314453125, 40.763901280945881, 0.0],
							[-123.50830078125, 40.763901280945881, 0.0],
							[-123.519287109375, 40.763901280945881, 0.0],
							[-123.519287109375, 40.772221877329024, 0.0],
							[-123.519287109375, 40.780541431860314, 0.0],
							[-123.519287109375, 40.78885994449481, 0.0],
							[-123.5302734375, 40.78885994449481, 0.0],
							[-123.5302734375, 40.797177415187669, 0.0],
							[-123.5302734375, 40.805493843894155, 0.0],
							[-123.5302734375, 40.813809230569603, 0.0],
							[-123.5302734375, 40.822123575169442, 0.0],
							[-123.5302734375, 40.830436877649234, 0.0],
							[-123.5302734375, 40.838749137964584, 0.0],
							[-123.5302734375, 40.847060356071218, 0.0],
							[-123.541259765625, 40.847060356071218, 0.0],
							[-123.541259765625, 40.855370531924947, 0.0],
							[-123.541259765625, 40.863679665481683, 0.0],
							[-123.541259765625, 40.871987756697408, 0.0],
							[-123.55224609375, 40.871987756697408, 0.0],
							[-123.55224609375, 40.88029480552823, 0.0],
							[-123.55224609375, 40.888600811930338, 0.0],
							[-123.55224609375, 40.896905775859999, 0.0],
							[-123.55224609375, 40.905209697273591, 0.0],
							[-123.55224609375, 40.913512576127573, 0.0],
							[-123.55224609375, 40.921814412378509, 0.0],
							[-123.55224609375, 40.93011520598305, 0.0],
							[-123.55224609375, 40.938414956897937, 0.0],
							[-123.55224609375, 40.946713665080019, 0.0],
							[-123.541259765625, 40.946713665080019, 0.0],
							[-123.5302734375, 40.946713665080019, 0.0],
							[-123.5302734375, 40.938414956897937, 0.0],
							[-123.5302734375, 40.93011520598305, 0.0],
							[-123.519287109375, 40.93011520598305, 0.0],
							[-123.519287109375, 40.921814412378509, 0.0],
							[-123.50830078125, 40.921814412378509, 0.0],
							[-123.497314453125, 40.921814412378509, 0.0],
							[-123.486328125, 40.921814412378509, 0.0],
							[-123.486328125, 40.913512576127573, 0.0],
							[-123.486328125, 40.905209697273591, 0.0],
							[-123.475341796875, 40.905209697273591, 0.0],
							[-123.475341796875, 40.896905775859999, 0.0],
							[-123.475341796875, 40.888600811930338, 0.0],
							[-123.475341796875, 40.88029480552823, 0.0],
							[-123.475341796875, 40.871987756697408, 0.0],
							[-123.46435546875, 40.871987756697408, 0.0],
							[-123.46435546875, 40.863679665481683, 0.0],
							[-123.475341796875, 40.863679665481683, 0.0],
							[-123.475341796875, 40.855370531924947, 0.0],
							[-123.475341796875, 40.847060356071218, 0.0],
							[-123.46435546875, 40.847060356071218, 0.0],
							[-123.46435546875, 40.838749137964584, 0.0],
							[-123.453369140625, 40.838749137964584, 0.0],
							[-123.4423828125, 40.838749137964584, 0.0],
							[-123.4423828125, 40.830436877649234, 0.0],
							[-123.431396484375, 40.830436877649234, 0.0],
							[-123.42041015625, 40.830436877649234, 0.0],
							[-123.409423828125, 40.830436877649234, 0.0],
							[-123.409423828125, 40.822123575169442, 0.0],
							[-123.3984375, 40.822123575169442, 0.0],
							[-123.3984375, 40.830436877649234, 0.0],
							[-123.387451171875, 40.830436877649234, 0.0],
							[-123.37646484375, 40.830436877649234, 0.0],
							[-123.365478515625, 40.830436877649234, 0.0],
							[-123.3544921875, 40.830436877649234, 0.0],
							[-123.3544921875, 40.838749137964584, 0.0],
							[-123.343505859375, 40.838749137964584, 0.0],
							[-123.33251953125, 40.838749137964584, 0.0],
							[-123.33251953125, 40.847060356071218, 0.0],
							[-123.33251953125, 40.855370531924947, 0.0],
							[-123.33251953125, 40.863679665481683, 0.0],
							[-123.321533203125, 40.863679665481683, 0.0],
							[-123.321533203125, 40.871987756697408, 0.0],
							[-123.310546875, 40.871987756697408, 0.0],
							[-123.299560546875, 40.871987756697408, 0.0],
							[-123.299560546875, 40.88029480552823, 0.0],
							[-123.299560546875, 40.888600811930338, 0.0],
							[-123.28857421875, 40.888600811930338, 0.0],
							[-123.28857421875, 40.896905775859999, 0.0],
							[-123.28857421875, 40.905209697273591, 0.0],
							[-123.277587890625, 40.905209697273591, 0.0],
							[-123.277587890625, 40.913512576127573, 0.0],
							[-123.2666015625, 40.913512576127573, 0.0],
							[-123.255615234375, 40.913512576127573, 0.0],
							[-123.24462890625, 40.913512576127573, 0.0],
							[-123.24462890625, 40.921814412378509, 0.0],
							[-123.24462890625, 40.93011520598305, 0.0],
							[-123.233642578125, 40.93011520598305, 0.0],
							[-123.233642578125, 40.938414956897937, 0.0],
							[-123.233642578125, 40.946713665080019, 0.0],
							[-123.24462890625, 40.946713665080019, 0.0],
							[-123.24462890625, 40.955011330486201, 0.0],
							[-123.233642578125, 40.955011330486201, 0.0],
							[-123.22265625, 40.955011330486201, 0.0],
							[-123.22265625, 40.963307953073524, 0.0],
							[-123.22265625, 40.971603532799094, 0.0],
							[-123.211669921875, 40.971603532799094, 0.0],
							[-123.211669921875, 40.979898069620127, 0.0],
							[-123.211669921875, 40.988191563493928, 0.0],
							[-123.22265625, 40.988191563493928, 0.0],
							[-123.22265625, 40.996484014377884, 0.0],
							[-123.211669921875, 40.996484014377884, 0.0],
							[-123.20068359375, 40.996484014377884, 0.0],
							[-123.20068359375, 41.004775422229471, 0.0],
							[-123.20068359375, 41.013065787006298, 0.0],
							[-123.20068359375, 41.021355108666015, 0.0],
							[-123.20068359375, 41.029643387166381, 0.0],
							[-123.20068359375, 41.037930622465275, 0.0],
							[-123.20068359375, 41.046216814520633, 0.0],
							[-123.20068359375, 41.054501963290498, 0.0],
							[-123.20068359375, 41.062786068733011, 0.0],
							[-123.20068359375, 41.0710691308064, 0.0],
							[-123.20068359375, 41.079351149468977, 0.0],
							[-123.189697265625, 41.079351149468977, 0.0],
							[-123.1787109375, 41.079351149468977, 0.0],
							[-123.167724609375, 41.079351149468977, 0.0],
							[-123.15673828125, 41.079351149468977, 0.0],
							[-123.145751953125, 41.079351149468977, 0.0],
							[-123.134765625, 41.079351149468977, 0.0],
							[-123.123779296875, 41.079351149468977, 0.0],
							[-123.11279296875, 41.079351149468977, 0.0],
							[-123.11279296875, 41.0710691308064, 0.0],
							[-123.101806640625, 41.0710691308064, 0.0],
							[-123.101806640625, 41.062786068733011, 0.0],
							[-123.0908203125, 41.062786068733011, 0.0],
							[-123.079833984375, 41.062786068733011, 0.0],
							[-123.079833984375, 41.054501963290498, 0.0],
							[-123.06884765625, 41.054501963290498, 0.0],
							[-123.057861328125, 41.054501963290498, 0.0],
							[-123.046875, 41.054501963290498, 0.0],
							[-123.046875, 41.046216814520633, 0.0],
							[-123.046875, 41.037930622465275, 0.0],
							[-123.046875, 41.029643387166381, 0.0],
							[-123.046875, 41.021355108666015, 0.0],
							[-123.035888671875, 41.021355108666015, 0.0],
							[-123.035888671875, 41.013065787006298, 0.0],
							[-123.02490234375, 41.013065787006298, 0.0],
							[-123.013916015625, 41.013065787006298, 0.0],
							[-123.0029296875, 41.013065787006298, 0.0],
							[-122.991943359375, 41.013065787006298, 0.0],
							[-122.991943359375, 41.021355108666015, 0.0],
							[-122.98095703125, 41.021355108666015, 0.0],
							[-122.969970703125, 41.021355108666015, 0.0],
							[-122.969970703125, 41.013065787006298, 0.0],
							[-122.958984375, 41.013065787006298, 0.0],
							[-122.947998046875, 41.013065787006298, 0.0],
							[-122.947998046875, 41.004775422229471, 0.0],
							[-122.93701171875, 41.004775422229471, 0.0],
							[-122.926025390625, 41.004775422229471, 0.0],
							[-122.9150390625, 41.004775422229471, 0.0],
							[-122.9150390625, 41.013065787006298, 0.0],
							[-122.904052734375, 41.013065787006298, 0.0],
							[-122.904052734375, 41.021355108666015, 0.0],
							[-122.904052734375, 41.029643387166381, 0.0],
							[-122.9150390625, 41.029643387166381, 0.0],
							[-122.9150390625, 41.037930622465275, 0.0],
							[-122.9150390625, 41.046216814520633, 0.0],
							[-122.9150390625, 41.054501963290498, 0.0],
							[-122.9150390625, 41.062786068733011, 0.0],
							[-122.926025390625, 41.062786068733011, 0.0],
							[-122.926025390625, 41.0710691308064, 0.0],
							[-122.93701171875, 41.0710691308064, 0.0],
							[-122.947998046875, 41.0710691308064, 0.0],
							[-122.947998046875, 41.079351149468977, 0.0],
							[-122.958984375, 41.079351149468977, 0.0],
							[-122.969970703125, 41.079351149468977, 0.0],
							[-122.969970703125, 41.08763212467916, 0.0],
							[-122.969970703125, 41.095912056395456, 0.0],
							[-122.969970703125, 41.104190944576466, 0.0],
							[-122.98095703125, 41.104190944576466, 0.0],
							[-122.98095703125, 41.112468789180859, 0.0],
							[-122.969970703125, 41.112468789180859, 0.0],
							[-122.969970703125, 41.120745590167452, 0.0],
							[-122.969970703125, 41.129021347495083, 0.0],
							[-122.958984375, 41.129021347495083, 0.0],
							[-122.958984375, 41.137296061122747, 0.0],
							[-122.969970703125, 41.137296061122747, 0.0],
							[-122.969970703125, 41.145569731009488, 0.0],
							[-122.958984375, 41.145569731009488, 0.0],
							[-122.958984375, 41.153842357114456, 0.0],
							[-122.958984375, 41.16211393939691, 0.0],
							[-122.958984375, 41.170384477816178, 0.0],
							[-122.969970703125, 41.170384477816178, 0.0],
							[-122.969970703125, 41.178653972331688, 0.0],
							[-122.969970703125, 41.186922422902946, 0.0],
							[-122.958984375, 41.186922422902946, 0.0],
							[-122.947998046875, 41.186922422902946, 0.0],
							[-122.93701171875, 41.186922422902946, 0.0],
							[-122.926025390625, 41.186922422902946, 0.0],
							[-122.926025390625, 41.195189829489578, 0.0],
							[-122.9150390625, 41.195189829489578, 0.0],
							[-122.904052734375, 41.195189829489578, 0.0],
							[-122.904052734375, 41.203456192051299, 0.0],
							[-122.89306640625, 41.203456192051299, 0.0],
							[-122.89306640625, 41.211721510547875, 0.0],
							[-122.882080078125, 41.211721510547875, 0.0],
							[-122.87109375, 41.211721510547875, 0.0],
							[-122.87109375, 41.203456192051299, 0.0],
							[-122.860107421875, 41.203456192051299, 0.0],
							[-122.84912109375, 41.203456192051299, 0.0],
							[-122.84912109375, 41.211721510547875, 0.0],
							[-122.838134765625, 41.211721510547875, 0.0],
							[-122.8271484375, 41.211721510547875, 0.0],
							[-122.816162109375, 41.211721510547875, 0.0],
							[-122.80517578125, 41.211721510547875, 0.0],
							[-122.794189453125, 41.211721510547875, 0.0],
							[-122.794189453125, 41.219985784939226, 0.0],
							[-122.783203125, 41.219985784939226, 0.0],
							[-122.772216796875, 41.219985784939226, 0.0],
							[-122.772216796875, 41.228249015185305, 0.0],
							[-122.772216796875, 41.236511201246202, 0.0],
							[-122.76123046875, 41.236511201246202, 0.0],
							[-122.76123046875, 41.244772343082076, 0.0],
							[-122.750244140625, 41.244772343082076, 0.0],
							[-122.7392578125, 41.244772343082076, 0.0],
							[-122.7392578125, 41.253032440653179, 0.0],
							[-122.728271484375, 41.253032440653179, 0.0],
							[-122.728271484375, 41.26129149391987, 0.0],
							[-122.728271484375, 41.269549502842573, 0.0],
							[-122.71728515625, 41.269549502842573, 0.0],
							[-122.706298828125, 41.269549502842573, 0.0],
							[-122.706298828125, 41.277806467381822, 0.0],
							[-122.6953125, 41.277806467381822, 0.0],
							[-122.684326171875, 41.277806467381822, 0.0],
							[-122.684326171875, 41.286062387498248, 0.0],
							[-122.684326171875, 41.294317263152571, 0.0],
							[-122.67333984375, 41.294317263152571, 0.0],
							[-122.662353515625, 41.294317263152571, 0.0],
							[-122.662353515625, 41.302571094305577, 0.0],
							[-122.6513671875, 41.302571094305577, 0.0],
							[-122.6513671875, 41.310823880918186, 0.0],
							[-122.6513671875, 41.319075622951374, 0.0],
							[-122.6513671875, 41.327326320366218, 0.0],
							[-122.640380859375, 41.327326320366218, 0.0],
							[-122.62939453125, 41.327326320366218, 0.0],
							[-122.618408203125, 41.327326320366218, 0.0],
							[-122.618408203125, 41.335575973123902, 0.0],
							[-122.607421875, 41.335575973123902, 0.0],
							[-122.596435546875, 41.335575973123902, 0.0],
							[-122.58544921875, 41.335575973123902, 0.0],
							[-122.58544921875, 41.343824581185693, 0.0],
							[-122.58544921875, 41.352072144512938, 0.0],
							[-122.58544921875, 41.360318663067083, 0.0],
							[-122.58544921875, 41.368564136809674, 0.0],
							[-122.574462890625, 41.368564136809674, 0.0],
							[-122.5634765625, 41.368564136809674, 0.0],
							[-122.552490234375, 41.368564136809674, 0.0],
							[-122.552490234375, 41.360318663067083, 0.0],
							[-122.54150390625, 41.360318663067083, 0.0],
							[-122.54150390625, 41.352072144512938, 0.0],
							[-122.54150390625, 41.343824581185693, 0.0],
							[-122.530517578125, 41.343824581185693, 0.0],
							[-122.51953125, 41.343824581185693, 0.0],
							[-122.508544921875, 41.343824581185693, 0.0],
							[-122.49755859375, 41.343824581185693, 0.0],
							[-122.49755859375, 41.335575973123902, 0.0],
							[-122.49755859375, 41.327326320366218, 0.0],
							[-122.486572265625, 41.327326320366218, 0.0],
							[-122.4755859375, 41.327326320366218, 0.0],
							[-122.464599609375, 41.327326320366218, 0.0],
							[-122.464599609375, 41.335575973123902, 0.0],
							[-122.45361328125, 41.335575973123902, 0.0],
							[-122.442626953125, 41.335575973123902, 0.0],
							[-122.442626953125, 41.343824581185693, 0.0],
							[-122.431640625, 41.343824581185693, 0.0],
							[-122.420654296875, 41.343824581185693, 0.0],
							[-122.420654296875, 41.352072144512938, 0.0],
							[-122.40966796875, 41.352072144512938, 0.0],
							[-122.40966796875, 41.360318663067083, 0.0],
							[-122.40966796875, 41.368564136809674, 0.0],
							[-122.420654296875, 41.368564136809674, 0.0],
							[-122.420654296875, 41.376808565702341, 0.0],
							[-122.40966796875, 41.376808565702341, 0.0],
							[-122.398681640625, 41.376808565702341, 0.0],
							[-122.398681640625, 41.385051949706799, 0.0],
							[-122.398681640625, 41.39329428878488, 0.0],
							[-122.40966796875, 41.39329428878488, 0.0],
							[-122.40966796875, 41.401535582898461, 0.0],
							[-122.40966796875, 41.409775832009551, 0.0],
							[-122.420654296875, 41.409775832009551, 0.0],
							[-122.420654296875, 41.418015036080241, 0.0],
							[-122.420654296875, 41.426253195072697, 0.0],
							[-122.431640625, 41.426253195072697, 0.0],
							[-122.431640625, 41.434490308949208, 0.0],
							[-122.431640625, 41.442726377672116, 0.0],
							[-122.431640625, 41.450961401203891, 0.0],
							[-122.431640625, 41.459195379507051, 0.0],
							[-122.431640625, 41.46742831254425, 0.0],
							[-122.420654296875, 41.46742831254425, 0.0],
							[-122.420654296875, 41.475660200278213, 0.0],
							[-122.420654296875, 41.48389104267175, 0.0],
							[-122.420654296875, 41.492120839687772, 0.0],
							[-122.420654296875, 41.500349591289279, 0.0],
							[-122.40966796875, 41.500349591289279, 0.0],
							[-122.40966796875, 41.508577297439338, 0.0],
							[-122.40966796875, 41.516803958101157, 0.0],
							[-122.398681640625, 41.516803958101157, 0.0],
							[-122.398681640625, 41.525029573238001, 0.0],
							[-122.3876953125, 41.525029573238001, 0.0],
							[-122.3876953125, 41.533254142813227, 0.0],
							[-122.376708984375, 41.533254142813227, 0.0],
							[-122.376708984375, 41.541477666790293, 0.0],
							[-122.36572265625, 41.541477666790293, 0.0],
							[-122.36572265625, 41.533254142813227, 0.0],
							[-122.354736328125, 41.533254142813227, 0.0],
							[-122.34375, 41.533254142813227, 0.0],
							[-122.34375, 41.541477666790293, 0.0],
							[-122.332763671875, 41.541477666790293, 0.0],
							[-122.32177734375, 41.541477666790293, 0.0],
							[-122.310791015625, 41.541477666790293, 0.0],
							[-122.310791015625, 41.549700145132725, 0.0],
							[-122.2998046875, 41.549700145132725, 0.0],
							[-122.288818359375, 41.549700145132725, 0.0],
							[-122.288818359375, 41.55792157780418, 0.0],
							[-122.27783203125, 41.55792157780418, 0.0],
							[-122.266845703125, 41.55792157780418, 0.0],
							[-122.255859375, 41.55792157780418, 0.0],
							[-122.244873046875, 41.55792157780418, 0.0],
							[-122.23388671875, 41.55792157780418, 0.0],
							[-122.222900390625, 41.55792157780418, 0.0],
							[-122.222900390625, 41.549700145132725, 0.0],
							[-122.2119140625, 41.549700145132725, 0.0],
							[-122.2119140625, 41.541477666790293, 0.0],
							[-122.200927734375, 41.541477666790293, 0.0],
							[-122.200927734375, 41.533254142813227, 0.0],
							[-122.18994140625, 41.533254142813227, 0.0],
							[-122.18994140625, 41.525029573238001, 0.0],
							[-122.178955078125, 41.525029573238001, 0.0],
							[-122.178955078125, 41.516803958101157, 0.0],
							[-122.16796875, 41.516803958101157, 0.0],
							[-122.16796875, 41.508577297439338, 0.0],
							[-122.156982421875, 41.508577297439338, 0.0],
							[-122.14599609375, 41.508577297439338, 0.0],
							[-122.14599609375, 41.500349591289279, 0.0],
							[-122.135009765625, 41.500349591289279, 0.0],
							[-122.135009765625, 41.492120839687772, 0.0],
							[-122.1240234375, 41.492120839687772, 0.0],
							[-122.113037109375, 41.492120839687772, 0.0],
							[-122.10205078125, 41.492120839687772, 0.0],
							[-122.091064453125, 41.492120839687772, 0.0],
							[-122.091064453125, 41.48389104267175, 0.0],
							[-122.080078125, 41.48389104267175, 0.0],
							[-122.069091796875, 41.48389104267175, 0.0],
							[-122.069091796875, 41.475660200278213, 0.0],
							[-122.05810546875, 41.475660200278213, 0.0],
							[-122.05810546875, 41.46742831254425, 0.0],
							[-122.047119140625, 41.46742831254425, 0.0],
							[-122.047119140625, 41.459195379507051, 0.0],
							[-122.0361328125, 41.459195379507051, 0.0],
							[-122.0361328125, 41.450961401203891, 0.0],
							[-122.025146484375, 41.450961401203891, 0.0],
							[-122.025146484375, 41.442726377672116, 0.0],
							[-122.01416015625, 41.442726377672116, 0.0],
							[-122.01416015625, 41.434490308949208, 0.0],
							[-122.003173828125, 41.434490308949208, 0.0],
							[-122.003173828125, 41.426253195072697, 0.0],
							[-121.9921875, 41.426253195072697, 0.0],
							[-121.9921875, 41.418015036080241, 0.0],
							[-121.981201171875, 41.418015036080241, 0.0],
							[-121.981201171875, 41.409775832009551, 0.0],
							[-121.981201171875, 41.401535582898461, 0.0],
							[-121.97021484375, 41.401535582898461, 0.0],
							[-121.97021484375, 41.39329428878488, 0.0],
							[-121.97021484375, 41.385051949706799, 0.0],
							[-121.959228515625, 41.385051949706799, 0.0],
							[-121.959228515625, 41.376808565702341, 0.0],
							[-121.9482421875, 41.376808565702341, 0.0],
							[-121.9482421875, 41.368564136809674, 0.0],
							[-121.9482421875, 41.360318663067083, 0.0],
							[-121.937255859375, 41.360318663067083, 0.0],
							[-121.937255859375, 41.352072144512938, 0.0],
							[-121.937255859375, 41.343824581185693, 0.0],
							[-121.92626953125, 41.343824581185693, 0.0],
							[-121.92626953125, 41.335575973123902, 0.0],
							[-121.92626953125, 41.327326320366218, 0.0],
							[-121.915283203125, 41.327326320366218, 0.0],
							[-121.915283203125, 41.319075622951374, 0.0],
							[-121.904296875, 41.319075622951374, 0.0],
							[-121.904296875, 41.327326320366218, 0.0],
							[-121.904296875, 41.335575973123902, 0.0],
							[-121.904296875, 41.343824581185693, 0.0],
							[-121.904296875, 41.352072144512938, 0.0],
							[-121.904296875, 41.360318663067083, 0.0],
							[-121.904296875, 41.368564136809674, 0.0],
							[-121.904296875, 41.376808565702341, 0.0],
							[-121.893310546875, 41.376808565702341, 0.0],
							[-121.88232421875, 41.376808565702341, 0.0],
							[-121.88232421875, 41.385051949706799, 0.0],
							[-121.871337890625, 41.385051949706799, 0.0],
							[-121.871337890625, 41.376808565702341, 0.0],
							[-121.8603515625, 41.376808565702341, 0.0],
							[-121.849365234375, 41.376808565702341, 0.0],
							[-121.849365234375, 41.385051949706799, 0.0],
							[-121.83837890625, 41.385051949706799, 0.0],
							[-121.827392578125, 41.385051949706799, 0.0],
							[-121.81640625, 41.385051949706799, 0.0],
							[-121.81640625, 41.376808565702341, 0.0],
							[-121.805419921875, 41.376808565702341, 0.0],
							[-121.79443359375, 41.376808565702341, 0.0],
							[-121.79443359375, 41.385051949706799, 0.0],
							[-121.783447265625, 41.385051949706799, 0.0],
							[-121.783447265625, 41.376808565702341, 0.0],
							[-121.7724609375, 41.376808565702341, 0.0],
							[-121.761474609375, 41.376808565702341, 0.0],
							[-121.75048828125, 41.376808565702341, 0.0],
							[-121.75048828125, 41.385051949706799, 0.0],
							[-121.739501953125, 41.385051949706799, 0.0],
							[-121.728515625, 41.385051949706799, 0.0],
							[-121.728515625, 41.376808565702341, 0.0],
							[-121.717529296875, 41.376808565702341, 0.0],
							[-121.70654296875, 41.376808565702341, 0.0],
							[-121.70654296875, 41.385051949706799, 0.0],
							[-121.695556640625, 41.385051949706799, 0.0],
							[-121.695556640625, 41.376808565702341, 0.0],
							[-121.6845703125, 41.376808565702341, 0.0],
							[-121.673583984375, 41.376808565702341, 0.0],
							[-121.66259765625, 41.376808565702341, 0.0],
							[-121.66259765625, 41.385051949706799, 0.0],
							[-121.651611328125, 41.385051949706799, 0.0],
							[-121.651611328125, 41.376808565702341, 0.0],
							[-121.640625, 41.376808565702341, 0.0],
							[-121.629638671875, 41.376808565702341, 0.0],
							[-121.61865234375, 41.376808565702341, 0.0],
							[-121.61865234375, 41.368564136809674, 0.0],
							[-121.607666015625, 41.368564136809674, 0.0],
							[-121.607666015625, 41.360318663067083, 0.0],
							[-121.5966796875, 41.360318663067083, 0.0],
							[-121.5966796875, 41.352072144512938, 0.0],
							[-121.5966796875, 41.343824581185693, 0.0],
							[-121.585693359375, 41.343824581185693, 0.0],
							[-121.585693359375, 41.335575973123902, 0.0],
							[-121.57470703125, 41.335575973123902, 0.0],
							[-121.57470703125, 41.327326320366218, 0.0],
							[-121.57470703125, 41.319075622951374, 0.0],
							[-121.563720703125, 41.319075622951374, 0.0],
							[-121.563720703125, 41.310823880918186, 0.0],
							[-121.552734375, 41.310823880918186, 0.0],
							[-121.552734375, 41.302571094305577, 0.0],
							[-121.552734375, 41.294317263152571, 0.0],
							[-121.552734375, 41.286062387498248, 0.0],
							[-121.541748046875, 41.286062387498248, 0.0],
							[-121.53076171875, 41.286062387498248, 0.0],
							[-121.519775390625, 41.286062387498248, 0.0],
							[-121.519775390625, 41.294317263152571, 0.0],
							[-121.5087890625, 41.294317263152571, 0.0],
							[-121.497802734375, 41.294317263152571, 0.0],
							[-121.497802734375, 41.286062387498248, 0.0],
							[-121.48681640625, 41.286062387498248, 0.0],
							[-121.475830078125, 41.286062387498248, 0.0],
							[-121.475830078125, 41.294317263152571, 0.0],
							[-121.46484375, 41.294317263152571, 0.0],
							[-121.46484375, 41.286062387498248, 0.0],
							[-121.453857421875, 41.286062387498248, 0.0],
							[-121.44287109375, 41.286062387498248, 0.0],
							[-121.431884765625, 41.286062387498248, 0.0],
							[-121.4208984375, 41.286062387498248, 0.0],
							[-121.409912109375, 41.286062387498248, 0.0],
							[-121.409912109375, 41.277806467381822, 0.0],
							[-121.409912109375, 41.269549502842573, 0.0],
							[-121.39892578125, 41.269549502842573, 0.0],
							[-121.387939453125, 41.269549502842573, 0.0],
							[-121.387939453125, 41.277806467381822, 0.0],
							[-121.387939453125, 41.286062387498248, 0.0],
							[-121.376953125, 41.286062387498248, 0.0],
							[-121.376953125, 41.277806467381822, 0.0],
							[-121.376953125, 41.269549502842573, 0.0],
							[-121.376953125, 41.26129149391987, 0.0],
							[-121.376953125, 41.253032440653179, 0.0],
							[-121.365966796875, 41.253032440653179, 0.0],
							[-121.365966796875, 41.244772343082076, 0.0],
							[-121.35498046875, 41.244772343082076, 0.0],
							[-121.35498046875, 41.236511201246202, 0.0],
							[-121.35498046875, 41.228249015185305, 0.0],
							[-121.343994140625, 41.228249015185305, 0.0],
							[-121.343994140625, 41.219985784939226, 0.0],
							[-121.3330078125, 41.219985784939226, 0.0],
							[-121.3330078125, 41.228249015185305, 0.0],
							[-121.322021484375, 41.228249015185305, 0.0],
							[-121.322021484375, 41.236511201246202, 0.0],
							[-121.31103515625, 41.236511201246202, 0.0],
							[-121.31103515625, 41.244772343082076, 0.0],
							[-121.31103515625, 41.253032440653179, 0.0],
							[-121.300048828125, 41.253032440653179, 0.0],
							[-121.300048828125, 41.26129149391987, 0.0],
							[-121.2890625, 41.26129149391987, 0.0],
							[-121.2890625, 41.269549502842573, 0.0],
							[-121.278076171875, 41.269549502842573, 0.0],
							[-121.278076171875, 41.277806467381822, 0.0],
							[-121.26708984375, 41.277806467381822, 0.0],
							[-121.26708984375, 41.286062387498248, 0.0],
							[-121.256103515625, 41.286062387498248, 0.0],
							[-121.256103515625, 41.294317263152571, 0.0],
							[-121.256103515625, 41.302571094305577, 0.0],
							[-121.2451171875, 41.302571094305577, 0.0],
							[-121.234130859375, 41.302571094305577, 0.0],
							[-121.234130859375, 41.310823880918186, 0.0],
							[-121.22314453125, 41.310823880918186, 0.0],
							[-121.22314453125, 41.319075622951374, 0.0],
							[-121.212158203125, 41.319075622951374, 0.0],
							[-121.212158203125, 41.327326320366218, 0.0],
							[-121.201171875, 41.327326320366218, 0.0],
							[-121.201171875, 41.335575973123902, 0.0],
							[-121.190185546875, 41.335575973123902, 0.0],
							[-121.190185546875, 41.343824581185693, 0.0],
							[-121.17919921875, 41.343824581185693, 0.0],
							[-121.17919921875, 41.352072144512938, 0.0],
							[-121.17919921875, 41.360318663067083, 0.0],
							[-121.168212890625, 41.360318663067083, 0.0],
							[-121.1572265625, 41.360318663067083, 0.0],
							[-121.1572265625, 41.368564136809674, 0.0],
							[-121.146240234375, 41.368564136809674, 0.0],
							[-121.146240234375, 41.376808565702341, 0.0],
							[-121.13525390625, 41.376808565702341, 0.0],
							[-121.13525390625, 41.385051949706799, 0.0],
							[-121.124267578125, 41.385051949706799, 0.0],
							[-121.124267578125, 41.39329428878488, 0.0],
							[-121.11328125, 41.39329428878488, 0.0],
							[-121.11328125, 41.401535582898461, 0.0],
							[-121.102294921875, 41.401535582898461, 0.0],
							[-121.102294921875, 41.409775832009551, 0.0],
							[-121.09130859375, 41.409775832009551, 0.0],
							[-121.09130859375, 41.418015036080241, 0.0],
							[-121.09130859375, 41.426253195072697, 0.0],
							[-121.080322265625, 41.426253195072697, 0.0],
							[-121.0693359375, 41.426253195072697, 0.0],
							[-121.0693359375, 41.434490308949208, 0.0],
							[-121.058349609375, 41.434490308949208, 0.0],
							[-121.04736328125, 41.434490308949208, 0.0],
							[-121.04736328125, 41.442726377672116, 0.0],
							[-121.04736328125, 41.450961401203891, 0.0],
							[-121.036376953125, 41.450961401203891, 0.0],
							[-121.036376953125, 41.459195379507051, 0.0],
							[-121.025390625, 41.459195379507051, 0.0],
							[-121.025390625, 41.46742831254425, 0.0],
							[-121.014404296875, 41.46742831254425, 0.0],
							[-121.014404296875, 41.475660200278213, 0.0],
							[-121.00341796875, 41.475660200278213, 0.0],
							[-120.992431640625, 41.475660200278213, 0.0],
							[-120.992431640625, 41.48389104267175, 0.0],
							[-120.992431640625, 41.492120839687772, 0.0],
							[-120.9814453125, 41.500349591289279, 0.0],
							[-120.9814453125, 41.508577297439338, 0.0],
							[-120.970458984375, 41.508577297439338, 0.0],
							[-120.95947265625, 41.508577297439338, 0.0],
							[-120.95947265625, 41.516803958101157, 0.0],
							[-120.948486328125, 41.516803958101157, 0.0],
							[-120.9375, 41.516803958101157, 0.0],
							[-120.9375, 41.525029573238001, 0.0],
							[-120.9375, 41.533254142813227, 0.0],
							[-120.926513671875, 41.533254142813227, 0.0],
							[-120.926513671875, 41.541477666790293, 0.0],
							[-120.91552734375, 41.541477666790293, 0.0],
							[-120.904541015625, 41.541477666790293, 0.0],
							[-120.904541015625, 41.549700145132725, 0.0],
							[-120.8935546875, 41.549700145132725, 0.0],
							[-120.882568359375, 41.549700145132725, 0.0],
							[-120.87158203125, 41.549700145132725, 0.0],
							[-120.87158203125, 41.55792157780418, 0.0],
							[-120.860595703125, 41.55792157780418, 0.0],
							[-120.849609375, 41.55792157780418, 0.0],
							[-120.838623046875, 41.55792157780418, 0.0],
							[-120.82763671875, 41.55792157780418, 0.0],
							[-120.816650390625, 41.55792157780418, 0.0],
							[-120.816650390625, 41.549700145132725, 0.0],
							[-120.8056640625, 41.549700145132725, 0.0],
							[-120.8056640625, 41.55792157780418, 0.0],
							[-120.794677734375, 41.55792157780418, 0.0],
							[-120.78369140625, 41.55792157780418, 0.0],
							[-120.772705078125, 41.55792157780418, 0.0],
							[-120.76171875, 41.55792157780418, 0.0],
							[-120.750732421875, 41.55792157780418, 0.0],
							[-120.73974609375, 41.55792157780418, 0.0],
							[-120.728759765625, 41.55792157780418, 0.0],
							[-120.7177734375, 41.55792157780418, 0.0],
							[-120.706787109375, 41.55792157780418, 0.0],
							[-120.69580078125, 41.55792157780418, 0.0],
							[-120.684814453125, 41.55792157780418, 0.0],
							[-120.673828125, 41.55792157780418, 0.0],
							[-120.662841796875, 41.55792157780418, 0.0],
							[-120.65185546875, 41.55792157780418, 0.0],
							[-120.640869140625, 41.55792157780418, 0.0],
							[-120.6298828125, 41.55792157780418, 0.0],
							[-120.618896484375, 41.55792157780418, 0.0],
							[-120.60791015625, 41.55792157780418, 0.0],
							[-120.596923828125, 41.55792157780418, 0.0],
							[-120.596923828125, 41.566141964768377, 0.0],
							[-120.596923828125, 41.574361305989129, 0.0],
							[-120.596923828125, 41.582579601430339, 0.0],
							[-120.5859375, 41.582579601430339, 0.0],
							[-120.574951171875, 41.582579601430339, 0.0],
							[-120.574951171875, 41.590796851056012, 0.0],
							[-120.574951171875, 41.599013054830223, 0.0],
							[-120.56396484375, 41.599013054830223, 0.0],
							[-120.552978515625, 41.599013054830223, 0.0],
							[-120.552978515625, 41.607228212717168, 0.0],
							[-120.5419921875, 41.607228212717168, 0.0],
							[-120.5419921875, 41.615442324681105, 0.0],
							[-120.5419921875, 41.623655390686395, 0.0],
							[-120.531005859375, 41.623655390686395, 0.0],
							[-120.531005859375, 41.631867410697481, 0.0],
							[-120.531005859375, 41.640078384678915, 0.0],
							[-120.52001953125, 41.640078384678915, 0.0],
							[-120.52001953125, 41.648288312595334, 0.0],
							[-120.509033203125, 41.648288312595334, 0.0],
							[-120.498046875, 41.648288312595334, 0.0],
							[-120.498046875, 41.656497194411443, 0.0],
							[-120.498046875, 41.664705030092065, 0.0],
							[-120.487060546875, 41.664705030092065, 0.0],
							[-120.487060546875, 41.672911819602092, 0.0],
							[-120.47607421875, 41.672911819602092, 0.0],
							[-120.47607421875, 41.681117562906522, 0.0],
							[-120.47607421875, 41.689322259970432, 0.0],
							[-120.465087890625, 41.689322259970432, 0.0],
							[-120.465087890625, 41.697525910759012, 0.0],
							[-120.465087890625, 41.70572851523751, 0.0],
							[-120.4541015625, 41.70572851523751, 0.0],
							[-120.443115234375, 41.70572851523751, 0.0],
							[-120.43212890625, 41.70572851523751, 0.0],
							[-120.421142578125, 41.70572851523751, 0.0],
							[-120.41015625, 41.70572851523751, 0.0],
							[-120.41015625, 41.697525910759012, 0.0],
							[-120.41015625, 41.689322259970432, 0.0],
							[-120.41015625, 41.681117562906522, 0.0],
							[-120.399169921875, 41.681117562906522, 0.0],
							[-120.38818359375, 41.681117562906522, 0.0],
							[-120.38818359375, 41.689322259970432, 0.0],
							[-120.377197265625, 41.689322259970432, 0.0],
							[-120.3662109375, 41.689322259970432, 0.0],
							[-120.355224609375, 41.689322259970432, 0.0],
							[-120.355224609375, 41.697525910759012, 0.0],
							[-120.355224609375, 41.70572851523751, 0.0],
							[-120.34423828125, 41.70572851523751, 0.0],
							[-120.34423828125, 41.697525910759012, 0.0],
							[-120.333251953125, 41.697525910759012, 0.0],
							[-120.322265625, 41.697525910759012, 0.0],
							[-120.311279296875, 41.697525910759012, 0.0],
							[-120.311279296875, 41.689322259970432, 0.0],
							[-120.30029296875, 41.689322259970432, 0.0],
							[-120.30029296875, 41.681117562906522, 0.0],
							[-120.289306640625, 41.681117562906522, 0.0],
							[-120.289306640625, 41.672911819602092, 0.0],
							[-120.289306640625, 41.664705030092065, 0.0],
							[-120.289306640625, 41.656497194411443, 0.0],
							[-120.289306640625, 41.648288312595334, 0.0],
							[-120.289306640625, 41.640078384678915, 0.0],
							[-120.289306640625, 41.631867410697481, 0.0],
							[-120.2783203125, 41.631867410697481, 0.0],
							[-120.2783203125, 41.623655390686395, 0.0],
							[-120.2783203125, 41.615442324681105, 0.0],
							[-120.2783203125, 41.607228212717168, 0.0],
							[-120.267333984375, 41.607228212717168, 0.0],
							[-120.25634765625, 41.607228212717168, 0.0],
							[-120.25634765625, 41.599013054830223, 0.0],
							[-120.25634765625, 41.590796851056012, 0.0],
							[-120.25634765625, 41.582579601430339, 0.0],
							[-120.25634765625, 41.574361305989129, 0.0],
							[-120.25634765625, 41.566141964768377, 0.0],
							[-120.25634765625, 41.55792157780418, 0.0],
							[-120.267333984375, 41.55792157780418, 0.0],
							[-120.2783203125, 41.55792157780418, 0.0],
							[-120.2783203125, 41.549700145132725, 0.0],
							[-120.2783203125, 41.541477666790293, 0.0],
							[-120.2783203125, 41.533254142813227, 0.0],
							[-120.2783203125, 41.525029573238001, 0.0],
							[-120.2783203125, 41.516803958101157, 0.0],
							[-120.2783203125, 41.508577297439338, 0.0],
							[-120.267333984375, 41.508577297439338, 0.0],
							[-120.267333984375, 41.500349591289279, 0.0],
							[-120.2783203125, 41.500349591289279, 0.0],
							[-120.2783203125, 41.492120839687772, 0.0],
							[-120.2783203125, 41.48389104267175, 0.0],
							[-120.2783203125, 41.475660200278213, 0.0],
							[-120.2783203125, 41.46742831254425, 0.0],
							[-120.2783203125, 41.459195379507051, 0.0],
							[-120.2783203125, 41.450961401203891, 0.0],
							[-120.2783203125, 41.442726377672116, 0.0],
							[-120.2783203125, 41.434490308949208, 0.0],
							[-120.2783203125, 41.426253195072697, 0.0],
							[-120.2783203125, 41.418015036080241, 0.0],
							[-120.2783203125, 41.409775832009551, 0.0],
							[-120.2783203125, 41.401535582898461, 0.0],
							[-120.2783203125, 41.39329428878488, 0.0],
							[-120.2783203125, 41.385051949706799, 0.0],
							[-120.2783203125, 41.376808565702341, 0.0],
							[-120.2783203125, 41.368564136809674, 0.0],
							[-120.2783203125, 41.360318663067083, 0.0],
							[-120.2783203125, 41.352072144512938, 0.0],
							[-120.2783203125, 41.343824581185693, 0.0],
							[-120.2783203125, 41.335575973123902, 0.0],
							[-120.2783203125, 41.327326320366218, 0.0],
							[-120.2783203125, 41.319075622951374, 0.0],
							[-120.2783203125, 41.310823880918186, 0.0],
							[-120.2783203125, 41.302571094305577, 0.0],
							[-120.2783203125, 41.294317263152571, 0.0],
							[-120.2783203125, 41.286062387498248, 0.0],
							[-120.2783203125, 41.277806467381822, 0.0],
							[-120.2783203125, 41.269549502842573, 0.0],
							[-120.2783203125, 41.26129149391987, 0.0],
							[-120.289306640625, 41.26129149391987, 0.0],
							[-120.30029296875, 41.26129149391987, 0.0],
							[-120.311279296875, 41.26129149391987, 0.0],
							[-120.311279296875, 41.253032440653179, 0.0],
							[-120.30029296875, 41.253032440653179, 0.0],
							[-120.289306640625, 41.253032440653179, 0.0],
							[-120.289306640625, 41.244772343082076, 0.0],
							[-120.289306640625, 41.236511201246202, 0.0],
							[-120.30029296875, 41.236511201246202, 0.0],
							[-120.311279296875, 41.236511201246202, 0.0],
							[-120.322265625, 41.236511201246202, 0.0],
							[-120.322265625, 41.228249015185305, 0.0],
							[-120.322265625, 41.219985784939226, 0.0],
							[-120.322265625, 41.211721510547875, 0.0],
							[-120.311279296875, 41.211721510547875, 0.0],
							[-120.30029296875, 41.211721510547875, 0.0],
							[-120.30029296875, 41.219985784939226, 0.0],
							[-120.289306640625, 41.219985784939226, 0.0],
							[-120.289306640625, 41.211721510547875, 0.0],
							[-120.289306640625, 41.203456192051299, 0.0],
							[-120.30029296875, 41.203456192051299, 0.0],
							[-120.311279296875, 41.203456192051299, 0.0],
							[-120.322265625, 41.203456192051299, 0.0],
							[-120.333251953125, 41.203456192051299, 0.0],
							[-120.34423828125, 41.203456192051299, 0.0],
							[-120.355224609375, 41.203456192051299, 0.0],
							[-120.355224609375, 41.211721510547875, 0.0],
							[-120.3662109375, 41.211721510547875, 0.0],
							[-120.3662109375, 41.203456192051299, 0.0],
							[-120.377197265625, 41.203456192051299, 0.0],
							[-120.38818359375, 41.203456192051299, 0.0],
							[-120.38818359375, 41.211721510547875, 0.0],
							[-120.399169921875, 41.211721510547875, 0.0],
							[-120.399169921875, 41.219985784939226, 0.0],
							[-120.41015625, 41.219985784939226, 0.0],
							[-120.421142578125, 41.219985784939226, 0.0],
							[-120.43212890625, 41.219985784939226, 0.0],
							[-120.443115234375, 41.219985784939226, 0.0],
							[-120.4541015625, 41.219985784939226, 0.0],
							[-120.465087890625, 41.219985784939226, 0.0],
							[-120.47607421875, 41.219985784939226, 0.0],
							[-120.487060546875, 41.219985784939226, 0.0],
							[-120.498046875, 41.219985784939226, 0.0],
							[-120.498046875, 41.228249015185305, 0.0],
							[-120.498046875, 41.236511201246202, 0.0],
							[-120.509033203125, 41.236511201246202, 0.0],
							[-120.52001953125, 41.236511201246202, 0.0],
							[-120.531005859375, 41.236511201246202, 0.0],
							[-120.5419921875, 41.236511201246202, 0.0],
							[-120.5419921875, 41.228249015185305, 0.0],
							[-120.5419921875, 41.219985784939226, 0.0],
							[-120.552978515625, 41.219985784939226, 0.0],
							[-120.56396484375, 41.219985784939226, 0.0],
							[-120.574951171875, 41.219985784939226, 0.0],
							[-120.5859375, 41.219985784939226, 0.0],
							[-120.596923828125, 41.219985784939226, 0.0],
							[-120.60791015625, 41.219985784939226, 0.0],
							[-120.618896484375, 41.219985784939226, 0.0],
							[-120.6298828125, 41.219985784939226, 0.0],
							[-120.640869140625, 41.219985784939226, 0.0],
							[-120.640869140625, 41.228249015185305, 0.0],
							[-120.65185546875, 41.228249015185305, 0.0],
							[-120.65185546875, 41.219985784939226, 0.0],
							[-120.662841796875, 41.219985784939226, 0.0],
							[-120.662841796875, 41.228249015185305, 0.0],
							[-120.673828125, 41.228249015185305, 0.0],
							[-120.684814453125, 41.228249015185305, 0.0],
							[-120.69580078125, 41.228249015185305, 0.0],
							[-120.69580078125, 41.219985784939226, 0.0],
							[-120.706787109375, 41.219985784939226, 0.0],
							[-120.7177734375, 41.219985784939226, 0.0],
							[-120.7177734375, 41.211721510547875, 0.0],
							[-120.728759765625, 41.211721510547875, 0.0],
							[-120.73974609375, 41.211721510547875, 0.0],
							[-120.73974609375, 41.203456192051299, 0.0],
							[-120.73974609375, 41.195189829489578, 0.0],
							[-120.750732421875, 41.195189829489578, 0.0],
							[-120.76171875, 41.195189829489578, 0.0],
							[-120.76171875, 41.186922422902946, 0.0],
							[-120.76171875, 41.178653972331688, 0.0],
							[-120.772705078125, 41.178653972331688, 0.0],
							[-120.772705078125, 41.170384477816178, 0.0],
							[-120.772705078125, 41.16211393939691, 0.0],
							[-120.78369140625, 41.16211393939691, 0.0],
							[-120.78369140625, 41.153842357114456, 0.0],
							[-120.794677734375, 41.153842357114456, 0.0],
							[-120.794677734375, 41.145569731009488, 0.0],
							[-120.8056640625, 41.145569731009488, 0.0],
							[-120.816650390625, 41.145569731009488, 0.0],
							[-120.816650390625, 41.137296061122747, 0.0],
							[-120.816650390625, 41.129021347495083, 0.0],
							[-120.82763671875, 41.129021347495083, 0.0],
							[-120.82763671875, 41.120745590167452, 0.0],
							[-120.82763671875, 41.112468789180859, 0.0],
							[-120.838623046875, 41.112468789180859, 0.0],
							[-120.838623046875, 41.104190944576466, 0.0],
							[-120.849609375, 41.104190944576466, 0.0],
							[-120.849609375, 41.095912056395456, 0.0],
							[-120.860595703125, 41.095912056395456, 0.0],
							[-120.860595703125, 41.08763212467916, 0.0],
							[-120.87158203125, 41.08763212467916, 0.0],
							[-120.87158203125, 41.079351149468977, 0.0],
							[-120.87158203125, 41.0710691308064, 0.0],
							[-120.882568359375, 41.0710691308064, 0.0],
							[-120.882568359375, 41.062786068733011, 0.0],
							[-120.882568359375, 41.054501963290498, 0.0],
							[-120.8935546875, 41.054501963290498, 0.0],
							[-120.8935546875, 41.046216814520633, 0.0],
							[-120.904541015625, 41.046216814520633, 0.0],
							[-120.904541015625, 41.037930622465275, 0.0],
							[-120.904541015625, 41.029643387166381, 0.0],
							[-120.91552734375, 41.029643387166381, 0.0],
							[-120.926513671875, 41.029643387166381, 0.0],
							[-120.926513671875, 41.021355108666015, 0.0],
							[-120.9375, 41.021355108666015, 0.0],
							[-120.9375, 41.013065787006298, 0.0],
							[-120.9375, 41.004775422229471, 0.0],
							[-120.948486328125, 41.004775422229471, 0.0],
							[-120.948486328125, 40.996484014377884, 0.0],
							[-120.95947265625, 40.996484014377884, 0.0],
							[-120.970458984375, 40.996484014377884, 0.0],
							[-120.9814453125, 40.996484014377884, 0.0],
							[-120.992431640625, 40.996484014377884, 0.0],
							[-120.992431640625, 41.004775422229471, 0.0],
							[-121.00341796875, 41.004775422229471, 0.0],
							[-121.00341796875, 40.996484014377884, 0.0],
							[-121.014404296875, 40.996484014377884, 0.0],
							[-121.025390625, 40.996484014377884, 0.0],
							[-121.036376953125, 40.996484014377884, 0.0],
							[-121.04736328125, 40.996484014377884, 0.0],
							[-121.058349609375, 40.996484014377884, 0.0],
							[-121.0693359375, 40.996484014377884, 0.0],
							[-121.080322265625, 40.996484014377884, 0.0],
							[-121.09130859375, 40.996484014377884, 0.0],
							[-121.102294921875, 40.996484014377884, 0.0],
							[-121.11328125, 40.996484014377884, 0.0],
							[-121.124267578125, 40.996484014377884, 0.0],
							[-121.13525390625, 40.996484014377884, 0.0],
							[-121.146240234375, 40.996484014377884, 0.0],
							[-121.1572265625, 40.996484014377884, 0.0],
							[-121.168212890625, 40.996484014377884, 0.0],
							[-121.17919921875, 40.996484014377884, 0.0],
							[-121.190185546875, 40.996484014377884, 0.0],
							[-121.201171875, 40.996484014377884, 0.0],
							[-121.212158203125, 40.996484014377884, 0.0],
							[-121.212158203125, 41.004775422229471, 0.0],
							[-121.22314453125, 41.004775422229471, 0.0],
							[-121.22314453125, 41.013065787006298, 0.0],
							[-121.22314453125, 41.021355108666015, 0.0],
							[-121.234130859375, 41.021355108666015, 0.0],
							[-121.234130859375, 41.029643387166381, 0.0],
							[-121.2451171875, 41.029643387166381, 0.0],
							[-121.2451171875, 41.037930622465275, 0.0],
							[-121.256103515625, 41.037930622465275, 0.0],
							[-121.256103515625, 41.046216814520633, 0.0],
							[-121.256103515625, 41.054501963290498, 0.0],
							[-121.2451171875, 41.054501963290498, 0.0],
							[-121.234130859375, 41.054501963290498, 0.0],
							[-121.234130859375, 41.062786068733011, 0.0],
							[-121.234130859375, 41.0710691308064, 0.0],
							[-121.234130859375, 41.079351149468977, 0.0],
							[-121.234130859375, 41.08763212467916, 0.0],
							[-121.234130859375, 41.095912056395456, 0.0],
							[-121.234130859375, 41.104190944576466, 0.0],
							[-121.2451171875, 41.104190944576466, 0.0],
							[-121.256103515625, 41.104190944576466, 0.0],
							[-121.256103515625, 41.112468789180859, 0.0],
							[-121.256103515625, 41.120745590167452, 0.0],
							[-121.256103515625, 41.129021347495083, 0.0],
							[-121.26708984375, 41.129021347495083, 0.0],
							[-121.278076171875, 41.129021347495083, 0.0],
							[-121.278076171875, 41.137296061122747, 0.0],
							[-121.278076171875, 41.145569731009488, 0.0],
							[-121.2890625, 41.145569731009488, 0.0],
							[-121.2890625, 41.153842357114456, 0.0],
							[-121.2890625, 41.16211393939691, 0.0],
							[-121.300048828125, 41.16211393939691, 0.0],
							[-121.31103515625, 41.16211393939691, 0.0],
							[-121.31103515625, 41.153842357114456, 0.0],
							[-121.31103515625, 41.145569731009488, 0.0],
							[-121.300048828125, 41.145569731009488, 0.0],
							[-121.300048828125, 41.137296061122747, 0.0],
							[-121.300048828125, 41.129021347495083, 0.0],
							[-121.300048828125, 41.120745590167452, 0.0],
							[-121.300048828125, 41.112468789180859, 0.0],
							[-121.300048828125, 41.104190944576466, 0.0],
							[-121.300048828125, 41.095912056395456, 0.0],
							[-121.31103515625, 41.095912056395456, 0.0],
							[-121.31103515625, 41.08763212467916, 0.0],
							[-121.322021484375, 41.08763212467916, 0.0],
							[-121.3330078125, 41.08763212467916, 0.0],
							[-121.3330078125, 41.095912056395456, 0.0],
							[-121.3330078125, 41.104190944576466, 0.0],
							[-121.343994140625, 41.104190944576466, 0.0],
							[-121.35498046875, 41.104190944576466, 0.0],
							[-121.35498046875, 41.095912056395456, 0.0],
							[-121.35498046875, 41.08763212467916, 0.0],
							[-121.365966796875, 41.08763212467916, 0.0],
							[-121.376953125, 41.08763212467916, 0.0],
							[-121.376953125, 41.095912056395456, 0.0],
							[-121.387939453125, 41.095912056395456, 0.0],
							[-121.39892578125, 41.095912056395456, 0.0],
							[-121.409912109375, 41.095912056395456, 0.0],
							[-121.4208984375, 41.095912056395456, 0.0],
							[-121.4208984375, 41.104190944576466, 0.0],
							[-121.431884765625, 41.104190944576466, 0.0],
							[-121.44287109375, 41.104190944576466, 0.0],
							[-121.44287109375, 41.095912056395456, 0.0],
							[-121.453857421875, 41.095912056395456, 0.0],
							[-121.46484375, 41.095912056395456, 0.0],
							[-121.46484375, 41.08763212467916, 0.0],
							[-121.475830078125, 41.08763212467916, 0.0],
							[-121.48681640625, 41.08763212467916, 0.0],
							[-121.497802734375, 41.08763212467916, 0.0],
							[-121.5087890625, 41.08763212467916, 0.0],
							[-121.519775390625, 41.08763212467916, 0.0],
							[-121.53076171875, 41.08763212467916, 0.0],
							[-121.541748046875, 41.08763212467916, 0.0],
							[-121.552734375, 41.08763212467916, 0.0],
							[-121.552734375, 41.095912056395456, 0.0],
							[-121.563720703125, 41.095912056395456, 0.0],
							[-121.563720703125, 41.104190944576466, 0.0],
							[-121.563720703125, 41.112468789180859, 0.0],
							[-121.57470703125, 41.112468789180859, 0.0],
							[-121.57470703125, 41.120745590167452, 0.0],
							[-121.585693359375, 41.120745590167452, 0.0],
							[-121.585693359375, 41.129021347495083, 0.0],
							[-121.585693359375, 41.137296061122747, 0.0],
							[-121.585693359375, 41.145569731009488, 0.0],
							[-121.5966796875, 41.145569731009488, 0.0],
							[-121.607666015625, 41.145569731009488, 0.0],
							[-121.607666015625, 41.153842357114456, 0.0],
							[-121.607666015625, 41.16211393939691, 0.0],
							[-121.607666015625, 41.170384477816178, 0.0],
							[-121.61865234375, 41.170384477816178, 0.0],
							[-121.629638671875, 41.170384477816178, 0.0],
							[-121.629638671875, 41.178653972331688, 0.0],
							[-121.629638671875, 41.186922422902946, 0.0],
							[-121.640625, 41.186922422902946, 0.0],
							[-121.651611328125, 41.186922422902946, 0.0],
							[-121.651611328125, 41.195189829489578, 0.0],
							[-121.66259765625, 41.195189829489578, 0.0],
							[-121.673583984375, 41.195189829489578, 0.0],
							[-121.673583984375, 41.203456192051299, 0.0],
							[-121.6845703125, 41.203456192051299, 0.0],
							[-121.695556640625, 41.203456192051299, 0.0],
							[-121.70654296875, 41.203456192051299, 0.0],
							[-121.717529296875, 41.203456192051299, 0.0],
							[-121.717529296875, 41.211721510547875, 0.0],
							[-121.728515625, 41.211721510547875, 0.0],
							[-121.728515625, 41.219985784939226, 0.0],
							[-121.739501953125, 41.219985784939226, 0.0],
							[-121.75048828125, 41.219985784939226, 0.0],
							[-121.761474609375, 41.219985784939226, 0.0],
							[-121.7724609375, 41.219985784939226, 0.0],
							[-121.7724609375, 41.228249015185305, 0.0],
							[-121.783447265625, 41.228249015185305, 0.0],
							[-121.783447265625, 41.236511201246202, 0.0],
							[-121.79443359375, 41.236511201246202, 0.0],
							[-121.805419921875, 41.236511201246202, 0.0],
							[-121.805419921875, 41.228249015185305, 0.0],
							[-121.81640625, 41.228249015185305, 0.0],
							[-121.81640625, 41.236511201246202, 0.0],
							[-121.827392578125, 41.236511201246202, 0.0],
							[-121.827392578125, 41.244772343082076, 0.0],
							[-121.83837890625, 41.244772343082076, 0.0],
							[-121.849365234375, 41.244772343082076, 0.0],
							[-121.8603515625, 41.244772343082076, 0.0],
							[-121.871337890625, 41.244772343082076, 0.0],
							[-121.88232421875, 41.244772343082076, 0.0],
							[-121.893310546875, 41.244772343082076, 0.0],
							[-121.893310546875, 41.253032440653179, 0.0],
							[-121.893310546875, 41.26129149391987, 0.0],
							[-121.904296875, 41.26129149391987, 0.0],
							[-121.904296875, 41.269549502842573, 0.0],
							[-121.904296875, 41.277806467381822, 0.0],
							[-121.915283203125, 41.277806467381822, 0.0],
							[-121.92626953125, 41.277806467381822, 0.0],
							[-121.92626953125, 41.269549502842573, 0.0],
							[-121.92626953125, 41.26129149391987, 0.0],
							[-121.937255859375, 41.26129149391987, 0.0],
							[-121.937255859375, 41.253032440653179, 0.0],
							[-121.9482421875, 41.253032440653179, 0.0],
							[-121.959228515625, 41.253032440653179, 0.0],
							[-121.959228515625, 41.244772343082076, 0.0],
							[-121.97021484375, 41.244772343082076, 0.0],
							[-121.981201171875, 41.244772343082076, 0.0],
							[-121.9921875, 41.244772343082076, 0.0],
							[-121.9921875, 41.236511201246202, 0.0],
							[-122.003173828125, 41.236511201246202, 0.0],
							[-122.01416015625, 41.236511201246202, 0.0],
							[-122.01416015625, 41.228249015185305, 0.0],
							[-122.025146484375, 41.228249015185305, 0.0],
							[-122.0361328125, 41.228249015185305, 0.0],
							[-122.047119140625, 41.228249015185305, 0.0],
							[-122.047119140625, 41.219985784939226, 0.0],
							[-122.047119140625, 41.211721510547875, 0.0],
							[-122.047119140625, 41.203456192051299, 0.0],
							[-122.047119140625, 41.195189829489578, 0.0],
							[-122.047119140625, 41.186922422902946, 0.0],
							[-122.05810546875, 41.186922422902946, 0.0],
							[-122.05810546875, 41.178653972331688, 0.0],
							[-122.05810546875, 41.170384477816178, 0.0],
							[-122.05810546875, 41.16211393939691, 0.0],
							[-122.05810546875, 41.153842357114456, 0.0],
							[-122.069091796875, 41.153842357114456, 0.0],
							[-122.069091796875, 41.145569731009488, 0.0],
							[-122.05810546875, 41.145569731009488, 0.0],
							[-122.047119140625, 41.145569731009488, 0.0],
							[-122.0361328125, 41.145569731009488, 0.0],
							[-122.025146484375, 41.145569731009488, 0.0],
							[-122.025146484375, 41.153842357114456, 0.0],
							[-122.01416015625, 41.153842357114456, 0.0],
							[-122.003173828125, 41.153842357114456, 0.0],
							[-122.003173828125, 41.145569731009488, 0.0],
							[-121.9921875, 41.145569731009488, 0.0],
							[-121.981201171875, 41.145569731009488, 0.0],
							[-121.981201171875, 41.137296061122747, 0.0],
							[-121.981201171875, 41.129021347495083, 0.0],
							[-121.981201171875, 41.120745590167452, 0.0],
							[-121.981201171875, 41.112468789180859, 0.0],
							[-121.981201171875, 41.104190944576466, 0.0],
							[-121.981201171875, 41.095912056395456, 0.0],
							[-121.981201171875, 41.08763212467916, 0.0],
							[-121.9921875, 41.08763212467916, 0.0],
							[-122.003173828125, 41.08763212467916, 0.0],
							[-122.003173828125, 41.079351149468977, 0.0],
							[-122.01416015625, 41.079351149468977, 0.0],
							[-122.01416015625, 41.0710691308064, 0.0],
							[-122.01416015625, 41.062786068733011, 0.0],
							[-122.01416015625, 41.054501963290498, 0.0],
							[-122.01416015625, 41.046216814520633, 0.0],
							[-122.025146484375, 41.046216814520633, 0.0],
							[-122.025146484375, 41.037930622465275, 0.0],
							[-122.0361328125, 41.037930622465275, 0.0],
							[-122.0361328125, 41.029643387166381, 0.0],
							[-122.0361328125, 41.021355108666015, 0.0],
							[-122.025146484375, 41.021355108666015, 0.0],
							[-122.025146484375, 41.013065787006298, 0.0],
							[-122.025146484375, 41.004775422229471, 0.0],
							[-122.025146484375, 40.996484014377884, 0.0],
							[-122.01416015625, 40.996484014377884, 0.0],
							[-122.01416015625, 40.988191563493928, 0.0],
							[-122.01416015625, 40.979898069620127, 0.0],
							[-122.025146484375, 40.979898069620127, 0.0],
							[-122.025146484375, 40.971603532799094, 0.0],
							[-122.025146484375, 40.963307953073524, 0.0],
							[-122.01416015625, 40.963307953073524, 0.0],
							[-122.01416015625, 40.955011330486201, 0.0],
							[-122.025146484375, 40.955011330486201, 0.0],
							[-122.025146484375, 40.946713665080019, 0.0],
							[-122.025146484375, 40.938414956897937, 0.0],
							[-122.025146484375, 40.93011520598305, 0.0],
							[-122.025146484375, 40.921814412378509, 0.0],
							[-122.025146484375, 40.913512576127573, 0.0],
							[-122.025146484375, 40.905209697273591, 0.0],
							[-122.025146484375, 40.896905775859999, 0.0],
							[-122.01416015625, 40.896905775859999, 0.0],
							[-122.003173828125, 40.896905775859999, 0.0],
							[-121.9921875, 40.896905775859999, 0.0],
							[-121.9921875, 40.888600811930338, 0.0],
							[-121.9921875, 40.88029480552823, 0.0],
							[-121.9921875, 40.871987756697408, 0.0],
							[-121.9921875, 40.863679665481683, 0.0],
							[-121.9921875, 40.855370531924947, 0.0],
							[-121.9921875, 40.847060356071218, 0.0],
							[-121.981201171875, 40.847060356071218, 0.0],
							[-121.981201171875, 40.838749137964584, 0.0],
							[-121.97021484375, 40.838749137964584, 0.0],
							[-121.97021484375, 40.830436877649234, 0.0],
							[-121.97021484375, 40.822123575169442, 0.0],
							[-121.97021484375, 40.813809230569603, 0.0],
							[-121.959228515625, 40.813809230569603, 0.0],
							[-121.9482421875, 40.813809230569603, 0.0],
							[-121.937255859375, 40.813809230569603, 0.0],
							[-121.92626953125, 40.813809230569603, 0.0],
							[-121.915283203125, 40.813809230569603, 0.0],
							[-121.915283203125, 40.805493843894155, 0.0],
							[-121.904296875, 40.805493843894155, 0.0],
							[-121.893310546875, 40.805493843894155, 0.0],
							[-121.88232421875, 40.805493843894155, 0.0],
							[-121.871337890625, 40.805493843894155, 0.0],
							[-121.8603515625, 40.805493843894155, 0.0],
							[-121.849365234375, 40.805493843894155, 0.0],
							[-121.849365234375, 40.797177415187669, 0.0],
							[-121.83837890625, 40.797177415187669, 0.0],
							[-121.83837890625, 40.805493843894155, 0.0],
							[-121.827392578125, 40.805493843894155, 0.0],
							[-121.81640625, 40.805493843894155, 0.0],
							[-121.81640625, 40.797177415187669, 0.0],
							[-121.805419921875, 40.797177415187669, 0.0],
							[-121.805419921875, 40.78885994449481, 0.0],
							[-121.79443359375, 40.78885994449481, 0.0],
							[-121.79443359375, 40.780541431860314, 0.0],
							[-121.783447265625, 40.780541431860314, 0.0],
							[-121.783447265625, 40.772221877329024, 0.0],
							[-121.783447265625, 40.763901280945881, 0.0],
							[-121.7724609375, 40.763901280945881, 0.0],
							[-121.7724609375, 40.75557964275589, 0.0],
							[-121.7724609375, 40.747256962804208, 0.0],
							[-121.761474609375, 40.747256962804208, 0.0],
							[-121.761474609375, 40.738933241136017, 0.0],
							[-121.75048828125, 40.738933241136017, 0.0],
							[-121.75048828125, 40.730608477796643, 0.0],
							[-121.75048828125, 40.72228267283149, 0.0],
							[-121.739501953125, 40.72228267283149, 0.0],
							[-121.728515625, 40.72228267283149, 0.0],
							[-121.728515625, 40.713955826286039, 0.0],
							[-121.717529296875, 40.713955826286039, 0.0],
							[-121.717529296875, 40.7056279382059, 0.0],
							[-121.70654296875, 40.7056279382059, 0.0],
							[-121.695556640625, 40.7056279382059, 0.0],
							[-121.695556640625, 40.69729900863674, 0.0],
							[-121.6845703125, 40.69729900863674, 0.0],
							[-121.6845703125, 40.7056279382059, 0.0],
							[-121.673583984375, 40.7056279382059, 0.0],
							[-121.673583984375, 40.69729900863674, 0.0],
							[-121.673583984375, 40.688969037624339, 0.0],
							[-121.673583984375, 40.68063802521457, 0.0],
							[-121.66259765625, 40.68063802521457, 0.0],
							[-121.66259765625, 40.672305971453397, 0.0],
							[-121.651611328125, 40.672305971453397, 0.0],
							[-121.640625, 40.672305971453397, 0.0],
							[-121.640625, 40.663972876386879, 0.0],
							[-121.629638671875, 40.663972876386879, 0.0],
							[-121.629638671875, 40.655638740061164, 0.0],
							[-121.629638671875, 40.647303562522502, 0.0],
							[-121.629638671875, 40.638967343817235, 0.0],
							[-121.629638671875, 40.630630083991797, 0.0],
							[-121.61865234375, 40.630630083991797, 0.0],
							[-121.61865234375, 40.622291783092706, 0.0],
							[-121.607666015625, 40.622291783092706, 0.0],
							[-121.607666015625, 40.613952441166596, 0.0],
							[-121.607666015625, 40.605612058260178, 0.0],
							[-121.5966796875, 40.605612058260178, 0.0],
							[-121.5966796875, 40.597270634420248, 0.0],
							[-121.5966796875, 40.588928169693745, 0.0],
							[-121.5966796875, 40.580584664127628, 0.0],
							[-121.585693359375, 40.580584664127628, 0.0],
							[-121.57470703125, 40.580584664127628, 0.0],
							[-121.57470703125, 40.57224011776902, 0.0],
							[-121.563720703125, 40.57224011776902, 0.0],
							[-121.552734375, 40.57224011776902, 0.0],
							[-121.552734375, 40.563894530665074, 0.0],
							[-121.552734375, 40.555547902863104, 0.0],
							[-121.541748046875, 40.555547902863104, 0.0],
							[-121.53076171875, 40.555547902863104, 0.0],
							[-121.53076171875, 40.547200234410482, 0.0],
							[-121.519775390625, 40.547200234410482, 0.0],
							[-121.519775390625, 40.538851525354659, 0.0],
							[-121.5087890625, 40.538851525354659, 0.0],
							[-121.5087890625, 40.530501775743204, 0.0],
							[-121.5087890625, 40.522150985623774, 0.0],
							[-121.497802734375, 40.522150985623774, 0.0],
							[-121.497802734375, 40.513799155044133, 0.0],
							[-121.497802734375, 40.505446284052113, 0.0],
							[-121.48681640625, 40.505446284052113, 0.0],
							[-121.48681640625, 40.497092372695668, 0.0],
							[-121.48681640625, 40.488737421022819, 0.0],
							[-121.48681640625, 40.48038142908171, 0.0],
							[-121.475830078125, 40.48038142908171, 0.0],
							[-121.46484375, 40.48038142908171, 0.0],
							[-121.46484375, 40.488737421022819, 0.0],
							[-121.453857421875, 40.488737421022819, 0.0],
							[-121.44287109375, 40.488737421022819, 0.0],
							[-121.431884765625, 40.488737421022819, 0.0],
							[-121.4208984375, 40.488737421022819, 0.0],
							[-121.409912109375, 40.488737421022819, 0.0],
							[-121.39892578125, 40.488737421022819, 0.0],
							[-121.387939453125, 40.488737421022819, 0.0],
							[-121.376953125, 40.488737421022819, 0.0],
							[-121.365966796875, 40.488737421022819, 0.0],
							[-121.35498046875, 40.488737421022819, 0.0],
							[-121.343994140625, 40.488737421022819, 0.0],
							[-121.3330078125, 40.488737421022819, 0.0],
							[-121.3330078125, 40.48038142908171, 0.0],
							[-121.322021484375, 40.48038142908171, 0.0],
							[-121.31103515625, 40.48038142908171, 0.0],
							[-121.300048828125, 40.48038142908171, 0.0],
							[-121.2890625, 40.48038142908171, 0.0],
							[-121.278076171875, 40.48038142908171, 0.0],
							[-121.26708984375, 40.48038142908171, 0.0],
							[-121.26708984375, 40.472024396920553, 0.0],
							[-121.256103515625, 40.472024396920553, 0.0],
							[-121.256103515625, 40.48038142908171, 0.0],
							[-121.256103515625, 40.488737421022819, 0.0],
							[-121.256103515625, 40.497092372695668, 0.0],
							[-121.256103515625, 40.505446284052113, 0.0],
							[-121.2451171875, 40.505446284052113, 0.0],
							[-121.234130859375, 40.505446284052113, 0.0],
							[-121.22314453125, 40.505446284052113, 0.0],
							[-121.22314453125, 40.513799155044133, 0.0],
							[-121.212158203125, 40.513799155044133, 0.0],
							[-121.212158203125, 40.505446284052113, 0.0],
							[-121.201171875, 40.505446284052113, 0.0],
							[-121.190185546875, 40.505446284052113, 0.0],
							[-121.190185546875, 40.497092372695668, 0.0],
							[-121.190185546875, 40.488737421022819, 0.0],
							[-121.17919921875, 40.488737421022819, 0.0],
							[-121.17919921875, 40.48038142908171, 0.0],
							[-121.168212890625, 40.48038142908171, 0.0],
							[-121.1572265625, 40.48038142908171, 0.0],
							[-121.1572265625, 40.488737421022819, 0.0],
							[-121.1572265625, 40.497092372695668, 0.0],
							[-121.146240234375, 40.497092372695668, 0.0],
							[-121.13525390625, 40.497092372695668, 0.0],
							[-121.124267578125, 40.497092372695668, 0.0],
							[-121.11328125, 40.497092372695668, 0.0],
							[-121.102294921875, 40.497092372695668, 0.0],
							[-121.09130859375, 40.497092372695668, 0.0],
							[-121.080322265625, 40.497092372695668, 0.0],
							[-121.0693359375, 40.497092372695668, 0.0],
							[-121.058349609375, 40.497092372695668, 0.0],
							[-121.058349609375, 40.488737421022819, 0.0],
							[-121.04736328125, 40.488737421022819, 0.0],
							[-121.036376953125, 40.488737421022819, 0.0],
							[-121.036376953125, 40.48038142908171, 0.0],
							[-121.036376953125, 40.472024396920553, 0.0],
							[-121.025390625, 40.472024396920553, 0.0],
							[-121.025390625, 40.46366632458767, 0.0],
							[-121.014404296875, 40.46366632458767, 0.0],
							[-121.00341796875, 40.46366632458767, 0.0],
							[-121.00341796875, 40.45530721213148, 0.0],
							[-121.00341796875, 40.44694705960049, 0.0],
							[-120.992431640625, 40.44694705960049, 0.0],
							[-120.992431640625, 40.438585867043294, 0.0],
							[-120.9814453125, 40.438585867043294, 0.0],
							[-120.970458984375, 40.438585867043294, 0.0],
							[-120.970458984375, 40.4302236345086, 0.0],
							[-120.95947265625, 40.4302236345086, 0.0],
							[-120.95947265625, 40.421860362045194, 0.0],
							[-120.948486328125, 40.421860362045194, 0.0],
							[-120.9375, 40.421860362045194, 0.0],
							[-120.926513671875, 40.421860362045194, 0.0],
							[-120.926513671875, 40.413496049701955, 0.0],
							[-120.91552734375, 40.413496049701955, 0.0],
							[-120.91552734375, 40.405130697527888, 0.0],
							[-120.904541015625, 40.405130697527888, 0.0],
							[-120.904541015625, 40.396764305572042, 0.0],
							[-120.8935546875, 40.396764305572042, 0.0],
							[-120.882568359375, 40.396764305572042, 0.0],
							[-120.87158203125, 40.396764305572042, 0.0],
							[-120.87158203125, 40.3883968738836, 0.0],
							[-120.860595703125, 40.3883968738836, 0.0],
							[-120.860595703125, 40.380028402511826, 0.0],
							[-120.860595703125, 40.371658891506087, 0.0],
							[-120.849609375, 40.371658891506087, 0.0],
							[-120.849609375, 40.363288340915823, 0.0],
							[-120.849609375, 40.354916750790608, 0.0],
							[-120.849609375, 40.34654412118006, 0.0],
							[-120.838623046875, 40.34654412118006, 0.0],
							[-120.838623046875, 40.33817045213393, 0.0],
							[-120.838623046875, 40.329795743702064, 0.0],
							[-120.838623046875, 40.321419995934377, 0.0],
							[-120.838623046875, 40.313043208880899, 0.0],
							[-120.82763671875, 40.313043208880899, 0.0],
							[-120.82763671875, 40.304665382591757, 0.0],
							[-120.82763671875, 40.296286517117153, 0.0],
							[-120.816650390625, 40.296286517117153, 0.0],
							[-120.816650390625, 40.287906612507399, 0.0],
							[-120.8056640625, 40.287906612507399, 0.0],
							[-120.8056640625, 40.279525668812916, 0.0],
							[-120.8056640625, 40.271143686084187, 0.0],
							[-120.8056640625, 40.262760664371825, 0.0],
							[-120.8056640625, 40.254376603726499, 0.0],
							[-120.8056640625, 40.245991504199012, 0.0],
							[-120.816650390625, 40.245991504199012, 0.0],
							[-120.816650390625, 40.237605365840238, 0.0],
							[-120.82763671875, 40.237605365840238, 0.0],
							[-120.838623046875, 40.237605365840238, 0.0],
							[-120.838623046875, 40.229218188701161, 0.0],
							[-120.849609375, 40.229218188701161, 0.0],
							[-120.849609375, 40.220829972832853, 0.0],
							[-120.860595703125, 40.220829972832853, 0.0],
							[-120.860595703125, 40.212440718286466, 0.0],
							[-120.860595703125, 40.204050425113287, 0.0],
							[-120.860595703125, 40.195659093364654, 0.0],
							[-120.87158203125, 40.195659093364654, 0.0],
							[-120.87158203125, 40.18726672309203, 0.0],
							[-120.882568359375, 40.18726672309203, 0.0],
							[-120.8935546875, 40.18726672309203, 0.0],
							[-120.904541015625, 40.18726672309203, 0.0],
							[-120.91552734375, 40.18726672309203, 0.0],
							[-120.91552734375, 40.178873314346959, 0.0],
							[-120.926513671875, 40.178873314346959, 0.0],
							[-120.9375, 40.178873314346959, 0.0],
							[-120.9375, 40.18726672309203, 0.0],
							[-120.9375, 40.195659093364654, 0.0],
							[-120.948486328125, 40.195659093364654, 0.0],
							[-120.948486328125, 40.204050425113287, 0.0],
							[-120.95947265625, 40.204050425113287, 0.0],
							[-120.970458984375, 40.204050425113287, 0.0],
							[-120.9814453125, 40.204050425113287, 0.0],
							[-120.992431640625, 40.204050425113287, 0.0],
							[-120.992431640625, 40.212440718286466, 0.0],
							[-121.00341796875, 40.212440718286466, 0.0],
							[-121.00341796875, 40.220829972832853, 0.0],
							[-121.014404296875, 40.220829972832853, 0.0],
							[-121.025390625, 40.220829972832853, 0.0],
							[-121.025390625, 40.212440718286466, 0.0],
							[-121.036376953125, 40.212440718286466, 0.0],
							[-121.036376953125, 40.204050425113287, 0.0],
							[-121.036376953125, 40.195659093364654, 0.0],
							[-121.04736328125, 40.195659093364654, 0.0],
							[-121.058349609375, 40.195659093364654, 0.0],
							[-121.058349609375, 40.18726672309203, 0.0],
							[-121.058349609375, 40.178873314346959, 0.0],
							[-121.058349609375, 40.170478867181096, 0.0],
							[-121.04736328125, 40.170478867181096, 0.0],
							[-121.04736328125, 40.162083381646177, 0.0],
							[-121.04736328125, 40.153686857794035, 0.0],
							[-121.04736328125, 40.145289295676605, 0.0],
							[-121.04736328125, 40.136890695345905, 0.0],
							[-121.036376953125, 40.136890695345905, 0.0],
							[-121.036376953125, 40.128491056854081, 0.0],
							[-121.036376953125, 40.120090380253323, 0.0],
							[-121.036376953125, 40.111688665595963, 0.0],
							[-121.036376953125, 40.103285912934403, 0.0],
							[-121.036376953125, 40.094882122321152, 0.0],
							[-121.036376953125, 40.086477293808812, 0.0],
							[-121.04736328125, 40.086477293808812, 0.0],
							[-121.04736328125, 40.078071427450091, 0.0],
							[-121.058349609375, 40.078071427450091, 0.0],
							[-121.0693359375, 40.078071427450091, 0.0],
							[-121.0693359375, 40.069664523297767, 0.0],
							[-121.058349609375, 40.069664523297767, 0.0],
							[-121.058349609375, 40.061256581404727, 0.0],
							[-121.0693359375, 40.061256581404727, 0.0],
							[-121.080322265625, 40.061256581404727, 0.0],
							[-121.080322265625, 40.05284760182397, 0.0],
							[-121.09130859375, 40.05284760182397, 0.0],
							[-121.09130859375, 40.061256581404727, 0.0],
							[-121.102294921875, 40.061256581404727, 0.0],
							[-121.11328125, 40.061256581404727, 0.0],
							[-121.124267578125, 40.061256581404727, 0.0],
							[-121.124267578125, 40.05284760182397, 0.0],
							[-121.13525390625, 40.05284760182397, 0.0],
							[-121.13525390625, 40.044437584608566, 0.0],
							[-121.146240234375, 40.044437584608566, 0.0],
							[-121.1572265625, 40.044437584608566, 0.0],
							[-121.1572265625, 40.036026529811707, 0.0],
							[-121.168212890625, 40.036026529811707, 0.0],
							[-121.168212890625, 40.027614437486648, 0.0],
							[-121.168212890625, 40.019201307686757, 0.0],
							[-121.17919921875, 40.019201307686757, 0.0],
							[-121.190185546875, 40.019201307686757, 0.0],
							[-121.190185546875, 40.010787140465517, 0.0],
							[-121.201171875, 40.010787140465517, 0.0],
							[-121.212158203125, 40.010787140465517, 0.0],
							[-121.212158203125, 40.002371935876475, 0.0],
							[-121.212158203125, 39.993955693973298, 0.0],
							[-121.212158203125, 39.985538414809731, 0.0],
							[-121.201171875, 39.985538414809731, 0.0],
							[-121.190185546875, 39.985538414809731, 0.0],
							[-121.190185546875, 39.977120098439634, 0.0],
							[-121.190185546875, 39.968700744916937, 0.0],
							[-121.17919921875, 39.968700744916937, 0.0],
							[-121.168212890625, 39.968700744916937, 0.0],
							[-121.168212890625, 39.977120098439634, 0.0],
							[-121.1572265625, 39.977120098439634, 0.0],
							[-121.1572265625, 39.968700744916937, 0.0],
							[-121.146240234375, 39.968700744916937, 0.0],
							[-121.146240234375, 39.960280354295698, 0.0],
							[-121.146240234375, 39.951858926630038, 0.0],
							[-121.146240234375, 39.943436461974215, 0.0],
							[-121.13525390625, 39.943436461974215, 0.0],
							[-121.124267578125, 39.943436461974215, 0.0],
							[-121.124267578125, 39.935012960382537, 0.0],
							[-121.124267578125, 39.926588421909436, 0.0],
							[-121.124267578125, 39.918162846609448, 0.0],
							[-121.124267578125, 39.909736234537185, 0.0],
							[-121.11328125, 39.909736234537185, 0.0],
							[-121.102294921875, 39.909736234537185, 0.0],
							[-121.102294921875, 39.901308585747358, 0.0],
							[-121.09130859375, 39.901308585747358, 0.0],
							[-121.080322265625, 39.901308585747358, 0.0],
							[-121.080322265625, 39.892879900294787, 0.0],
							[-121.0693359375, 39.892879900294787, 0.0],
							[-121.058349609375, 39.892879900294787, 0.0],
							[-121.04736328125, 39.892879900294787, 0.0],
							[-121.04736328125, 39.901308585747358, 0.0],
							[-121.036376953125, 39.901308585747358, 0.0],
							[-121.036376953125, 39.892879900294787, 0.0],
							[-121.025390625, 39.892879900294787, 0.0],
							[-121.025390625, 39.884450178234388, 0.0],
							[-121.025390625, 39.876019419621151, 0.0],
							[-121.014404296875, 39.876019419621151, 0.0],
							[-121.00341796875, 39.876019419621151, 0.0],
							[-121.00341796875, 39.867587624510186, 0.0],
							[-120.992431640625, 39.867587624510186, 0.0],
							[-120.992431640625, 39.876019419621151, 0.0],
							[-120.9814453125, 39.876019419621151, 0.0],
							[-120.970458984375, 39.876019419621151, 0.0],
							[-120.970458984375, 39.884450178234388, 0.0],
							[-120.95947265625, 39.884450178234388, 0.0],
							[-120.948486328125, 39.884450178234388, 0.0],
							[-120.948486328125, 39.892879900294787, 0.0],
							[-120.9375, 39.892879900294787, 0.0],
							[-120.926513671875, 39.892879900294787, 0.0],
							[-120.91552734375, 39.892879900294787, 0.0],
							[-120.904541015625, 39.892879900294787, 0.0],
							[-120.904541015625, 39.901308585747358, 0.0],
							[-120.8935546875, 39.901308585747358, 0.0],
							[-120.882568359375, 39.901308585747358, 0.0],
							[-120.87158203125, 39.901308585747358, 0.0],
							[-120.860595703125, 39.901308585747358, 0.0],
							[-120.860595703125, 39.909736234537185, 0.0],
							[-120.849609375, 39.909736234537185, 0.0],
							[-120.838623046875, 39.909736234537185, 0.0],
							[-120.82763671875, 39.909736234537185, 0.0],
							[-120.816650390625, 39.909736234537185, 0.0],
							[-120.8056640625, 39.909736234537185, 0.0],
							[-120.8056640625, 39.901308585747358, 0.0],
							[-120.794677734375, 39.901308585747358, 0.0],
							[-120.794677734375, 39.892879900294787, 0.0],
							[-120.794677734375, 39.884450178234388, 0.0],
							[-120.78369140625, 39.884450178234388, 0.0],
							[-120.772705078125, 39.884450178234388, 0.0],
							[-120.772705078125, 39.892879900294787, 0.0],
							[-120.772705078125, 39.901308585747358, 0.0],
							[-120.76171875, 39.901308585747358, 0.0],
							[-120.750732421875, 39.901308585747358, 0.0],
							[-120.73974609375, 39.901308585747358, 0.0],
							[-120.728759765625, 39.901308585747358, 0.0],
							[-120.728759765625, 39.909736234537185, 0.0],
							[-120.7177734375, 39.909736234537185, 0.0],
							[-120.7177734375, 39.918162846609448, 0.0],
							[-120.7177734375, 39.926588421909436, 0.0],
							[-120.706787109375, 39.926588421909436, 0.0],
							[-120.69580078125, 39.926588421909436, 0.0],
							[-120.69580078125, 39.935012960382537, 0.0],
							[-120.684814453125, 39.935012960382537, 0.0],
							[-120.673828125, 39.935012960382537, 0.0],
							[-120.662841796875, 39.935012960382537, 0.0],
							[-120.65185546875, 39.935012960382537, 0.0],
							[-120.65185546875, 39.943436461974215, 0.0],
							[-120.640869140625, 39.943436461974215, 0.0],
							[-120.640869140625, 39.951858926630038, 0.0],
							[-120.6298828125, 39.951858926630038, 0.0],
							[-120.618896484375, 39.951858926630038, 0.0],
							[-120.618896484375, 39.960280354295698, 0.0],
							[-120.60791015625, 39.960280354295698, 0.0],
							[-120.60791015625, 39.968700744916937, 0.0],
							[-120.596923828125, 39.968700744916937, 0.0],
							[-120.5859375, 39.968700744916937, 0.0],
							[-120.574951171875, 39.968700744916937, 0.0],
							[-120.56396484375, 39.968700744916937, 0.0],
							[-120.56396484375, 39.960280354295698, 0.0],
							[-120.552978515625, 39.960280354295698, 0.0],
							[-120.552978515625, 39.951858926630038, 0.0],
							[-120.5419921875, 39.951858926630038, 0.0],
							[-120.531005859375, 39.951858926630038, 0.0],
							[-120.52001953125, 39.951858926630038, 0.0],
							[-120.52001953125, 39.960280354295698, 0.0],
							[-120.509033203125, 39.960280354295698, 0.0],
							[-120.498046875, 39.960280354295698, 0.0],
							[-120.498046875, 39.951858926630038, 0.0],
							[-120.487060546875, 39.951858926630038, 0.0],
							[-120.487060546875, 39.943436461974215, 0.0],
							[-120.47607421875, 39.943436461974215, 0.0],
							[-120.465087890625, 39.943436461974215, 0.0],
							[-120.465087890625, 39.935012960382537, 0.0],
							[-120.4541015625, 39.935012960382537, 0.0],
							[-120.4541015625, 39.926588421909436, 0.0],
							[-120.4541015625, 39.918162846609448, 0.0],
							[-120.443115234375, 39.918162846609448, 0.0],
							[-120.43212890625, 39.918162846609448, 0.0],
							[-120.43212890625, 39.909736234537185, 0.0],
							[-120.43212890625, 39.901308585747358, 0.0],
							[-120.43212890625, 39.892879900294787, 0.0],
							[-120.421142578125, 39.892879900294787, 0.0],
							[-120.421142578125, 39.884450178234388, 0.0],
							[-120.41015625, 39.884450178234388, 0.0],
							[-120.399169921875, 39.884450178234388, 0.0],
							[-120.399169921875, 39.892879900294787, 0.0],
							[-120.38818359375, 39.892879900294787, 0.0],
							[-120.377197265625, 39.892879900294787, 0.0],
							[-120.377197265625, 39.884450178234388, 0.0],
							[-120.3662109375, 39.884450178234388, 0.0],
							[-120.355224609375, 39.884450178234388, 0.0],
							[-120.355224609375, 39.892879900294787, 0.0],
							[-120.34423828125, 39.892879900294787, 0.0],
							[-120.34423828125, 39.901308585747358, 0.0],
							[-120.333251953125, 39.901308585747358, 0.0],
							[-120.333251953125, 39.909736234537185, 0.0],
							[-120.34423828125, 39.909736234537185, 0.0],
							[-120.34423828125, 39.918162846609448, 0.0],
							[-120.333251953125, 39.918162846609448, 0.0],
							[-120.322265625, 39.918162846609448, 0.0],
							[-120.322265625, 39.926588421909436, 0.0],
							[-120.311279296875, 39.926588421909436, 0.0],
							[-120.30029296875, 39.926588421909436, 0.0],
							[-120.30029296875, 39.935012960382537, 0.0],
							[-120.30029296875, 39.943436461974215, 0.0],
							[-120.30029296875, 39.951858926630038, 0.0],
							[-120.30029296875, 39.960280354295698, 0.0],
							[-120.311279296875, 39.960280354295698, 0.0],
							[-120.311279296875, 39.968700744916937, 0.0],
							[-120.311279296875, 39.977120098439634, 0.0],
							[-120.311279296875, 39.985538414809731, 0.0],
							[-120.311279296875, 39.993955693973298, 0.0],
							[-120.322265625, 39.993955693973298, 0.0],
							[-120.322265625, 40.002371935876475, 0.0],
							[-120.322265625, 40.010787140465517, 0.0],
							[-120.322265625, 40.019201307686757, 0.0],
							[-120.322265625, 40.027614437486648, 0.0],
							[-120.322265625, 40.036026529811707, 0.0],
							[-120.311279296875, 40.036026529811707, 0.0],
							[-120.30029296875, 40.036026529811707, 0.0],
							[-120.289306640625, 40.036026529811707, 0.0],
							[-120.289306640625, 40.044437584608566, 0.0],
							[-120.2783203125, 40.044437584608566, 0.0],
							[-120.2783203125, 40.036026529811707, 0.0],
							[-120.267333984375, 40.036026529811707, 0.0],
							[-120.25634765625, 40.036026529811707, 0.0],
							[-120.25634765625, 40.027614437486648, 0.0],
							[-120.245361328125, 40.027614437486648, 0.0],
							[-120.245361328125, 40.036026529811707, 0.0],
							[-120.234375, 40.036026529811707, 0.0],
							[-120.223388671875, 40.036026529811707, 0.0],
							[-120.21240234375, 40.036026529811707, 0.0],
							[-120.21240234375, 40.027614437486648, 0.0],
							[-120.201416015625, 40.027614437486648, 0.0],
							[-120.201416015625, 40.019201307686757, 0.0],
							[-120.1904296875, 40.019201307686757, 0.0],
							[-120.179443359375, 40.019201307686757, 0.0],
							[-120.16845703125, 40.019201307686757, 0.0],
							[-120.16845703125, 40.010787140465517, 0.0],
							[-120.157470703125, 40.010787140465517, 0.0],
							[-120.157470703125, 40.002371935876475, 0.0],
							[-120.157470703125, 39.993955693973298, 0.0],
							[-120.16845703125, 39.993955693973298, 0.0],
							[-120.179443359375, 39.993955693973298, 0.0],
							[-120.1904296875, 39.993955693973298, 0.0],
							[-120.201416015625, 39.993955693973298, 0.0],
							[-120.21240234375, 39.993955693973298, 0.0],
							[-120.223388671875, 39.993955693973298, 0.0],
							[-120.223388671875, 39.985538414809731, 0.0],
							[-120.21240234375, 39.985538414809731, 0.0],
							[-120.201416015625, 39.985538414809731, 0.0],
							[-120.1904296875, 39.985538414809731, 0.0],
							[-120.179443359375, 39.985538414809731, 0.0],
							[-120.16845703125, 39.985538414809731, 0.0],
							[-120.157470703125, 39.985538414809731, 0.0],
							[-120.146484375, 39.985538414809731, 0.0],
							[-120.146484375, 39.977120098439634, 0.0],
							[-120.135498046875, 39.977120098439634, 0.0],
							[-120.135498046875, 39.968700744916937, 0.0],
							[-120.12451171875, 39.968700744916937, 0.0],
							[-120.12451171875, 39.960280354295698, 0.0],
							[-120.12451171875, 39.951858926630038, 0.0],
							[-120.113525390625, 39.951858926630038, 0.0],
							[-120.113525390625, 39.943436461974215, 0.0],
							[-120.1025390625, 39.943436461974215, 0.0],
							[-120.1025390625, 39.935012960382537, 0.0],
							[-120.1025390625, 39.926588421909436, 0.0],
							[-120.091552734375, 39.926588421909436, 0.0],
							[-120.091552734375, 39.918162846609448, 0.0],
							[-120.091552734375, 39.909736234537185, 0.0],
							[-120.091552734375, 39.901308585747358, 0.0],
							[-120.091552734375, 39.892879900294787, 0.0],
							[-120.08056640625, 39.892879900294787, 0.0],
							[-120.08056640625, 39.884450178234388, 0.0],
							[-120.091552734375, 39.884450178234388, 0.0],
							[-120.091552734375, 39.876019419621151, 0.0],
							[-120.091552734375, 39.867587624510186, 0.0],
							[-120.1025390625, 39.867587624510186, 0.0],
							[-120.1025390625, 39.859154792956687, 0.0],
							[-120.1025390625, 39.850720925015963, 0.0],
							[-120.1025390625, 39.842286020743394, 0.0],
							[-120.091552734375, 39.842286020743394, 0.0],
							[-120.091552734375, 39.833850080194466, 0.0],
							[-120.091552734375, 39.825413103424779, 0.0],
							[-120.091552734375, 39.816975090489997, 0.0],
							[-120.091552734375, 39.808536041445905, 0.0],
							[-120.091552734375, 39.800095956348393, 0.0],
							[-120.091552734375, 39.791654835253418, 0.0],
							[-120.091552734375, 39.783212678217048, 0.0],
							[-120.091552734375, 39.774769485295458, 0.0],
							[-120.091552734375, 39.76632525654491, 0.0],
							[-120.1025390625, 39.76632525654491, 0.0],
							[-120.1025390625, 39.757879992021756, 0.0],
							[-120.1025390625, 39.749433691782457, 0.0],
							[-120.113525390625, 39.749433691782457, 0.0],
							[-120.113525390625, 39.740986355883564, 0.0],
							[-120.113525390625, 39.732537984381729, 0.0],
							[-120.12451171875, 39.732537984381729, 0.0],
							[-120.135498046875, 39.732537984381729, 0.0],
							[-120.135498046875, 39.724088577333703, 0.0],
							[-120.135498046875, 39.715638134796329, 0.0],
							[-120.135498046875, 39.707186656826551, 0.0],
							[-120.12451171875, 39.707186656826551, 0.0],
							[-120.12451171875, 39.698734143481396, 0.0],
							[-120.12451171875, 39.69028059481802, 0.0],
							[-120.12451171875, 39.681826010893637, 0.0],
							[-120.12451171875, 39.673370391765587, 0.0],
							[-120.12451171875, 39.664913737491297, 0.0],
							[-120.12451171875, 39.656456048128291, 0.0],
							[-120.12451171875, 39.647997323734181, 0.0],
							[-120.12451171875, 39.639537564366698, 0.0],
							[-120.12451171875, 39.631076770083659, 0.0],
							[-120.113525390625, 39.631076770083659, 0.0],
							[-120.1025390625, 39.631076770083659, 0.0],
							[-120.1025390625, 39.622614940942974, 0.0],
							[-120.1025390625, 39.61415207700265, 0.0],
							[-120.1025390625, 39.605688178320804, 0.0],
							[-120.091552734375, 39.605688178320804, 0.0],
							[-120.08056640625, 39.605688178320804, 0.0],
							[-120.069580078125, 39.605688178320804, 0.0],
							[-120.069580078125, 39.61415207700265, 0.0],
							[-120.05859375, 39.61415207700265, 0.0],
							[-120.047607421875, 39.61415207700265, 0.0],
							[-120.047607421875, 39.605688178320804, 0.0],
							[-120.03662109375, 39.605688178320804, 0.0],
							[-120.025634765625, 39.605688178320804, 0.0],
							[-120.025634765625, 39.597223244955636, 0.0],
							[-120.0146484375, 39.597223244955636, 0.0],
							[-120.0146484375, 39.605688178320804, 0.0],
							[-120.0146484375, 39.61415207700265, 0.0],
							[-120.0146484375, 39.622614940942974, 0.0],
							[-120.0146484375, 39.631076770083659, 0.0],
							[-120.003662109375, 39.631076770083659, 0.0],
							[-119.99267578125, 39.631076770083659, 0.0],
							[-119.99267578125, 39.639537564366698, 0.0],
							[-120.003662109375, 39.639537564366698, 0.0],
							[-120.003662109375, 39.647997323734181, 0.0],
							[-120.003662109375, 39.656456048128291, 0.0],
							[-120.003662109375, 39.664913737491297, 0.0],
							[-120.003662109375, 39.673370391765587, 0.0],
							[-119.99267578125, 39.673370391765587, 0.0],
							[-119.99267578125, 39.681826010893637, 0.0],
							[-119.99267578125, 39.69028059481802, 0.0],
							[-119.99267578125, 39.698734143481396, 0.0],
							[-119.99267578125, 39.707186656826551, 0.0],
							[-119.99267578125, 39.715638134796329, 0.0],
							[-119.99267578125, 39.724088577333703, 0.0],
							[-119.99267578125, 39.732537984381729, 0.0],
							[-119.99267578125, 39.740986355883564, 0.0],
							[-119.99267578125, 39.749433691782457, 0.0],
							[-119.99267578125, 39.757879992021756, 0.0],
							[-119.99267578125, 39.76632525654491, 0.0],
							[-119.99267578125, 39.774769485295458, 0.0],
							[-119.99267578125, 39.783212678217048, 0.0],
							[-119.99267578125, 39.791654835253418, 0.0],
							[-119.99267578125, 39.800095956348393, 0.0],
							[-119.99267578125, 39.808536041445905, 0.0],
							[-119.99267578125, 39.816975090489997, 0.0],
							[-119.99267578125, 39.825413103424779, 0.0],
							[-119.99267578125, 39.833850080194466, 0.0],
							[-119.99267578125, 39.842286020743394, 0.0],
							[-119.99267578125, 39.850720925015963, 0.0],
							[-119.99267578125, 39.859154792956687, 0.0],
							[-119.99267578125, 39.867587624510186, 0.0],
							[-119.99267578125, 39.876019419621151, 0.0],
							[-119.981689453125, 39.876019419621151, 0.0],
							[-119.970703125, 39.876019419621151, 0.0],
							[-119.959716796875, 39.876019419621151, 0.0],
							[-119.94873046875, 39.876019419621151, 0.0],
							[-119.937744140625, 39.876019419621151, 0.0],
							[-119.9267578125, 39.876019419621151, 0.0],
							[-119.915771484375, 39.876019419621151, 0.0],
							[-119.90478515625, 39.876019419621151, 0.0],
							[-119.893798828125, 39.876019419621151, 0.0],
							[-119.8828125, 39.876019419621151, 0.0],
							[-119.871826171875, 39.876019419621151, 0.0],
							[-119.86083984375, 39.876019419621151, 0.0],
							[-119.849853515625, 39.876019419621151, 0.0],
							[-119.8388671875, 39.876019419621151, 0.0],
							[-119.827880859375, 39.876019419621151, 0.0],
							[-119.81689453125, 39.876019419621151, 0.0],
							[-119.805908203125, 39.876019419621151, 0.0],
							[-119.794921875, 39.876019419621151, 0.0],
							[-119.783935546875, 39.876019419621151, 0.0],
							[-119.77294921875, 39.876019419621151, 0.0],
							[-119.761962890625, 39.876019419621151, 0.0],
							[-119.7509765625, 39.876019419621151, 0.0],
							[-119.739990234375, 39.876019419621151, 0.0],
							[-119.72900390625, 39.876019419621151, 0.0],
							[-119.718017578125, 39.876019419621151, 0.0],
							[-119.70703125, 39.876019419621151, 0.0],
							[-119.696044921875, 39.876019419621151, 0.0],
							[-119.68505859375, 39.876019419621151, 0.0],
							[-119.674072265625, 39.876019419621151, 0.0],
							[-119.6630859375, 39.876019419621151, 0.0],
							[-119.652099609375, 39.876019419621151, 0.0],
							[-119.64111328125, 39.876019419621151, 0.0],
							[-119.630126953125, 39.876019419621151, 0.0],
							[-119.619140625, 39.876019419621151, 0.0],
							[-119.608154296875, 39.876019419621151, 0.0],
							[-119.59716796875, 39.876019419621151, 0.0],
							[-119.586181640625, 39.876019419621151, 0.0],
							[-119.586181640625, 39.867587624510186, 0.0],
							[-119.586181640625, 39.859154792956687, 0.0],
							[-119.586181640625, 39.850720925015963, 0.0],
							[-119.586181640625, 39.842286020743394, 0.0],
							[-119.586181640625, 39.833850080194466, 0.0],
							[-119.586181640625, 39.825413103424779, 0.0],
							[-119.586181640625, 39.816975090489997, 0.0],
							[-119.586181640625, 39.808536041445905, 0.0],
							[-119.586181640625, 39.800095956348393, 0.0],
							[-119.586181640625, 39.791654835253418, 0.0],
							[-119.586181640625, 39.783212678217048, 0.0],
							[-119.586181640625, 39.774769485295458, 0.0],
							[-119.586181640625, 39.76632525654491, 0.0],
							[-119.586181640625, 39.757879992021756, 0.0],
							[-119.586181640625, 39.749433691782457, 0.0],
							[-119.586181640625, 39.740986355883564, 0.0],
							[-119.586181640625, 39.732537984381729, 0.0],
							[-119.586181640625, 39.724088577333703, 0.0],
							[-119.586181640625, 39.715638134796329, 0.0],
							[-119.586181640625, 39.707186656826551, 0.0],
							[-119.5751953125, 39.707186656826551, 0.0],
							[-119.5751953125, 39.698734143481396, 0.0],
							[-119.5751953125, 39.69028059481802, 0.0],
							[-119.5751953125, 39.681826010893637, 0.0],
							[-119.5751953125, 39.673370391765587, 0.0],
							[-119.5751953125, 39.664913737491297, 0.0],
							[-119.5751953125, 39.656456048128291, 0.0],
							[-119.5751953125, 39.647997323734181, 0.0],
							[-119.5751953125, 39.639537564366698, 0.0],
							[-119.5751953125, 39.631076770083659, 0.0],
							[-119.5751953125, 39.622614940942974, 0.0],
							[-119.564208984375, 39.622614940942974, 0.0],
							[-119.55322265625, 39.622614940942974, 0.0],
							[-119.542236328125, 39.622614940942974, 0.0],
							[-119.53125, 39.622614940942974, 0.0],
							[-119.520263671875, 39.622614940942974, 0.0],
							[-119.50927734375, 39.622614940942974, 0.0],
							[-119.498291015625, 39.622614940942974, 0.0],
							[-119.4873046875, 39.622614940942974, 0.0],
							[-119.476318359375, 39.622614940942974, 0.0],
							[-119.46533203125, 39.622614940942974, 0.0],
							[-119.454345703125, 39.622614940942974, 0.0],
							[-119.443359375, 39.622614940942974, 0.0],
							[-119.432373046875, 39.622614940942974, 0.0],
							[-119.42138671875, 39.622614940942974, 0.0],
							[-119.410400390625, 39.622614940942974, 0.0],
							[-119.3994140625, 39.622614940942974, 0.0],
							[-119.388427734375, 39.622614940942974, 0.0],
							[-119.37744140625, 39.622614940942974, 0.0],
							[-119.37744140625, 39.61415207700265, 0.0],
							[-119.37744140625, 39.605688178320804, 0.0],
							[-119.37744140625, 39.597223244955636, 0.0],
							[-119.37744140625, 39.588757276965453, 0.0],
							[-119.37744140625, 39.580290274408647, 0.0],
							[-119.37744140625, 39.571822237343731, 0.0],
							[-119.37744140625, 39.563353165829298, 0.0],
							[-119.37744140625, 39.554883059924023, 0.0],
							[-119.37744140625, 39.546411919686712, 0.0],
							[-119.37744140625, 39.53793974517626, 0.0],
							[-119.37744140625, 39.52946653645165, 0.0],
							[-119.37744140625, 39.520992293571958, 0.0],
							[-119.37744140625, 39.512517016596362, 0.0],
							[-119.37744140625, 39.504040705584153, 0.0],
							[-119.37744140625, 39.495563360594709, 0.0],
							[-119.37744140625, 39.487084981687488, 0.0],
							[-119.37744140625, 39.478605568922092, 0.0],
							[-119.37744140625, 39.470125122358169, 0.0],
							[-119.37744140625, 39.461643642055485, 0.0],
							[-119.37744140625, 39.453161128073923, 0.0],
							[-119.37744140625, 39.444677580473424, 0.0],
							[-119.37744140625, 39.436192999314081, 0.0],
							[-119.37744140625, 39.427707384656038, 0.0],
							[-119.37744140625, 39.419220736559545, 0.0],
							[-119.37744140625, 39.410733055084961, 0.0],
							[-119.37744140625, 39.402244340292761, 0.0],
							[-119.37744140625, 39.393754592243468, 0.0],
							[-119.37744140625, 39.385263810997756, 0.0],
							[-119.37744140625, 39.376771996616355, 0.0],
							[-119.37744140625, 39.368279149160131, 0.0],
							[-119.37744140625, 39.359785268690004, 0.0],
							[-119.37744140625, 39.351290355267039, 0.0],
							[-119.388427734375, 39.351290355267039, 0.0],
							[-119.3994140625, 39.351290355267039, 0.0],
							[-119.410400390625, 39.351290355267039, 0.0],
							[-119.42138671875, 39.351290355267039, 0.0],
							[-119.432373046875, 39.351290355267039, 0.0],
							[-119.443359375, 39.351290355267039, 0.0],
							[-119.454345703125, 39.351290355267039, 0.0],
							[-119.46533203125, 39.351290355267039, 0.0],
							[-119.476318359375, 39.351290355267039, 0.0],
							[-119.476318359375, 39.342794408952372, 0.0],
							[-119.476318359375, 39.334297429807236, 0.0],
							[-119.476318359375, 39.325799417892981, 0.0],
							[-119.476318359375, 39.317300373271024, 0.0],
							[-119.476318359375, 39.308800296002921, 0.0],
							[-119.476318359375, 39.300299186150291, 0.0],
							[-119.476318359375, 39.291797043774871, 0.0],
							[-119.476318359375, 39.283293868938493, 0.0],
							[-119.476318359375, 39.274789661703068, 0.0],
							[-119.476318359375, 39.266284422130646, 0.0],
							[-119.476318359375, 39.25777815028335, 0.0],
							[-119.476318359375, 39.249270846223389, 0.0],
							[-119.476318359375, 39.240762510013099, 0.0],
							[-119.476318359375, 39.232253141714892, 0.0],
							[-119.476318359375, 39.223742741391291, 0.0],
							[-119.476318359375, 39.215231309104908, 0.0],
							[-119.476318359375, 39.206718844918477, 0.0],
							[-119.476318359375, 39.198205348894803, 0.0],
							[-119.4873046875, 39.198205348894803, 0.0],
							[-119.498291015625, 39.198205348894803, 0.0],
							[-119.50927734375, 39.198205348894803, 0.0],
							[-119.520263671875, 39.198205348894803, 0.0],
							[-119.53125, 39.198205348894803, 0.0],
							[-119.542236328125, 39.198205348894803, 0.0],
							[-119.55322265625, 39.198205348894803, 0.0],
							[-119.564208984375, 39.198205348894803, 0.0],
							[-119.564208984375, 39.189690821096796, 0.0],
							[-119.564208984375, 39.18117526158747, 0.0],
							[-119.564208984375, 39.172658670429946, 0.0],
							[-119.564208984375, 39.164141047687423, 0.0],
							[-119.564208984375, 39.155622393423222, 0.0],
							[-119.564208984375, 39.147102707700753, 0.0],
							[-119.564208984375, 39.138581990583511, 0.0],
							[-119.564208984375, 39.130060242135102, 0.0],
							[-119.564208984375, 39.121537462419248, 0.0],
							[-119.564208984375, 39.113013651499735, 0.0],
							[-119.564208984375, 39.104488809440483, 0.0],
							[-119.564208984375, 39.095962936305483, 0.0],
							[-119.564208984375, 39.087436032158827, 0.0],
							[-119.5751953125, 39.087436032158827, 0.0],
							[-119.586181640625, 39.087436032158827, 0.0],
							[-119.59716796875, 39.087436032158827, 0.0],
							[-119.608154296875, 39.087436032158827, 0.0],
							[-119.619140625, 39.087436032158827, 0.0],
							[-119.630126953125, 39.087436032158827, 0.0],
							[-119.64111328125, 39.087436032158827, 0.0],
							[-119.652099609375, 39.087436032158827, 0.0],
							[-119.6630859375, 39.087436032158827, 0.0],
							[-119.674072265625, 39.087436032158827, 0.0],
							[-119.68505859375, 39.087436032158827, 0.0],
							[-119.696044921875, 39.087436032158827, 0.0],
							[-119.70703125, 39.087436032158827, 0.0],
							[-119.718017578125, 39.087436032158827, 0.0],
							[-119.72900390625, 39.087436032158827, 0.0],
							[-119.739990234375, 39.087436032158827, 0.0],
							[-119.7509765625, 39.087436032158827, 0.0],
							[-119.761962890625, 39.087436032158827, 0.0],
							[-119.77294921875, 39.087436032158827, 0.0],
							[-119.77294921875, 39.078908097064748, 0.0],
							[-119.77294921875, 39.070379131087506, 0.0],
							[-119.783935546875, 39.070379131087506, 0.0],
							[-119.783935546875, 39.061849134291535, 0.0],
							[-119.794921875, 39.061849134291535, 0.0],
							[-119.794921875, 39.053318106741308, 0.0],
							[-119.805908203125, 39.053318106741308, 0.0],
							[-119.805908203125, 39.044786048501429, 0.0],
							[-119.81689453125, 39.044786048501429, 0.0],
							[-119.81689453125, 39.036252959636599, 0.0],
							[-119.827880859375, 39.036252959636599, 0.0],
							[-119.827880859375, 39.027718840211605, 0.0],
							[-119.827880859375, 39.019183690291349, 0.0],
							[-119.8388671875, 39.019183690291349, 0.0],
							[-119.8388671875, 39.010647509940839, 0.0],
							[-119.8388671875, 39.002110299225137, 0.0],
							[-119.8388671875, 38.993572058209452, 0.0],
							[-119.8388671875, 38.985032786959074, 0.0],
							[-119.849853515625, 38.985032786959074, 0.0],
							[-119.86083984375, 38.985032786959074, 0.0],
							[-119.871826171875, 38.985032786959074, 0.0],
							[-119.8828125, 38.985032786959074, 0.0],
							[-119.893798828125, 38.985032786959074, 0.0],
							[-119.893798828125, 38.993572058209452, 0.0],
							[-119.893798828125, 39.002110299225137, 0.0],
							[-119.893798828125, 39.010647509940839, 0.0],
							[-119.893798828125, 39.019183690291349, 0.0],
							[-119.893798828125, 39.027718840211605, 0.0],
							[-119.893798828125, 39.036252959636599, 0.0],
							[-119.893798828125, 39.044786048501429, 0.0],
							[-119.893798828125, 39.053318106741308, 0.0],
							[-119.893798828125, 39.061849134291535, 0.0],
							[-119.893798828125, 39.070379131087506, 0.0],
							[-119.893798828125, 39.078908097064748, 0.0],
							[-119.893798828125, 39.087436032158827, 0.0],
							[-119.893798828125, 39.095962936305483, 0.0],
							[-119.893798828125, 39.104488809440483, 0.0],
							[-119.893798828125, 39.113013651499735, 0.0],
							[-119.893798828125, 39.121537462419248, 0.0],
							[-119.893798828125, 39.130060242135102, 0.0],
							[-119.893798828125, 39.138581990583511, 0.0],
							[-119.8828125, 39.138581990583511, 0.0],
							[-119.8828125, 39.147102707700753, 0.0],
							[-119.8828125, 39.155622393423222, 0.0],
							[-119.8828125, 39.164141047687423, 0.0],
							[-119.8828125, 39.172658670429946, 0.0],
							[-119.8828125, 39.18117526158747, 0.0],
							[-119.8828125, 39.189690821096796, 0.0],
							[-119.893798828125, 39.189690821096796, 0.0],
							[-119.893798828125, 39.198205348894803, 0.0],
							[-119.90478515625, 39.198205348894803, 0.0],
							[-119.90478515625, 39.206718844918477, 0.0],
							[-119.90478515625, 39.215231309104908, 0.0],
							[-119.893798828125, 39.215231309104908, 0.0],
							[-119.893798828125, 39.223742741391291, 0.0],
							[-119.893798828125, 39.232253141714892, 0.0],
							[-119.893798828125, 39.240762510013099, 0.0],
							[-119.90478515625, 39.240762510013099, 0.0],
							[-119.90478515625, 39.249270846223389, 0.0],
							[-119.893798828125, 39.249270846223389, 0.0],
							[-119.893798828125, 39.25777815028335, 0.0],
							[-119.893798828125, 39.266284422130646, 0.0],
							[-119.893798828125, 39.274789661703068, 0.0],
							[-119.893798828125, 39.283293868938493, 0.0],
							[-119.893798828125, 39.291797043774871, 0.0],
							[-119.90478515625, 39.291797043774871, 0.0],
							[-119.90478515625, 39.300299186150291, 0.0],
							[-119.915771484375, 39.300299186150291, 0.0],
							[-119.915771484375, 39.308800296002921, 0.0],
							[-119.915771484375, 39.317300373271024, 0.0],
							[-119.915771484375, 39.325799417892981, 0.0],
							[-119.9267578125, 39.325799417892981, 0.0],
							[-119.937744140625, 39.325799417892981, 0.0],
							[-119.94873046875, 39.325799417892981, 0.0],
							[-119.94873046875, 39.317300373271024, 0.0],
							[-119.94873046875, 39.308800296002921, 0.0],
							[-119.959716796875, 39.308800296002921, 0.0],
							[-119.959716796875, 39.300299186150291, 0.0],
							[-119.959716796875, 39.291797043774871, 0.0],
							[-119.970703125, 39.291797043774871, 0.0],
							[-119.981689453125, 39.291797043774871, 0.0],
							[-119.99267578125, 39.291797043774871, 0.0],
							[-119.99267578125, 39.283293868938493, 0.0],
							[-120.003662109375, 39.283293868938493, 0.0],
							[-120.003662109375, 39.291797043774871, 0.0],
							[-120.003662109375, 39.300299186150291, 0.0],
							[-120.003662109375, 39.308800296002921, 0.0],
							[-120.003662109375, 39.317300373271024, 0.0],
							[-120.003662109375, 39.325799417892981, 0.0],
							[-120.003662109375, 39.334297429807236, 0.0],
							[-120.0146484375, 39.334297429807236, 0.0],
							[-120.0146484375, 39.325799417892981, 0.0],
							[-120.0146484375, 39.317300373271024, 0.0],
							[-120.0146484375, 39.308800296002921, 0.0],
							[-120.0146484375, 39.300299186150291, 0.0],
							[-120.0146484375, 39.291797043774871, 0.0],
							[-120.0146484375, 39.283293868938493, 0.0],
							[-120.0146484375, 39.274789661703068, 0.0],
							[-120.0146484375, 39.266284422130646, 0.0],
							[-120.0146484375, 39.25777815028335, 0.0],
							[-120.0146484375, 39.249270846223389, 0.0],
							[-120.0146484375, 39.240762510013099, 0.0],
							[-120.0146484375, 39.232253141714892, 0.0],
							[-120.025634765625, 39.232253141714892, 0.0],
							[-120.03662109375, 39.232253141714892, 0.0],
							[-120.047607421875, 39.232253141714892, 0.0],
							[-120.05859375, 39.232253141714892, 0.0],
							[-120.05859375, 39.223742741391291, 0.0],
							[-120.069580078125, 39.223742741391291, 0.0],
							[-120.08056640625, 39.223742741391291, 0.0],
							[-120.08056640625, 39.215231309104908, 0.0],
							[-120.08056640625, 39.206718844918477, 0.0],
							[-120.091552734375, 39.206718844918477, 0.0],
							[-120.091552734375, 39.198205348894803, 0.0],
							[-120.091552734375, 39.189690821096796, 0.0],
							[-120.091552734375, 39.18117526158747, 0.0],
							[-120.1025390625, 39.18117526158747, 0.0],
							[-120.113525390625, 39.18117526158747, 0.0],
							[-120.12451171875, 39.18117526158747, 0.0],
							[-120.12451171875, 39.172658670429946, 0.0],
							[-120.135498046875, 39.172658670429946, 0.0],
							[-120.135498046875, 39.164141047687423, 0.0],
							[-120.135498046875, 39.155622393423222, 0.0],
							[-120.146484375, 39.155622393423222, 0.0],
							[-120.146484375, 39.147102707700753, 0.0],
							[-120.146484375, 39.138581990583511, 0.0],
							[-120.146484375, 39.130060242135102, 0.0],
							[-120.157470703125, 39.130060242135102, 0.0],
							[-120.157470703125, 39.121537462419248, 0.0],
							[-120.157470703125, 39.113013651499735, 0.0],
							[-120.157470703125, 39.104488809440483, 0.0],
							[-120.157470703125, 39.095962936305483, 0.0],
							[-120.157470703125, 39.087436032158827, 0.0],
							[-120.157470703125, 39.078908097064748, 0.0],
							[-120.146484375, 39.078908097064748, 0.0],
							[-120.146484375, 39.070379131087506, 0.0],
							[-120.135498046875, 39.070379131087506, 0.0],
							[-120.12451171875, 39.070379131087506, 0.0],
							[-120.12451171875, 39.061849134291535, 0.0],
							[-120.113525390625, 39.061849134291535, 0.0],
							[-120.113525390625, 39.053318106741308, 0.0],
							[-120.12451171875, 39.053318106741308, 0.0],
							[-120.135498046875, 39.053318106741308, 0.0],
							[-120.146484375, 39.053318106741308, 0.0],
							[-120.146484375, 39.044786048501429, 0.0],
							[-120.157470703125, 39.044786048501429, 0.0],
							[-120.16845703125, 39.044786048501429, 0.0],
							[-120.16845703125, 39.036252959636599, 0.0],
							[-120.179443359375, 39.036252959636599, 0.0],
							[-120.179443359375, 39.027718840211605, 0.0],
							[-120.179443359375, 39.019183690291349, 0.0],
							[-120.179443359375, 39.010647509940839, 0.0],
							[-120.1904296875, 39.010647509940839, 0.0],
							[-120.1904296875, 39.002110299225137, 0.0],
							[-120.1904296875, 38.993572058209452, 0.0],
							[-120.1904296875, 38.985032786959074, 0.0],
							[-120.179443359375, 38.985032786959074, 0.0],
							[-120.16845703125, 38.985032786959074, 0.0],
							[-120.157470703125, 38.985032786959074, 0.0],
							[-120.157470703125, 38.976492485539403, 0.0],
							[-120.146484375, 38.976492485539403, 0.0],
							[-120.146484375, 38.967951154015921, 0.0],
							[-120.146484375, 38.959408792454227, 0.0],
							[-120.146484375, 38.950865400919994, 0.0],
							[-120.146484375, 38.942320979479021, 0.0],
							[-120.146484375, 38.933775528197209, 0.0],
							[-120.157470703125, 38.933775528197209, 0.0],
							[-120.157470703125, 38.925229047140533, 0.0],
							[-120.157470703125, 38.916681536375087, 0.0],
							[-120.146484375, 38.916681536375087, 0.0],
							[-120.146484375, 38.908132995967044, 0.0],
							[-120.146484375, 38.899583425982712, 0.0],
							[-120.146484375, 38.891032826488463, 0.0],
							[-120.157470703125, 38.891032826488463, 0.0],
							[-120.157470703125, 38.882481197550796, 0.0],
							[-120.146484375, 38.882481197550796, 0.0],
							[-120.146484375, 38.873928539236296, 0.0],
							[-120.135498046875, 38.873928539236296, 0.0],
							[-120.12451171875, 38.873928539236296, 0.0],
							[-120.12451171875, 38.865374851611641, 0.0],
							[-120.113525390625, 38.865374851611641, 0.0],
							[-120.113525390625, 38.856820134743622, 0.0],
							[-120.1025390625, 38.856820134743622, 0.0],
							[-120.1025390625, 38.848264388699121, 0.0],
							[-120.1025390625, 38.83970761354513, 0.0],
							[-120.091552734375, 38.83970761354513, 0.0],
							[-120.08056640625, 38.83970761354513, 0.0],
							[-120.069580078125, 38.83970761354513, 0.0],
							[-120.069580078125, 38.83114980934873, 0.0],
							[-120.05859375, 38.83114980934873, 0.0],
							[-120.047607421875, 38.83114980934873, 0.0],
							[-120.03662109375, 38.83114980934873, 0.0],
							[-120.025634765625, 38.83114980934873, 0.0],
							[-120.025634765625, 38.822590976177104, 0.0],
							[-120.025634765625, 38.81403111409756, 0.0],
							[-120.025634765625, 38.805470223177451, 0.0],
							[-120.03662109375, 38.805470223177451, 0.0],
							[-120.03662109375, 38.79690830348428, 0.0],
							[-120.047607421875, 38.79690830348428, 0.0],
							[-120.047607421875, 38.788345355085625, 0.0],
							[-120.047607421875, 38.779781378049165, 0.0],
							[-120.047607421875, 38.771216372442723, 0.0],
							[-120.03662109375, 38.771216372442723, 0.0],
							[-120.03662109375, 38.76265033833414, 0.0],
							[-120.03662109375, 38.754083275791416, 0.0],
							[-120.03662109375, 38.745515184882642, 0.0],
							[-120.03662109375, 38.736946065676001, 0.0],
							[-120.025634765625, 38.736946065676001, 0.0],
							[-120.025634765625, 38.728375918239784, 0.0],
							[-120.025634765625, 38.719804742642374, 0.0],
							[-120.0146484375, 38.719804742642374, 0.0],
							[-120.0146484375, 38.711232538952252, 0.0],
							[-120.003662109375, 38.711232538952252, 0.0],
							[-119.99267578125, 38.711232538952252, 0.0],
							[-119.981689453125, 38.711232538952252, 0.0],
							[-119.981689453125, 38.702659307238001, 0.0],
							[-119.981689453125, 38.694085047568322, 0.0],
							[-119.981689453125, 38.685509760012003, 0.0],
							[-119.970703125, 38.685509760012003, 0.0],
							[-119.970703125, 38.676933444637918, 0.0],
							[-119.981689453125, 38.676933444637918, 0.0],
							[-119.981689453125, 38.668356101515066, 0.0],
							[-119.970703125, 38.668356101515066, 0.0],
							[-119.970703125, 38.659777730712527, 0.0],
							[-119.970703125, 38.651198332299501, 0.0],
							[-119.970703125, 38.642617906345265, 0.0],
							[-119.970703125, 38.634036452919212, 0.0],
							[-119.970703125, 38.625453972090838, 0.0],
							[-119.970703125, 38.616870463929736, 0.0],
							[-119.970703125, 38.60828592850558, 0.0],
							[-119.970703125, 38.599700365888189, 0.0],
							[-119.970703125, 38.591113776147431, 0.0],
							[-119.970703125, 38.582526159353314, 0.0],
							[-119.970703125, 38.573937515575921, 0.0],
							[-119.970703125, 38.565347844885451, 0.0],
							[-119.970703125, 38.556757147352208, 0.0],
							[-119.981689453125, 38.556757147352208, 0.0],
							[-119.99267578125, 38.556757147352208, 0.0],
							[-119.99267578125, 38.565347844885451, 0.0],
							[-119.99267578125, 38.573937515575921, 0.0],
							[-120.003662109375, 38.573937515575921, 0.0],
							[-120.0146484375, 38.573937515575921, 0.0],
							[-120.025634765625, 38.573937515575921, 0.0],
							[-120.025634765625, 38.565347844885451, 0.0],
							[-120.025634765625, 38.556757147352208, 0.0],
							[-120.025634765625, 38.548165423046576, 0.0],
							[-120.03662109375, 38.548165423046576, 0.0],
							[-120.03662109375, 38.53957267203905, 0.0],
							[-120.047607421875, 38.53957267203905, 0.0],
							[-120.047607421875, 38.530978894400242, 0.0],
							[-120.05859375, 38.530978894400242, 0.0],
							[-120.05859375, 38.522384090200838, 0.0],
							[-120.05859375, 38.513788259511642, 0.0],
							[-120.05859375, 38.505191402403547, 0.0],
							[-120.069580078125, 38.505191402403547, 0.0],
							[-120.08056640625, 38.505191402403547, 0.0],
							[-120.08056640625, 38.496593518947563, 0.0],
							[-120.091552734375, 38.496593518947563, 0.0],
							[-120.1025390625, 38.496593518947563, 0.0],
							[-120.113525390625, 38.496593518947563, 0.0],
							[-120.12451171875, 38.496593518947563, 0.0],
							[-120.135498046875, 38.496593518947563, 0.0],
							[-120.135498046875, 38.487994609214802, 0.0],
							[-120.146484375, 38.487994609214802, 0.0],
							[-120.157470703125, 38.487994609214802, 0.0],
							[-120.157470703125, 38.496593518947563, 0.0],
							[-120.16845703125, 38.496593518947563, 0.0],
							[-120.179443359375, 38.496593518947563, 0.0],
							[-120.179443359375, 38.487994609214802, 0.0],
							[-120.1904296875, 38.487994609214802, 0.0],
							[-120.201416015625, 38.487994609214802, 0.0],
							[-120.201416015625, 38.496593518947563, 0.0],
							[-120.21240234375, 38.496593518947563, 0.0],
							[-120.223388671875, 38.496593518947563, 0.0],
							[-120.223388671875, 38.487994609214802, 0.0],
							[-120.234375, 38.487994609214802, 0.0],
							[-120.245361328125, 38.487994609214802, 0.0],
							[-120.25634765625, 38.487994609214802, 0.0],
							[-120.267333984375, 38.487994609214802, 0.0],
							[-120.2783203125, 38.487994609214802, 0.0],
							[-120.289306640625, 38.487994609214802, 0.0],
							[-120.30029296875, 38.487994609214802, 0.0],
							[-120.311279296875, 38.487994609214802, 0.0],
							[-120.322265625, 38.487994609214802, 0.0],
							[-120.333251953125, 38.487994609214802, 0.0],
							[-120.34423828125, 38.487994609214802, 0.0],
							[-120.355224609375, 38.487994609214802, 0.0],
							[-120.3662109375, 38.487994609214802, 0.0],
							[-120.377197265625, 38.487994609214802, 0.0],
							[-120.38818359375, 38.487994609214802, 0.0],
							[-120.399169921875, 38.487994609214802, 0.0],
							[-120.41015625, 38.487994609214802, 0.0],
							[-120.421142578125, 38.487994609214802, 0.0],
							[-120.43212890625, 38.487994609214802, 0.0],
							[-120.443115234375, 38.487994609214802, 0.0],
							[-120.4541015625, 38.487994609214802, 0.0],
							[-120.465087890625, 38.487994609214802, 0.0],
							[-120.47607421875, 38.487994609214802, 0.0],
							[-120.487060546875, 38.487994609214802, 0.0],
							[-120.498046875, 38.487994609214802, 0.0],
							[-120.509033203125, 38.487994609214802, 0.0],
							[-120.52001953125, 38.487994609214802, 0.0],
							[-120.531005859375, 38.487994609214802, 0.0],
							[-120.531005859375, 38.496593518947563, 0.0],
							[-120.531005859375, 38.505191402403547, 0.0],
							[-120.5419921875, 38.505191402403547, 0.0],
							[-120.5419921875, 38.496593518947563, 0.0],
							[-120.552978515625, 38.496593518947563, 0.0],
							[-120.56396484375, 38.496593518947563, 0.0],
							[-120.574951171875, 38.496593518947563, 0.0],
							[-120.5859375, 38.496593518947563, 0.0],
							[-120.596923828125, 38.496593518947563, 0.0],
							[-120.60791015625, 38.496593518947563, 0.0],
							[-120.618896484375, 38.496593518947563, 0.0],
							[-120.6298828125, 38.496593518947563, 0.0],
							[-120.640869140625, 38.496593518947563, 0.0],
							[-120.65185546875, 38.496593518947563, 0.0],
							[-120.662841796875, 38.496593518947563, 0.0],
							[-120.673828125, 38.496593518947563, 0.0],
							[-120.684814453125, 38.496593518947563, 0.0],
							[-120.69580078125, 38.496593518947563, 0.0],
							[-120.706787109375, 38.496593518947563, 0.0],
							[-120.7177734375, 38.496593518947563, 0.0],
							[-120.7177734375, 38.505191402403547, 0.0],
							[-120.728759765625, 38.505191402403547, 0.0],
							[-120.73974609375, 38.505191402403547, 0.0],
							[-120.73974609375, 38.513788259511642, 0.0],
							[-120.750732421875, 38.513788259511642, 0.0],
							[-120.76171875, 38.513788259511642, 0.0],
							[-120.772705078125, 38.513788259511642, 0.0],
							[-120.772705078125, 38.505191402403547, 0.0],
							[-120.772705078125, 38.496593518947563, 0.0],
							[-120.78369140625, 38.496593518947563, 0.0],
							[-120.78369140625, 38.487994609214802, 0.0],
							[-120.794677734375, 38.487994609214802, 0.0],
							[-120.8056640625, 38.487994609214802, 0.0],
							[-120.816650390625, 38.487994609214802, 0.0],
							[-120.82763671875, 38.487994609214802, 0.0],
							[-120.838623046875, 38.487994609214802, 0.0],
							[-120.849609375, 38.487994609214802, 0.0],
							[-120.860595703125, 38.487994609214802, 0.0],
							[-120.87158203125, 38.487994609214802, 0.0],
							[-120.882568359375, 38.487994609214802, 0.0],
							[-120.8935546875, 38.487994609214802, 0.0],
							[-120.904541015625, 38.487994609214802, 0.0],
							[-120.91552734375, 38.487994609214802, 0.0],
							[-120.926513671875, 38.487994609214802, 0.0],
							[-120.9375, 38.487994609214802, 0.0],
							[-120.948486328125, 38.487994609214802, 0.0],
							[-120.95947265625, 38.487994609214802, 0.0],
							[-120.970458984375, 38.487994609214802, 0.0],
							[-120.9814453125, 38.487994609214802, 0.0],
							[-120.992431640625, 38.487994609214802, 0.0],
							[-121.00341796875, 38.487994609214802, 0.0],
							[-121.014404296875, 38.487994609214802, 0.0],
							[-121.025390625, 38.487994609214802, 0.0],
							[-121.036376953125, 38.487994609214802, 0.0],
							[-121.036376953125, 38.479394673276438, 0.0],
							[-121.036376953125, 38.47079371120379, 0.0],
							[-121.036376953125, 38.462191723068266, 0.0],
							[-121.036376953125, 38.453588708941375, 0.0],
							[-121.036376953125, 38.444984668894712, 0.0],
							[-121.036376953125, 38.436379603, 0.0],
							[-121.036376953125, 38.427773511329022, 0.0],
							[-121.036376953125, 38.419166393953716, 0.0],
							[-121.036376953125, 38.410558250946075, 0.0],
							[-121.036376953125, 38.401949082378223, 0.0],
							[-121.036376953125, 38.393338888322383, 0.0],
							[-121.036376953125, 38.384727668850836, 0.0],
							[-121.036376953125, 38.37611542403603, 0.0],
							[-121.036376953125, 38.367502153950454, 0.0],
							[-121.036376953125, 38.358887858666755, 0.0],
							[-121.036376953125, 38.350272538257641, 0.0],
							[-121.036376953125, 38.341656192795931, 0.0],
							[-121.036376953125, 38.333038822354553, 0.0],
							[-121.036376953125, 38.32442042700653, 0.0],
							[-121.036376953125, 38.315801006824991, 0.0],
							[-121.036376953125, 38.30718056188315, 0.0],
							[-121.036376953125, 38.298559092254344, 0.0],
							[-121.025390625, 38.298559092254344, 0.0],
							[-121.025390625, 38.289936598012012, 0.0],
							[-121.025390625, 38.281313079229669, 0.0],
							[-121.025390625, 38.272688535980961, 0.0],
							[-121.014404296875, 38.272688535980961, 0.0],
							[-121.014404296875, 38.264062968339609, 0.0],
							[-121.014404296875, 38.255436376379471, 0.0],
							[-121.014404296875, 38.24680876017446, 0.0],
							[-121.014404296875, 38.238180119798642, 0.0],
							[-121.014404296875, 38.229550455326134, 0.0],
							[-121.025390625, 38.229550455326134, 0.0],
							[-121.025390625, 38.220919766831194, 0.0],
							[-121.025390625, 38.212288054388168, 0.0],
							[-121.025390625, 38.203655318071498, 0.0],
							[-121.014404296875, 38.203655318071498, 0.0],
							[-121.00341796875, 38.203655318071498, 0.0],
							[-120.992431640625, 38.203655318071498, 0.0],
							[-120.992431640625, 38.195021557955727, 0.0],
							[-120.9814453125, 38.195021557955727, 0.0],
							[-120.9814453125, 38.203655318071498, 0.0],
							[-120.970458984375, 38.203655318071498, 0.0],
							[-120.95947265625, 38.203655318071498, 0.0],
							[-120.95947265625, 38.212288054388168, 0.0],
							[-120.948486328125, 38.212288054388168, 0.0],
							[-120.9375, 38.212288054388168, 0.0],
							[-120.9375, 38.220919766831194, 0.0],
							[-120.926513671875, 38.220919766831194, 0.0],
							[-120.926513671875, 38.229550455326134, 0.0],
							[-120.926513671875, 38.238180119798642, 0.0],
							[-120.91552734375, 38.238180119798642, 0.0],
							[-120.91552734375, 38.229550455326134, 0.0],
							[-120.904541015625, 38.229550455326134, 0.0],
							[-120.904541015625, 38.238180119798642, 0.0],
							[-120.8935546875, 38.238180119798642, 0.0],
							[-120.882568359375, 38.238180119798642, 0.0],
							[-120.87158203125, 38.238180119798642, 0.0],
							[-120.860595703125, 38.238180119798642, 0.0],
							[-120.849609375, 38.238180119798642, 0.0],
							[-120.838623046875, 38.238180119798642, 0.0],
							[-120.82763671875, 38.238180119798642, 0.0],
							[-120.816650390625, 38.238180119798642, 0.0],
							[-120.8056640625, 38.238180119798642, 0.0],
							[-120.794677734375, 38.238180119798642, 0.0],
							[-120.78369140625, 38.238180119798642, 0.0],
							[-120.772705078125, 38.238180119798642, 0.0],
							[-120.76171875, 38.238180119798642, 0.0],
							[-120.750732421875, 38.238180119798642, 0.0],
							[-120.73974609375, 38.238180119798642, 0.0],
							[-120.728759765625, 38.238180119798642, 0.0],
							[-120.7177734375, 38.238180119798642, 0.0],
							[-120.706787109375, 38.238180119798642, 0.0],
							[-120.69580078125, 38.238180119798642, 0.0],
							[-120.684814453125, 38.238180119798642, 0.0],
							[-120.673828125, 38.238180119798642, 0.0],
							[-120.662841796875, 38.238180119798642, 0.0],
							[-120.65185546875, 38.238180119798642, 0.0],
							[-120.640869140625, 38.238180119798642, 0.0],
							[-120.6298828125, 38.238180119798642, 0.0],
							[-120.618896484375, 38.238180119798642, 0.0],
							[-120.60791015625, 38.238180119798642, 0.0],
							[-120.596923828125, 38.238180119798642, 0.0],
							[-120.5859375, 38.238180119798642, 0.0],
							[-120.574951171875, 38.238180119798642, 0.0],
							[-120.56396484375, 38.238180119798642, 0.0],
							[-120.552978515625, 38.238180119798642, 0.0],
							[-120.5419921875, 38.238180119798642, 0.0],
							[-120.531005859375, 38.238180119798642, 0.0],
							[-120.52001953125, 38.238180119798642, 0.0],
							[-120.509033203125, 38.238180119798642, 0.0],
							[-120.498046875, 38.238180119798642, 0.0],
							[-120.487060546875, 38.238180119798642, 0.0],
							[-120.47607421875, 38.238180119798642, 0.0],
							[-120.465087890625, 38.238180119798642, 0.0],
							[-120.4541015625, 38.238180119798642, 0.0],
							[-120.443115234375, 38.238180119798642, 0.0],
							[-120.43212890625, 38.238180119798642, 0.0],
							[-120.421142578125, 38.238180119798642, 0.0],
							[-120.41015625, 38.238180119798642, 0.0],
							[-120.399169921875, 38.238180119798642, 0.0],
							[-120.38818359375, 38.238180119798642, 0.0],
							[-120.377197265625, 38.238180119798642, 0.0],
							[-120.3662109375, 38.238180119798642, 0.0],
							[-120.355224609375, 38.238180119798642, 0.0],
							[-120.34423828125, 38.238180119798642, 0.0],
							[-120.333251953125, 38.238180119798642, 0.0],
							[-120.322265625, 38.238180119798642, 0.0],
							[-120.311279296875, 38.238180119798642, 0.0],
							[-120.30029296875, 38.238180119798642, 0.0],
							[-120.289306640625, 38.238180119798642, 0.0],
							[-120.289306640625, 38.229550455326134, 0.0],
							[-120.30029296875, 38.229550455326134, 0.0],
							[-120.30029296875, 38.220919766831194, 0.0],
							[-120.30029296875, 38.212288054388168, 0.0],
							[-120.30029296875, 38.203655318071498, 0.0],
							[-120.311279296875, 38.203655318071498, 0.0],
							[-120.322265625, 38.203655318071498, 0.0],
							[-120.322265625, 38.195021557955727, 0.0],
							[-120.322265625, 38.186386774115512, 0.0],
							[-120.333251953125, 38.186386774115512, 0.0],
							[-120.34423828125, 38.186386774115512, 0.0],
							[-120.34423828125, 38.177750966625595, 0.0],
							[-120.34423828125, 38.169114135560854, 0.0],
							[-120.34423828125, 38.160476280996221, 0.0],
							[-120.355224609375, 38.160476280996221, 0.0],
							[-120.355224609375, 38.151837403006773, 0.0],
							[-120.3662109375, 38.151837403006773, 0.0],
							[-120.377197265625, 38.151837403006773, 0.0],
							[-120.377197265625, 38.143197501667643, 0.0],
							[-120.377197265625, 38.134556577054127, 0.0],
							[-120.377197265625, 38.125914629241549, 0.0],
							[-120.377197265625, 38.117271658305413, 0.0],
							[-120.377197265625, 38.108627664321276, 0.0],
							[-120.38818359375, 38.108627664321276, 0.0],
							[-120.38818359375, 38.09998264736479, 0.0],
							[-120.377197265625, 38.09998264736479, 0.0],
							[-120.377197265625, 38.091336607511742, 0.0],
							[-120.377197265625, 38.082689544838004, 0.0],
							[-120.377197265625, 38.074041459419554, 0.0],
							[-120.377197265625, 38.065392351332477, 0.0],
							[-120.3662109375, 38.065392351332477, 0.0],
							[-120.355224609375, 38.065392351332477, 0.0],
							[-120.34423828125, 38.065392351332477, 0.0],
							[-120.333251953125, 38.065392351332477, 0.0],
							[-120.322265625, 38.065392351332477, 0.0],
							[-120.311279296875, 38.065392351332477, 0.0],
							[-120.311279296875, 38.056742220652943, 0.0],
							[-120.30029296875, 38.056742220652943, 0.0],
							[-120.289306640625, 38.056742220652943, 0.0],
							[-120.2783203125, 38.056742220652943, 0.0],
							[-120.267333984375, 38.056742220652943, 0.0],
							[-120.25634765625, 38.056742220652943, 0.0],
							[-120.245361328125, 38.056742220652943, 0.0],
							[-120.234375, 38.056742220652943, 0.0],
							[-120.234375, 38.065392351332477, 0.0],
							[-120.234375, 38.074041459419554, 0.0],
							[-120.223388671875, 38.074041459419554, 0.0],
							[-120.21240234375, 38.074041459419554, 0.0],
							[-120.201416015625, 38.074041459419554, 0.0],
							[-120.1904296875, 38.074041459419554, 0.0],
							[-120.179443359375, 38.074041459419554, 0.0],
							[-120.179443359375, 38.065392351332477, 0.0],
							[-120.16845703125, 38.065392351332477, 0.0],
							[-120.16845703125, 38.056742220652943, 0.0],
							[-120.179443359375, 38.056742220652943, 0.0],
							[-120.1904296875, 38.056742220652943, 0.0],
							[-120.1904296875, 38.048091067457236, 0.0],
							[-120.1904296875, 38.039438891821739, 0.0],
							[-120.1904296875, 38.030785693822942, 0.0],
							[-120.1904296875, 38.022131473537442, 0.0],
							[-120.201416015625, 38.022131473537442, 0.0],
							[-120.21240234375, 38.022131473537442, 0.0],
							[-120.21240234375, 38.013476231041921, 0.0],
							[-120.21240234375, 38.004819966413187, 0.0],
							[-120.21240234375, 37.996162679728123, 0.0],
							[-120.223388671875, 37.996162679728123, 0.0],
							[-120.234375, 37.996162679728123, 0.0],
							[-120.245361328125, 37.996162679728123, 0.0],
							[-120.245361328125, 37.987504371063729, 0.0],
							[-120.234375, 37.987504371063729, 0.0],
							[-120.223388671875, 37.987504371063729, 0.0],
							[-120.21240234375, 37.987504371063729, 0.0],
							[-120.21240234375, 37.97884504049712, 0.0],
							[-120.21240234375, 37.970184688105483, 0.0],
							[-120.21240234375, 37.96152331396614, 0.0],
							[-120.21240234375, 37.952860918156489, 0.0],
							[-120.21240234375, 37.944197500754044, 0.0],
							[-120.223388671875, 37.944197500754044, 0.0],
							[-120.234375, 37.944197500754044, 0.0],
							[-120.234375, 37.935533061836423, 0.0],
							[-120.234375, 37.926867601481341, 0.0],
							[-120.234375, 37.918201119766628, 0.0],
							[-120.234375, 37.90953361677019, 0.0],
							[-120.234375, 37.900865092570051, 0.0],
							[-120.223388671875, 37.900865092570051, 0.0],
							[-120.21240234375, 37.900865092570051, 0.0],
							[-120.21240234375, 37.892195547244356, 0.0],
							[-120.201416015625, 37.892195547244356, 0.0],
							[-120.1904296875, 37.892195547244356, 0.0],
							[-120.179443359375, 37.892195547244356, 0.0],
							[-120.16845703125, 37.892195547244356, 0.0],
							[-120.157470703125, 37.892195547244356, 0.0],
							[-120.157470703125, 37.883524980871307, 0.0],
							[-120.16845703125, 37.883524980871307, 0.0],
							[-120.16845703125, 37.874853393529271, 0.0],
							[-120.16845703125, 37.86618078529667, 0.0],
							[-120.16845703125, 37.857507156252034, 0.0],
							[-120.16845703125, 37.848832506474011, 0.0],
							[-120.16845703125, 37.840156836041338, 0.0],
							[-120.16845703125, 37.831480145032877, 0.0],
							[-120.16845703125, 37.822802433527563, 0.0],
							[-120.179443359375, 37.822802433527563, 0.0],
							[-120.179443359375, 37.814123701604458, 0.0],
							[-120.179443359375, 37.805443949342724, 0.0],
							[-120.16845703125, 37.805443949342724, 0.0],
							[-120.16845703125, 37.796763176821599, 0.0],
							[-120.179443359375, 37.796763176821599, 0.0],
							[-120.179443359375, 37.78808138412046, 0.0],
							[-120.179443359375, 37.779398571318765, 0.0],
							[-120.179443359375, 37.770714738496096, 0.0],
							[-120.1904296875, 37.770714738496096, 0.0],
							[-120.201416015625, 37.770714738496096, 0.0],
							[-120.201416015625, 37.762029885732105, 0.0],
							[-120.201416015625, 37.75334401310657, 0.0],
							[-120.21240234375, 37.75334401310657, 0.0],
							[-120.223388671875, 37.75334401310657, 0.0],
							[-120.234375, 37.75334401310657, 0.0],
							[-120.234375, 37.74465712069938, 0.0],
							[-120.245361328125, 37.74465712069938, 0.0],
							[-120.25634765625, 37.74465712069938, 0.0],
							[-120.25634765625, 37.735969208590504, 0.0],
							[-120.25634765625, 37.727280276860036, 0.0],
							[-120.267333984375, 37.727280276860036, 0.0],
							[-120.2783203125, 37.727280276860036, 0.0],
							[-120.289306640625, 37.727280276860036, 0.0],
							[-120.30029296875, 37.727280276860036, 0.0],
							[-120.30029296875, 37.718590325588146, 0.0],
							[-120.311279296875, 37.718590325588146, 0.0],
							[-120.322265625, 37.718590325588146, 0.0],
							[-120.322265625, 37.709899354855139, 0.0],
							[-120.322265625, 37.701207364741393, 0.0],
							[-120.322265625, 37.692514355327425, 0.0],
							[-120.322265625, 37.683820326693805, 0.0],
							[-120.311279296875, 37.683820326693805, 0.0],
							[-120.311279296875, 37.675125278921278, 0.0],
							[-120.311279296875, 37.666429212090605, 0.0],
							[-120.311279296875, 37.657732126282731, 0.0],
							[-120.311279296875, 37.64903402157865, 0.0],
							[-120.311279296875, 37.640334898059479, 0.0],
							[-120.322265625, 37.640334898059479, 0.0],
							[-120.333251953125, 37.640334898059479, 0.0],
							[-120.333251953125, 37.64903402157865, 0.0],
							[-120.34423828125, 37.64903402157865, 0.0],
							[-120.34423828125, 37.657732126282731, 0.0],
							[-120.34423828125, 37.666429212090605, 0.0],
							[-120.355224609375, 37.666429212090605, 0.0],
							[-120.3662109375, 37.666429212090605, 0.0],
							[-120.3662109375, 37.675125278921278, 0.0],
							[-120.377197265625, 37.675125278921278, 0.0],
							[-120.38818359375, 37.675125278921278, 0.0],
							[-120.38818359375, 37.666429212090605, 0.0],
							[-120.38818359375, 37.657732126282731, 0.0],
							[-120.377197265625, 37.657732126282731, 0.0],
							[-120.377197265625, 37.64903402157865, 0.0],
							[-120.377197265625, 37.640334898059479, 0.0],
							[-120.377197265625, 37.631634755806445, 0.0],
							[-120.377197265625, 37.622933594900864, 0.0],
							[-120.377197265625, 37.614231415424172, 0.0],
							[-120.377197265625, 37.605528217457888, 0.0],
							[-120.377197265625, 37.596824001083661, 0.0],
							[-120.3662109375, 37.596824001083661, 0.0],
							[-120.3662109375, 37.588118766383211, 0.0],
							[-120.3662109375, 37.579412513438392, 0.0],
							[-120.355224609375, 37.579412513438392, 0.0],
							[-120.355224609375, 37.570705242331151, 0.0],
							[-120.355224609375, 37.561996953143513, 0.0],
							[-120.34423828125, 37.561996953143513, 0.0],
							[-120.34423828125, 37.553287645957653, 0.0],
							[-120.34423828125, 37.544577320855822, 0.0],
							[-120.333251953125, 37.544577320855822, 0.0],
							[-120.333251953125, 37.53586597792038, 0.0],
							[-120.333251953125, 37.527153617233779, 0.0],
							[-120.322265625, 37.527153617233779, 0.0],
							[-120.322265625, 37.518440238878597, 0.0],
							[-120.322265625, 37.509725842937506, 0.0],
							[-120.322265625, 37.50101042949327, 0.0],
							[-120.322265625, 37.492293998628767, 0.0],
							[-120.311279296875, 37.492293998628767, 0.0],
							[-120.311279296875, 37.483576550426996, 0.0],
							[-120.30029296875, 37.483576550426996, 0.0],
							[-120.30029296875, 37.474858084971025, 0.0],
							[-120.30029296875, 37.466138602344053, 0.0],
							[-120.289306640625, 37.466138602344053, 0.0],
							[-120.289306640625, 37.457418102629369, 0.0],
							[-120.2783203125, 37.457418102629369, 0.0],
							[-120.2783203125, 37.448696585910376, 0.0],
							[-120.2783203125, 37.439974052270586, 0.0],
							[-120.2783203125, 37.431250501793571, 0.0],
							[-120.2783203125, 37.422525934563069, 0.0],
							[-120.2783203125, 37.413800350662882, 0.0],
							[-120.2783203125, 37.405073750176932, 0.0],
							[-120.267333984375, 37.405073750176932, 0.0],
							[-120.267333984375, 37.39634613318924, 0.0],
							[-120.267333984375, 37.387617499783943, 0.0],
							[-120.267333984375, 37.378887850045253, 0.0],
							[-120.267333984375, 37.370157184057511, 0.0],
							[-120.25634765625, 37.370157184057511, 0.0],
							[-120.245361328125, 37.370157184057511, 0.0],
							[-120.245361328125, 37.361425501905167, 0.0],
							[-120.234375, 37.361425501905167, 0.0],
							[-120.234375, 37.352692803672738, 0.0],
							[-120.234375, 37.343959089444901, 0.0],
							[-120.223388671875, 37.343959089444901, 0.0],
							[-120.223388671875, 37.335224359306387, 0.0],
							[-120.223388671875, 37.326488613342057, 0.0],
							[-120.21240234375, 37.326488613342057, 0.0],
							[-120.21240234375, 37.317751851636878, 0.0],
							[-120.201416015625, 37.317751851636878, 0.0],
							[-120.201416015625, 37.3090140742759, 0.0],
							[-120.201416015625, 37.300275281344312, 0.0],
							[-120.1904296875, 37.300275281344312, 0.0],
							[-120.1904296875, 37.291535472927364, 0.0],
							[-120.1904296875, 37.28279464911045, 0.0],
							[-120.179443359375, 37.28279464911045, 0.0],
							[-120.179443359375, 37.274052809979054, 0.0],
							[-120.179443359375, 37.26530995561874, 0.0],
							[-120.179443359375, 37.256566086115214, 0.0],
							[-120.16845703125, 37.256566086115214, 0.0],
							[-120.16845703125, 37.247821201554288, 0.0],
							[-120.179443359375, 37.247821201554288, 0.0],
							[-120.179443359375, 37.239075302021831, 0.0],
							[-120.16845703125, 37.239075302021831, 0.0],
							[-120.157470703125, 37.239075302021831, 0.0],
							[-120.146484375, 37.239075302021831, 0.0],
							[-120.146484375, 37.230328387603869, 0.0],
							[-120.135498046875, 37.230328387603869, 0.0],
							[-120.12451171875, 37.230328387603869, 0.0],
							[-120.12451171875, 37.221580458386505, 0.0],
							[-120.113525390625, 37.221580458386505, 0.0],
							[-120.1025390625, 37.221580458386505, 0.0],
							[-120.091552734375, 37.221580458386505, 0.0],
							[-120.091552734375, 37.212831514455942, 0.0],
							[-120.08056640625, 37.212831514455942, 0.0],
							[-120.08056640625, 37.204081555898512, 0.0],
							[-120.091552734375, 37.204081555898512, 0.0],
							[-120.1025390625, 37.204081555898512, 0.0],
							[-120.1025390625, 37.195330582800636, 0.0],
							[-120.1025390625, 37.186578595248839, 0.0],
							[-120.113525390625, 37.186578595248839, 0.0],
							[-120.12451171875, 37.186578595248839, 0.0],
							[-120.135498046875, 37.186578595248839, 0.0],
							[-120.146484375, 37.186578595248839, 0.0],
							[-120.157470703125, 37.186578595248839, 0.0],
							[-120.16845703125, 37.186578595248839, 0.0],
							[-120.179443359375, 37.186578595248839, 0.0],
							[-120.179443359375, 37.195330582800636, 0.0],
							[-120.179443359375, 37.204081555898512, 0.0],
							[-120.1904296875, 37.204081555898512, 0.0],
							[-120.201416015625, 37.204081555898512, 0.0],
							[-120.201416015625, 37.212831514455942, 0.0],
							[-120.201416015625, 37.221580458386505, 0.0],
							[-120.21240234375, 37.221580458386505, 0.0],
							[-120.21240234375, 37.230328387603869, 0.0],
							[-120.223388671875, 37.230328387603869, 0.0],
							[-120.234375, 37.230328387603869, 0.0],
							[-120.234375, 37.239075302021831, 0.0],
							[-120.234375, 37.247821201554288, 0.0],
							[-120.245361328125, 37.247821201554288, 0.0],
							[-120.245361328125, 37.256566086115214, 0.0],
							[-120.245361328125, 37.26530995561874, 0.0],
							[-120.245361328125, 37.274052809979054, 0.0],
							[-120.245361328125, 37.28279464911045, 0.0],
							[-120.245361328125, 37.291535472927364, 0.0],
							[-120.25634765625, 37.291535472927364, 0.0],
						],
					],
				],
			},
		},
	],
};

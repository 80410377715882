import * as React from 'react';

import $ from 'jquery';

import InstallOrderInput from './InstallOrderInput';
import SignalAnimation from './SignalAnimation';
import InstallInstructions from './InstallInstructions';
import footerImg from '../../../assets/images/bg2.png';

import { Box, Container, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function InstallPage(props) {
	const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

	const [order, setOrder] = React.useState();
	const [installProgress, setInstallProgress] = React.useState();
	const [errorMsg, setErrorMsg] = React.useState();
	const [animationClass, setAnimationClass] = React.useState();
	const [alignmentScore, setAlignmentScore] = React.useState();
	const [pathLoss, setPathLoss] = React.useState();

	React.useEffect(() => {
		if (order) {
			startMonitoring(order);
		}
	}, [order]);

	React.useEffect(() => {
		if (installProgress === 'connecting') {
			setAnimationClass('signal-check');
		} else if (installProgress === 'connected') {
			continuousMonitoring(order.rn_serial, Date.now());
		} else if (installProgress === 'timed out') {
			$('path#signal3').one(whichInfAnimationEvent(), function () {
				setAnimationClass();
			});
		} else if (installProgress === 'failed') {
			$('path#signal3').one(whichInfAnimationEvent(), function () {
				setAnimationClass('signal-fail');
			});
		} else if (installProgress === 'finalized') {
			$('path#signal3').one(whichInfAnimationEvent(), function () {
				setAnimationClass('signal-pass');
			});
		}
	}, [installProgress]);

	const startMonitoring = () => {
		setInstallProgress('connecting');
		fetch(
			`https://taranamon.digitalpath.net/api/monitor/start?serial=${order.rn_serial}&mac_address=${order.router_mac_address}`
		)
			.then((response) => response.json())
			.then((response) => {
				console.log('start monitoring response:', response);
				if (response.statusCode === 200) {
					setInstallProgress('connected');
					// continuousMonitoring(orderData.rn_serial, Date.now());
				} else {
					console.log(response.error);
					setInstallProgress('failed');
					setErrorMsg('Failed to connect');
				}
			});
	};

	const continuousMonitoring = (serial, start, badResponses = 0) => {
		if (Date.now() - start > 600000) {
			console.log('timed out');
			setInstallProgress('timed out');
			setErrorMsg('Connection timed out.');
			return;
		}
		if (badResponses > 9) {
			console.log('Too many bad responses');
			setInstallProgress('failed');
			setErrorMsg(
				'The connection to the monitoring server was interrupted or could not be established.'
			);
			return;
		}
		setTimeout(() => {
			$.ajax({
				url: 'https://taranamon.digitalpath.net/api/monitor/signal',
				async: true,
				data: { serial: serial },
				type: 'GET',
				success: async function (response) {
					if (response.host) {
						console.log(response.host);
						badResponses = 0;
						setAlignmentScore(response.host.cpe_score);
						setPathLoss([
							response.host.cpe_path_loss[0],
							response.host.cpe_path_loss[1],
						]);
					} else {
						badResponses++;
						console.log(`${badResponses} bad responses in a row`);
					}
					continuousMonitoring(serial, start, badResponses);
				},
				error: function () {
					console.log(
						'Error occurred while monitoring signals. Will try again.'
					);
					badResponses++;
					continuousMonitoring(serial, start, badResponses);
				},
			});
		}, 1000);
	};

	const finalize = () => {
		setInstallProgress('finalizing');
		$.ajax({
			url: 'https://taranamon.digitalpath.net/api/speedtest',
			async: true,
			data: { serial: order.rn_serial },
			type: 'GET',
			success: function (response) {
				setInstallProgress('finalized');
				console.log('speed test response', response);
			},
			error: function (response) {
				setInstallProgress('failed');
				setErrorMsg('Call us at (555) 555-5555');
				console.log('speed test response', response);
			},
		});
	};

	function whichInfAnimationEvent() {
		var t,
			el = document.createElement('fakeelement');

		var animations = {
			animation: 'animationiteration',
			OAnimation: 'oAnimationIteration',
			MozAnimation: 'mozAnimationIteration',
			WebkitAnimation: 'webkitAnimationIteration',
		};

		for (t in animations) {
			if (el.style[t] !== undefined) {
				return animations[t];
			}
		}
	}

	return (
		<React.Fragment>
			<Container
				maxWidth='md'
				sx={{
					height: 'calc(100vh - 82px)',
					paddingTop: '1em',
					fontSize: mobile ? '0.9em' : '1em',
				}}
			>
				<InstallOrderInput mobile={mobile} order={order} setOrder={setOrder} />
				{/* <Typography fontSize='inherit'>
					{order &&
						`${order.customer_name} | ${order.address}, ${order.city_name} ${order.ship_state_abbr}`}
				</Typography> */}
				<SignalAnimation animationClass={animationClass} />
				<InstallInstructions
					mobile={mobile}
					installProgress={installProgress}
					errorMsg={errorMsg}
					startMonitoring={startMonitoring}
					alignmentScore={alignmentScore}
					pathLoss={pathLoss}
					finalize={finalize}
				/>
			</Container>
			<Box
				sx={{
					backgroundImage: `url(${footerImg})`,
					backgroundRepeat: 'repeat-x',
					height: '82px',
					marginTop: '100px',
				}}
			/>
		</React.Fragment>
	);
}

import * as React from 'react';

import { TextField } from '@mui/material';

import debounce from '../../../functions/debounce';

export default function InstallOrderInput(props) {
	const { mobile, order, setOrder } = props;
	const [orderNumber, setOrderNumber] = React.useState('');
	const [inputError, setInputError] = React.useState();
	const [fetchingOrder, setFetchingOrder] = React.useState(false);

	React.useEffect(() => {
		async function checkOrder() {
			const filtered = orderNumber.toLowerCase().replace(/-/g, '');
			if (orderNumber) {
				if (
					/^[A-z1-9]{3}-[A-z1-9]{3}-[A-z1-9]{3}$|^[A-z1-9]{9}$/.test(
						orderNumber
					)
				) {
					setInputError();
					setFetchingOrder(true);
					console.log('fetching order');
					await fetch(
						`https://09b2zjmgmj.execute-api.us-west-2.amazonaws.com/default/getOrderDetails?order_code=${filtered}&extended=true`
					)
						.then((response) => response.json())
						.then((response) => {
							setFetchingOrder(false);
							if (response.data[0]) {
								console.log(response.data[0]);
								setOrder(response.data[0]);
								setOrderNumber(
									`${filtered.toUpperCase().replace(/.{3}(?!$)/g, '$&-')}`
								);
							} else {
								setInputError('Invalid order number');
							}
						});
				} else if (/^[a-z0-9]{0,}$/.test(filtered)) {
					if (filtered.length <= 9) {
						setInputError();
					} else {
						setInputError('Order numbers must be 9 characters');
					}
				} else {
					setInputError('Invalid character in order number');
				}
			} else {
				setInputError();
			}
		}
		if (!order) {
			checkOrder();
		}
	}, [orderNumber, setOrder]);

	const handleOrderInput = (e) => {
		setOrderNumber(e.target.value);
	};

	return (
		<TextField
			value={orderNumber}
			label='Order Number'
			placeholder='123-ABC-4D5'
			onInput={handleOrderInput}
			error={inputError ? true : false}
			helperText={inputError}
			disabled={fetchingOrder || (order ? true : false)}
			size={mobile ? 'small' : 'medium'}
			sx={{ marginBottom: '0.5em' }}
			InputProps={{
				sx: {
					fontFamily: 'monospace',
				},
			}}
		/>
	);
}

export const metro = {
	type: 'Feature',
	geometry: {
		type: 'MultiPolygon',
		coordinates: [
			[
				[
					[-121.999648313792804, 39.204638710947187, 0.0],
					[-122.000463397986493, 39.202711115223941, 0.0],
					[-121.997601882860096, 39.195833736507488, 0.0],
					[-121.995460661043097, 39.18780367595128, 0.0],
					[-121.9981239209866, 39.187238904628252, 0.0],
					[-122.010214471526496, 39.189127999061967, 0.0],
					[-122.010661550973794, 39.187672951058588, 0.0],
					[-122.018419328476, 39.187820333268661, 0.0],
					[-122.018579356142297, 39.190645614644453, 0.0],
					[-122.018470700020799, 39.19223311943837, 0.0],
					[-122.0296547791162, 39.199125938630416, 0.0],
					[-122.019438060206994, 39.205834066619232, 0.0],
					[-122.023852893226206, 39.211488897779468, 0.0],
					[-122.021437176282504, 39.217130150718368, 0.0],
					[-122.023396439317395, 39.217185134414358, 0.0],
					[-122.023392107910894, 39.218672363212811, 0.0],
					[-122.019062694709802, 39.220412328130131, 0.0],
					[-122.018036367399702, 39.218551562277867, 0.0],
					[-121.996343672817204, 39.213074520499312, 0.0],
					[-121.9980545884967, 39.208808037464678, 0.0],
					[-121.9974291156514, 39.208495922057693, 0.0],
					[-121.997788794674406, 39.207449410642262, 0.0],
					[-121.996005723523297, 39.206772392212748, 0.0],
					[-121.996485151558304, 39.205349993103333, 0.0],
					[-121.9988503691783, 39.205885202265939, 0.0],
					[-121.999648313792804, 39.204638710947187, 0.0],
				],
			],
			[
				[
					[-121.686235485486307, 39.360735588336418, 0.0],
					[-121.685111359174698, 39.36074055950639, 0.0],
					[-121.6850405747346, 39.359573498210587, 0.0],
					[-121.684702093516194, 39.359492449803632, 0.0],
					[-121.685179571620296, 39.358896057263337, 0.0],
					[-121.687863753512303, 39.35867142439357, 0.0],
					[-121.688055369459505, 39.357279097982499, 0.0],
					[-121.692278025738602, 39.356471418196442, 0.0],
					[-121.695430566999306, 39.355712478942721, 0.0],
					[-121.697529320290798, 39.355756759016508, 0.0],
					[-121.7101200844169, 39.355655989466079, 0.0],
					[-121.710188997251905, 39.36086008538107, 0.0],
					[-121.7124917508349, 39.360884895144622, 0.0],
					[-121.712450086041798, 39.361811891115188, 0.0],
					[-121.715449314776805, 39.361904532263409, 0.0],
					[-121.715494384116099, 39.362977550707853, 0.0],
					[-121.716219004649304, 39.363001394123373, 0.0],
					[-121.716192054267907, 39.366626156391163, 0.0],
					[-121.711477691477, 39.366672158886082, 0.0],
					[-121.711337562144607, 39.368969820534971, 0.0],
					[-121.711374525499195, 39.373930942541847, 0.0],
					[-121.706772515458098, 39.373921234445383, 0.0],
					[-121.699041341013, 39.373918719612092, 0.0],
					[-121.697663969852798, 39.370530708939619, 0.0],
					[-121.684908291647204, 39.370653623009133, 0.0],
					[-121.6792950403064, 39.36918745673762, 0.0],
					[-121.682167596007602, 39.363326360469742, 0.0],
					[-121.685106908139602, 39.363207013926058, 0.0],
					[-121.686235485486307, 39.360735588336418, 0.0],
				],
			],
			[
				[
					[-121.701241015938095, 39.415081655622522, 0.0],
					[-121.703120491437403, 39.412026375883677, 0.0],
					[-121.704710319186603, 39.409259539213018, 0.0],
					[-121.706821728294997, 39.408640243380923, 0.0],
					[-121.709724129078793, 39.408339864756933, 0.0],
					[-121.711386050789898, 39.406919279737977, 0.0],
					[-121.716223602657607, 39.406920980920127, 0.0],
					[-121.7165299007724, 39.416415683616918, 0.0],
					[-121.714562092409395, 39.416833352252098, 0.0],
					[-121.714905764379793, 39.417731748042591, 0.0],
					[-121.712825721733793, 39.41815646607732, 0.0],
					[-121.713276829498497, 39.419191638413047, 0.0],
					[-121.711171516915101, 39.419596044247413, 0.0],
					[-121.711273904474595, 39.421859493031569, 0.0],
					[-121.707877007855899, 39.421919932269738, 0.0],
					[-121.703500871668794, 39.420646760148429, 0.0],
					[-121.701241015938095, 39.415081655622522, 0.0],
				],
			],
			[
				[
					[-121.840001051367494, 39.703793229706427, 0.0],
					[-121.842110141658495, 39.704795362478578, 0.0],
					[-121.843113381072001, 39.704995570148988, 0.0],
					[-121.843845185232993, 39.704574635465413, 0.0],
					[-121.843666835753496, 39.702742658095573, 0.0],
					[-121.843899934084803, 39.702214258655509, 0.0],
					[-121.845778983870105, 39.701026990983841, 0.0],
					[-121.848010721056298, 39.70030476128818, 0.0],
					[-121.849426859785595, 39.700373038893112, 0.0],
					[-121.846003332167399, 39.708691101314358, 0.0],
					[-121.843224875632202, 39.708797290472937, 0.0],
					[-121.843253769747307, 39.71057029825942, 0.0],
					[-121.835774827429304, 39.710928609288167, 0.0],
					[-121.834464478532297, 39.710914272697472, 0.0],
					[-121.834173918885895, 39.709962414804231, 0.0],
					[-121.8314196796332, 39.709769704614239, 0.0],
					[-121.830866170605006, 39.708962903230891, 0.0],
					[-121.8334072132335, 39.708766354956637, 0.0],
					[-121.8342437714238, 39.708637439700091, 0.0],
					[-121.834209652936707, 39.704464657856903, 0.0],
					[-121.837340188617205, 39.703725393244738, 0.0],
					[-121.840001051367494, 39.703793229706427, 0.0],
				],
			],
			[
				[
					[-121.6496068905371, 39.268313313812129, 0.0],
					[-121.659093754886598, 39.26911056745994, 0.0],
					[-121.657566517133105, 39.263387675886783, 0.0],
					[-121.658887828260305, 39.262084345582977, 0.0],
					[-121.658958306649595, 39.259840406834698, 0.0],
					[-121.662647003544095, 39.259949224047311, 0.0],
					[-121.667426482143597, 39.265345432079833, 0.0],
					[-121.670907087487095, 39.265280432636963, 0.0],
					[-121.675993264700494, 39.265345610770709, 0.0],
					[-121.678584075837307, 39.268779733106108, 0.0],
					[-121.678532304862998, 39.275953761097313, 0.0],
					[-121.678173666265394, 39.279609612801558, 0.0],
					[-121.673839449541603, 39.281334951950321, 0.0],
					[-121.669411624418302, 39.281512948706087, 0.0],
					[-121.669385691535695, 39.283811838824327, 0.0],
					[-121.665401310897394, 39.283921684715189, 0.0],
					[-121.667045355456693, 39.289044007995358, 0.0],
					[-121.662595601956994, 39.289084260542552, 0.0],
					[-121.659951732279396, 39.289074671773207, 0.0],
					[-121.659865474387402, 39.283020827039422, 0.0],
					[-121.654101599917297, 39.282135441071013, 0.0],
					[-121.651446733851401, 39.277865208867091, 0.0],
					[-121.648798779136001, 39.276044789240323, 0.0],
					[-121.6448644935831, 39.272666572548772, 0.0],
					[-121.6470481735996, 39.270280980709813, 0.0],
					[-121.6496068905371, 39.268313313812129, 0.0],
				],
			],
			[
				[
					[-121.457461178091705, 39.537482630404021, 0.0],
					[-121.457888424284405, 39.529015220314797, 0.0],
					[-121.462218628598194, 39.515838283056702, 0.0],
					[-121.4592327035094, 39.503840251551964, 0.0],
					[-121.453343678266506, 39.511655270132131, 0.0],
					[-121.440049691226903, 39.516011785712116, 0.0],
					[-121.437258452079107, 39.508695064812549, 0.0],
					[-121.427830762380296, 39.481140799684923, 0.0],
					[-121.452321384778699, 39.478506093819597, 0.0],
					[-121.458053507025497, 39.460043576841592, 0.0],
					[-121.472308535888402, 39.453955733775487, 0.0],
					[-121.503173119255706, 39.453505127700048, 0.0],
					[-121.529785886774505, 39.453767381477611, 0.0],
					[-121.527689495500795, 39.431494179619378, 0.0],
					[-121.540468590766494, 39.431089547822303, 0.0],
					[-121.553273359154602, 39.430974999615792, 0.0],
					[-121.554122409320897, 39.437127545466737, 0.0],
					[-121.553899419134396, 39.445196064243767, 0.0],
					[-121.5600876066609, 39.46511390963618, 0.0],
					[-121.564094233822601, 39.465691228491252, 0.0],
					[-121.576748581872806, 39.465712801218118, 0.0],
					[-121.574063235342507, 39.490998592138233, 0.0],
					[-121.5799628821743, 39.495518398895541, 0.0],
					[-121.597967698231997, 39.494787175011417, 0.0],
					[-121.600260428064402, 39.493001620129959, 0.0],
					[-121.613796630409595, 39.492605690544579, 0.0],
					[-121.616417157487604, 39.498789830113722, 0.0],
					[-121.610640257571404, 39.498841344288728, 0.0],
					[-121.6137428729552, 39.509233963457369, 0.0],
					[-121.613376607907199, 39.520683001402681, 0.0],
					[-121.600683716109401, 39.524860761025337, 0.0],
					[-121.592534815326701, 39.526332517815398, 0.0],
					[-121.582632491898906, 39.525776595694907, 0.0],
					[-121.5790327801366, 39.531100656633889, 0.0],
					[-121.565735107344196, 39.531005035145519, 0.0],
					[-121.548489607878906, 39.527202330459467, 0.0],
					[-121.542180259957107, 39.527640645674452, 0.0],
					[-121.541736180943502, 39.53391434444255, 0.0],
					[-121.529338169824896, 39.53316656715787, 0.0],
					[-121.517375235138701, 39.533138286075797, 0.0],
					[-121.512410970724602, 39.528468495103951, 0.0],
					[-121.487836697195107, 39.524780156317263, 0.0],
					[-121.478372684898105, 39.531924240206827, 0.0],
					[-121.468663917463203, 39.538299229955037, 0.0],
					[-121.457461178091705, 39.537482630404021, 0.0],
				],
			],
			[
				[
					[-120.652278735484003, 39.759858211814553, 0.0],
					[-120.652294495654701, 39.766632511296329, 0.0],
					[-120.641286111276301, 39.77779640011773, 0.0],
					[-120.653678432530995, 39.781827932333748, 0.0],
					[-120.656610413065096, 39.786901817250097, 0.0],
					[-120.656743240278701, 39.792420486478832, 0.0],
					[-120.655990374831006, 39.798982408386387, 0.0],
					[-120.647141949793607, 39.797588463653092, 0.0],
					[-120.6362168438162, 39.792158655999607, 0.0],
					[-120.616274560103406, 39.788218054932017, 0.0],
					[-120.603954854308498, 39.777670691262337, 0.0],
					[-120.592617798509096, 39.763298067009558, 0.0],
					[-120.575999350563094, 39.762198214519039, 0.0],
					[-120.564102408514501, 39.753763274263427, 0.0],
					[-120.551151688496603, 39.73989243195701, 0.0],
					[-120.533336640036794, 39.725185147827311, 0.0],
					[-120.528166547568105, 39.708074527500969, 0.0],
					[-120.546296653924202, 39.7000829069096, 0.0],
					[-120.561211827477095, 39.702907620996029, 0.0],
					[-120.581094912323707, 39.719556356124123, 0.0],
					[-120.581539614839599, 39.73298464365655, 0.0],
					[-120.592718968906695, 39.737685056236913, 0.0],
					[-120.592648470324406, 39.745485949346531, 0.0],
					[-120.6015216750056, 39.752249332312772, 0.0],
					[-120.612144581421305, 39.746652472748643, 0.0],
					[-120.618163551966504, 39.746975918882391, 0.0],
					[-120.633822709383494, 39.767986955592342, 0.0],
					[-120.641709495749197, 39.762692675204647, 0.0],
					[-120.652278735484003, 39.759858211814553, 0.0],
				],
			],
			[
				[
					[-120.915573212556268, 39.946186297860905, 0.0],
					[-120.914834217769794, 39.946401191416093, 0.0],
					[-120.910848367503789, 39.945037725695911, 0.0],
					[-120.906849038474505, 39.944024144396622, 0.0],
					[-120.904482377026383, 39.94286006993574, 0.0],
					[-120.8999611445673, 39.941313462547221, 0.0],
					[-120.900092433712217, 39.940362815551012, 0.0],
					[-120.896397219908607, 39.936587892121615, 0.0],
					[-120.890489871501899, 39.935844909585157, 0.0],
					[-120.887824553552207, 39.934852505315, 0.0],
					[-120.888300161937593, 39.934122143865288, 0.0],
					[-120.889774540151237, 39.933208629307906, 0.0],
					[-120.889782090120704, 39.933189579829993, 0.0],
					[-120.891653941866949, 39.9308877052434, 0.0],
					[-120.891899773470001, 39.929941690010942, 0.0],
					[-120.893372206179706, 39.928380635597513, 0.0],
					[-120.893822597892566, 39.927834159931592, 0.0],
					[-120.894113469509904, 39.927418289756751, 0.0],
					[-120.896283468018453, 39.925408720535039, 0.0],
					[-120.896114328815202, 39.924798227619682, 0.0],
					[-120.896940401540505, 39.924639905789697, 0.0],
					[-120.89758104119349, 39.924676540476284, 0.0],
					[-120.897751625884197, 39.924597959514692, 0.0],
					[-120.899202292739105, 39.924769251007042, 0.0],
					[-120.899780389400604, 39.924802309201162, 0.0],
					[-120.906096299075102, 39.925639743071393, 0.0],
					[-120.906349789599702, 39.925820985843039, 0.0],
					[-120.906649956903394, 39.925866511012821, 0.0],
					[-120.907116159847362, 39.926368931641079, 0.0],
					[-120.908926819587606, 39.92766353225921, 0.0],
					[-120.908748455202087, 39.92812803434137, 0.0],
					[-120.911414436929704, 39.931001126911553, 0.0],
					[-120.916235605209707, 39.933701508789888, 0.0],
					[-120.919311741862202, 39.933973078172983, 0.0],
					[-120.92038863986393, 39.934027182796406, 0.0],
					[-120.929217085295306, 39.933919583838637, 0.0],
					[-120.930539876416617, 39.934039126490035, 0.0],
					[-120.931585004964759, 39.933462215979652, 0.0],
					[-120.931809506016904, 39.932270329723451, 0.0],
					[-120.932490446369997, 39.93192269177316, 0.0],
					[-120.933367575490252, 39.93270910936112, 0.0],
					[-120.935799620177733, 39.934402537774602, 0.0],
					[-120.93685301362369, 39.934354824151249, 0.0],
					[-120.938399332666805, 39.93420811029533, 0.0],
					[-120.938913646534175, 39.934415507295583, 0.0],
					[-120.940230803952048, 39.934766696836284, 0.0],
					[-120.941584209455996, 39.934203110398691, 0.0],
					[-120.943255786518407, 39.932488889100647, 0.0],
					[-120.943410231719781, 39.932278213901114, 0.0],
					[-120.943409775289993, 39.932026151582583, 0.0],
					[-120.944332360620834, 39.931020358762183, 0.0],
					[-120.944604536480696, 39.930649089839122, 0.0],
					[-120.945190941218897, 39.92996112110697, 0.0],
					[-120.946018745619696, 39.929871420297097, 0.0],
					[-120.947310747650207, 39.930159920250318, 0.0],
					[-120.948120127222197, 39.93253462669206, 0.0],
					[-120.948319353927715, 39.932581006660293, 0.0],
					[-120.950537508928633, 39.932721621179844, 0.0],
					[-120.950688747491697, 39.93266492735296, 0.0],
					[-120.951919408846607, 39.933273186567753, 0.0],
					[-120.953771731141302, 39.934687995719983, 0.0],
					[-120.954874997578102, 39.935776358724887, 0.0],
					[-120.954972131368237, 39.93579371678738, 0.0],
					[-120.957243776915803, 39.936000479443102, 0.0],
					[-120.957278953993452, 39.936229108946584, 0.0],
					[-120.959262954909903, 39.93766145479109, 0.0],
					[-120.960387263318694, 39.937317512468397, 0.0],
					[-120.960470595303917, 39.937330992406501, 0.0],
					[-120.960555456202002, 39.937272776771628, 0.0],
					[-120.961408024253402, 39.937374091141677, 0.0],
					[-120.961525761865758, 39.937501678127106, 0.0],
					[-120.961582424329805, 39.937510843953483, 0.0],
					[-120.963271056642895, 39.938693618322212, 0.0],
					[-120.966292063587602, 39.940168869113549, 0.0],
					[-120.9640503948673, 39.943057512497042, 0.0],
					[-120.960615367631789, 39.942392514999518, 0.0],
					[-120.959883201533899, 39.942316291063612, 0.0],
					[-120.958912681433958, 39.942062886736629, 0.0],
					[-120.954709212947279, 39.941249124331591, 0.0],
					[-120.953552544056194, 39.942501678247233, 0.0],
					[-120.953366434560564, 39.942515784890865, 0.0],
					[-120.953311823925404, 39.942579177649243, 0.0],
					[-120.946592716394804, 39.943032243634768, 0.0],
					[-120.942203632234595, 39.940033020929263, 0.0],
					[-120.935718641124296, 39.943079707868563, 0.0],
					[-120.9275730370952, 39.948275495743239, 0.0],
					[-120.925486778709868, 39.948003538469607, 0.0],
					[-120.925302617178005, 39.948051958941697, 0.0],
					[-120.915573212556268, 39.946186297860905, 0.0],
				],
			],
			[
				[
					[-121.240773914325899, 40.288675428174557, 0.0],
					[-121.2458464843656, 40.287462088646087, 0.0],
					[-121.251263285595201, 40.290343020408812, 0.0],
					[-121.241539303378701, 40.295593217694147, 0.0],
					[-121.241740641917502, 40.299408103731039, 0.0],
					[-121.2490612071648, 40.299282121203817, 0.0],
					[-121.248835353444704, 40.304359505896912, 0.0],
					[-121.242071900754496, 40.306780362875926, 0.0],
					[-121.239266334119606, 40.305811381272797, 0.0],
					[-121.237318090953295, 40.306878476822583, 0.0],
					[-121.234964484067604, 40.307001084003183, 0.0],
					[-121.234799647725396, 40.307995031917983, 0.0],
					[-121.231357799184707, 40.311382026652147, 0.0],
					[-121.237135852467702, 40.311627708344503, 0.0],
					[-121.238813839002503, 40.31365631632287, 0.0],
					[-121.236747572224402, 40.315930128365672, 0.0],
					[-121.236790211953803, 40.318754027707143, 0.0],
					[-121.236959745353502, 40.320675242880021, 0.0],
					[-121.236958853887998, 40.320717186378999, 0.0],
					[-121.234690669729204, 40.32339075312926, 0.0],
					[-121.233298855152199, 40.323473239228662, 0.0],
					[-121.231181102164996, 40.320891620233958, 0.0],
					[-121.231931778813802, 40.316333114077317, 0.0],
					[-121.231731815564999, 40.314538999868077, 0.0],
					[-121.227456430628493, 40.314094801591622, 0.0],
					[-121.222496861388507, 40.314386743435051, 0.0],
					[-121.221068841717894, 40.309212124004453, 0.0],
					[-121.222483408457904, 40.303928017682253, 0.0],
					[-121.226760000527804, 40.300375527094602, 0.0],
					[-121.226216918887005, 40.295857806777171, 0.0],
					[-121.226928284257994, 40.291982568293982, 0.0],
					[-121.240773914325899, 40.288675428174557, 0.0],
				],
			],
			[
				[
					[-120.631268114482594, 40.425888538273647, 0.0],
					[-120.629563036313002, 40.413290013958481, 0.0],
					[-120.627192553609603, 40.408922480958871, 0.0],
					[-120.588938373336191, 40.391228566982974, 0.0],
					[-120.560255729148182, 40.376184238903967, 0.0],
					[-120.577198173386734, 40.365287646136466, 0.0],
					[-120.590414685904747, 40.365147044939462, 0.0],
					[-120.605810516976248, 40.376535741896468, 0.0],
					[-120.633598305974203, 40.402128011604503, 0.0],
					[-120.643262613461204, 40.402521824267502, 0.0],
					[-120.643254062000295, 40.397872669953671, 0.0],
					[-120.649569203295798, 40.398141957251667, 0.0],
					[-120.651200010135398, 40.404523046715752, 0.0],
					[-120.667481372087593, 40.404113976850013, 0.0],
					[-120.667949360660302, 40.411946109807452, 0.0],
					[-120.666122073816496, 40.417349132927832, 0.0],
					[-120.667716928842395, 40.432238561536778, 0.0],
					[-120.668891740485293, 40.436555739994553, 0.0],
					[-120.664176885876998, 40.438831437012318, 0.0],
					[-120.646771439153795, 40.44572914008274, 0.0],
					[-120.641167669094798, 40.440004958523353, 0.0],
					[-120.625137848421105, 40.438303359417738, 0.0],
					[-120.631268114482594, 40.425888538273647, 0.0],
				],
			],
			[
				[
					[-122.1522277183998, 39.163033272311317, 0.0],
					[-122.150854293164699, 39.160875971435829, 0.0],
					[-122.147380241338297, 39.160951101253417, 0.0],
					[-122.141100259511703, 39.159522294522887, 0.0],
					[-122.132780083825807, 39.155838801475497, 0.0],
					[-122.132870545593406, 39.148684930368297, 0.0],
					[-122.138101449942397, 39.148846853125249, 0.0],
					[-122.139527868186207, 39.148707453037531, 0.0],
					[-122.137730353093801, 39.145600177770511, 0.0],
					[-122.139236612568496, 39.145452678928187, 0.0],
					[-122.142858023985895, 39.148671660632843, 0.0],
					[-122.1443181350228, 39.147509595796123, 0.0],
					[-122.151385784083999, 39.1475553365416, 0.0],
					[-122.153035831241397, 39.147721236731947, 0.0],
					[-122.153153643641303, 39.148464477133459, 0.0],
					[-122.156197693578903, 39.148533943562029, 0.0],
					[-122.156307626466898, 39.148126783847133, 0.0],
					[-122.156872873587901, 39.148147953265543, 0.0],
					[-122.1613015541121, 39.148125375153498, 0.0],
					[-122.161311984349695, 39.14753843271739, 0.0],
					[-122.162271798032293, 39.147569433161102, 0.0],
					[-122.165893559852606, 39.152869256671863, 0.0],
					[-122.161744206636996, 39.159743745700318, 0.0],
					[-122.155865110381697, 39.159733056765013, 0.0],
					[-122.155926674094999, 39.160143186968469, 0.0],
					[-122.154655826149295, 39.160267877764412, 0.0],
					[-122.154466231292005, 39.159768138941857, 0.0],
					[-122.153762028637601, 39.159966306048219, 0.0],
					[-122.153726400968495, 39.162629243188462, 0.0],
					[-122.1522277183998, 39.163033272311317, 0.0],
				],
			],
			[
				[
					[-122.200590616007105, 39.279882764895589, 0.0],
					[-122.2006422323782, 39.28062556880807, 0.0],
					[-122.197478110313099, 39.280657088004013, 0.0],
					[-122.197354058774494, 39.281735527193661, 0.0],
					[-122.194909895738704, 39.281771705326477, 0.0],
					[-122.194745858538099, 39.282126991282823, 0.0],
					[-122.193746635956202, 39.282148323762179, 0.0],
					[-122.192877071952395, 39.282046959498857, 0.0],
					[-122.192885978217404, 39.283758318663487, 0.0],
					[-122.191642671690602, 39.283864882813702, 0.0],
					[-122.191859511218098, 39.279678533452817, 0.0],
					[-122.191650099858805, 39.277954103781617, 0.0],
					[-122.189457108983106, 39.277900489837727, 0.0],
					[-122.189301199699202, 39.276407166236801, 0.0],
					[-122.186135185574102, 39.276313347564979, 0.0],
					[-122.186085120433205, 39.272721751436073, 0.0],
					[-122.186115613353195, 39.269621704247903, 0.0],
					[-122.189364204000398, 39.269568682699237, 0.0],
					[-122.189597305077598, 39.268052660811968, 0.0],
					[-122.191105050732602, 39.267973938618361, 0.0],
					[-122.191436479359893, 39.272558594258143, 0.0],
					[-122.196055618984104, 39.272754964192117, 0.0],
					[-122.200458273287396, 39.273051994618122, 0.0],
					[-122.200446370090802, 39.274943239356418, 0.0],
					[-122.202581115772901, 39.274941480234233, 0.0],
					[-122.202602279, 39.276911041848642, 0.0],
					[-122.198797713432597, 39.277057527047702, 0.0],
					[-122.198904661909097, 39.279832955216413, 0.0],
					[-122.200590616007105, 39.279882764895589, 0.0],
				],
			],
			[
				[
					[-122.198149539493699, 39.545222192334649, 0.0],
					[-122.197063048353002, 39.545201213452501, 0.0],
					[-122.196232623544503, 39.540721491420797, 0.0],
					[-122.194831629036599, 39.540658093592683, 0.0],
					[-122.194832492140307, 39.538871737649657, 0.0],
					[-122.193485503939797, 39.538786211770912, 0.0],
					[-122.193308031110007, 39.534052598832901, 0.0],
					[-122.189686309052107, 39.534064512040622, 0.0],
					[-122.1895723098594, 39.531588100239468, 0.0],
					[-122.185200082200495, 39.531472232952602, 0.0],
					[-122.185154931106595, 39.530839770422567, 0.0],
					[-122.185721093751994, 39.530785586444537, 0.0],
					[-122.185750192503804, 39.530239531859792, 0.0],
					[-122.186631300779695, 39.530226359060769, 0.0],
					[-122.186658582068702, 39.529088467741268, 0.0],
					[-122.1852516769696, 39.529079569430259, 0.0],
					[-122.1851348902577, 39.528458261962427, 0.0],
					[-122.183774096064894, 39.52839165834807, 0.0],
					[-122.183715805710904, 39.525721053058781, 0.0],
					[-122.185097642410298, 39.525662282059933, 0.0],
					[-122.185180394923293, 39.524217818240587, 0.0],
					[-122.187074805675607, 39.524115834897543, 0.0],
					[-122.187046603001704, 39.52210244748413, 0.0],
					[-122.184505659427103, 39.522033966387468, 0.0],
					[-122.184577357836503, 39.520490287469393, 0.0],
					[-122.189448019207305, 39.516968476596972, 0.0],
					[-122.1902329127211, 39.516247129321513, 0.0],
					[-122.192563356469407, 39.511713417644401, 0.0],
					[-122.192953318744003, 39.509595625030109, 0.0],
					[-122.196816090322997, 39.509552458289427, 0.0],
					[-122.196885367579696, 39.511634974964721, 0.0],
					[-122.205140002946194, 39.511589127113631, 0.0],
					[-122.207782847002306, 39.511086051441673, 0.0],
					[-122.210225717836394, 39.513738292134363, 0.0],
					[-122.212343885848199, 39.516331949303897, 0.0],
					[-122.212316561403895, 39.52110351151844, 0.0],
					[-122.214468701267606, 39.521159533103088, 0.0],
					[-122.214324728688993, 39.522926129057559, 0.0],
					[-122.214483975620894, 39.531472128771398, 0.0],
					[-122.212691298488593, 39.531701162219314, 0.0],
					[-122.2126667694357, 39.53249799218014, 0.0],
					[-122.212196469346694, 39.532519739632242, 0.0],
					[-122.212326572013097, 39.538939254446198, 0.0],
					[-122.202919333614901, 39.539028207009082, 0.0],
					[-122.202973350210399, 39.539818776713652, 0.0],
					[-122.200515965800406, 39.539904592313597, 0.0],
					[-122.200682572686702, 39.542390265241089, 0.0],
					[-122.199502590313401, 39.542571461654958, 0.0],
					[-122.199441406396701, 39.544776114489053, 0.0],
					[-122.198244617268301, 39.544799688475592, 0.0],
					[-122.198149539493699, 39.545222192334649, 0.0],
				],
			],
			[
				[
					[-122.174187875233699, 39.75263787795587, 0.0],
					[-122.174134986311302, 39.750874778344318, 0.0],
					[-122.17178486249, 39.750788910889582, 0.0],
					[-122.171608777331301, 39.747264012612689, 0.0],
					[-122.168864444187903, 39.747177276170433, 0.0],
					[-122.168751282794204, 39.743476932921972, 0.0],
					[-122.176704920473796, 39.743567716551951, 0.0],
					[-122.176697281941998, 39.740035713744547, 0.0],
					[-122.166060900187304, 39.739687508513867, 0.0],
					[-122.166054340684397, 39.736196761321523, 0.0],
					[-122.168802702842996, 39.736243619969819, 0.0],
					[-122.178041384542993, 39.739950765151988, 0.0],
					[-122.178042704899099, 39.736251053756838, 0.0],
					[-122.187217258165703, 39.73643513620253, 0.0],
					[-122.187832783598594, 39.734325190091432, 0.0],
					[-122.1964980372111, 39.734326381044497, 0.0],
					[-122.196994754266299, 39.740177560302342, 0.0],
					[-122.205639661053695, 39.740483955222473, 0.0],
					[-122.2055818227701, 39.743833065336688, 0.0],
					[-122.215432550829703, 39.743878675046638, 0.0],
					[-122.216333726704406, 39.751512179539233, 0.0],
					[-122.216772515856903, 39.754469659302579, 0.0],
					[-122.225221627547896, 39.75861857722176, 0.0],
					[-122.224722211747604, 39.760804255658769, 0.0],
					[-122.219990040008398, 39.760721777146941, 0.0],
					[-122.220097376399096, 39.75836371990826, 0.0],
					[-122.215815134206494, 39.758239411841792, 0.0],
					[-122.205068926998806, 39.758206309425567, 0.0],
					[-122.201387644997396, 39.758207671219438, 0.0],
					[-122.201282844029805, 39.762075275535608, 0.0],
					[-122.196810639200606, 39.762067974053643, 0.0],
					[-122.196538972062498, 39.754651400322203, 0.0],
					[-122.183637865494205, 39.754483484246443, 0.0],
					[-122.183470893149305, 39.753709003525437, 0.0],
					[-122.1847554416142, 39.753796559649082, 0.0],
					[-122.184979158443497, 39.753237969207383, 0.0],
					[-122.182408810965299, 39.75280806921586, 0.0],
					[-122.1819040519445, 39.754356909655741, 0.0],
					[-122.180170801131098, 39.755689008156899, 0.0],
					[-122.177985675952002, 39.754576036118507, 0.0],
					[-122.174187875233699, 39.75263787795587, 0.0],
				],
			],
			[
				[
					[-122.003308532316296, 39.741982645207273, 0.0],
					[-122.002288521203695, 39.740722216075817, 0.0],
					[-122.005620973355704, 39.739876178891677, 0.0],
					[-122.0157219457937, 39.73707119512504, 0.0],
					[-122.020751940542397, 39.747406722550238, 0.0],
					[-122.018386614188699, 39.748285983883662, 0.0],
					[-122.017755800742904, 39.747099073330759, 0.0],
					[-122.015716577170494, 39.747652375727547, 0.0],
					[-122.015311738427599, 39.747215742543951, 0.0],
					[-122.013306357132095, 39.747899537306523, 0.0],
					[-122.012569291176803, 39.746456370108767, 0.0],
					[-122.011925582587693, 39.746654864101913, 0.0],
					[-122.009245688748607, 39.74739744691253, 0.0],
					[-122.009033620917194, 39.746956341286072, 0.0],
					[-122.006381084504497, 39.747748472281323, 0.0],
					[-122.004466462640295, 39.744534563008131, 0.0],
					[-122.007411355399, 39.743751641228982, 0.0],
					[-122.006910238601506, 39.742717085189973, 0.0],
					[-122.004515912220199, 39.741829048779429, 0.0],
					[-122.003308532316296, 39.741982645207273, 0.0],
				],
			],
			[
				[
					[-121.814723053556236, 39.714381852854544, 0.0],
					[-121.814704644570398, 39.714351761502712, 0.0],
					[-121.817506953016206, 39.712320121496496, 0.0],
					[-121.821457555517796, 39.711426146664657, 0.0],
					[-121.825142273731899, 39.711392469525123, 0.0],
					[-121.825396909074499, 39.715347162327511, 0.0],
					[-121.835025458384706, 39.7183055656566, 0.0],
					[-121.836765770667, 39.716527740558199, 0.0],
					[-121.837005707134395, 39.716315761382553, 0.0],
					[-121.837241579196998, 39.716146040755348, 0.0],
					[-121.839696926175094, 39.717335827584968, 0.0],
					[-121.841912273572106, 39.719211937662642, 0.0],
					[-121.842850864352101, 39.717358457260552, 0.0],
					[-121.844027941714401, 39.714305938824367, 0.0],
					[-121.846563131028404, 39.714751898423479, 0.0],
					[-121.848262798572705, 39.713680710509273, 0.0],
					[-121.848305867535203, 39.713637630089991, 0.0],
					[-121.852520204594398, 39.717981845347573, 0.0],
					[-121.853198638657204, 39.718662578283592, 0.0],
					[-121.853848168901905, 39.722533125095808, 0.0],
					[-121.855023993837094, 39.722691463138553, 0.0],
					[-121.855379641720603, 39.72416566826265, 0.0],
					[-121.855333806724545, 39.724192802201024, 0.0],
					[-121.859014579807393, 39.723948232423133, 0.0],
					[-121.863971234804495, 39.723398803444248, 0.0],
					[-121.870050820996994, 39.722915500632787, 0.0],
					[-121.881774435891202, 39.728808315926727, 0.0],
					[-121.881706943747503, 39.728988080374151, 0.0],
					[-121.882234437857903, 39.738914750111618, 0.0],
					[-121.882924471045897, 39.742396837321373, 0.0],
					[-121.882107281929905, 39.746651303306791, 0.0],
					[-121.883458803416502, 39.748634577163642, 0.0],
					[-121.878100781798381, 39.751289304507146, 0.0],
					[-121.879491602311504, 39.753269244415307, 0.0],
					[-121.883495870371604, 39.757500880314353, 0.0],
					[-121.887171490855593, 39.761755274615183, 0.0],
					[-121.885289833072406, 39.764353535189741, 0.0],
					[-121.884672778374195, 39.764773141988456, 0.0],
					[-121.886965892910794, 39.768050760815228, 0.0],
					[-121.8981928697808, 39.768121825368503, 0.0],
					[-121.898467273299403, 39.770956287304827, 0.0],
					[-121.898237866421098, 39.770948433015448, 0.0],
					[-121.896226627353798, 39.771601529111329, 0.0],
					[-121.894644549105607, 39.773131671783979, 0.0],
					[-121.892630293444299, 39.778001329846163, 0.0],
					[-121.891277188268802, 39.779042415652839, 0.0],
					[-121.889796768803507, 39.780692132863827, 0.0],
					[-121.889056633269007, 39.78165441568148, 0.0],
					[-121.888035602021205, 39.782813141044009, 0.0],
					[-121.887827758322928, 39.782885843207147, 0.0],
					[-121.903263757375498, 39.797539894568487, 0.0],
					[-121.908060207699194, 39.804503772958043, 0.0],
					[-121.914486641764597, 39.809353680245692, 0.0],
					[-121.920511362767698, 39.817100813007052, 0.0],
					[-121.921737069690195, 39.818666016328841, 0.0],
					[-121.915520715998795, 39.820548215704477, 0.0],
					[-121.906651888244994, 39.820630588711047, 0.0],
					[-121.902268861667196, 39.820710735396837, 0.0],
					[-121.892176732170498, 39.818992329755318, 0.0],
					[-121.884942420530905, 39.821030175247749, 0.0],
					[-121.878420432119896, 39.821736677565582, 0.0],
					[-121.874648271890294, 39.819232627082627, 0.0],
					[-121.874036018459606, 39.817745450009319, 0.0],
					[-121.873932881612205, 39.815475392287738, 0.0],
					[-121.881417656664397, 39.808536339577408, 0.0],
					[-121.875675346782998, 39.802389178391387, 0.0],
					[-121.883825844558402, 39.79670201204339, 0.0],
					[-121.885483253368903, 39.791596977811068, 0.0],
					[-121.868720074967598, 39.781045619319102, 0.0],
					[-121.868814768075268, 39.780978991678708, 0.0],
					[-121.8680290203352, 39.780495945971388, 0.0],
					[-121.867196267516107, 39.77978439318079, 0.0],
					[-121.867188609677299, 39.775491124479068, 0.0],
					[-121.859260623304706, 39.77575204653288, 0.0],
					[-121.857863138743198, 39.775737859542481, 0.0],
					[-121.8580116523122, 39.778159250449107, 0.0],
					[-121.853310724080202, 39.776505015755447, 0.0],
					[-121.849834672557861, 39.776480191591602, 0.0],
					[-121.844935639371243, 39.776071181399764, 0.0],
					[-121.839373100762302, 39.777534528974975, 0.0],
					[-121.836913586121497, 39.777641333851022, 0.0],
					[-121.832026238971906, 39.776711830381657, 0.0],
					[-121.832035255949705, 39.776800310573172, 0.0],
					[-121.820056092605597, 39.774670156409528, 0.0],
					[-121.815361552098096, 39.77374391601753, 0.0],
					[-121.812231689087795, 39.774205677443469, 0.0],
					[-121.807960473067993, 39.769763947987173, 0.0],
					[-121.805253631104307, 39.767265411267957, 0.0],
					[-121.80256400222811, 39.763743168065531, 0.0],
					[-121.799118343584695, 39.765135465825132, 0.0],
					[-121.790150381835701, 39.765779565379901, 0.0],
					[-121.777151240490895, 39.766559581792123, 0.0],
					[-121.771192946051201, 39.767527528966667, 0.0],
					[-121.764152104399201, 39.76784692895351, 0.0],
					[-121.757107984628306, 39.770756143610079, 0.0],
					[-121.756327923329096, 39.768905429764473, 0.0],
					[-121.761807154585199, 39.766180258120521, 0.0],
					[-121.762529868872093, 39.765625700280751, 0.0],
					[-121.757904310330602, 39.759008229467348, 0.0],
					[-121.771502551377097, 39.758785778843503, 0.0],
					[-121.774151935372402, 39.756752083496472, 0.0],
					[-121.775116574555199, 39.754717374853648, 0.0],
					[-121.778417683136894, 39.753775746302992, 0.0],
					[-121.778000674254301, 39.750618091896953, 0.0],
					[-121.779143374059203, 39.745698918605697, 0.0],
					[-121.780078326561593, 39.744623647782909, 0.0],
					[-121.786809020692601, 39.743503438334947, 0.0],
					[-121.793458429009704, 39.743121167634712, 0.0],
					[-121.798846433707595, 39.742176301624703, 0.0],
					[-121.799831785180004, 39.740936012801825, 0.0],
					[-121.799564875855296, 39.740969713850987, 0.0],
					[-121.795747491418396, 39.739636054573467, 0.0],
					[-121.794684255930918, 39.733676680718361, 0.0],
					[-121.786805689888894, 39.732943795188078, 0.0],
					[-121.786224098076005, 39.733012229528342, 0.0],
					[-121.785993630645507, 39.73304386185719, 0.0],
					[-121.776529758533897, 39.733393001262108, 0.0],
					[-121.7745420039479, 39.733399393750886, 0.0],
					[-121.772450155082694, 39.734529794922167, 0.0],
					[-121.766646097169001, 39.735645064367887, 0.0],
					[-121.7626455842951, 39.736036773857499, 0.0],
					[-121.759488499106197, 39.736030284005608, 0.0],
					[-121.757553857835504, 39.736329381623513, 0.0],
					[-121.757874360665895, 39.733195915599488, 0.0],
					[-121.767674700901395, 39.7324619218926, 0.0],
					[-121.772115409927594, 39.732245999209709, 0.0],
					[-121.773329038898297, 39.731837417057797, 0.0],
					[-121.773358321963002, 39.727442491451193, 0.0],
					[-121.773286711546305, 39.726293711949467, 0.0],
					[-121.791948389938568, 39.725908722638337, 0.0],
					[-121.789832366756798, 39.725131215179047, 0.0],
					[-121.791644760951201, 39.723112559366889, 0.0],
					[-121.791818415643405, 39.720750335747397, 0.0],
					[-121.7910981998984, 39.716505352545113, 0.0],
					[-121.789368519442107, 39.715973708411653, 0.0],
					[-121.789154879830804, 39.714691455624227, 0.0],
					[-121.786162398724699, 39.71299188913521, 0.0],
					[-121.793641244474202, 39.711401148593083, 0.0],
					[-121.799302129682403, 39.711455178192033, 0.0],
					[-121.798976391479698, 39.709143727165177, 0.0],
					[-121.798139097793396, 39.708468968018018, 0.0],
					[-121.797631649506911, 39.706457661989596, 0.0],
					[-121.793072778950005, 39.705425423439422, 0.0],
					[-121.7926649253411, 39.705082829864303, 0.0],
					[-121.794200790662202, 39.704254399622613, 0.0],
					[-121.792923976294105, 39.702492600885577, 0.0],
					[-121.800558033804293, 39.698609461298268, 0.0],
					[-121.802022301094993, 39.700317045601203, 0.0],
					[-121.801350656717204, 39.700812442478707, 0.0],
					[-121.804106553446601, 39.704837912214721, 0.0],
					[-121.804048376887394, 39.704837285080743, 0.0],
					[-121.803557570996801, 39.705151069930267, 0.0],
					[-121.804011319276199, 39.705663139821823, 0.0],
					[-121.803240491856499, 39.706023500505452, 0.0],
					[-121.803713322661139, 39.706715079020427, 0.0],
					[-121.809275195669798, 39.709253044584642, 0.0],
					[-121.8111124248869, 39.711151179045267, 0.0],
					[-121.8137714957182, 39.713915847774892, 0.0],
					[-121.814723053556236, 39.714381852854544, 0.0],
				],
			],
			[
				[
					[-122.095948710985994, 40.050419980896407, 0.0],
					[-122.091009894898093, 40.043951415964102, 0.0],
					[-122.091314543916397, 40.036493691834657, 0.0],
					[-122.091449878615094, 40.033694897117947, 0.0],
					[-122.092767502833297, 40.0297652444644, 0.0],
					[-122.08982260098, 40.02857158144684, 0.0],
					[-122.089526366533605, 40.020454185229411, 0.0],
					[-122.092569203602807, 40.020432987510468, 0.0],
					[-122.092509171270393, 40.018278020078007, 0.0],
					[-122.098657455572393, 40.018238429916273, 0.0],
					[-122.101523189292905, 40.024259834499837, 0.0],
					[-122.104278349172404, 40.030157225398177, 0.0],
					[-122.109374567480202, 40.030594356523963, 0.0],
					[-122.110451961363196, 40.033126856281932, 0.0],
					[-122.108046445251603, 40.034826065430977, 0.0],
					[-122.108077096832304, 40.039346652690753, 0.0],
					[-122.111540799603105, 40.042449834605591, 0.0],
					[-122.111374091214401, 40.047166274548879, 0.0],
					[-122.107395858685805, 40.050794498078133, 0.0],
					[-122.095948710985994, 40.050419980896407, 0.0],
				],
			],
			[
				[
					[-122.169465294615307, 39.918609870751652, 0.0],
					[-122.174046469499899, 39.918667498644993, 0.0],
					[-122.174131051031694, 39.920624138003667, 0.0],
					[-122.178684172687596, 39.920642835163846, 0.0],
					[-122.178670983257803, 39.918508450711613, 0.0],
					[-122.180433125549598, 39.918506758452267, 0.0],
					[-122.180457920189298, 39.918896831846702, 0.0],
					[-122.183411920847803, 39.91889838867727, 0.0],
					[-122.184317517355495, 39.919230263539127, 0.0],
					[-122.184384676308298, 39.919672191479883, 0.0],
					[-122.188174749047107, 39.919688181015843, 0.0],
					[-122.194900515157101, 39.919716756586418, 0.0],
					[-122.197498360419004, 39.919727765736582, 0.0],
					[-122.197516870560506, 39.923001816574121, 0.0],
					[-122.1988893237653, 39.923006153081232, 0.0],
					[-122.198843626779095, 39.926487278051987, 0.0],
					[-122.200104705272395, 39.926606282528908, 0.0],
					[-122.199991501255198, 39.932291377902061, 0.0],
					[-122.197676190344097, 39.932259305519374, 0.0],
					[-122.197605870128598, 39.933502375416722, 0.0],
					[-122.202469880242802, 39.933556895840653, 0.0],
					[-122.202391305097194, 39.937091417673848, 0.0],
					[-122.200510062792503, 39.937120327146097, 0.0],
					[-122.200069544316193, 39.935429394488928, 0.0],
					[-122.195097322721693, 39.93528909239577, 0.0],
					[-122.195016056113005, 39.936996577872442, 0.0],
					[-122.192762274787199, 39.937128642365757, 0.0],
					[-122.192691642865796, 39.933458775059037, 0.0],
					[-122.190438089503502, 39.933506600643248, 0.0],
					[-122.190337520125397, 39.934340598175723, 0.0],
					[-122.188112574995102, 39.934404155215631, 0.0],
					[-122.188218083622303, 39.932807327262843, 0.0],
					[-122.180839622453206, 39.932827660758392, 0.0],
					[-122.180879818748593, 39.93393753219592, 0.0],
					[-122.178889081646403, 39.93392589958794, 0.0],
					[-122.178882991643604, 39.935094676358077, 0.0],
					[-122.174457909676207, 39.935159509640251, 0.0],
					[-122.174304493451103, 39.936982211061043, 0.0],
					[-122.171896516379903, 39.936987500506923, 0.0],
					[-122.171857162500203, 39.935225017503029, 0.0],
					[-122.170708670041705, 39.935196280007013, 0.0],
					[-122.170746412700595, 39.935716745662432, 0.0],
					[-122.169515070314503, 39.935679755440212, 0.0],
					[-122.169542550026506, 39.937936269576689, 0.0],
					[-122.167186982063498, 39.937881162353342, 0.0],
					[-122.167147291487495, 39.933241072304909, 0.0],
					[-122.167089192076503, 39.931493926883057, 0.0],
					[-122.162242843199493, 39.931442754698473, 0.0],
					[-122.162271206609404, 39.926677557210589, 0.0],
					[-122.167139657850598, 39.926775212144513, 0.0],
					[-122.166973841047096, 39.920555968726291, 0.0],
					[-122.166918815686699, 39.917933871148527, 0.0],
					[-122.169395709380495, 39.917963785741783, 0.0],
					[-122.169465294615307, 39.918609870751652, 0.0],
				],
			],
			[
				[
					[-122.190164670942295, 40.193136501508178, 0.0],
					[-122.183833412152296, 40.19306573931069, 0.0],
					[-122.182404346077206, 40.190767645752679, 0.0],
					[-122.180594822502897, 40.189396190798952, 0.0],
					[-122.174305106125203, 40.189250242652463, 0.0],
					[-122.1741699013985, 40.185616772764007, 0.0],
					[-122.176276570064402, 40.184251724375343, 0.0],
					[-122.181460732051306, 40.185574001478173, 0.0],
					[-122.185640516673402, 40.184963959150373, 0.0],
					[-122.190206559286295, 40.184149414473211, 0.0],
					[-122.191418834687894, 40.185450565488033, 0.0],
					[-122.199616526135102, 40.185714101007768, 0.0],
					[-122.199884160524505, 40.184401245006427, 0.0],
					[-122.204144640298296, 40.183495409707668, 0.0],
					[-122.204392806508494, 40.179878212397327, 0.0],
					[-122.205980563563102, 40.177998128387507, 0.0],
					[-122.212640231048695, 40.177180642008231, 0.0],
					[-122.222434927399803, 40.165683546789573, 0.0],
					[-122.2237129954854, 40.156737287703947, 0.0],
					[-122.228147755678094, 40.153977126264358, 0.0],
					[-122.2322251792137, 40.149905708462718, 0.0],
					[-122.231953225342195, 40.147385396217153, 0.0],
					[-122.238002076764403, 40.147777284258403, 0.0],
					[-122.240571921351503, 40.153470489694797, 0.0],
					[-122.240991923916397, 40.151057128672633, 0.0],
					[-122.249997687239699, 40.15079054742759, 0.0],
					[-122.251117390094194, 40.160324520946922, 0.0],
					[-122.256267970139902, 40.161116973679988, 0.0],
					[-122.261790957699802, 40.164090175033749, 0.0],
					[-122.261476101750105, 40.167652961443252, 0.0],
					[-122.258679236453304, 40.171991963555172, 0.0],
					[-122.252176711709595, 40.173259161449373, 0.0],
					[-122.253389191541103, 40.176053248417659, 0.0],
					[-122.255264058116595, 40.177590890484169, 0.0],
					[-122.261875636925097, 40.184034364785397, 0.0],
					[-122.255694899654003, 40.185044163168932, 0.0],
					[-122.2558748677381, 40.181824456012151, 0.0],
					[-122.253962200177497, 40.182554754467532, 0.0],
					[-122.251417560346198, 40.183078598910051, 0.0],
					[-122.249100893863499, 40.185270466329847, 0.0],
					[-122.247023983542704, 40.186641952149479, 0.0],
					[-122.246654936897002, 40.18883640313129, 0.0],
					[-122.248457761174095, 40.18913269250033, 0.0],
					[-122.247707412140002, 40.192099812363928, 0.0],
					[-122.250515875576397, 40.192449259841197, 0.0],
					[-122.250284447652405, 40.198340626115318, 0.0],
					[-122.2461689984016, 40.198909915239199, 0.0],
					[-122.2444963764774, 40.195499369733021, 0.0],
					[-122.2382135660947, 40.196238455398948, 0.0],
					[-122.234178822537302, 40.195007044571661, 0.0],
					[-122.224206279992899, 40.188828544183032, 0.0],
					[-122.218712511120401, 40.189821057146553, 0.0],
					[-122.2166504151335, 40.190052111479908, 0.0],
					[-122.214465300180706, 40.203881773968838, 0.0],
					[-122.208869592808497, 40.208875608410217, 0.0],
					[-122.204472451761305, 40.205889903151409, 0.0],
					[-122.204514400253601, 40.203765643297537, 0.0],
					[-122.2040030775741, 40.20207875792088, 0.0],
					[-122.201710979461097, 40.201476391500513, 0.0],
					[-122.204399054624602, 40.198830084116032, 0.0],
					[-122.204296200649694, 40.193320714742107, 0.0],
					[-122.197102779500995, 40.193214675527507, 0.0],
					[-122.190164670942295, 40.193136501508178, 0.0],
				],
			],
			[
				[
					[-122.354439645042604, 41.527917553131907, 0.0],
					[-122.363123341407785, 41.522745729636746, 0.0],
					[-122.362360480692104, 41.516946252121919, 0.0],
					[-122.362301109570282, 41.481093813984103, 0.0],
					[-122.385464555048856, 41.486591021126074, 0.0],
					[-122.395338194998402, 41.493995884860468, 0.0],
					[-122.394053576068103, 41.503136405967979, 0.0],
					[-122.393322742181326, 41.508474134171998, 0.0],
					[-122.385711224600115, 41.516954868267099, 0.0],
					[-122.383373736947874, 41.525611794898794, 0.0],
					[-122.373788862956729, 41.54529837432176, 0.0],
					[-122.364579866377014, 41.54092879941404, 0.0],
					[-122.352880681946658, 41.539331320415521, 0.0],
					[-122.350061601361034, 41.534256975361387, 0.0],
					[-122.354439645042604, 41.527917553131907, 0.0],
				],
			],
		],
	},
};

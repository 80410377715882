import * as React from 'react';

import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import * as Color from '@mui/material/colors';

export default function PlanCard(props) {
	const { setUserProgress } = props;

	const handlePlanClick = () => {
		setUserProgress(7);
	};

	return (
		<Card
			sx={{
				width: '300px',
				borderTop: `5px outset ${Color.blue[700]}`,
				borderBottom: `3px inset ${Color.blue[700]}`,
				transition: 'transform 0.3s ease',
				'&:hover': {
					transform: 'translateY(-5px)',
				},
			}}
		>
			<CardContent
				sx={{
					height: '300px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Box>
					<Typography variant='h4' sx={{ fontWeight: 600, mb: 2 }}>
						Gen8 Metro 200 Plus
					</Typography>
					<Typography>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum
						elit lobortis nisi accumsan, nec lacinia ex blandit.
					</Typography>
				</Box>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
						<Typography variant='h5' sx={{ fontWeight: 600 }}>
							$89.99
						</Typography>
						<Typography sx={{ fontWeight: 600 }}>/mo</Typography>
					</Box>
					<Button
						variant='contained'
						sx={{ width: '100%', mb: 1 }}
						onClick={handlePlanClick}
					>
						<Box sx={{ display: 'flex' }}>
							Select Plan
							<ChevronRight />
						</Box>
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
}

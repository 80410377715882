import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import LandingPage from './pages/landing/LandingPage';
import InstallPage from './pages/install/InstallPage';
// import DetailsPage from './pages/details/DetailsPage';
import ContractPage from './pages/contract/ContractPage';

export default function AppContentArea(props) {
	console.log('AppContentArea render');
	return (
		<Routes>
			<Route path='/' element={<LandingPage />} />
			{/* <Route path='/details' element={<DetailsPage />} /> */}
			<Route path='/install' element={<InstallPage />} />
			<Route path='/contract' element={<ContractPage />} />
		</Routes>
	);
}

import * as React from 'react';

import { highResLidar } from './data/highResLidar';
import { lowResLidar } from './data/lowResLidar';
import { metro } from './data/metro';

import { GeoJsonLayer } from '@deck.gl/layers';

/* Lidar and metro layers */
export default React.memo(function MiscLayers(props) {
	console.log('MiscLayers render');
	const { setMiscLayers } = props;

	React.useEffect(() => {
		console.log('initializing high res lidar layer');
		const highResLidarLayer = new GeoJsonLayer({
			id: 'high-res-lidar',
			data: highResLidar,
			pickable: true,
			getFillColor: [255, 255, 0, 0],
		});

		console.log('initializing low res lidar layer');
		const lowResLidarLayer = new GeoJsonLayer({
			id: 'low-res-lidar',
			data: lowResLidar,
			pickable: true,
			getFillColor: [0, 255, 255, 0],
		});

		console.log('initializing metro layer');
		const metroLayer = new GeoJsonLayer({
			id: 'metro-layer',
			data: metro,
			pickable: true,
			getFillColor: [255, 0, 255, 0],
		});

		setMiscLayers([highResLidarLayer, lowResLidarLayer, metroLayer]);
	}, []); // empty dependency array - we only want this to run once

	return null;
});
